import * as React from 'react';
import Logo from 'assets/files/logo.png';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Preloader from '../preloader';

const Header = ({ isHomePagePreloaderVisible }) => (
  <div className="header">
    <Link className="header__link" to="/">
      <img alt="Rodzinne inwestycje" className="header__logo" src={Logo} />
    </Link>
    {isHomePagePreloaderVisible && <Preloader />}
  </div>
);

Header.propTypes = {
  isHomePagePreloaderVisible: PropTypes.bool.isRequired,
};

export default Header;
