import API from 'shared/connectors/config';

export const getSettlements = (page) => API.get(`settlement?page=${page}`,);

export const getSecondarySettlements = ({ secondary }) => API.get(`settlement${secondary && '?secondary=true'}`);

export const getPlotsBySettlementId = id => API.get(`plots/list/${id}`);

export const getPlotsByFilter = params => API.get(`plots/list/all`, { params });

export const getPlots = params => API.get(`plots`, { params });

export const getPlotDetails = id => API.get(`plot/${id}`);

export const getPlotsOptions = () => API.get(`plots/label`);

export const getSettlementsOptions = () => API.get(`settlements/label`);

export const getPdfContract = (statusUuid, customDate) =>
  API.get(`pdf?customDate=${customDate}&status_uuid=${statusUuid}`, { responseType: 'blob' });

export const setPurchaseDate = (statusUuid, date) => API.put(`status/purchase/${statusUuid}`, { date });

export const saveFiles = (file, { file_format, file_name, settlement_uuid }) =>
  API.post(
    `files?${settlement_uuid ? `settlement_uuid=${settlement_uuid}` : ''}&file_format=${file_format}&file_name=${file_name}`,
    file,
  );

export const deleteFile = uuid => {
  API.get(`files/delete/${uuid}`);
};
export const addSettlement = (data) =>
  API.post(
    `settlement`,
    data,
  );
// export const addSettlement = data => {
//   API.post('settlement', data);
// };

export const getFilesByStatus = statusUuid => API.get(`files/plot/${statusUuid}`);

export const getArchivedSettlements = () => API.get('settlement/plots/archiving');

export const changeArchving = (uuid, is_archiving) => API.post(`settlement/archiving/${uuid}`, is_archiving);
