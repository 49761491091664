const SETTLEMENTS = {
  GET_SETTLEMENTS_INIT: 'GET_SETTLEMENTS_INIT',
  GET_SETTLEMENTS_SUCCESS: 'GET_SETTLEMENTS_SUCCESS',
  GET_SETTLEMENTS_FAILURE: 'GET_SETTLEMENTS_FAILURE',
  GET_SETTLEMENTS_OPTIONS_INIT: 'GET_SETTLEMENTS_OPTIONS_INIT',
  GET_SETTLEMENTS_OPTIONS_SUCCESS: 'GET_SETTLEMENTS_OPTIONS_SUCCESS',
  GET_SETTLEMENTS_OPTIONS_FAILURE: 'GET_SETTLEMENTS_OPTIONS_FAILURE',
  GET_PLOTS_BY_ID_INIT: 'GET_PLOTS_BY_ID_INIT',
  GET_PLOTS_BY_ID_SUCCESS: 'GET_PLOTS_BY_ID_SUCCESS',
  GET_PLOTS_BY_ID_FAILURE: 'GET_PLOTS_BY_ID_FAILURE',
  GET_SECONDARY_SETTLEMENTS_INIT: 'GET_SECONDARY_SETTLEMENTS_INIT',
  GET_SECONDARY_SETTLEMENTS_SUCCESS: 'GET_SECONDARY_SETTLEMENTS_SUCCESS',
  GET_SECONDARY_SETTLEMENTS_FAILURE: 'GET_SECONDARY_SETTLEMENTS_FAILURE',
  GET_PLOTS_INIT: 'GET_PLOTS_INIT',
  GET_PLOTS_SUCCESS: 'GET_PLOTS_SUCCESS',
  GET_PLOTS_FAILURE: 'GET_PLOTS_FAILURE',
  GET_PLOT_DETAILS_INIT: 'GET_PLOT_DETAILS_INIT',
  GET_PLOT_DETAILS_SUCCESS: 'GET_PLOT_DETAILS_SUCCESS',
  GET_PLOT_DETAILS_FAILURE: 'GET_PLOT_DETAILS_FAILURE',
  RESERVATION_PLOT_INIT: 'RESERVATION_PLOT_INIT',
  RESERVATION_PLOT_SUCCESS: 'RESERVATION_PLOT_SUCCESS',
  RESERVATION_PLOT_FAILURE: 'RESERVATION_PLOT_FAILURE',
  GET_PLOTS_BY_FILTER_INIT: 'GET_PLOTS_BY_FILTER_INIT',
  GET_PLOTS_BY_FILTER_SUCCESS: 'GET_PLOTS_BY_FILTER_SUCCESS',
  GET_PLOTS_BY_FILTER_FAILURE: 'GET_PLOTS_BY_FILTER_FAILURE',
  GET_PLOTS_OPTIONS_INIT: 'GET_PLOTS_OPTIONS_INIT',
  GET_PLOTS_OPTIONS_SUCCESS: 'GET_PLOTS_OPTIONS_SUCCESS',
  GET_PLOTS_OPTIONS_FAILURE: 'GET_PLOTS_OPTIONS_FAILURE',
  GET_PDF_CONTRACT_INIT: 'GET_PDF_CONTRACT_INIT',
  GET_PDF_CONTRACT_SUCCESS: 'GET_PDF_CONTRACT_SUCCESS',
  GET_PDF_CONTRACT_FAILURE: 'GET_PDF_CONTRACT_FAILURE',
  FILTERS_DATA_INIT: 'FILTERS_DATA_INIT',
  FILTERS_DATA_SUCCESS: 'FILTERS_DATA_SUCCESS',
  FILTERS_DATA_FAILURE: 'FILTERS_DATA_FAILURE',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',
  GET_FILES_BY_STATUS_INIT: 'GET_FILES_BY_STATUS_INIT',
  GET_FILES_BY_STATUS_SUCCESS: 'GET_FILES_BY_STATUS_SUCCESS',
  GET_FILES_BY_STATUS_FAILURE: 'GET_FILES_BY_STATUS_FAILURE',
  ADD_SETTLEMENTS_SUCCESS: 'ADD_SETTLEMENTS_SUCCESS',
  ADD_SETTLEMENTS_FAILURE: 'ADD_SETTLEMENTS_FAILURE',
  GET_ARCHIVED_SETTLEMENTS_INIT: 'GET_ARCHIVED_SETTLEMENTS_INIT',
  GET_ARCHIVED_SETTLEMENTS_SUCCESS: 'GET_ARCHIVED_SETTLEMENTS_SUCCESS',
  GET_ARCHIVED_SETTLEMENTS_FAILURE: 'GET_ARCHIVED_SETTLEMENTS_FAILURE',
  CHANGE_ARCHVING_INIT: "CHANGE_ARCHVING_INIT",
  CHANGE_ARCHVING_SUCCESS: "CHANGE_ARCHVING_SUCCESS",
  CHANGE_ARCHVING_FAILURE:"CHANGE_ARCHVING_FAILURE",
};

export default SETTLEMENTS;
