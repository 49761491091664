import * as React from 'react';
import PropTypes from 'prop-types';
import { FIELD_NAMES } from 'shared/consts';
import Label from './components/label';

const RegularField = ({
  type,
  field: { name, onChange, value },
  field,
  form,
  errors,
  placeholder,
  label,
  fullWidth,
  className,
  onBlur,
  maxLength,
  inputOnChange,
  onKeyDown,
}) => {
  const isError = !!(form.touched[name] && errors[name]);
  const fieldClasses = `regular-field__input ${isError ? 'regular-field__input--error' : ''} ${className}`;
  return (
    <div className="regular-field">
      <div className={`regular-field__container ${fullWidth ? 'regular-field__container--full-width' : ''}`}>
        <Label errors={errors} field={field} isError={isError} label={label} />
        <div className={fieldClasses}>
          {type === 'textarea' ? (
            <textarea
              name={name}
              placeholder={placeholder}
              onBlur={onBlur}
              value={value}
              onChange={e => {
                onChange(e);
                inputOnChange && inputOnChange(e);
              }}
            />
          ) : (
            <input
              autoComplete={name === FIELD_NAMES.PASSWORD ? 'on' : 'new-password'}
              name={name}
              // onChange={onChange}
              onChange={e => {
                onChange(e);
                inputOnChange && inputOnChange(e);
              }}
              placeholder={placeholder}
              type={type}
              value={value}
              onBlur={onBlur}
              maxLength={maxLength}
              onKeyDown={onKeyDown}
            />
          )}
        </div>
      </div>
    </div>
  );
};
RegularField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  inputOnChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  onBlur: PropTypes.string.isRequired,
  onKeyDown:PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
};

RegularField.defaultProps = {
  className: '',
  fullWidth: false,
  placeholder: '',
};

export default RegularField;
