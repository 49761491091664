import React from 'react';
import UserIcon from 'assets/files/user-icon.svg';
import { string } from 'prop-types';

function UserInfo({ firstName, lastName }) {
  return (
    <div className="dashboard-header__left">
      <div className="dashboard-header__user-icon">
        <img alt="Użytkownik" src={UserIcon} />
      </div>
      <div className="dashboard-header__fullname">
        {firstName}
        <br />
        {lastName}
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
};

export default UserInfo;
