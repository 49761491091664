const EMPLOYEE_LABELS = {
  ADMIN: 'Admin',
  DDI: 'DDI',
  ADMINISTRATION: 'Administracja',
  ACCOUNTING: 'Księgowość',
  MARKETING: 'Marketing',
  ADVISER: 'Doradcy',
  DIR: 'Dir',
  DP: 'Dp',
};

export default EMPLOYEE_LABELS;
