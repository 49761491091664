import { connect } from 'react-redux';
import * as Actions from 'store/actions/start';
import { offerChangeStatus } from 'store/actions/offer';
import { getPlots } from 'store/actions/settlements';
import * as Selectors from 'store/selectors/startSelector';
import { getAuthorizedUser } from 'store/selectors/authSelector';
import Start from './Start.main';

const mapDispatchToProps = {
  getNews: () => Actions.getNews(),
  getSingleNews: uuid => Actions.getSingleNews(uuid),
  createNews: (title, text) => Actions.createNews(title, text),
  deleteNews: uuid => Actions.deleteNews(uuid),
  editNews: (uuid, title, text) => Actions.editNews(uuid, title, text),
  getStatuses: () => Actions.getStatuses(),
  getOffers: () => Actions.getOffers(),
  offerChangeStatus: dataForm => offerChangeStatus(dataForm),
  deleteAcceptedOffer: uuid => Actions.deleteAcceptedOffer(uuid),
  getPlots: params => getPlots(params),
};

const mapStateToProps = state => ({
  news: Selectors.getNews(state),
  authorizedUser: getAuthorizedUser(state),
  singleNews: Selectors.getSingleNews(state),
  statuses: Selectors.getStatuses(state),
  offers: Selectors.getOffers(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Start);
