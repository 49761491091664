import { SETTLEMENTS } from 'store/types';

// const init = () => ({
//   type: SETTLEMENTS.FILTERS_DATA_INIT,
// });

export const success = data => ({
  type: SETTLEMENTS.FILTERS_DATA_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SETTLEMENTS.FILTERS_DATA_FAILURE,
});

const savePlotFilters = data => async dispatch => {
  // dispatch(init());
  try {
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default savePlotFilters;
