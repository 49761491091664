import React, { useState } from 'react';
import { UserBar, File, RegularField, Button } from 'shared/components';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';

const PandingCashout = ({ plot, isAccountant, isUserBooking, setPurchaseDate }) => {
  const {
    order_status: { investor, ddi },
  } = plot;
  const [date, setDate] = useState(moment(plot?.order_status?.date_of_purchase).format('YYYY-MM-DD'));
  return (
    <div className="preBooking">
      {isAccountant && isUserBooking && (
        <UserBar type="INWST" name={`${investor.first_name} ${investor.last_name}`} phone={investor.phone} />
      )}
      {!isUserBooking && <UserBar type="DDI" name={`${ddi.first_name} ${ddi.last_name}`} phone={ddi.phone} />}
      {isAccountant ? (
        <>
          <p className="preBooking--paragraph">
            Klient ma czas na dostarczenie danych do umowy i wpłatę zadatku. Po tym czasie rezerwacja może zostać zdjęta.
          </p>
          <div className="preBooking--files">
            <File label={plot?.order_status?.files[0].file_name} path={plot?.order_status?.files[0].access_path} />
            <File label={plot?.order_status?.files[1].file_name} path={plot?.order_status?.files[1].access_path} />
            <Formik
              initialValues={{
                statusUuid: plot?.order_status?.uuid,
                date,
              }}
              onSubmit={values => {
                setPurchaseDate(values.statusUuid, values.date);
              }}
            >
              {({ handleSubmit, errors }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <Field
                      component={RegularField}
                      errors={errors}
                      label="Przewidywana data spłaty"
                      name="date"
                      inputOnChange={e => setDate(e.target.value)}
                      type="date"
                      fullWidth
                    />
                    <Button label="Zmień datę" type="button" classNames="orange" onClick={handleSubmit} />
                  </form>
                </>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <p className="preBooking--paragraph">
          Po anulowaniu wstępnej rezerwacji, działka może trafic dokolejnego DDI na liście oczekujących
        </p>
      )}
    </div>
  );
};

PandingCashout.propTypes = {
  isAccountant: PropTypes.bool.isRequired,
  isUserBooking: PropTypes.bool.isRequired,
  plot: PropTypes.array.isRequired,
  setPurchaseDate: PropTypes.func.isRequired,
};

export default PandingCashout;
