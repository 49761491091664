import React from 'react';
import { Badge } from 'shared/components';
import { EMPLOYEE_SHORTCUTS, USER_ROLES } from 'shared/consts';

const ROlE_OPTIONS = [
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.DDI} type={EMPLOYEE_SHORTCUTS.DDI} />
        Dział sprzedaży
      </div>
    ),
    value: USER_ROLES.DDI,
    searchableLabel: 'Dział sprzedaży',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.ADVISER} type={EMPLOYEE_SHORTCUTS.ADVISER} />
        Doradca
      </div>
    ),
    value: USER_ROLES.ADVISER,
    searchableLabel: 'Doradca',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.ACCOUNTING} type={EMPLOYEE_SHORTCUTS.ACCOUNTING} />
        Księgowość
      </div>
    ),
    value: USER_ROLES.ACCOUNTING,
    searchableLabel: 'Księgowość',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.ADMINISTRATION} type={EMPLOYEE_SHORTCUTS.ADMINISTRATION} />
        Administracja
      </div>
    ),
    value: USER_ROLES.ADMINISTRATION,
    searchableLabel: 'Administracja',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.MARKETING} type={EMPLOYEE_SHORTCUTS.MARKETING} />
        Marketing
      </div>
    ),
    value: USER_ROLES.MARKETING,
    searchableLabel: 'Marketing',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.ADMIN} type={EMPLOYEE_SHORTCUTS.ADMIN} />
        Admin
      </div>
    ),
    value: USER_ROLES.ADMIN,
    searchableLabel: 'Admin',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.DIR} type={EMPLOYEE_SHORTCUTS.DIR} />
        Dir
      </div>
    ),
    value: USER_ROLES.DIR,
    searchableLabel: 'Dir',
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={EMPLOYEE_SHORTCUTS.DP} type={EMPLOYEE_SHORTCUTS.DP} />
        Dp
      </div>
    ),
    value: USER_ROLES.DP,
    searchableLabel: 'Dp',
  },
];

export default ROlE_OPTIONS;
