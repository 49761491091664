import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.GET_ARCHIVED_EMPLOYEE_INIT,
});

export const success = data => ({
  type: USER.GET_ARCHIVED_EMPLOYEE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.GET_ARCHIVED_EMPLOYEE_FAILURE,
});

const getArchivedEmployee = uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getArchivedEmployee(uuid);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getArchivedEmployee;
