import { SETTLEMENTS } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  settlements: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  secondarySettlements: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  plots: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  plot: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  plotsOptions: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  plotsByFilter: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  settlementsOptions: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  filters: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  files: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  filesByStatus: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedSettlements: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const settlementsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case SETTLEMENTS.ADD_SETTLEMENTS_SUCCESS: {
      console.log(payload)
      console.log([...state.settlements.data.data, payload.data])
      return {
        ...state,
        settlements: {
          data: {
            data:[...state.settlements.data.data, payload.data.data],
          },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.ADD_SETTLEMENTS_FAILURE: {
      return {
        ...state,
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_INIT: {
      return {
        ...state,
        settlements: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        settlements: {
          data: {...payload.settlements },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_FAILURE: {
      return {
        ...state,
        settlements: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_ARCHIVED_SETTLEMENTS_INIT: {
      return {
        ...state,
        archivedSettlements: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_ARCHIVED_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        archivedSettlements: {
          data: {
            data: payload.archivedSettlements,
          },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_ARCHIVED_SETTLEMENTS_FAILURE: {
      return {
        ...state,
        archivedSettlements: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_OPTIONS_INIT: {
      return {
        ...state,
        settlementsOptions: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_OPTIONS_SUCCESS: {
      return {
        ...state,
        settlementsOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_SUCCESS_FAILURE: {
      return {
        ...state,
        settlementsOptions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.CHANGE_ARCHVING_SUCCESS: {
      return {
        ...state,
        // archivedSettlements: {
        //   data: {
        //     data: payload.archivedSettlements,
        //   },
        //   status: REQUEST_STATUS.SUCCESS,
        // },
      };
    }
    case SETTLEMENTS.CHANGE_ARCHVING_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      };
    }
    case SETTLEMENTS.GET_PLOTS_BY_ID_SUCCESS: {
      const settlements = state.settlements.data.data;
      const secondarySettlements = state.secondarySettlements.data.data;

      const isSecondary = settlements.findIndex(({ uuid }) => uuid === action.payload.uuid) === -1;

      const settlementIdx = isSecondary
        ? secondarySettlements.findIndex(({ uuid }) => uuid === action.payload.uuid)
        : settlements.findIndex(({ uuid }) => uuid === action.payload.uuid);

      const settlementscp = isSecondary ? [...secondarySettlements] : [...settlements];

      settlementscp[settlementIdx] = {
        ...settlementscp[settlementIdx],
        plots: action.payload.plots,
      };

      const key = isSecondary ? 'secondarySettlements' : 'settlements';
      return {
        ...state,
        [key]: {
          ...state[key],
          data: {
            ...state[key].data,
            data: [...settlementscp],
          },
        },
      };
    }
    case SETTLEMENTS.GET_SECONDARY_SETTLEMENTS_INIT: {
      return {
        ...state,
        secondarySettlements: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_SECONDARY_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        secondarySettlements: {
          data: payload.settlements,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_SECONDARY_SETTLEMENTS_FAILURE: {
      return {
        ...state,
        secondarySettlements: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_INIT: {
      return {
        ...state,
        plots: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_SUCCESS: {
      return {
        ...state,
        plots: {
          data: payload.plots,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_FAILURE: {
      return {
        ...state,
        plots: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_PLOT_DETAILS_INIT: {
      return {
        ...state,
        plot: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_PLOT_DETAILS_SUCCESS: {
      return {
        ...state,
        plot: {
          data: payload.plots,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_PLOT_DETAILS_FAILURE: {
      return {
        ...state,
        plot: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_BY_FILTER_INIT: {
      return {
        ...state,
        plotsByFilter: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_BY_FILTER_SUCCESS: {
      return {
        ...state,
        plotsByFilter: {
          data: payload.plots,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_BY_FILTER_FAILURE: {
      return {
        ...state,
        plotsByFilter: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_OPTIONS_INIT: {
      return {
        ...state,
        plotsOptions: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_OPTIONS_SUCCESS: {
      return {
        ...state,
        plotsOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_PLOTS_OPTIONS_FAILURE: {
      return {
        ...state,
        plotsOptions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_SETTLEMENTS_OPTIONS_FAILURE: {
      return {
        ...state,
        settlementsOptions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.FILTERS_DATA_INIT: {
      return {
        ...state,
        filters: {
          data: undefined,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.FILTERS_DATA_SUCCESS: {
      return {
        ...state,
        filters: {
          data: payload.data,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.FILTERS_DATA_FAILURE: {
      return {
        ...state,
        filters: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_FILES_BY_STATUS_INIT: {
      return {
        ...state,
        filesByStatus: {
          data: undefined,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SETTLEMENTS.GET_FILES_BY_STATUS_SUCCESS: {
      return {
        ...state,
        filesByStatus: {
          data: payload.data,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case SETTLEMENTS.GET_FILES_BY_STATUS_FAILURE: {
      return {
        ...state,
        filesByStatus: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default settlementsReducer;
