import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'shared/components';
import { AREA_TYPES } from 'shared/consts';
import * as moment from 'moment';
import { isMobile, mapRoleToShortcut } from 'shared/utils';
import Status from './components/Status';

function Left({ item, area, shortcut }) {
  const renderContent = () => {
    switch (area) {
      case AREA_TYPES.EMPLOYEES: {
        return (
          <div className="list__left-wrapper">
            <Badge label={shortcut} type={shortcut} /> {item.first_name} {item.last_name}
          </div>
        );
      }
      case AREA_TYPES.ARCHIVED_EMPLOYEES: {
        const { first_name, last_name, role } = item;
        const short = mapRoleToShortcut(role);
        return (
          <div className="list__left-wrapper">
            <Badge label={short} type={short} /> {first_name} {last_name}
          </div>
        );
      }
      case AREA_TYPES.ARCHIVED_PARTNERS: {
        return (
          <div className="list__left-wrapper">
            <Badge label="Partner" type="Partner" />
            {item.name}
          </div>
        );
      }
      case AREA_TYPES.ARCHIVED_INVESTORS: {
        return (
          <div className="list__left-wrapper">
            <Badge label="Inwestor" type="Inwst" />
            {item.first_name} {item.last_name}
          </div>
        );
      }
      case AREA_TYPES.PARTNERS: {
        return (
          <>
            <div className="list__left-wrapper">
              <Badge label="Partner" type="Partner" /> {item.name}
            </div>
            <div className="list__columns-wrapper">
              {!isMobile() && <div className="list__column">{moment(item.created_at).format('DD.MM.YYYY')}</div>}
              <div className="list__column list__column--bold">{item.provision * 100}%</div>
              <Status active={item.active} />
            </div>
          </>
        );
      }
      case AREA_TYPES.INVESTORS: {
        return (
          <div className="list__left-wrapper">
            <Badge label={shortcut} type={shortcut} />
            {item.first_name} {item.last_name}
          </div>
        );
      }
      default:
        return null;
    }
  };
  return <div className="list__left">{renderContent()}</div>;
}

Left.propTypes = {
  area: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  shortcut: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default Left;
