import React from 'react';
import PropTypes from 'prop-types';
import { Modal, UserBar, Button, InputComparison } from 'shared/components';
import { MODAL_TYPES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Header } from './components';

function AddDepositModal({ onClose, opened, title, plot, openModal, openPriceModal, authorizedUser: { uuid, role } }) {
  const isMobileDevice = isMobile();
  const isUserBooking = uuid === plot?.order_status?.ddi?.uuid;
  const isAdmin = role === 'admin';
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
            <UserBar
              type="INWST"
              name={`${plot?.order_status?.investor?.first_name} ${plot?.order_status?.investor?.last_name}`}
              phone={plot?.order_status?.investor?.phone}
            />
            <Header plot={plot} />
            <div className="historyPaymentModal__inputs">
              <InputComparison
                label="Zadatek"
                first={plot?.order_status?.payed_deposit}
                second={plot?.order_status?.offer?.deposit}
              />
              {!isUserBooking && plot?.status === 'signed_contract' && !isAdmin && (
                <Button classNames="green" label="Dodaj wpłatę" onClick={() => openPriceModal(MODAL_TYPES.PAY_FOR_PLOT)} />
              )}
              {!isUserBooking && plot?.status === 'pending_cashouts' && !isAdmin && (
                <Button classNames="green" label="Dodaj wpłatę" onClick={() => openModal(MODAL_TYPES.ADD_DEPOSIT)} />
              )}
            </div>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

AddDepositModal.propTypes = {
  authorizedUser: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  openPriceModal: PropTypes.func.isRequired,
  plot: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddDepositModal;
