const REPORT = {
  GET_REPORTS_DATA_INIT: 'GET_REPORTS_DATA_INIT',
  GET_REPORTS_DATA_SUCCESS: 'GET_REPORTS_DATA_SUCCESS',
  GET_REPORTS_DATA_FAILURE: 'GET_REPORTS_DATA_FAILURE',
  REPORTS_FILTERS_INIT: 'REPORTS_FILTERS_INIT',
  REPORTS_FILTERS_SUCCESS: 'REPORTS_FILTERS_SUCCESS',
  REPORTS_FILTERS_FAILURE: 'REPORTS_FILTERS_FAILURE',
  REPORTS_FILTERS_LIST_INIT: 'REPORTS_FILTERS_LIST_INIT',
  REPORTS_FILTERS_LIST_SUCCESS: 'REPORTS_FILTERS_LIST_SUCCESS',
  REPORTS_FILTERS_LIST_FAILURE: 'REPORTS_FILTERS_LIST_FAILURE',
  DELETE_REPORTS_SAVE: 'DELETE_REPORTS_SAVE',
  REPORTS_EXCEL_INIT: 'REPORTS_EXCEL_INIT',
  REPORTS_EXCEL_SUCCESS: 'REPORTS_EXCEL_SUCCESS',
  REPORTS_EXCEL_FAILURE: 'REPORTS_EXCEL_FAILURE',
  GET_COMPANIES_LABELS_INIT: 'GET_COMPANIES_LABELS_INIT',
  GET_COMPANIES_LABELS_SUCCESS: 'GET_COMPANIES_LABELS_SUCCESS',
  GET_COMPANIES_LABELS_FAILURE: 'GET_COMPANIES_LABELS_FAILURE',
};

export default REPORT;
