import React from 'react';
import PropTypes from 'prop-types';
import { Modal, SVG } from 'shared/components';
import { SVG_TYPE, MODAL_TYPES } from 'shared/consts';
import Map from 'screens/settlement-plots/components/map';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const MapModal = ({ onClose, opened, children, settlementId, plots, history, openModal }) => (
  // const handleClickPlot = e => {
  //   const activeElement = document.getElementsByClassName('map__plot--active');
  //   activeElement.length >= 1 && activeElement[0].classList.remove('map__plot--active');
  //   e.target.classList.add('map__plot--active');
  //   const activePlot = plots.filter(({ name }) => e.target.id === name);
  //   setPlotInfo(activePlot[0]);
  // };
  <TransitionGroup component={null}>
    {opened && (
      <CSSTransition classNames="modal__transition">
        <Modal onClose={onClose} opened={opened} type={MODAL_TYPES.MAP} className={settlementId}>
          {children}
          <div className="map-desktop--close" onClick={onClose}>
            <SVG type={SVG_TYPE.CLOSE} className="svg--exit--black" />
          </div>
          <Map
            openModal={openModal}
            history={history}
            className={`map-desktop map-desktop--${settlementId}`}
            settlementId={settlementId}
            plots={plots}
          />
        </Modal>
      </CSSTransition>
    )}
  </TransitionGroup>
);
MapModal.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  plots: PropTypes.array.isRequired,
  settlementId: PropTypes.string.isRequired,
  //   plot: PropTypes.shape({
  //     order_status: PropTypes.shape({
  //       uuid: PropTypes.string.isRequired,
  //     }).isRequired,
  //     uuid: PropTypes.string.isRequired,
  //   }).isRequired,
};

export default MapModal;
