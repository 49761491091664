import React, { useState } from 'react';
import { PageContent } from 'shared/components';
import Slider from 'react-slick';
import { bool, func } from 'prop-types';
import { object } from 'yup';
import StatusesMobile from './StatusesMobile';
import OffersMobile from './OffersMobile';
// import ListItem from 'screens/notifications-right/components/list-item/ListItem';

const MobileNews = ({
  statuses: {
    status_pre_booking,
    status_put_a_down_payment,
    status_signed_contract,
    status_pre_booking_count,
    status_signed_contract_count,
    status_put_a_down_payment_count,
  },
  offers: { offer_accepted, offer_waiting, offer_accepted_count, offer_waiting_count },
  authorizedUser,
  history,
  acceptModalOpen,
  offerChangeStatus,
}) => {
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    initialSlide: false,
    focusOnSelect: false,
    infinite: false,
    swipeToSlide: true,
    centerMode: false,
    variableWidth: true,
    swipe: true,
  };

  const statusesSliderUtils = [
    {
      status: 'preliminary',
      label: 'Wstępne rezerwacje',
      count: status_pre_booking_count,
    },
    {
      status: 'reservations',
      label: 'Oczekujące na 1-szą wpłatę',
      count: status_put_a_down_payment_count,
    },
    {
      status: 'assigned',
      label: 'Oczekujące na wpłatę do 100%',
      count: status_signed_contract_count,
    },
  ];

  const offersSliderUtils = [
    {
      status: 'waiting',
      label: 'Oczekujące',
      amount: offer_waiting_count,
    },
    {
      status: 'accepted',
      label: 'Zaakceptowane',
      amount: offer_accepted_count,
    },
  ];

  const [statusesSubpage, setStatusesSubpage] = useState('preliminary');
  const [offersSubPage, setOffersSubpage] = useState('waiting');

  return (
    <>
      <PageContent>
        <h1>Statusy</h1>
        <div className="statuses__slider">
          <Slider {...settings}>
            {statusesSliderUtils.map(({ status, label, count }) => (
              <div onClick={() => setStatusesSubpage(status)} className={status === statusesSubpage ? 'active' : ''}>
                <h3 className="statuses__slider--item">
                  <span>{count}</span> {label}
                </h3>
              </div>
            ))}
          </Slider>
        </div>

        <div className="statuses__mobileContent">
          {statusesSubpage === 'preliminary' ? (
            <ul>
              <StatusesMobile list={status_pre_booking} authorizedUser={authorizedUser} history={history} />
            </ul>
          ) : (
            ''
          )}
          {statusesSubpage === 'reservations' ? (
            <ul>
              <StatusesMobile list={status_put_a_down_payment} authorizedUser={authorizedUser} history={history} />
            </ul>
          ) : (
            ''
          )}
          {statusesSubpage === 'assigned' ? (
            <ul>
              <StatusesMobile arrow list={status_signed_contract} authorizedUser={authorizedUser} history={history} />
            </ul>
          ) : (
            ''
          )}
        </div>
        {}
        <h1>Oferty dedykowane</h1>
        <div className="offers__slider">
          <Slider {...settings}>
            {offersSliderUtils.map(({ status, label, amount }) => (
              <div onClick={() => setOffersSubpage(status)} className={status === offersSubPage ? 'active' : ''}>
                <h3 className="offers__slider--item">
                  <span>{amount}</span> {label}
                </h3>
              </div>
            ))}
          </Slider>
        </div>
        <div className="statuses__mobileContent">
          {offersSubPage === 'waiting' ? (
            <ul>
              <OffersMobile
                role={authorizedUser.role}
                list={offer_waiting}
                acceptModalOpen={acceptModalOpen}
                offerChangeStatus={offerChangeStatus}
              />
            </ul>
          ) : (
            ''
          )}
          {offersSubPage === 'accepted' ? (
            <ul>
              <OffersMobile
                ifAcceptOffers
                upload
                role={authorizedUser.role}
                list={offer_accepted}
                acceptModalOpen={acceptModalOpen}
                offerChangeStatus={offerChangeStatus}
              />
            </ul>
          ) : (
            ''
          )}
        </div>
      </PageContent>
    </>
  );
};

export default MobileNews;

MobileNews.propTypes = {
  acceptModalOpen: func.isRequired,
  authorizedUser: object.isRequired,
  isMobile: bool.isRequired,
  offerChangeStatus: func.isRequired,
  offers: object.isRequired,
  statuses: object.isRequired,
};
