import { object, objectOf, func } from 'prop-types';
import React from 'react';
import StatusesList from './StatusesList';

const Statuses = ({
  statuses: {
    status_pre_booking,
    status_put_a_down_payment,
    status_signed_contract,
    status_pre_booking_count,
    status_signed_contract_count,
    status_put_a_down_payment_count,
  },
  authorizedUser,
  history,
  getPlots,
}) => {
  const status = {
    status_pre_booking: 'status_pre_booking',
    status_put_a_down_payment: 'status_put_a_down_payment',
    status_signed_contract: 'status_signed_contract',
  };

  return (
    <>
      <div className="statuses">
        <div className="statuses__title">Statusy</div>
        <div className="statuses__content">
          <div className="statuses__content--item">
            <StatusesList
              history={history}
              title="Wstępne rezerwacje"
              list={status_pre_booking}
              authorizedUser={authorizedUser}
              count={status_pre_booking_count}
              getPlots={getPlots}
              status={status.status_pre_booking}
            />
          </div>
          <div className="statuses__content--item">
            <StatusesList
              history={history}
              title="Oczekujące na 1-szą wpłatę"
              counterClassName="reservations"
              list={status_put_a_down_payment}
              arrow
              authorizedUser={authorizedUser}
              count={status_put_a_down_payment_count}
              getPlots={getPlots}
              status={status.status_put_a_down_payment}
            />
          </div>
          <div className="statuses__content--item">
            <StatusesList
              history={history}
              title="Oczekujące na wpłatę do 100%"
              counterClassName="assigned"
              list={status_signed_contract}
              arrow
              authorizedUser={authorizedUser}
              count={status_signed_contract_count}
              getPlots={getPlots}
              status={status.status_signed_contract}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Statuses;

Statuses.propTypes = {
  authorizedUser: objectOf.isRequired,
  getPlots: func.isRequired,
  statuses: object.isRequired,
};
