import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions } from 'shared/components';
// import { MODAL_TYPES, ROLE_OPTIONS } from 'shared/consts';
import { MODAL_TYPES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function ShowModal({ onClose, opened, children, title }) {
  const isMobileDevice = isMobile();
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.SHOW}>
            {children}
            <ModalActions onClose={onClose} type={MODAL_TYPES.SHOW} />
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

ShowModal.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default ShowModal;
