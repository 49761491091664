import * as React from 'react';
import Select, { components } from 'react-select';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const HeaderSelect = ({ placeholder, options, field: { value }, onChange, id }) => {
  const customStyles = {
    container: provided => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      height: '25px',
      display: 'flex',
      justifyContent: 'space-between',
      border: '2px solid rgba(112, 112, 112, 0.3)',
      margin: '2px',
      borderRadius: '3px',
    }),

    menuList: provided => ({
      ...provided,
      // height: '500px',
      textAlign: 'left',
      fontSize: '10px',
    }),

    menu: provided => ({
      ...provided,
      borderRadius: 'unset',
      width: '175px',
      position: 'relative',
      // right: '-10px',
    }),
    option: provided => ({
      ...provided,
      height: '29px',
      cursor: 'pointer',
    }),
  };

  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <SVG type={SVG_TYPE.SMALL_ARROW} />
    </components.DropdownIndicator>
  );

  const getValue = () => options && options.find(option => option.value === value);

  const handleChange = tempValue => {
    onChange && onChange(tempValue, id);
  };

  return (
    <div className="selectWrapper">
      <Select
        options={options}
        components={{ DropdownIndicator }}
        placeholder={placeholder}
        isSearchable={false}
        styles={customStyles}
        menuPortalTarget={document.body}
        value={getValue()}
        onChange={handleChange}
      />
    </div>
  );
};

export default HeaderSelect;

HeaderSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
};
