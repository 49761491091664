import { createSelector } from 'reselect';

const getUserStore = state => state.userStore;
const getUsers = createSelector(getUserStore, ({ users: { data = [] } }) => data);
const getUsersStatus = createSelector(getUserStore, ({ users: { status } }) => status);
const getUsersAmount = createSelector(getUserStore, ({ usersAmount: { data = {} } }) => data);
const getActiveUser = createSelector(getUserStore, ({ activeUser: { data = {} } }) => data);
const getDdiOptions = createSelector(getUserStore, ({ ddiOptions: { data = [] } }) => data);
const getArchivedEmployees = createSelector(getUserStore, ({ archivedEmployees: { data = [] } }) => data);
const getAllDdi = createSelector(getUserStore, ({ allDdi: { data = [] } }) => data);
const getArchivedEmployeesStatus = createSelector(getUserStore, ({ archivedEmployees: { status } }) => status);
const getArchivedEmployee = createSelector(getUserStore, ({ archivedEmployee: { data = [] } }) => data);
const getAdvisersList = createSelector(getUserStore, ({ advisersList: { data = [] } }) => data);

export {
  getAllDdi,
  getUsers,
  getUsersAmount,
  getActiveUser,
  getUsersStatus,
  getDdiOptions,
  getArchivedEmployees,
  getArchivedEmployeesStatus,
  getArchivedEmployee,
  getAdvisersList,
};
