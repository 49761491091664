import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { EMPLOYEE_SHORTCUTS, USER_ROLES } from 'shared/consts';
import { NewUser } from 'shared/components';
import { matchUrlToRole } from 'shared/utils';
import { history } from 'store/store';
import getUsers from './getUsers';
import getUsersAmount from './getUsersAmount';

const init = () => ({
  type: USER.DELETE_USER_INIT,
});

export const success = () => ({
  type: USER.DELETE_USER_SUCCESS,
});

const failure = () => ({
  type: USER.DELETE_USER_FAILURE,
});

const deleteUser = userData => async dispatch => {
  dispatch(init());
  try {
    await Connector.deleteUser(userData);
    dispatch(getUsersAmount());
    await dispatch(getUsers(matchUrlToRole(window.location.pathname)));
    const url = window.location.pathname.split('/');
    url.pop();
    history.push(url.join('/'));
    await toast(
      <NewUser
        badgeType={EMPLOYEE_SHORTCUTS[Object.keys(USER_ROLES).find(role => USER_ROLES[role] === userData.role)]}
        first_name={userData.first_name}
        label="Usunięto użytkownika"
        last_name={userData.last_name}
      />,
    );
    dispatch(success());
  } catch (err) {
    dispatch(failure());
  }
};

export default deleteUser;
