/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, Button, RegularField } from 'shared/components';
import { Field, Formik, Form as FormikForm } from 'formik';
import { SVG_TYPE } from 'shared/consts';

import Tile from './Tile';
import { SORT_PLOT_BY_ARR } from '../consts';

function Form({ onSubmit, onClose, onChange, plots, plotFilters }) {
  const handleSortingContent = values => {
    // console.log(values);
    // console.log(plots);
    const sortBy = values.value;
    switch (sortBy) {
      case 'plotSizeDesc':
        if (plots) {
          const sorted = plots.sort((a, b) => a.plot_size - b.plot_size);
          onChange(sorted);
        }
        break;
      case 'plotSizeAsc':
        if (plots) {
          const sorted = plots.sort((a, b) => b.plot_size - a.plot_size);
          onChange(sorted);
        }
        break;
      case 'priceDesc':
        if (plots) {
          const sorted = plots.sort((a, b) => a.plot_size - b.plot_size);
          onChange(sorted);
        }
        break;
      case 'priceAsc':
        if (plots) {
          const sorted = plots.sort((a, b) => b.plot_size - a.plot_size);
          onChange(sorted);
        }
        break;
      default:
    }
  };

  console.log(plotFilters);

  return (
    <Formik
      enableReinitialize
      initialValues={
        plotFilters
          ? { ...plotFilters }
          : {
              water: null,
              electrocity: null,
              telecomunication: null,
              sewers: null,
              free: null,
              reservation: null,
              pending_cashouts: null,
              signed_contract: null,
              sold: null,
              priceMin: '',
              priceMax: '',
              plotSizeMax: '',
              plotSizeMin: '',
              sortBy: '',
            }
      }
      onSubmit={async values => {
        console.log(values);
        // const response =
        await onSubmit(values);
        onClose();
        // console.log(response);
      }}
    >
      {({ handleSubmit, errors }) => (
        <FormikForm className="filters__form">
          <Field
            className="filters--bottom-border"
            component={SelectField}
            errors={errors}
            fullWidth
            isCustomLabel
            label="Sortuj po"
            name="sortBy"
            options={SORT_PLOT_BY_ARR}
            placeholder="Wybierz status"
            onChange={handleSortingContent}
          />
          <p className="select-field__label select-field__label--bottom">Filtruj</p>
          <div className="filters__form--border">
            <p className="select-field__label--small">Cena w zł</p>
            <div className="filters__form--half filters__form--half-border">
              <Field component={RegularField} placeholder="od" errors={errors} name="priceMin" type="text" />
              -
              <Field component={RegularField} placeholder="do" errors={errors} name="priceMax" type="text" />
            </div>
            <p className="select-field__label--small">Powierzchnia w m²</p>
            <div className="filters__form--half filters__form--half-border">
              <Field component={RegularField} placeholder="od" errors={errors} name="plotSizeMin" type="text" />
              -
              <Field component={RegularField} placeholder="do" errors={errors} name="plotSizeMax" type="text" />
            </div>
            <div className="filters--pack">
              <span className="select-field__label--small">Media i dostępność</span>
              <div className="filters__group">
                <Field component={Tile} label="Wodociągi" name="water" icon={SVG_TYPE.MEDIA_WATER} />
                <Field component={Tile} label="Prąd" name="electrocity" icon={SVG_TYPE.MEDIA_POWER} />
                <Field component={Tile} label="Zbiornik wodny" name="sewers" icon={SVG_TYPE.MEDIA_HEAT} />
                <Field component={Tile} label="Telekomunikacja" name="telecomunication" icon={SVG_TYPE.MEDIA_TELECOMUNICATION} />
              </div>
            </div>
            <div className="filters--pack">
              <span className="select-field__label--small">Statusy</span>
              <div className="filters__group">
                <Field component={Tile} label="Wolne" name="free" status="green" />
                <Field component={Tile} label="Rezerwacja" name="reservation" status="yellow" />
                <Field component={Tile} label="Oczekujące na wpłatę" name="pending_cashouts" status="orange" />
                <Field component={Tile} label="Umowa podpisana." name="signed_contract" status="red" />
                <Field component={Tile} label="Sprzedane" name="sold" status="black" />
              </div>
            </div>
            <div className="filters__button">
              <Button classNames="orange" label="Wyszukaj" onClick={handleSubmit} />
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}

Form.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  plotFilters: PropTypes.object.isRequired,
  plots: PropTypes.array.isRequired,
};

export default Form;
