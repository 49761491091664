import React from 'react';
import * as Connector from 'shared/connectors/investorsConnector';
import { addFile } from 'shared/connectors/partnerConnector';
import { INVESTOR } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import getInvestors from './getInvestors';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: INVESTOR.ADD_INVESTOR_INIT,
});

export const success = data => ({
  type: INVESTOR.ADD_INVESTOR_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INVESTOR.ADD_INVESTOR_FAILURE,
});

const addInvestorRequestBuilder = investorData => {
  const additionalInvestorInfo = Object.keys(investorData.investorAdditionalInformation).reduce(
    (acc, current) => ({
      ...acc,
      [current]: investorData.investorAdditionalInformation[current] === true ? 1 : 0,
    }),
    {},
  );
  const rq = {
    ...additionalInvestorInfo,
    ...investorData,
  };
  delete rq.investorAdditionalInformation;
  delete rq.investorCard;
  return rq;
};

const addInvestor = (formData, file) => async dispatch => {
  dispatch(init());
  try {
    // const file = formData.investorCard;
    dispatch(showPreloader());
    const { data } = await Connector.addInvestor(addInvestorRequestBuilder(formData));
    if (file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      console.log(file);
      await addFile(fileForm, {
        investor_uuid: data.uuid,
        file_format: file.type.split('/').pop().split('.')[0],
        file_name: file.name.split('.')[0],
      });
    }
    dispatch(hidePreloader());
    dispatch(getInvestors());
    toast(<NewUser badgeType="Inwst" first_name={data.first_name} last_name={data.last_name} label="Dodano inwestora" />);
    dispatch(success(data));
    return null;
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default addInvestor;
