import React from 'react';
import { File } from 'shared/components';
import PropTypes from 'prop-types';

const ShowDocs = ({ files }) => (
  <div className="settlementFiles">
    <div className="header padding">
      <div>
        <span className="files__counter">{files.length}</span>
        <span className="files__title">Pliki</span>
      </div>
    </div>
    <div className="content">
      {files?.map(file => (
        <>
          <File label={file.file_name} path={file?.access_path} uuid={file.uuid} />
        </>
      ))}
    </div>
  </div>
);

export default ShowDocs;

ShowDocs.propTypes = {
  authorizedUser: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  plots: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};
