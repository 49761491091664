import * as Connector from 'shared/connectors/startConnector';
import getOffers from './getOffers';

const deleteAcceptedOffer = uuid => async dispatch => {
  try {
    await Connector.deleteAcceptedOffer(uuid);
    dispatch(getOffers());
  } catch (err) {
    // dispatch(failure());
  }
};

export default deleteAcceptedOffer;
