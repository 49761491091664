import React from 'react';
import { Button, File } from 'shared/components';
import { SVG_TYPE, MODAL_TYPES } from 'shared/consts';
import PropTypes from 'prop-types';

const ManageDocs = ({ openModal, plots, remove, authorizedUser }) => {
  const isAdmin = authorizedUser.role === 'admin';
  return (
    <div className="settlementFiles">
      <div className="header">
        <div>
          <span className="files__counter">{plots[0]?.settlement.files.length}</span>
          <span className="files__title">Pliki</span>
        </div>
        {isAdmin && (
          <Button
            classNames="transparent no-padding right-icon"
            iconType={SVG_TYPE.ADD}
            label="Dodaj"
            onClick={() => openModal(MODAL_TYPES.ADD_FILES)}
          />
        )}
      </div>
      <div className="content">
        {plots[0]?.settlement.files?.map(file => (
          <>
            <File label={file.file_name} path={file?.access_path} remove={remove} uuid={file.uuid} isAdmin={isAdmin} />
          </>
        ))}
      </div>
    </div>
  );
};

export default ManageDocs;

ManageDocs.propTypes = {
  authorizedUser: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  plots: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};
