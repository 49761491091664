import * as React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { MODAL_TYPES, SVG_TYPE } from 'shared/consts';
import { Button } from 'shared/components';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    const { opened } = this.props;
    this.state = {
      isOpened: opened || false,
    };
  }

  componentDidMount() {
    const { onOpen } = this.props;
    if (onOpen) onOpen();
  }

  shouldComponentUpdate(nextProps) {
    const { opened } = this.props;
    if (nextProps.opened !== opened) {
      this.setOpened(nextProps.opened);
    }
    return true;
  }

  componentWillUnmount() {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  setOpened = isOpened => {
    const { onOpenedChange } = this.props;
    this.setState({ isOpened }, () => onOpenedChange && onOpenedChange(isOpened));
  };

  submit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit();
    this.setOpened(false);
  };

  toggle = () => {
    const { isOpened } = this.state;
    this.setOpened(!isOpened);
  };

  close = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
    // this.setOpened(false);
  };

  handleChildClick = e => {
    e.stopPropagation();
  };

  render() {
    const { isOpened } = this.state;
    const { children, className, title, type, isMultiStep, step, handleStepChange } = this.props;
    return (
      <>
        {isOpened && (
          <div
            className={`modal ${type === MODAL_TYPES.DELETE && 'modal--delete'} ${
              type === MODAL_TYPES.RESERVATION && 'modal--reservation'
            } ${type === MODAL_TYPES.MANAGE && 'modal--manage'} ${type === MODAL_TYPES.RANGE && 'modal--range'}
             ${type === MODAL_TYPES.SORT && 'modal--sort'} ${type === MODAL_TYPES.GROUP && 'modal--group'} ${
              type === MODAL_TYPES.ADD_APPOINTMENT_NOTE && 'modal--appointment'
            } ${type === MODAL_TYPES.SHOW && 'modal--show'} ${type === MODAL_TYPES.MAP && 'modal--map'} 
             `}
            onClick={this.close}
          >
            <div
              className={`modal__inner modal__inner--${className || ''} ${type === MODAL_TYPES.MAP ? 'modal__inner--map' : ''}`}
              onClick={this.handleChildClick}
            >
              {title && (
                <div className={`modal__header ${isMultiStep ? 'modal__header--multistep' : ''}`}>
                  <p className="modal__title">{title}</p>
                  {isMultiStep && (
                    <Button
                      classNames="light-gray right-icon"
                      iconType={SVG_TYPE.DROPDOWN_INDICATOR}
                      label={step === 1 ? 'dane szczegółowe' : 'dane podstawowe'}
                      onClick={isMultiStep && handleStepChange}
                    />
                  )}
                </div>
              )}
              <div className="modal__content">{children}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Modal.defaultProps = {
  className: '',
  handleStepChange: null,
  isMultiStep: false,
  onOpen: null,
  onOpenedChange: null,
  step: null,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleStepChange: PropTypes.func,
  isMultiStep: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onOpenedChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  step: PropTypes.number,
  title: PropTypes.element.isRequired,
  type: PropTypes.oneOf([MODAL_TYPES.CONFIRM, MODAL_TYPES.DELETE]).isRequired,
};

export default props => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body'));
