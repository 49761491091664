import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import { RegularField, Button } from 'shared/components';
import { ROUTES, VALIDATION_MESSAGES } from 'shared/consts';
import PropTypes from 'prop-types';
import Title from '../title';

const { EMAIL, REQUIRED_FIELD } = VALIDATION_MESSAGES;

const getLoginSchema = () =>
  object().shape({
    email: string().email(EMAIL).required(REQUIRED_FIELD),
    password: string().required(REQUIRED_FIELD),
  });

const Login = ({ login, isHomePagePreloaderVisible }) => {
  const [isWrongPasswordOrEmail, setIsWrongPasswordOrEmail] = useState(false);
  const handleOnSubmit = async values => {
    if (!(await login(values))) setIsWrongPasswordOrEmail(true);
  };

  return (
    <div className={`login ${isHomePagePreloaderVisible ? 'login--shadowed' : ''}`}>
      <Title title="Zaloguj się" />
      <div className="login__form">
        <Formik initialValues={{ email: '', password: '' }} onSubmit={handleOnSubmit} validationSchema={getLoginSchema()}>
          {({ handleSubmit, errors }) => (
            <>
              <form onSubmit={handleSubmit}>
                {isWrongPasswordOrEmail && !isHomePagePreloaderVisible && (
                  <div className="login__error">błędny email lub hasło</div>
                )}
                <Field component={RegularField} errors={errors} label="email" name="email" type="text" />
                <Field component={RegularField} errors={errors} label="hasło" name="password" type="password" />
                {!isHomePagePreloaderVisible && (
                  <div className="login__actions">
                    <Link className="login__reset-password" to={ROUTES.RESET_PASSWORD}>
                      Nie pamiętam hasła
                    </Link>
                    <Button classNames="green" label="Zaloguj się" type="submit" />
                  </div>
                )}
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

Login.propTypes = {
  isHomePagePreloaderVisible: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

export default Login;
