import { createSelector } from 'reselect';
import moment from 'moment';
// import { create } from 'yup/lib/Reference'

const getNotificationStore = state => state.notificationStore;
const getNotifications = createSelector(getNotificationStore, ({ notifications: { data = [] } }) => data);
const getActiveDayNotifications = createSelector(getNotificationStore, ({ activeDayNotifications: { data = [] } }) =>
  data.reduce((acc, date) => {
    const weekDay = moment(date.date).format('dddd');
    if (!acc[weekDay]) {
      acc[weekDay] = [];
    }

    acc[weekDay].push(date);
    return acc;
  }, []),
);
const getSingleNotification = createSelector(getNotificationStore, ({ singleNotification: { data = {} } }) => data);
const getEmployees = createSelector(getNotificationStore, ({ employees: { data = {} } }) => data);

export { getNotifications, getActiveDayNotifications, getSingleNotification, getEmployees };
