import React from 'react';
import { array, bool, func, string } from 'prop-types';
import { OfferBar } from 'screens/plot/components/PlotData';

const OffersMobile = ({ list, acceptModalOpen, offerChangeStatus, role, ifAcceptOffers }) => (
  <>
    {list?.map(listItem => (
      <div className="offerBar__wrapper">
        <OfferBar
          userRole={role}
          item={listItem}
          startView
          openModal={acceptModalOpen}
          ifAcceptOffers={ifAcceptOffers}
          offerChangeStatus={offerChangeStatus}
        />
      </div>
    ))}
  </>
);

export default OffersMobile;

OffersMobile.defaultProps = {
  ifAcceptOffers: false,
};

OffersMobile.propTypes = {
  acceptModalOpen: func.isRequired,
  ifAcceptOffers: bool,
  list: array.isRequired,
  offerChangeStatus: func.isRequired,
  role: string.isRequired,
  upload: bool.isRequired,
};
