import { connect } from 'react-redux';
import * as Actions from 'store/actions/notification';
import * as InvestorActions from 'store/actions/investors';
import * as SettlementsActions from 'store/actions/settlements';
import { getActiveDayNotifications, getEmployees } from 'store/selectors/notificationSelector';
import { getInvestorsOptions } from 'store/selectors/investorSelector';
import { getPlotsOptions, getSettlementsOptions } from 'store/selectors/settlementsSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import Notifications from './Notifications.main';

const mapDispatchToProps = {
  getActiveDayNotifications: params => Actions.getActiveDayNotifications(params),
  addNotification: (notificationData, file) => Actions.addNotification(notificationData, file),
  getInvestorsOptions: () => InvestorActions.getInvestorsOptions(),
  getPlotsOptions: () => SettlementsActions.getPlotsOptions(),
  getSettlementsOptions: () => SettlementsActions.getSettlementsOptions(),
  changeStatus: (status, uuid, params) => Actions.changeStatus(status, uuid, params),
  getEmployees: () => Actions.getEmployees(),
};

const mapStateToProps = state => ({
  activeDayNotifications: getActiveDayNotifications(state),
  investorsOptions: getInvestorsOptions(state),
  plotsOptions: getPlotsOptions(state),
  settlementsOptions: getSettlementsOptions(state),
  authorizedUserRole: getAuthorizedUserRole(state),
  employees: getEmployees(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
