import React from 'react';
import { Modal, UserBar, ModalActions, Badge } from 'shared/components';
import { MODAL_TYPES } from 'shared/consts';
import PropTypes from 'prop-types';

const CancelReservationModal = ({ title, opened, onClose, plot, onSubmit }) => {
  const handleSubmit = () => {
    const values = {
      status_uuid: plot?.order_status.uuid,
      plot_uuid: plot?.uuid,
      order_status: plot?.order_status.uuid,
      sell_status: 'available',
    };
    onSubmit(values);
  };
  return (
    <>
      <Modal title={title} opened={opened} onClose={onClose} type={MODAL_TYPES.CANCEL_RESERVATION}>
        <div className="cancel_reservation">
          <UserBar
            type="INWST"
            name={`${plot?.order_status?.investor?.first_name} ${plot?.order_status?.investor?.last_name}`}
            phone={plot?.order_status?.investor?.phone_number}
          />
          <div className="employees-right__select details">
            <div className="employees-right__select-wrapper">
              <div className="settlement">
                <Badge label="Osada" type="ADM" />
                <div className="settlement-name">{plot?.settlement.name}</div>
              </div>
            </div>
          </div>
          <div className="employees-right__select details">
            <div className="employees-right__select-wrapper">
              <div className="settlement">
                <Badge label="Działka" type="DO" />
                <div className="settlement-name">{plot?.name}</div>
              </div>
            </div>
          </div>
          <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.DELETE} />
        </div>
      </Modal>
    </>
  );
};

export default CancelReservationModal;

CancelReservationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  plot: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};
