import { Connector } from 'modules/authModule';
import { AUTH } from 'store/types';

const init = () => ({
  type: AUTH.GET_AUTHORIZED_USER_INIT,
});

export const success = data => ({
  type: AUTH.GET_AUTHORIZED_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.GET_AUTHORIZED_USER_FAILURE,
});

const getAuthorizedUser = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getAuthorizedUser();
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getAuthorizedUser;
