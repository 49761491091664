import { REPORT } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  reportsData: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  reportsFilters: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  reportsFiltersList: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  reportsExcel: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  companies: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const reportsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case REPORT.GET_REPORTS_DATA_INIT: {
      return {
        ...state,
        reportsData: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case REPORT.GET_REPORTS_DATA_SUCCESS: {
      return {
        ...state,
        reportsData: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case REPORT.GET_REPORTS_DATA_FAILURE: {
      return {
        ...state,
        reportsData: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case REPORT.REPORTS_FILTERS_INIT: {
      return {
        ...state,
        reportsFilters: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case REPORT.REPORTS_FILTERS_SUCCESS: {
      return {
        ...state,
        reportsFilters: {
          data: { ...state.reportsFilters.data, ...payload.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case REPORT.REPORTS_FILTERS_FAILURE: {
      return {
        ...state,
        reportsFilters: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case REPORT.REPORTS_FILTERS_LIST_INIT: {
      return {
        ...state,
        reportsFiltersList: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case REPORT.REPORTS_FILTERS_LIST_SUCCESS: {
      return {
        ...state,
        reportsFiltersList: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case REPORT.REPORTS_FILTERS_LIST_FAILURE: {
      return {
        ...state,
        reportsFiltersList: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case REPORT.DELETE_REPORTS_SAVE: {
      return {
        ...state,
        reportsFiltersList: {
          data: [...state.reportsFiltersList.data.filter(({ uuid }) => uuid !== payload.uuid)],
        },
      };
    }

    case REPORT.REPORTS_EXCEL_INIT: {
      return {
        ...state,
        reportsExcel: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case REPORT.REPORTS_EXCEL_SUCCESS: {
      return {
        ...state,
        reportsExcel: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case REPORT.REPORTS_EXCEL_FAILURE: {
      return {
        ...state,
        reportsExcel: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case REPORT.GET_COMPANIES_LABELS_INIT: {
      return {
        ...state,
        companies: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case REPORT.GET_COMPANIES_LABELS_SUCCESS: {
      return {
        ...state,
        companies: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case REPORT.GET_COMPANIES_LABELS_FAILURE: {
      return {
        ...state,
        companies: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default reportsReducer;
