import React, { useEffect } from 'react';
import { object, shape, string, func } from 'prop-types';
import { ActiveWindow, Button, SVG, Badge } from 'shared/components';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import { useHistory, useLocation } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';
// import { NOTIFICATION } from 'store/types';

const SingleNotificationRight = ({
  match: {
    params: { uuid },
  },
  singleNotification: { date, title, description, status, plot, type, meeting, investor, user, employee },
  getSingleNotification,
  changeSingleNotificationStatus,
  authorizedUser,
  history,
}) => {
  const { replace } = useHistory();
  const { state } = useLocation();
  useEffect(async () => {
    await getSingleNotification(uuid);
  }, [uuid]);
  const hour = date ? moment(date).format('HH:MM') : null;
  const isContact = type === 'contact';
  const handleDoneStatus = id => {
    const currentStatus = { status: 'done' };
    changeSingleNotificationStatus(currentStatus, id);
  };

  const handleToDoStatus = id => {
    const currentStatus = { status: 'to_do' };
    changeSingleNotificationStatus(currentStatus, id);
  };

  const LOCATION = {
    in_field: 'W terenie',
    conference_room: 'Na miejscu',
  };
  const USER = {
    sales_department: 'DDI',
    adviser: 'DO',
    accounting_department: 'KS',
    administration: 'ADM',
    marketing: 'MA',
    admin: 'AD',
    dp: 'DP,',
  };
  // const USER_LABEL = {
  //   sales_department: 'DDI',
  //   adviser: 'Doradca',
  // };
  const done = status === 'done';
  const isAdminOrDdiUser = authorizedUser === 'admin' || authorizedUser === 'sales_department';
  const isNotificationOfDdiOrAdviser = user?.role === 'sales_department' || user?.role === 'adviser';
  return (
    <ActiveWindow.Wrapper customClassName="notificationsRight">
      <div className="notificationsRight__header">
        <div className="notificationsRight__title">Podgląd powiadomienia</div>
        <Button
          classNames="transparent no-padding right-icon"
          iconType={SVG_TYPE.EXIT}
          label="zamknij"
          onClick={() => replace(state.pathname || ROUTES.CALENDAR)}
        />
      </div>
      <div className="single-notification-right">
        <div className="single-notification-right wrapper">
          <div className="single-notification-right__hour">{hour}</div>
          {done && (
            <div className="single-notification-right__done" onClick={() => handleToDoStatus(uuid)}>
              <SVG type={SVG_TYPE.CHECKED} />
            </div>
          )}
          {!done && (
            <div className="single-notification-right__toDo" onClick={() => handleDoneStatus(uuid)}>
              Do zrobienia
            </div>
          )}
        </div>
        <div className="single-notification-right__content">
          {isAdminOrDdiUser && (
            <>
              {isNotificationOfDdiOrAdviser && user && (
                <div className="single-notification-right__row">
                  <div
                    className="employees-right__select"
                    onClick={() => history.push(generatePath(ROUTES.EMPLOYEES_DDI_UUID, { uuid: user?.uuid }))}
                  >
                    <Badge type={USER[user?.role]} label={USER[user?.role]} />
                    <div className="employees-right__select-investor">
                      {user?.first_name} {user?.last_name}
                    </div>
                  </div>
                  <div>
                    <a className="employees-right__phone" href={`tel:${user?.phone}`}>
                      <SVG type={SVG_TYPE.PHONE} />
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
          {isContact && investor && (
            <div className="single-notification-right__row">
              <div
                className="employees-right__select"
                onClick={() => history.push(generatePath(ROUTES.INVESTORS_ID, { uuid: investor?.uuid }))}
              >
                <Badge type="DO" label="Inwst" />
                <div className="employees-right__select-investor">
                  {investor?.first_name} {investor?.last_name}
                </div>
              </div>
              <div>
                <a className="employees-right__phone" href={`tel:${investor?.phone_number}`}>
                  <SVG type={SVG_TYPE.PHONE} />
                </a>
              </div>
            </div>
          )}
          {employee && (
            <div className="single-notification-right__row">
              <div className="employees-right__select">
                <Badge type={USER[employee?.role]} label={USER[employee?.role]} />
                <div className="employees-right__select-investor">
                  {employee?.first_name} {employee?.last_name}
                </div>
              </div>
              <div>
                <a className="employees-right__phone" href={`tel:${employee?.phone}`}>
                  <SVG type={SVG_TYPE.PHONE} />
                </a>
              </div>
            </div>
          )}
          <div className="single-notification-right__title">{title}</div>
          <div className="single-notification-right__description">{description}</div>
          {type === 'meeting' ? (
            <div className="employees-right__select details">
              <div className="locationWrapper">
                Miejsce spotkania
                <div className="location">{LOCATION[meeting?.meeting_place]}</div>
              </div>
            </div>
          ) : (
            ''
          )}
          {plot && (
            <div
              className="employees-right__select details"
              onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_ID, { uuid: plot?.settlement.uuid }))}
            >
              <div className="employees-right__select-wrapper">
                <div className="settlement">
                  <Badge label="Osada" type="ADM" />
                  <div className="settlement-name">{plot?.settlement.name}</div>
                </div>
              </div>
            </div>
          )}
          {plot && (
            <div
              className="employees-right__select details"
              onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid: plot?.uuid }))}
            >
              <div className="employees-right__select-wrapper">
                <div className="settlement">
                  <Badge label="Działka" type="DO" />
                  <div className="settlement-name">{plot?.name}</div>
                </div>
              </div>
            </div>
          )}
          {/* <ModalActions type={MODAL_TYPES.NOTIFICATION} /> */}
        </div>
      </div>
      {/* <List activeDayNotifications={activeDayNotifications} /> */}
    </ActiveWindow.Wrapper>
  );
};

SingleNotificationRight.propTypes = {
  authorizedUser: object.isRequired,
  changeSingleNotificationStatus: func.isRequired,
  getSingleNotification: func.isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
  singleNotification: object.isRequired,
};

export default SingleNotificationRight;
