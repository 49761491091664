import React from 'react';
import { Button } from 'shared/components';
import { func, array } from 'prop-types';

const AddOffer = ({ openAddOffer, user }) => (
  <div className="addOffer">
    <p>Dedykowane oferty</p>
    <Button classNames="light--gray-bold " label="Utwórz ofertę" onClick={() => openAddOffer({ id: user.uuid, full_data: 0 })} />
  </div>
);

AddOffer.propTypes = {
  openAddOffer: func.isRequired,
  user: array.isRequired,
};

export default AddOffer;
