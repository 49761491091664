import React from 'react';
import { AvailabilityBadge, Button, ReservationSurname } from 'shared/components';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import { ROUTES, RESERVATION_STATUS } from 'shared/consts';

const PlotDetails = ({ plot, openModal, isMobile, setPlotInfo, history, authorizedUser }) => {
  const { name, price_per_m2, plot_size, price, status, uuid } = plot;
  return (
    <>
      <div
        className="map__details--close"
        onClick={() => {
          setPlotInfo(false);
          const activeElement = document.getElementsByClassName('map__plot--active');
          activeElement.length >= 1 && activeElement[0].classList.remove('map__plot--active');
        }}
      >
        <div className={`map__details ${isMobile ? 'map__details--mobile' : ''}`}>
          <div className="map__details--link" onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid }))} />
          <AvailabilityBadge type={status} count={name} />
          <div className="map__details--surface"> {plot_size} ㎡</div>
          <div className="map__details--surface"> {Math.trunc(price_per_m2)} zł/㎡</div>
          <div className="map__details--price"> {price} zł</div>
          {plot.status === RESERVATION_STATUS.FREE ? (
            <Button classNames="orange" label="Rezerwuj" onClick={() => openModal(uuid)} />
          ) : (
            <div className="map__details--ddi">
              <ReservationSurname
                bookedOn={plot.order_status}
                authorizedUserUuid={authorizedUser}
                name={plot.order_status?.investor?.first_name}
                surname={plot.order_status?.investor?.last_name}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PlotDetails;

PlotDetails.propTypes = {
  authorizedUser: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  plot: PropTypes.array.isRequired,
  setPlotInfo: PropTypes.func.isRequired,
};
