import React, { useState } from 'react';
import { object, array, func, bool } from 'prop-types';
import { Button, ActiveWindow, DeleteModal, Badge, SVG } from 'shared/components';
import { FIELD_TYPE } from 'shared/components/active-window/consts';
import { AREA_TYPES, ACTION_TYPES, MODAL_TYPES, SVG_TYPE, FORM_TYPES } from 'shared/consts';
import { checkAccess } from 'shared/utils';
import AddInvestorForm from 'screens/investors/components/AddInvestorForm/AddInvestorForm';
import DeleteModalContent from '../delete-modal-content/DeleteModalContent';

const { INPUT, SELECT } = FIELD_TYPE;

function InvestorData({
  archived,
  authorizedUserRole,
  data,
  data: { active, first_name, last_name, email, status, phone_number, files, ddi, adivser },
  deleteInvestor,
  editInvestor,
  usersDdi,
  getAllDdi,
  restoreInvestor,
  getEmployees,
  employees,
}) {
  const [modalOpened, setModalOpened] = useState(null);
  const activePropertyLabel = <Badge label={status} type={`${status}_form`} />;
  const activePropertyCustomClassName = active ? 'active' : '';

  const isDeleteEnabled = checkAccess(AREA_TYPES.INVESTORS, ACTION_TYPES.DELETE);
  const isEditEnable = checkAccess(AREA_TYPES.INVESTORS, ACTION_TYPES.EDIT);

  const closeModal = () => setModalOpened(null);

  const handleDelete = () => {
    deleteInvestor(data);
    setModalOpened(null);
  };
  return (
    <div className="active-window__data">
      <AddInvestorForm
        onClose={() => setModalOpened(null)}
        onSubmit={editInvestor}
        opened={modalOpened === MODAL_TYPES.EDIT}
        type={FORM_TYPES.ADD}
        userRole={authorizedUserRole}
        usersDdi={usersDdi}
        getAllDdi={getAllDdi}
        data={data}
        employees={employees}
        getEmployees={getEmployees}
      />
      <DeleteModal
        title="Czy chcesz usunąć inwestora?"
        onClose={closeModal}
        onSubmit={handleDelete}
        opened={modalOpened === MODAL_TYPES.DELETE}
      >
        <DeleteModalContent data={data} />
      </DeleteModal>
      <div className="active-window__group-wrapper">
        <div className="active-window__group-label">Status</div>
        <div className="active-window__row-align">
          <ActiveWindow.Field type={SELECT} value={activePropertyLabel} customClassName={activePropertyCustomClassName} />
          <div className="employees-right__field-wrapper">
            <div className="employees-right__field-label">Numer tel</div>
            <div className="employees-right__input">
              <span className="employees-right__input--phone">+48</span> {phone_number}
            </div>
          </div>
        </div>
        {ddi && (
          <>
            <div className="active-window__group-label">Przypisany DDI</div>
            <div className="active-window__row-align">
              <ActiveWindow.Field type={INPUT} label="Imię" value={ddi.first_name} />
              <ActiveWindow.Field type={INPUT} label="Nazwisko" value={ddi.last_name} />
            </div>
          </>
        )}
        {adivser && (
          <>
            <div className="active-window__group-label">Przypisany doradca</div>
            <div className="active-window__row-align">
              <ActiveWindow.Field type={INPUT} label="Imię" value={adivser.first_name} />
              <ActiveWindow.Field type={INPUT} label="Nazwisko" value={adivser.last_name} />
            </div>
          </>
        )}
        <div className="active-window__group-label">Dane konta</div>
        <ActiveWindow.Field type={INPUT} label="Imię" value={first_name} />
        <ActiveWindow.Field type={INPUT} label="Nazwisko" value={last_name} />
        <ActiveWindow.Field type={INPUT} label="Adres email" value={email} />
        <div className="active-window__group-label">Wymagane pliki</div>
        <div className="active-window__files">
          <div className="active-window__files--container">
            <SVG type={SVG_TYPE.LIGHT_FILE} />{' '}
            <a href={files && files[0]?.access_path} target="_blank" rel="noreferrer">
              {' '}
              .
            </a>{' '}
            <span> {files && files[0]?.file_name}</span>
          </div>
        </div>
        {/* <div className="active-window__group-label">Informacje</div> */}
        {/* <ActiveWindow.Field type={INPUT} label="REGON" value={regon || 'Brak'} />
        <ActiveWindow.Field type={INPUT} label="NIP" value={nip} />
        <ActiveWindow.Field type={INPUT} label="KRS" value={krs || 'Brak'} /> */}
        {archived ? (
          <div className="employees-right__actions">
            <Button iconType={SVG_TYPE.RESTORE} classNames="orange" label="Przywróć" onClick={() => restoreInvestor(data)} />
          </div>
        ) : (
          <div className="active-window__actions">
            {isEditEnable && <Button classNames="orange" label="Edytuj dane" onClick={() => setModalOpened(MODAL_TYPES.EDIT)} />}
            {isDeleteEnabled && <Button classNames="red" label="Usuń konto" onClick={() => setModalOpened(MODAL_TYPES.DELETE)} />}
          </div>
        )}
      </div>
    </div>
  );
}

InvestorData.defaultProps = {
  archived: false,
};
InvestorData.propTypes = {
  archived: bool,
  authorizedUserRole: array.isRequired,
  bonusesOptions: array.isRequired,
  data: object.isRequired,
  deleteInvestor: func.isRequired,
  editInvestor: func.isRequired,
  employees: array.isRequired,
  getAllDdi: func.isRequired,
  getEmployees: func.isRequired,
  restoreInvestor: func.isRequired,
  usersDdi: array.isRequired,
};

export default InvestorData;
