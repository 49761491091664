import * as React from 'react';
import { string, func, bool, array } from 'prop-types';
import { USER_ROLES, RESERVATION_STATUS } from 'shared/consts';
import { SINGLE_PLOT_SUBMENU } from 'shared/components/active-window/consts';
import { StatusHeader, StatusButtons, Offers, PlotHead, PreBooking, PandingCashout, SignedContract, Sold } from './index';
import ShowDocs from '../ShowDocs';

const PlotsById = ({
  setActiveMenu,
  activeMenu,
  plot,
  openReservationModal,
  authorizedUser,
  openAddOffer,
  offerChangeStatus,
  waitingOffer,
  acceptedOffers,
  getAcceptedOffers,
  getStatusPlot,
  statusPlot,
  openModal,
  getPdfContract,
  openDepositModal,
  openPriceModal,
  setPurchaseDate,
  filesByStatus,
  setIsConditionalActModal,
}) => {
  const { role, uuid } = authorizedUser;
  const isSubmenuStatus = activeMenu === SINGLE_PLOT_SUBMENU.STATUS;
  const isSubMenuDocs = activeMenu === SINGLE_PLOT_SUBMENU.DOCS;
  const isUserBooking = uuid === plot?.order_status?.ddi?.uuid;
  const isOfferStatusFree = plot?.status === RESERVATION_STATUS.FREE;
  const isOfferStatusReservation = plot?.status === RESERVATION_STATUS.RESERVATION;
  const isStatusPendingCashout = plot?.status === RESERVATION_STATUS.PENDING_CASHOUTS;
  const isStatusSignedContractt = plot?.status === RESERVATION_STATUS.SIGNED_CONTRACT;
  const isStatusSold = plot?.status === RESERVATION_STATUS.SOLD;
  const isAdmin = role === USER_ROLES.ADMIN;
  const isAccountant = role === USER_ROLES.ACCOUNTING;
  const isDdiAdmin = (role === USER_ROLES.DDI || isAdmin) && isOfferStatusFree;
  // console.log('dostaliśmy się tutaj');
  // console.log('uuid usera', uuid);
  // console.log('uuid usera co rezerwuje działkę', plot?.order_status?.ddi?.uuid);
  // console.log(plot);
  return (
    <div className="plot employees-right">
      <PlotHead setActiveMenu={setActiveMenu} activeMenu={activeMenu} plot={plot} />
      <div className="plot__wrapper">
        {isSubmenuStatus && (
          <>
            <StatusHeader
              status={plot?.status}
              dates={plot?.order_status.reservation_date}
              daysFromStatusChange={plot?.days_from_status_change}
            />
            <StatusButtons
              openModal={openModal}
              openDepositModal={openDepositModal}
              openPriceModal={openPriceModal}
              loggedUser={authorizedUser}
              plot={plot}
              openReservationModal={openReservationModal}
              setIsConditionalActModal={setIsConditionalActModal}
            />
            {isDdiAdmin && (
              <Offers
                openAddOffer={openAddOffer}
                waitingOffer={waitingOffer}
                plotUuid={plot?.uuid}
                offerChangeStatus={offerChangeStatus}
                user={authorizedUser}
                acceptedOffers={acceptedOffers}
                getAcceptedOffers={getAcceptedOffers}
                isUserBooking={isUserBooking}
              />
            )}
            {isOfferStatusReservation && (
              <PreBooking
                getStatusPlot={getStatusPlot}
                statusPlot={statusPlot}
                loggedUser={authorizedUser}
                plot={plot}
                plotUuid={plot?.uuid}
                isUserBooking={isUserBooking}
                isAdmin={isAdmin}
                isAccountant={isAccountant}
                getPdfContract={getPdfContract}
              />
            )}
            {isStatusPendingCashout && (
              <PandingCashout
                isUserBooking={isUserBooking}
                isAccountant={isAccountant}
                plot={plot}
                setPurchaseDate={setPurchaseDate}
              />
            )}
            {isStatusSignedContractt && (
              <SignedContract
                isAdmin={isAdmin}
                plot={plot}
                isAccountant={isAccountant}
                isUserBooking={isUserBooking}
                setPurchaseDate={setPurchaseDate}
              />
            )}
            {isStatusSold && <Sold isAdmin={isAdmin} plot={plot} isAccountant={isAccountant} isUserBooking={isUserBooking} />}
          </>
        )}
        {isSubMenuDocs && (isAdmin || isUserBooking || isAccountant) && (
          <>
            {console.log('wyswietlaj pliki')}
            <ShowDocs files={filesByStatus} />
          </>
        )}
      </div>
    </div>
  );
};

PlotsById.propTypes = {
  acceptedOffers: array.isRequired,
  activeMenu: string.isRequired,
  activeSubMenu: string.isRequired,
  authorizedUser: string.isRequired,
  filesByStatus: array.isRequired,
  getAcceptedOffers: func.isRequired,
  getPdfContract: func.isRequired,
  getStatusPlot: func.isRequired,
  isMobile: bool.isRequired,
  offerChangeStatus: string.isRequired,
  openAddOffer: func.isRequired,
  openDepositModal: func.isRequired,
  openModal: func.isRequired,
  openPriceModal: func.isRequired,
  openReservationModal: func.isRequired,
  pathname: string.isRequired,
  plot: array.isRequired,
  setActiveMenu: func.isRequired,
  setActiveSubMenu: string.isRequired,
  setIsConditionalActModal: func.isRequired,
  setPurchaseDate: func.isRequired,
  statusPlot: array.isRequired,
  waitingOffer: array.isRequired,
};
export default PlotsById;
