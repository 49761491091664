import * as Connector from 'shared/connectors/statusConnector';
import { STATUS } from 'store/types';
import { addFile } from 'shared/connectors/partnerConnector';
import { getPlotDetails } from 'store/actions/settlements';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: STATUS.CONFIRM_DEPOSIT_PAYMENT_INIT,
});

export const success = () => ({
  type: STATUS.CONFIRM_DEPOSIT_PAYMENT_SUCCESS,
});

const failure = () => ({
  type: STATUS.CONFIRM_DEPOSIT_PAYMENT_FAILURE,
});

const addDepositRequestBuilder = formData => {
  const rq = {
    ...formData,
  };
  delete rq.file;
  return rq;
};

const confirmDepositPayment = (formData, file) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const { data } = await Connector.confirmDepositPayment(addDepositRequestBuilder(formData));
    dispatch(success(data));
    if (data.uuid && file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      await addFile(fileForm, {
        down_payment_uuid: data.uuid,
        file_format: file.type.split('/').pop().split('.')[0],
        file_name: file.name.split('.')[0],
      });
    }
    await dispatch(getPlotDetails(formData.plot_uuid));
    dispatch(hidePreloader());
    return data;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default confirmDepositPayment;
