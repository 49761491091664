import React from 'react';
import moment from 'moment';
import { SVG } from 'shared/components';
import { SVG_TYPE, USER_ROLES, MODAL_TYPES } from 'shared/consts';
import PropTypes from 'prop-types';

const OfferBar = ({ ifAcceptOffers, userRole, offerChangeStatus, openModal, item, plotUuid, download, startView }) => {
  const { price, percentage_reduction, updated_at, uuid } = item;
  const { RESERVATION, SINGLE_OFFER } = MODAL_TYPES;
  const isAdmin = userRole === USER_ROLES.ADMIN;
  return (
    <div className="offerBar__item">
      <div className="offerBar">
        <div className="offerBar__info" onClick={() => openModal(SINGLE_OFFER, item)}>
          <p className="offerBar__price">
            {' '}
            {Math.round(price)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
            zł
          </p>
          {percentage_reduction && <p className="offerBar__discount">-{percentage_reduction}%</p>}
          <p className="offerBar__data">{moment(updated_at).format('DD.MM')}</p>
        </div>
        {(!ifAcceptOffers || isAdmin) && !download && (
          <div
            className="offerBar__cancel"
            onClick={() => offerChangeStatus({ offerUuid: uuid, acceptance: 'no', plotUuid, userRole })}
          >
            usuń
          </div>
        )}
      </div>
      {!ifAcceptOffers && isAdmin && !startView && !download && (
        <div className="offerBar--accept" onClick={() => openModal(RESERVATION, item)}>
          <SVG type={SVG_TYPE.CONFIRM} className="svg--confirm--green" />
        </div>
      )}
      {/* {(!ifAcceptOffers || download) && (
        <div className="offerBar--accept" onClick={() => console.log('pobieramy')}>
          <SVG type={SVG_TYPE.DOWNLOAD} className="svg--download--black" />
        </div>
      )} */}
      {startView && isAdmin && !ifAcceptOffers && (
        <div className="offerBar--accept" onClick={() => openModal(RESERVATION, item)}>
          <SVG type={SVG_TYPE.CONFIRM} className="svg--confirm--green" />
        </div>
      )}
    </div>
  );
};

OfferBar.defaultProps = {
  ifAcceptOffers: null,
};

OfferBar.propTypes = {
  download: PropTypes.bool.isRequired,
  ifAcceptOffers: PropTypes.bool,
  item: PropTypes.array.isRequired,
  offerChangeStatus: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  plotUuid: PropTypes.string.isRequired,
  startView: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default OfferBar;
