import API from 'shared/connectors/config';

export function getReportsData(values) {
  console.log(values);
  return API.get(`/reports`, { params: values });
}

export function saveReportsFilters(modifiedFilters) {
  return API.post(`/reports/save`, modifiedFilters);
}

export function getReportsFiltersList() {
  return API.get(`/reports/save`);
}

export function uploadReportsSettings(uuid) {
  return API.get(`/reports/save/${uuid}`);
}

export function exportReports(reports_data, options) {
  return API.post(`/excel`, { reports_data, options });
}

export function deleteSave(uuid) {
  return API.get(`/reports/save/delete/${uuid}`);
}

export function getCompaniesLabels() {
  return API.get(`/companies`);
}
