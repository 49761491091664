import React from 'react';
import { Link } from 'react-router-dom';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';
import { isMobile as checkIsMobile } from 'shared/utils';

function Header({ activeUser, prevPath }) {
  const isMobile = checkIsMobile();
  return (
    <div className="employees-right__title">
      <div className="employees-right__title-wrapper">
        {isMobile && (
          <Link className="employees-right__back" to={prevPath || ROUTES.EMPLOYEES}>
            <SVG type={SVG_TYPE.DROPDOWN_INDICATOR} />
          </Link>
        )}
        <div className="employees-right__fullname">
          {activeUser.first_name} {activeUser.last_name}
        </div>
      </div>
      <a className="employees-right__phone" href={`tel:${activeUser.phone}`}>
        <SVG type={SVG_TYPE.PHONE} />
      </a>
    </div>
  );
}

Header.propTypes = {
  activeUser: PropTypes.object.isRequired,
  prevPath: PropTypes.string.isRequired,
};

export default Header;
