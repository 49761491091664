import React, { useEffect, useState } from 'react';
import { isMobile } from 'shared/utils';
import { array, func, object, string } from 'prop-types';
import AcceptanceOfferModal from 'screens/plot/components/PlotData/AcceptanceOfferModal';
import { MODAL_TYPES } from 'shared/consts';
import { SingleOfferModal } from 'shared/components';
import News from './components/news';
import Statuses from './components/statuses';
import Offers from './components/offers';
import MobileNews from './components/mobile-news';
import CreateNewsModal from './components/create-news-modal';
import EditNewsModal from './components/edit-news-modal';

function Start({
  getNews,
  news,
  createNews,
  deleteNews,
  editNews,
  authorizedUser,
  getStatuses,
  statuses,
  history,
  getOffers,
  offers,
  offerChangeStatus,
  deleteAcceptedOffer,
  getPlots,
}) {
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [currentEditted, setCurrentEditted] = useState({});
  const [modalOpened, setModalOpened] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const { RESERVATION, SINGLE_OFFER } = MODAL_TYPES;
  const isAcceptModalOpened = modalOpened === RESERVATION;
  const isSingleOfferOpened = modalOpened === SINGLE_OFFER;
  const isUserBooking = authorizedUser.uuid === offerData?.ddi?.uuid;
  const handleOpenCreateModal = () => setIsCreateModalOpened(prevState => !prevState);
  const handleOpenEditModal = (uuid, title, text) => {
    const singleNewsData = { uuid, title, text };
    setCurrentEditted(singleNewsData);
    setIsEditModalOpened(prevState => !prevState);
  };
  const checkIsMobile = isMobile();
  const onCreateModalSubmit = values => {
    createNews(values.title, values.text);
  };
  const onEditModalSubmit = (uuid, titleValue, textValue) => {
    editNews(uuid, titleValue, textValue);
  };
  const acceptModalOpen = (type, item) => {
    setModalOpened(type);
    setOfferData(item);
  };
  useEffect(() => {
    getNews();
    getStatuses();
    getOffers();
  }, []);
  return (
    <>
      <div className="start">
        <News
          authorizedUser={authorizedUser}
          news={news}
          handleOpenCreateModal={handleOpenCreateModal}
          handleOpenEditModal={handleOpenEditModal}
          deleteNews={deleteNews}
        />
        {!checkIsMobile ? (
          <>
            <Statuses authorizedUser={authorizedUser} statuses={statuses} history={history} getPlots={getPlots} />
            <Offers
              authorizedUser={authorizedUser}
              offers={offers}
              history={history}
              acceptModalOpen={acceptModalOpen}
              offerChangeStatus={offerChangeStatus}
              deleteAcceptedOffer={deleteAcceptedOffer}
            />
          </>
        ) : (
          <MobileNews
            authorizedUser={authorizedUser}
            isMobile={isMobile}
            statuses={statuses}
            offers={offers}
            history={history}
            acceptModalOpen={acceptModalOpen}
            offerChangeStatus={offerChangeStatus}
          />
        )}
        <CreateNewsModal isOpened={isCreateModalOpened} onClose={handleOpenCreateModal} onSubmit={onCreateModalSubmit} />
        <EditNewsModal
          isOpened={isEditModalOpened}
          onClose={handleOpenEditModal}
          onSubmit={onEditModalSubmit}
          currentEditted={currentEditted}
        />
        <AcceptanceOfferModal
          title="Potwierdź ofertę dedykowaną"
          opened={isAcceptModalOpened}
          onClose={() => setModalOpened(null)}
          offerData={offerData}
          offerChangeStatus={offerChangeStatus}
          userRole={authorizedUser.role}
          plotUuid={offerData?.plot.uuid}
        />
        <SingleOfferModal
          offerChangeStatus={offerChangeStatus}
          title="Podgląd oferty"
          opened={isSingleOfferOpened}
          onClose={() => setModalOpened(null)}
          offerData={offerData}
          plotUuid={offerData?.plot.uuid}
          userRole={authorizedUser.role}
          isUserBooking={isUserBooking}
        />
      </div>
    </>
  );
}

export default Start;

Start.propTypes = {
  authorizedUser: string.isRequired,
  createNews: func.isRequired,
  deleteAcceptedOffer: func.isRequired,
  deleteNews: func.isRequired,
  editNews: func.isRequired,
  getNews: func.isRequired,
  getOffers: func.isRequired,
  getPlots: func.isRequired,
  getSingleNews: func.isRequired,
  getStatuses: func.isRequired,
  news: array.isRequired,
  offerChangeStatus: func.isRequired,
  offers: object.isRequired,
  singleNews: array.isRequired,
  statuses: object.isRequired,
};
