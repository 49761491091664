import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const SelectOfert = ({ values: { offer_uuid }, acceptedOffers, setFieldValue }) => {
  const handleSelectOffer = id => {
    offer_uuid === id ? setFieldValue('offer_uuid', null) : setFieldValue('offer_uuid', id);
  };
  return (
    <div>
      <div className="reservationModal__offer">
        {acceptedOffers.map(item => {
          const price = (item?.deposit_percentage / 100) * item?.price;
          return (
            <div
              onClick={() => handleSelectOffer(item.uuid)}
              className={`reservationModal__offer--item ${offer_uuid === item.uuid && 'reservationModal__offer--item--active'}`}
            >
              <div className="reservationModal__offer--item--half">
                <p className="reservationModal__offer--price">{item?.price} zł</p>
                {item?.percentage_reduction && (
                  <p className="reservationModal__offer--discount">-{item?.percentage_reduction}%</p>
                )}
              </div>
              <div className="reservationModal__offer--item--half">
                <p className="reservationModal__offer--deposit">
                  zadatek:
                  <p className="reservationModal__offer--deposit-price">
                    {price.toFixed().toString().replace(/.{3}$/, ' $&')} zł
                  </p>
                </p>
                <SVG
                  className={`reservationModal__offer-icon ${offer_uuid === item.uuid && 'reservationModal__offer-icon--active'}`}
                  type={SVG_TYPE.CHECKED}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
SelectOfert.propTypes = {
  acceptedOffers: PropTypes.array.isRequired,
  errors: PropTypes.any.isRequired,
  setFieldValue: PropTypes.node.isRequired,
  values: PropTypes.shape({
    offer_uuid: PropTypes.string.isRequired,
  }).isRequired,
};

export default SelectOfert;
