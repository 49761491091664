import React, { useState, useMemo, useEffect } from 'react';
import { shape, string, func, array, bool } from 'prop-types';
import { INVESTOR_SUBMENU } from 'shared/components/active-window/consts';
import { getInvestorDetails } from './utils';
import InvestorByUuid from './components/investor-by-uuid/InvestorByUuid';

const InvestorsRight = ({
  authorizedUserRole,
  investors,
  archived,
  archivedInvestor,
  match: {
    params: { uuid },
  },
  actions: { deleteInvestor, editInvestor, getAllDdi, getArchivedInvestor, restoreInvestor, getEmployees },
  usersDdi,
  employees,
}) => {
  if (uuid) {
    const [activeMenu, setActiveMenu] = useState(INVESTOR_SUBMENU.BASIC);
    let investor;
    if (!archived) {
      investor = useMemo(() => getInvestorDetails(uuid, investors) ?? {}, [uuid, investors]);
    } else {
      useEffect(async () => {
        await getArchivedInvestor(uuid);
      }, [uuid]);
      investor = archivedInvestor;
    }
    return (
      <InvestorByUuid
        archived={archived}
        investor={investor}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
        deleteInvestor={deleteInvestor}
        editInvestor={editInvestor}
        getAllDdi={getAllDdi}
        usersDdi={usersDdi}
        authorizedUserRole={authorizedUserRole}
        restoreInvestor={restoreInvestor}
        employees={employees}
        getEmployees={getEmployees}
      />
    );
  }
  return null;
};

InvestorsRight.defaultProps = {
  archived: false,
  archivedInvestor: '',
};

InvestorsRight.propTypes = {
  actions: shape({
    editInvestor: func.isRequired,
    deleteInvestor: func.isRequired,
    getAllDdi: func.isRequired,
  }).isRequired,
  archived: bool,
  archivedInvestor: shape({}),
  authorizedUserRole: string.isRequired,
  employees: array.isRequired,
  getEmployees: func.isRequired,
  investors: shape({}).isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
  usersDdi: array.isRequired,
};

export default InvestorsRight;
