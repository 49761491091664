import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const OfferBar = ({ SettlementName, plotNumber, size, priceNew, priceOld, percentageReduction }) => (
  <div className="addOfferModal__header">
    <div className="addOfferModal__header--top">
      <SVG className="addOfferModal--icon" type={SVG_TYPE.BLUE_HOME} />
      <p className="addOfferModal--number" />
      <p className="addOfferModal--plotName"> {SettlementName} </p>
    </div>
    <div className="addOfferModal__header--bottom addOfferModal__header--half">
      <div className="addOfferModal--half">
        <div className="addOfferModal__plot-number">
          <p className="addOfferModal--dot" />
          {plotNumber}
        </div>
        <p className="addOfferModal__yardage">
          <p className="addOfferModal__yardage--dot" /> {size} m2
        </p>
      </div>
      <div className="addOfferModal--half">
        <div className="addOfferModal__price">
          <p className="addOfferModal__oldPrice">{priceOld === priceNew ? '' : priceOld} </p>
          <p className="addOfferModal__newPrice">
            {' '}
            {Math.round(priceNew)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
            zł
          </p>
        </div>
        <p className="addOfferModal__header--input addOfferModal__header--percentage addOfferModal__header--percentage--green">
          {percentageReduction || '0'}
        </p>
      </div>
    </div>
  </div>
);

OfferBar.propTypes = {
  number: PropTypes.string.isRequired,
  percentageReduction: PropTypes.string.isRequired,
  plotNumber: PropTypes.string.isRequired,
  priceNew: PropTypes.string.isRequired,
  priceOld: PropTypes.string.isRequired,
  SettlementName: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default OfferBar;
