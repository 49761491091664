import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

function SearchMobile() {
  return (
    <div className="dashboard-header__search-mobile">
      <SVG type={SVG_TYPE.LOUPE} />
    </div>
  );
}

export default SearchMobile;
