import { object, string } from 'yup';
import { VALIDATION_MESSAGES, USER_ROLES } from 'shared/consts';
import { FORM_STEPS } from './consts';

const { ADMIN } = USER_ROLES;
const { EMAIL, REQUIRED_FIELD, REQUIRED_9_DIGITS } = VALIDATION_MESSAGES;

const getFormSchema = loggedUserRole =>
  object().shape({
    business_partner_uuid: string().when('role', {
      is: USER_ROLES.ADVISER,
      then: string().required(REQUIRED_FIELD),
    }),
    sales_department_uuid: string().when('role', {
      is: USER_ROLES.ADMIN,
      then: string().required(REQUIRED_FIELD),
    }),
    // sales_department_uuid: string().required(REQUIRED_FIELD),
    ...(loggedUserRole === ADMIN && {
      ddi_uuid: string().when('role', {
        is: USER_ROLES.ADVISER,
        then: string().required(REQUIRED_FIELD),
      }),
    }),
    email: string().email(EMAIL).required(REQUIRED_FIELD),
    first_name: string().required(REQUIRED_FIELD),
    investorCard: string().required(REQUIRED_FIELD),
    last_name: string().required(REQUIRED_FIELD),
    phone_number: string().required(REQUIRED_FIELD).min(9, REQUIRED_9_DIGITS).max(9, REQUIRED_9_DIGITS),
    status: string().required(REQUIRED_FIELD),
  });

const getButtonLabelForFormStep = formStep => (formStep === FORM_STEPS.BASIC ? 'dane szczegółówe >' : 'dane podstawowe >');

export { getFormSchema, getButtonLabelForFormStep };
