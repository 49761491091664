import React from 'react';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { SUBMENU_ARR } from './consts';

function SubMenu({ isSetMapOpen, activeMenu, setActiveMenu, menu = SUBMENU_ARR }) {
  return (
    <div className="active-window__submenu">
      {menu.map(item => (
        <div
          key={item}
          className={`active-window__submenu-item ${activeMenu === item ? 'active-window__submenu-item--active' : ''}`}
          onClick={() => {
            setActiveMenu(item);
            activeMenu === false && isSetMapOpen(false);
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

SubMenu.defaultProps = {
  isSetMapOpen: null,
};

SubMenu.propTypes = {
  activeMenu: PropTypes.string.isRequired,
  isSetMapOpen: PropTypes.func,
  menu: arrayOf(shape({})).isRequired,
  setActiveMenu: PropTypes.func.isRequired,
};

export default SubMenu;
