import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { EMPLOYEE_SHORTCUTS, USER_ROLES } from 'shared/consts';
import { NewUser } from 'shared/components';
import { matchUrlToRole } from 'shared/utils';
import getUsers from './getUsers';
import getUsersAmount from './getUsersAmount';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: USER.ADD_USER_INIT,
});

export const success = data => ({
  type: USER.ADD_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.ADD_USER_FAILURE,
});

const addUser = userData => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const { data } = await Connector.addUser(userData);
    dispatch(getUsersAmount());
    await dispatch(getUsers(matchUrlToRole(window.location.pathname)));
    await toast(
      <NewUser
        badgeType={EMPLOYEE_SHORTCUTS[Object.keys(USER_ROLES).find(role => USER_ROLES[role] === userData.role)]}
        first_name={userData.first_name}
        label="Dodano użytkownika"
        last_name={userData.last_name}
        phone={userData.phone}
      />,
    );
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err.response.data);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default addUser;
