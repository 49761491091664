import React from 'react';
import { SVG } from 'shared/components';
import PropTypes from 'prop-types';
import './Trigger.scss';
import Counter from './counter/Counter';

const Trigger = ({
  className,
  content,
  id,
  svgName,
  svgClass,
  svgWrapperClass,
  onClick,
  dateStart,
  dateEnd,
  filters,
  extend,
}) => {
  // const isCounterNeeded = id === 'filter' || id === 'sort' ? true : '';
  const isVerticalButton = className === 'wrapper__right--box--trigger' ? true : '';
  const isSvgWrapperClass = svgWrapperClass === 'vertical' ? true : '';
  const isRangeButton = svgWrapperClass === 'noSvg' ? true : '';
  const isFilters = id === 'filter' && filters.isFilters;
  const isSort = id === 'sort' && filters.isSort;
  const isRange = id === 'range' && filters.isRange;

  return (
    <div className="btnWrapper">
      <button type="button" className={`${className} ${extend ? 'extend' : ''}`} id={id} onClick={onClick}>
        {(isFilters || isSort || isRange) && <Counter />}
        {isRangeButton && (
          <div className="range">
            <div className="ranges-wrapper">
              <div className="range--startDate">{dateStart}</div>
              <div className="range--endDate">{dateEnd}</div>
            </div>
          </div>
        )}
        <div className={isSvgWrapperClass ? 'vertical' : 'iconWrapper'}>
          {!isRangeButton && <SVG type={svgName} className={(isVerticalButton && 'smallerSvg', svgClass)} />}
        </div>
        <div>{content}</div>
      </button>
    </div>
  );
};

Trigger.protoTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  content: PropTypes.string,
};

export default Trigger;

Trigger.propTypes = {
  content: PropTypes.string.isRequired,
  dateEnd: PropTypes.string.isRequired,
  dateStart: PropTypes.string.isRequired,
  extend: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  svgClass: PropTypes.string.isRequired,
  svgName: PropTypes.string.isRequired,
  svgWrapperClass: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
