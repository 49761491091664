import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import authReducer from './authReducer';
import viewManagementReducer from './viewManagementReducer';
import userReducer from './userReducer';
import partnerReducer from './partnerReducer';
import settlementsReducer from './settlementsReducer';
import investorReducer from './investorReducer';
import notificationReducer from './notificationReducer';
import reportsReducer from './reportsReducer';
import offerReducer from './offerReducer';
import startReduceer from './startReducer';
import searchReducer from './searchReducer';

export const historyBrowser = createBrowserHistory();

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    authStore: authReducer,
    viewManagementStore: viewManagementReducer,
    userStore: userReducer,
    partnerStore: partnerReducer,
    settlementStore: settlementsReducer,
    investorStore: investorReducer,
    notificationStore: notificationReducer,
    reportStore: reportsReducer,
    offerStore: offerReducer,
    startStore: startReduceer,
    searchStore: searchReducer,
  });

export default rootReducer;
