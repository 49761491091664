import React from 'react';
import PropTypes from 'prop-types';
import { REQUEST_STATUS } from 'shared/consts';
import { withRouter } from 'react-router';
import SkeletonItem from './components/skeleton';
import Left from './components/left';
import Right from './components/right';

const { IDLE, INIT } = REQUEST_STATUS;

function List({ data, shortcut, activeRow, history, path, dataStatus, area, isMobile }) {
  const isLoading = dataStatus === IDLE || dataStatus === INIT;
  if (data.length > 0) {
    return (
      <div className={`list ${isMobile ? 'list--mobile' : ''}`}>
        {data.map(item => (
          <div key={item.uuid} className="list__item-wrapper">
            <div
              className={`list__item ${activeRow === item.uuid ? 'list__item--active' : ''}`}
              onClick={() => history.push(`${path}/${item.uuid}`)}
            >
              {/* {console.log('id aktywnej strony', activeRow)}
              {console.log('uuid itemu', item.uuid)} */}
              <Left area={area} item={item} shortcut={shortcut} isMobile={isMobile} />
              <Right area={area} item={item} isMobile={isMobile} />
            </div>
          </div>
        ))}
      </div>
    );
  }
  if (isLoading) return <SkeletonItem />;
  return <div className="list__empty">Brak danych</div>;
}

List.defaultProps = {
  activeRow: '',
  area: '',
  data: '',
  dataStatus: '',
  isMobile: false,
  path: '',
  shortcut: '',
};

List.propTypes = {
  activeRow: PropTypes.string,
  area: PropTypes.string,
  data: PropTypes.array,
  dataStatus: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
  path: PropTypes.string,
  shortcut: PropTypes.string,
};

export default withRouter(List);
