import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({
  tranche: {
    all_brutto_still_pay,
    all_deposit_payed,
    all_netto_still_pay,
    all_brutto_price_with_discount,
    all_brutto,
    all_netto,
    all_gave_discount,
    all_second_tranche,
  },
}) => (
  <footer className="footer">
    <div className="amounts">
      <section className="amounts__box">
        <div className="amounts__box--title">Cena katalogowa</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_brutto)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Udzielony rabat</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_gave_discount)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Cena całość brutto</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_brutto_price_with_discount)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Cena całość netto</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_netto)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Zapłacony zadatek</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_deposit_payed)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Wpłacone w II transzy</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_second_tranche)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Do zapłaty brutto</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_brutto_still_pay)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
      <section className="amounts__box">
        <div className="amounts__box--title">Do zapłaty netto</div>
        <div className="amounts__box--number1">
          {' '}
          {Math.round(all_netto_still_pay)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
          zł
        </div>
      </section>
    </div>
  </footer>
);
export default Footer;

Footer.propTypes = {
  tranche: PropTypes.object.isRequired,
};
