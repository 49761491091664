import { connect } from 'react-redux';
import * as Actions from 'store/actions/partner';
import { getEmployees } from 'store/actions/notification';
import { getEmployees as getEmployeesList } from 'store/selectors/notificationSelector';
import { getPartners, getPartnersStatus, getBonusesOptions } from 'store/selectors/partnerSelector';
import { getAuthorizedUser } from 'store/selectors/authSelector';
import Partners from './Partners.main';

const mapDispatchToProps = {
  getPartners: () => Actions.getPartners(),
  getBonusesOptions: () => Actions.getBonusesOptions(),
  addPartner: (data, file) => Actions.addPartner(data, file),
  getEmployees: () => getEmployees(),
  getPartnersByFilter: uuid => Actions.getPartnersByFilter(uuid),
};

const mapStateToProps = state => ({
  partners: getPartners(state),
  partnersStatus: getPartnersStatus(state),
  bonusesOptions: getBonusesOptions(state),
  authorizedUser: getAuthorizedUser(state),
  employees: getEmployeesList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
