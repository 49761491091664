import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'shared/consts';
import Login from 'screens/home-page/components/login';
import ResetPassword from 'screens/home-page/components/reset-password';
import Header from 'screens/home-page/components/header';
import CodeVerification from 'screens/home-page/components/code-verification';
import ActivateAccount from 'screens/home-page/components/activate-account';
import NewPassword from 'screens/home-page/components/new-password';
import PropTypes from 'prop-types';
import { isTokenSaved } from 'shared/utils';
import { ToastContainer } from 'react-toastify';

const HomePage = ({
  login,
  userToVerify,
  verifyCode,
  isHomePagePreloaderVisible,
  activateAccount,
  sendResetPasswordEmail,
  setNewPassword,
}) => {
  if (!isTokenSaved()) {
    return (
      <div className="home-page">
        <div className="home-page__left" />
        <div className="home-page__right">
          <Header isHomePagePreloaderVisible={isHomePagePreloaderVisible} />
          <div className={`home-page__wrapper ${isHomePagePreloaderVisible ? 'home-page__wrapper--animated' : ''}`}>
            <ToastContainer autoClose={5000} closeOnClick={false} draggablePercent={40} hideProgressBar position="bottom-right" />
            <Switch>
              <Route
                exact
                path={ROUTES.HOME_PAGE}
                render={() => <Login isHomePagePreloaderVisible={isHomePagePreloaderVisible} login={login} />}
              />
              <Route
                exact
                path={ROUTES.RESET_PASSWORD}
                render={props => (
                  <ResetPassword
                    {...props}
                    isHomePagePreloaderVisible={isHomePagePreloaderVisible}
                    sendResetPasswordEmail={sendResetPasswordEmail}
                  />
                )}
              />
              <Route
                exact
                path={ROUTES.CODE_VERIFICATION}
                render={() => (
                  <CodeVerification
                    isHomePagePreloaderVisible={isHomePagePreloaderVisible}
                    login={login}
                    userToVerify={userToVerify}
                    verifyCode={verifyCode}
                  />
                )}
              />
              <Route
                exact
                path={ROUTES.ACTIVATE_ACCOUNT}
                render={() => (
                  <ActivateAccount activateAccount={activateAccount} isHomePagePreloaderVisible={isHomePagePreloaderVisible} />
                )}
              />
              <Route
                exact
                path={ROUTES.NEW_PASSWORD}
                render={() => (
                  <NewPassword isHomePagePreloaderVisible={isHomePagePreloaderVisible} setNewPassword={setNewPassword} />
                )}
              />
              <Route render={() => <Login isHomePagePreloaderVisible={isHomePagePreloaderVisible} login={login} />} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
  return <Redirect to={ROUTES.START} />;
};

HomePage.propTypes = {
  activateAccount: PropTypes.func.isRequired,
  isHomePagePreloaderVisible: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  sendResetPasswordEmail: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  userToVerify: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  verifyCode: PropTypes.func.isRequired,
};

export default HomePage;
