import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';
import { Button } from 'shared/components';
import { ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';
import Title from '../title';

function CodeVerification({ userToVerify: { phone, email, password }, login, verifyCode, isHomePagePreloaderVisible }) {
  const [isWrongCode, setIsWrongCode] = useState(false);
  const handleOnComplete = async code => {
    if (!(await verifyCode({ email, password, login_token: code }))) setIsWrongCode(true);
  };
  const handleOnClick = () => {
    setIsWrongCode(false);
    login({ email, password });
  };
  if (phone) {
    return (
      <div className={`code-verification ${isHomePagePreloaderVisible ? 'code-verification--shadowed' : ''}`}>
        <Title title="Weryfikacja dwuetapowa" />
        {isWrongCode && !isHomePagePreloaderVisible && <div className="code-verification__error">błędny kod</div>}
        <div className="code-verification__form">
          <ReactCodeInput
            className="code-verification__wrapper"
            fieldHeight={40}
            fields={5}
            fieldWidth={40}
            onComplete={handleOnComplete}
            type="number"
          />
        </div>
        {!isHomePagePreloaderVisible && (
          <>
            <div className="code-verification__description">Wprowadź kod wysłany na numer tel: {phone}</div>
            <Button classNames="gray" label="wyślij kod ponownie" onClick={handleOnClick} />
          </>
        )}
      </div>
    );
  }
  return <Redirect to={ROUTES.LOGIN} />;
}

CodeVerification.propTypes = {
  isHomePagePreloaderVisible: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  userToVerify: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  verifyCode: PropTypes.func.isRequired,
};
export default CodeVerification;
