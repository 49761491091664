import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { string, array } from 'prop-types';

function ReservationSurname({ name, surname, authorizedUserUuid, bookedOn }) {
  const isUserBooking = authorizedUserUuid === bookedOn?.ddi?.uuid;
  return (
    <div className="reservationSurname">
      <div>
        <SVG type={SVG_TYPE.ARROW_GRAY} />
      </div>
      <p className={`reservationSurname--dot reservationSurname--dot--${isUserBooking ? 'ddi' : 'inwst'}`} />
      <div className="reservationSurname--name">
        {isUserBooking ? `${name.charAt(0)}.` : `${bookedOn?.ddi?.first_name.charAt(0)}.`}
      </div>
      <div className="reservationSurname--surname">{isUserBooking ? surname : bookedOn?.ddi?.last_name}</div>
    </div>
  );
}

ReservationSurname.defaultProps = {
  authorizedUserUuid: null,
  bookedOn: [],
  name: '',
};

ReservationSurname.propTypes = {
  authorizedUserUuid: string,
  bookedOn: array,
  name: string,
  surname: string.isRequired,
};

export default ReservationSurname;
