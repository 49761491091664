import React, { useState } from 'react';
import { shape, string, func, array, bool } from 'prop-types';
import { INVESTOR_SUBMENU } from 'shared/components/active-window/consts';
import { getSettlementDetails } from './utils';
import SettlementsByUuid from './components/settlements-by-uuid/SettlementsByUuid';

const SettlementsRight = ({
  authorizedUserRole,
  archived,
  archivedSettlements,
  match: {
    params: { uuid },
  },
  actions: { deleteInvestor, editInvestor, getAllDdi, restoreSettlement, getEmployees },
  usersDdi,
  employees,
}) => {
  if (uuid) {
    const [activeMenu, setActiveMenu] = useState(INVESTOR_SUBMENU.BASIC);
    console.log(archivedSettlements.data);
    return (
      <>
        {archivedSettlements.data && uuid && (
          <SettlementsByUuid
            archived={archived}
            settlement={getSettlementDetails(uuid,archivedSettlements.data)}
            setActiveMenu={setActiveMenu}
            activeMenu={activeMenu}
            deleteInvestor={deleteInvestor}
            editInvestor={editInvestor}
            getAllDdi={getAllDdi}
            usersDdi={usersDdi}
            authorizedUserRole={authorizedUserRole}
            restoreSettlement={restoreSettlement}
            employees={employees}
            getEmployees={getEmployees}
          />
        )}
      </>
    );
  }
  return null;
};

SettlementsRight.defaultProps = {
  archived: false,
};

SettlementsRight.propTypes = {
  actions: shape({
    editInvestor: func.isRequired,
    deleteInvestor: func.isRequired,
    getAllDdi: func.isRequired,
  }).isRequired,
  archived: bool,
  archivedSettlements: shape({}).isRequired,
  authorizedUserRole: string.isRequired,
  employees: array.isRequired,
  getEmployees: func.isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
  usersDdi: array.isRequired,
};

export default SettlementsRight;
