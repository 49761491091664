import { connect } from 'react-redux';
import * as Actions from 'store/actions/report';
import {
  getReportsData,
  getReportsFiltersList,
  updateFilters,
  getReportsExcel,
  getReportsDataStatus,
  getCompaniesLabels,
} from 'store/selectors/reportSelector';
import { getSettlementsOptions } from 'store/actions/settlements';
import { getSettlementsOptions as getSettlementsOptionsSelector } from 'store/selectors/settlementsSelector';
import * as UserActions from 'store/actions/user';
import { getUsers } from 'store/selectors/userSelector';
import * as InvestorActions from 'store/actions/investors';
import * as PartnerActions from 'store/actions/partner';
import { getInvestorsOptions } from 'store/selectors/investorSelector';
import { getPartnersOptions } from 'store/selectors/partnerSelector';
import Reports from './Reports.main';

const mapDispatchToProps = {
  getReportsData: values => Actions.getReportsData(values),
  sortedReportsData: sorted => Actions.sortedReportsData(sorted),
  getSettlementsOptions: () => getSettlementsOptions(),
  getUsers: role => UserActions.getUsers(role),
  getInvestorsOptions: () => InvestorActions.getInvestorsOptions(),
  getPartnersOptions: () => PartnerActions.getPartnersOptions(),
  updateFilters: values => Actions.updateFilters(values),
  saveReportsFilters: (value, filters, hiddenColumns) => Actions.saveReportsFilters(value, filters, hiddenColumns),
  getReportsFiltersList: () => Actions.getReportsFiltersList(),
  uploadReportsSettings: uuid => Actions.uploadReportsSettings(uuid),
  exportReports: (reports_data, options) => Actions.getReportsExcel(reports_data, options),
  deleteReportSave: uuid => Actions.deleteReportSave(uuid),
  getCompaniesLabels: () => Actions.getCompaniesLabels(),
};

const mapStateToProps = state => ({
  reportsData: getReportsData(state),
  settlementsOptions: getSettlementsOptionsSelector(state),
  users: getUsers(state),
  investorsOptions: getInvestorsOptions(state),
  partnersOptions: getPartnersOptions(state),
  filters: updateFilters(state),
  reportsFiltersList: getReportsFiltersList(state),
  excel: getReportsExcel(state),
  status: getReportsDataStatus(state),
  companiesLabels: getCompaniesLabels(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
