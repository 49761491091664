import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ROLE_OPTIONS, FORM_TYPES, POSITION_OPTIONS, MODAL_TYPES, SVG_TYPE, USER_ROLES } from 'shared/consts';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'shared/components';
import Form from 'screens/employees/components/form';
import DeleteModal from 'screens/employees/components/delete-modal';
import moment from 'moment';

function Data({
  activeUser,
  editUser,
  deleteUser,
  resendActivationEmail,
  isDeleteEnabled,
  isEditEnabled,
  isActivationEmailEnabled,
  authorizedUserRole,
  getPartnersOptions,
  partnersOptions,
  getDdiOptions,
  ddiOptions,
  archived,
  restoreEmployee,
}) {
  const [modalOpened, setModalOpened] = useState(null);
  const handleDelete = () => {
    deleteUser(activeUser);
    setModalOpened(null);
  };
  const handleResendActivationEmail = async () => {
    await resendActivationEmail(activeUser.uuid);
  };

  const isOpened = modalOpened === MODAL_TYPES.EDIT;
  const isDDIAndPartnerVisible =
    activeUser.role === USER_ROLES.ADVISER && activeUser.business_partner !== undefined && activeUser.ddi !== undefined;

  return (
    <div className="employees-right__data">
      {console.log(activeUser)}
      {isOpened && (
        <Form
          data={activeUser}
          onClose={() => {
            setModalOpened(null);
          }}
          onSubmit={editUser}
          opened={isOpened}
          type={FORM_TYPES.EDIT}
          userRole={authorizedUserRole}
          partnersOptions={partnersOptions}
          getPartnersOptions={getPartnersOptions}
          getDdiOptions={getDdiOptions}
          ddiOptions={ddiOptions}
        />
      )}
      <DeleteModal
        data={activeUser}
        onClose={() => {
          setModalOpened(null);
        }}
        onSubmit={handleDelete}
        opened={modalOpened === MODAL_TYPES.DELETE}
      />

      {Object.entries(activeUser).length > 0 ? (
        <>
          <div className="employees-right__group-wrapper">
            <div className="employees-right__group-label">Rola w systemie</div>
            <div className="employees-right__select">{ROLE_OPTIONS.find(({ value }) => value === activeUser.role)?.label}</div>
          </div>
          <div className="employees-right__group-wrapper">
            <div className="employees-right__group-label">Stanowisko</div>
            <div className="employees-right__select">
              {POSITION_OPTIONS.get(activeUser.role).find(({ value }) => value === activeUser.position)?.label}
            </div>
          </div>
          {isDDIAndPartnerVisible && (
            <>
              <div className="employees-right__group-wrapper">
                <div className="employees-right__group-label">Przypisany partner</div>
                <div className="employees-right__select">{activeUser?.business_partner?.name}</div>
              </div>
              <div className="employees-right__group-wrapper">
                <div className="employees-right__group-label">Przypisany DDI</div>
                <div className="employees-right__select">
                  {activeUser?.ddi?.first_name} {activeUser?.ddi?.last_name}{' '}
                </div>
              </div>
            </>
          )}
          <div className="employees-right__group-wrapper">
            <div className="employees-right__group-label">Dane konta</div>
            <div className="employees-right__field-wrapper">
              <div className="employees-right__field-label">Imię</div>
              <div className="employees-right__input">{activeUser.first_name}</div>
            </div>
            <div className="employees-right__field-wrapper">
              <div className="employees-right__field-label">Nazwisko</div>
              <div className="employees-right__input">{activeUser.last_name}</div>
            </div>
            <div className="employees-right__field-wrapper">
              <div className="employees-right__field-label">Adres email</div>
              <div className="employees-right__input">{activeUser.email}</div>
            </div>
            <div className="employees-right__field-wrapper">
              <div className="employees-right__field-label">Numer tel</div>
              <div className="employees-right__input">
                <span className="employees-right__input--phone">+48</span> {activeUser.phone}
              </div>
            </div>
            {archived && (
              <div className="employees-right__field-wrapper">
                <div className="employees-right__field-label">Data zarchiwizowania</div>
                <div className="employees-right__input">{moment(activeUser.deleted_at).format('HH:MM DD.MM.YYYY')}</div>
              </div>
            )}
            {isActivationEmailEnabled && (
              <Button classNames="green" label="Wyślij ponownie link aktywacyjny" onClick={handleResendActivationEmail} />
            )}
            <div className="employees-right__actions">
              {isEditEnabled && (
                <Button classNames="orange" label="Edytuj dane" onClick={() => setModalOpened(MODAL_TYPES.EDIT)} />
              )}
              {isDeleteEnabled && (
                <Button classNames="red" label="Usuń konto" onClick={() => setModalOpened(MODAL_TYPES.DELETE)} />
              )}
              {archived && (
                <Button
                  iconType={SVG_TYPE.RESTORE}
                  classNames="orange"
                  label="Przywróć"
                  onClick={() => restoreEmployee(activeUser)}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="employees-right__group-wrapper">
            <Skeleton height={20} width={112} />
            <Skeleton height={40} width="100%" />
          </div>
          <div className="employees-right__group-wrapper">
            <Skeleton height={20} width={112} />
            <Skeleton height={40} width="100%" />
          </div>
          <div className="employees-right__group-wrapper">
            <Skeleton height={20} width={112} />
            <Skeleton height={40} width="100%" />
          </div>
          <div className="employees-right__group-wrapper">
            <Skeleton height={20} width={112} />
            <Skeleton height={40} width="100%" />
          </div>
          <div className="employees-right__actions">
            <Skeleton height={40} width={130} />
            <Skeleton height={40} width={130} />
          </div>
        </>
      )}
    </div>
  );
}

Data.propTypes = {
  activeUser: PropTypes.object.isRequired,
  archived: PropTypes.bool.isRequired,
  authorizedUserRole: PropTypes.string.isRequired,
  ddiOptions: PropTypes.array.isRequired,
  deleteUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  isActivationEmailEnabled: PropTypes.bool.isRequired,
  isDeleteEnabled: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  resendActivationEmail: PropTypes.func.isRequired,
  restoreEmployee: PropTypes.func.isRequired,
};

export default Data;
