import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_FILES_BY_STATUS_INIT,
});

export const success = data => ({
  type: SETTLEMENTS.GET_FILES_BY_STATUS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_FILES_BY_STATUS_FAILURE,
});

const getFilesByStatus = statusUuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getFilesByStatus(statusUuid);
    dispatch(success(data));
  } catch (err) {
    console.log(err);
    dispatch(failure());
  }
};

export default getFilesByStatus;
