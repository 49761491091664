import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getArchivedInvestor } from 'store/actions/investors';
import { getInvestors, getArchivedInvestor as getArchivedInvestorSelector } from 'store/selectors/investorSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import { getAllDdi, getUsers as getUsersList } from 'store/selectors/userSelector';
import { getArchivedSettlementsSelector } from 'store/selectors/settlementsSelector';
import changeArchving from 'store/actions/settlements/changeArchving';
import SettlementsRight from './SettlementsRight.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getArchivedInvestor: uuid => getArchivedInvestor(uuid),
      // editInvestor: (data, file) => editInvestor(data, file),
      // deleteInvestor: data => deleteInvestor(data),
      // getAllDdi: () => UsersActions.getAllDdi(),
      // getEmployees: () => UsersActions.getUsers(),
      restoreSettlement: (uuid) => changeArchving(uuid, {is_archiving:false}),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  archivedInvestor: getArchivedInvestorSelector(state),
  investors: getInvestors(state),
  usersDdi: getAllDdi(state),
  authorizedUserRole: getAuthorizedUserRole(state),
  employees: getUsersList(state),
  archivedSettlements: getArchivedSettlementsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsRight);
