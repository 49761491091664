import { connect } from 'react-redux';
import * as Actions from 'store/actions/user';
import { getArchivedEmployees, getArchivedEmployeesStatus } from 'store/selectors/userSelector';
import ArchivedEmployees from './ArchivedEmployees.main';

const mapDispatchToProps = {
  getArchivedEmployees: () => Actions.getArchivedEmployees(),
};

const mapStateToProps = state => ({
  archivedEmployees: getArchivedEmployees(state),
  archivedEmployeesStatus: getArchivedEmployeesStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedEmployees);
