import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import ActiveWindow from './ActiveWindow.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = () => ({
  isMobile: checkIsMobile(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveWindow);
