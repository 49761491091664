import { func, bool, shape, string, array, object } from 'prop-types';
import React, { useEffect } from 'react';
import { SINGLE_PLOT_SUBMENU } from 'shared/components/active-window/consts';
import { MODAL_TYPES, RESERVATION_STATUS } from 'shared/consts';
import {
  ReservationModal,
  AddOfferModal,
  AddFileOfferModal,
  AddDepositModal,
  HistoryPaymentsModal,
  AddAppointmentNotaryModal,
  ConfirmSaleModal,
  PayForPlotModal,
  CancelReservationModal,
} from 'shared/components';
import PlotsById from './components/PlotData/PlotsById';

function Plot({
  isMobile,
  plot,
  users,
  investorsLabel,
  actions: {
    getPlotDetails,
    changeStatus,
    getUsers,
    getInvestorsLabel,
    addOffer,
    offerChangeStatus,
    getWaitingOffers,
    getAcceptedOffers,
    getStatusPlot,
    addContracts,
    confirmDepositPayment,
    getPdfContract,
    arrangingTheAct,
    setPurchaseDate,
    getFilesByStatus,
    getAdvisersList,
  },
  location: { pathname },
  authorizedUser,
  waitingOffers,
  acceptedOffers,
  statusPlot,
  filesByStatus,
  advisersList,
}) {
  const [activeMenu, setActiveMenu] = React.useState(SINGLE_PLOT_SUBMENU.STATUS);
  const [modalOpened, setModalOpened] = React.useState(null);
  const [isConditionalActModal, setIsConditionalActModal] = React.useState(false);
  const [depositModalOpened, setDepositModalOpened] = React.useState(null); // Seperate state because of opening this modal inside another modal - when the parent modal closing, it sets the main modal state to null and close children modal too
  const [priceModalOpened, setPriceModalOpened] = React.useState(null);
  const isDownPayment =
    plot?.status === RESERVATION_STATUS.PENDING_CASHOUTS &&
    parseFloat(plot?.order_status?.payed_deposit) >= parseFloat(plot?.order_status?.offer?.deposit);

  useEffect(async () => {
    const role = authorizedUser?.role;
    // const isUserDdi = role === USER_ROLES.DDI;
    const plotUuid = pathname.split('/')[4];

    await getPlotDetails(plotUuid);
    await getAcceptedOffers({ userRole: role, plotUuid });
    await getWaitingOffers({ userRole: role, plotUuid });
  }, []);

  useEffect(async () => {
    plot?.status === 'pending_cashouts' &&
      isDownPayment &&
      (await changeStatus({ sell_status: 'reservation/signed_contract', order_status: plot?.order_status?.uuid }, plot?.uuid));
  }, [plot]);

  useEffect(async () => {
    plot?.order_status?.uuid && (await getFilesByStatus(plot?.order_status?.uuid));
  }, [plot]);

  const handleReservation = (data, plot_uuid) => {
    changeStatus(data, plot_uuid);
    setModalOpened(null);
  };

  const handleCancelReservaion = values => {
    changeStatus(values, values.plot_uuid);
    setModalOpened(null);
  };

  const openReservationModal = id => {
    getPlotDetails(id);
    setModalOpened(MODAL_TYPES.RESERVATION);
  };
  const openAddOffer = data => {
    getInvestorsLabel(data);
    setModalOpened(MODAL_TYPES.ADD_OFFER);
  };

  const openModal = type => {
    setModalOpened(type);
  };

  const openDepositModal = type => {
    setDepositModalOpened(type);
    setModalOpened(null);
  };

  const openPriceModal = type => {
    setPriceModalOpened(type);
    setModalOpened(null);
  };

  const onClose = () => {
    setModalOpened(null);
    acceptedOffers = null;
  };

  const onDepositModalClose = () => {
    setDepositModalOpened(null);
    acceptedOffers = null;
  };

  const onPriceModalClose = () => {
    setPriceModalOpened(null);
    acceptedOffers = null;
  };

  const handleConfirmDepositPayment = async (data, file) => {
    const response = await confirmDepositPayment(data, file);
    response &&
      isDownPayment &&
      changeStatus({ sell_status: 'reservation/signed_contract', order_status: data.order_status }, data.plot_uuid);
    onDepositModalClose();
  };

  const handleConfirmPricePayment = async (data, file) => {
    await confirmDepositPayment(data, file);
    onPriceModalClose();
  };

  const {
    RESERVATION,
    ADD_OFFER,
    ADD_FILE_OFFER,
    ADD_DEPOSIT,
    HISTORY_PAYMENT,
    ADD_APPOINTMENT_NOTE,
    CONFIRM_SALE,
    PAY_FOR_PLOT,
    CANCEL_RESERVATION,
  } = MODAL_TYPES;
  const isReservationOpened = modalOpened === RESERVATION;
  const isAddOfferOpened = modalOpened === ADD_OFFER;
  const isAddFileOfferOpened = modalOpened === ADD_FILE_OFFER;
  const isAddDepositOpened = depositModalOpened === ADD_DEPOSIT;
  const isPayForPlotOpened = priceModalOpened === PAY_FOR_PLOT;
  const isHistoryPaymentOpened = modalOpened === HISTORY_PAYMENT;
  const isAddAppointmentNotaryModal = modalOpened === ADD_APPOINTMENT_NOTE;
  const isConfirmSaleModal = modalOpened === CONFIRM_SALE;
  const isCancelReservationModal = modalOpened === CANCEL_RESERVATION;
  return (
    <>
      <PlotsById
        openReservationModal={openReservationModal}
        isMobile={isMobile}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
        plot={plot}
        authorizedUser={authorizedUser}
        openAddOffer={openAddOffer}
        offerChangeStatus={offerChangeStatus}
        waitingOffer={waitingOffers}
        getInvestorsLabel={getInvestorsLabel}
        pathname={pathname}
        getAcceptedOffers={getAcceptedOffers}
        acceptedOffers={acceptedOffers}
        getStatusPlot={getStatusPlot}
        statusPlot={statusPlot}
        openModal={openModal}
        openDepositModal={openDepositModal}
        getPdfContract={getPdfContract}
        openPriceModal={openPriceModal}
        setPurchaseDate={setPurchaseDate}
        filesByStatus={filesByStatus}
        setIsConditionalActModal={setIsConditionalActModal}
      />
      <ReservationModal
        loggedUserRole={authorizedUser}
        title="Rezerwuj działkę"
        opened={isReservationOpened}
        onClose={() => setModalOpened(null)}
        onSubmit={handleReservation}
        plot={plot}
        getUsers={getUsers}
        ddiUsers={users}
        getInvestors={getInvestorsLabel}
        investorsOptions={investorsLabel}
        acceptedOffers={acceptedOffers}
        getAcceptedOffers={getAcceptedOffers}
        authorizedUser={authorizedUser.uuid}
        getAdvisersList={getAdvisersList}
        advisersList={advisersList}
      />
      <AddOfferModal
        title="Tworzenie dedykowanej oferty"
        opened={isAddOfferOpened}
        onClose={() => onClose()}
        plot={plot}
        onSubmit={data => addOffer(data)}
        investorsLabel={investorsLabel}
        authorizedUser={authorizedUser}
      />
      <AddFileOfferModal
        title="Potwierdź umowę"
        opened={isAddFileOfferOpened}
        onClose={() => onClose()}
        plot={plot}
        onSubmit={(values, files) => addContracts(values, files)}
        investorsLabel={investorsLabel}
        authorizedUser={authorizedUser}
      />
      <AddDepositModal
        title="Potwierdź wpłatę lub część zadatku"
        opened={isAddDepositOpened}
        onClose={() => onDepositModalClose()}
        plot={plot}
        onSubmit={(data, file) => {
          handleConfirmDepositPayment(data, file);
        }}
      />
      <PayForPlotModal
        title="Zapłać za działkę"
        opened={isPayForPlotOpened}
        onClose={() => onPriceModalClose()}
        plot={plot}
        onSubmit={(data, file) => {
          handleConfirmPricePayment(data, file);
        }}
      />
      <HistoryPaymentsModal
        title="Historia płatności"
        authorizedUser={authorizedUser}
        opened={isHistoryPaymentOpened}
        onClose={() => onClose()}
        plot={plot}
        openModal={openDepositModal}
        openPriceModal={openPriceModal}
        onSubmit={() => {
          console.log('.');
        }}
      />
      <AddAppointmentNotaryModal
        title="Ustal termin aktu"
        authorizedUser={authorizedUser}
        opened={isAddAppointmentNotaryModal}
        onClose={() => onClose()}
        plot={plot}
        onSubmit={data => {
          arrangingTheAct(data, plot?.uuid);
          onClose();
        }}
        isConditionalActModal={isConditionalActModal}
      />
      <ConfirmSaleModal
        title="Potwierdź sprzedaż"
        opened={isConfirmSaleModal}
        onClose={() => onClose()}
        plot={plot}
        openModal={openModal}
        onSubmit={(values, files) => {
          addContracts(values, files);
          onClose();
        }}
      />
      <CancelReservationModal
        title="Czy na pewno chcesz zwolnić tę działkę do sprzedaży ?"
        opened={isCancelReservationModal}
        onClose={() => onClose()}
        plot={plot}
        onSubmit={values => handleCancelReservaion(values)}
      />
    </>
  );
}

Plot.propTypes = {
  acceptedOffers: string.isRequired,
  actions: shape({
    addContracts: func.isRequired,
    arrangingTheAct: func.isRequired,
    getAdvisersList: func.isRequired,
    getInvestorsOptions: func.isRequired,
    getPdfContract: func.isRequired,
    addOffer: func.isRequired,
    changeStatus: func.isRequired,
    offerChangeStatus: func.isRequired,
    getFilesByStatus: func.isRequired,
    getStatusPlot: func.isRequired,
    getPlotDetails: func.isRequired,
    getUsers: func.isRequired,
    getInvestorsLabel: func.isRequired,
    getSingleNotification: func.isRequired,
    getWaitingOffers: func.isRequired,
  }).isRequired,
  advisersList: array.isRequired,
  authorizedUser: string.isRequired,
  filesByStatus: array.isRequired,
  history: string.isRequired,
  investorsLabel: array.isRequired,
  investorsOptions: array.isRequired,
  isMobile: bool.isRequired,
  plot: array.isRequired,
  setPurchaseDate: func.isRequired,
  singleNotification: object.isRequired,
  statusPlot: array.isRequired,
  userRole: string.isRequired,
  users: string.isRequired,
  waitingOffers: array.isRequired,
};

export default Plot;
