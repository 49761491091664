import React from 'react';
import * as Connector from 'shared/connectors/settlementConnector';
import { NewUser } from 'shared/components';
import { toast } from 'react-toastify';

const setPurchaseDate = async (statusUuid, date) => {
  try {
    await Connector.setPurchaseDate(statusUuid, date);
    await toast(<NewUser label="Wykonano akcję" first_name="Zmieniono" last_name="datę" />);
  } catch (err) {
    console.log(err);
  }
};

export default setPurchaseDate;
