import { bool, func, shape } from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { array, object } from 'yup';
import { Head, Form } from './components';

function Filters({ isOpened, onClose, actions: { getPlotsByFilter, sortedPlotList }, plots, plotFilters }) {
  if (isOpened) {
    return (
      <div className="filters">
        <div className="filters--outside" onClick={onClose} />
        <div className="filters__inner">
          <div className="filters__content">
            <Head onClose={onClose} />
            <Form
              onSubmit={getPlotsByFilter}
              onClose={onClose}
              onChange={sortedPlotList}
              plots={plots}
              plotFilters={plotFilters}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
}

Filters.propTypes = {
  actions: shape({
    getPlotsByFilter: func.isRequired,
    sortedPlotList: func.isRequired,
  }).isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  plotFilters: object.isRequired,
  plots: array.isRequired,
};

export default props => ReactDOM.createPortal(<Filters {...props} />, document.querySelector('body'));
