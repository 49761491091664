import React from 'react';
import { Badge } from 'shared/components';
import INVESTORS_SHORTCUTS from 'screens/investors/investorsShortcuts';

const INVESTOR_STATUS = [
  {
    label: (
      <div className="select-field__option">
        <Badge label={INVESTORS_SHORTCUTS.PROSPECT} type="prospect_form" />
      </div>
    ),
    value: INVESTORS_SHORTCUTS.PROSPECT,
    searchableLabel: INVESTORS_SHORTCUTS.PROSPECT,
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={INVESTORS_SHORTCUTS.IN} type="in_form" />
      </div>
    ),
    value: INVESTORS_SHORTCUTS.IN,
    searchableLabel: INVESTORS_SHORTCUTS.IN,
  },
  {
    label: (
      <div className="select-field__option">
        <Badge label={INVESTORS_SHORTCUTS.OUT} type="out_form" />
      </div>
    ),
    value: INVESTORS_SHORTCUTS.OUT,
    searchableLabel: INVESTORS_SHORTCUTS.OUT,
  },
];

export default INVESTOR_STATUS;
