import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_PDF_CONTRACT_INIT,
});

export const success = data => ({
  type: SETTLEMENTS.GET_PDF_CONTRACT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_PDF_CONTRACT_FAILURE,
});

const getPdfContract = (statusUuid, customDate) => async dispatch => {
  dispatch(init());
  try {
    await Connector.getPdfContract(statusUuid, customDate).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.download = 'umowa.pdf';
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
    });
  } catch (err) {
    dispatch(failure());
  }
};

export default getPdfContract;
