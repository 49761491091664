import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { RegularField } from '../fields';

const saveSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Min. 3 znaki').max(50, 'Maks. 50 znaków').required('Pole wymagane'),
});

function SaveModal({ onClose, opened, title, onSubmit }) {
  const isMobileDevice = isMobile();
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.SHOW}>
            <Formik
              initialValues={{
                name: '',
              }}
              validationSchema={saveSchema}
              onSubmit={value => {
                onSubmit(value);
              }}
            >
              {({ handleSubmit, errors }) => (
                <form className="form">
                  <Field component={RegularField} fullWidth name="name" placeholder="Nazwa dokumentu" errors={errors} />
                  <ModalActions onClose={onClose} type={MODAL_TYPES.SAVE} handleSubmit={handleSubmit} />
                </form>
              )}
            </Formik>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

SaveModal.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default SaveModal;
