/* eslint-disable react/prop-types */
import React from 'react';
import { Table, AvailabilityBadge, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import image from 'assets/files/home-page-background.png';
import { arrayOf, func, shape, bool, number } from 'prop-types';

const COLUMNS = [
  {
    id: 'role',
    accessor: 'role',
    Header: 'Zdjęcie',
    width: 130,
    Cell: () => (
      <div className="cell--thumbnail thumbnail">
        <img src={image} alt="foto" />
      </div>
    ),
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Nazwa',
    className: 'cell--company-name',
    Cell: ({ value, original: { company } }) => (
      <div className="cell--name ">
        <h3>
          <SVG type={SVG_TYPE.BLUE_HOME} />
          {value}
        </h3>
        <p>{company && company.company_name}</p>
      </div>
    ),
  },
  {
    id: 'availability',
    accessor: 'availability',
    Header: 'Dostępność',
    width: 230,
    Cell: ({
      original: {
        free,
        reservation,
        signed_contract,
        pending_cashouts,
        all_status_count,
        min_price,
        max_price,
        min_size,
        max_size,
      },
    }) => (
      <div className="cell--settlementsAvailability settlementsAvailability">
        <div className="settlementsAvailability__header">
          <span className="settlementsAvailability__totalCount">{all_status_count}</span>
          <AvailabilityBadge count={free} type="free" />
          <AvailabilityBadge count={reservation} type="reservation" />
          <AvailabilityBadge count={pending_cashouts} type="reservation" />
          <AvailabilityBadge count={signed_contract} type="signed_contract" />
        </div>
        <div className="settlementsAvailability__sizes">
          <ul className="settlementsAvailability__sizes--list">
            <li className="settlementsAvailability__sizes--element">
              {min_price} - {max_price} zł
            </li>
            <li className="settlementsAvailability__sizes--element">
              {min_size} m2 - {max_size} m2
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Akcje',
    width: 150,
    Cell: ({ original: { updated_at } }) => (
      <div className="cell--media media">
        <div className="media__lastUpdated">{updated_at}</div>
        <div className="media__list">
          <SVG type={SVG_TYPE.MEDIA_WATER} />
          <SVG type={SVG_TYPE.MEDIA_POWER} />
          <SVG type={SVG_TYPE.MEDIA_HEAT} />
        </div>
      </div>
    ),
  },
];

const SettlementList = ({archivedSettlements, onSettlementActiveChange, isActive, isMobile, rowActiveByLink }) => {
  const [activeRowId, setActiveRowId] = React.useState(undefined);

  React.useEffect(() => {
    if (rowActiveByLink != null) {
      setActiveRowId(rowActiveByLink);
    } else if (isActive === false) {
      setActiveRowId(undefined);
    }
  }, [isActive, rowActiveByLink]);

  const onActiveChange = idx => {
    !isMobile && setActiveRowId(idx);
    onSettlementActiveChange(idx);
  };

  return (
    <div className="settlementList">
      <Table onActiveUpdate={onActiveChange} activeRowId={activeRowId} data={archivedSettlements} columns={COLUMNS} />
    </div>
  );
};
SettlementList.propTypes = {
  isActive: bool.isRequired,
  onSettlementActiveChange: func.isRequired,
  rowActiveByLink: number.isRequired,
  settlements: arrayOf(shape({})).isRequired,
};
export default SettlementList;
