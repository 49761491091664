import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import { object, string, ref } from 'yup';
import { RegularField, Button } from 'shared/components';
import { VALIDATION_MESSAGES, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';
import Title from '../title';

const { REQUIRED_FIELD, SAME_PASSWORD } = VALIDATION_MESSAGES;
const getNewPasswordSchema = () =>
  object().shape({
    confirm_new_password: string()
      .required(REQUIRED_FIELD)
      .oneOf([ref('new_password'), null], SAME_PASSWORD),
    new_password: string().required(REQUIRED_FIELD),
  });

const NewPassword = ({ setNewPassword, isHomePagePreloaderVisible, history }) => {
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const handleOnSubmit = async values => {
    if (!(await setNewPassword(values))) {
      setIsTokenExpired(true);
    } else {
      history.push(ROUTES.HOME_PAGE);
    }
  };
  if (sessionStorage.getItem('resetToken')) {
    return (
      <div className={`login ${isHomePagePreloaderVisible ? 'login--shadowed' : ''}`}>
        <Title title="Ustaw hasło" />
        <div className="login__form">
          <Formik
            initialValues={{ new_password: '', confirm_new_password: '' }}
            onSubmit={handleOnSubmit}
            validationSchema={getNewPasswordSchema()}
          >
            {({ handleSubmit, errors }) => (
              <>
                <form onSubmit={handleSubmit}>
                  {isTokenExpired && !isHomePagePreloaderVisible && (
                    <div className="login__error">ważność linku wygasła, wygeneruj go ponownie</div>
                  )}
                  <Field component={RegularField} errors={errors} label="hasło" name="new_password" type="password" />
                  <Field
                    component={RegularField}
                    errors={errors}
                    label="potwierdź hasło"
                    name="confirm_new_password"
                    type="password"
                  />
                  {!isHomePagePreloaderVisible && (
                    <div className="login__actions">
                      <Link className="reset-password__back" to={ROUTES.HOME_PAGE}>
                        wróć
                      </Link>
                      <Button classNames="green" label="Dalej" type="submit" />
                    </div>
                  )}
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  return <Redirect to={ROUTES.HOME_PAGE} />;
};

NewPassword.propTypes = {
  history: PropTypes.object.isRequired,
  isHomePagePreloaderVisible: PropTypes.bool.isRequired,
  setNewPassword: PropTypes.func.isRequired,
};

export default NewPassword;
