import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

function Search({ handleSearchView, userSearchInput, closeSearchView }) {
  return (
    <div className="dashboard-header__search">
      <p className="dashboard-header__info">Szukaj:</p>
      <input className="dashboard-header__input" type="text" onChange={handleSearchView} value={userSearchInput} />
      {userSearchInput && (
        <div className="dashboard-header__clear" onClick={() => closeSearchView()}>
          <SVG type={SVG_TYPE.EXIT} />
        </div>
      )}
    </div>
  );
}

export default Search;

Search.propTypes = {
  closeSearchView: PropTypes.func.isRequired,
  handleSearchView: PropTypes.func.isRequired,
  userSearchInput: PropTypes.func.isRequired,
};
