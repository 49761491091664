import * as Connector from 'shared/connectors/investorsConnector';
import { INVESTOR } from 'store/types';

const init = () => ({
  type: INVESTOR.GET_INVESTORS_OPTIONS_INIT,
});

export const success = data => ({
  type: INVESTOR.GET_INVESTORS_OPTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INVESTOR.GET_INVESTORS_OPTIONS_FAILURE,
});

const getInvestorsOptions = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getInvestorsOptions();
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getInvestorsOptions;
