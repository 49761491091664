import React from 'react';
import { object } from 'prop-types';

function DeleteModalContent({ data }) {
  return (
    <>
      <div className="employees-right__group-wrapper">
        <div className="employees-right__group-label">Dane konta</div>
      </div>
      <div className="employees-right__container">
        <div className="employees-right__field-wrapper">
          <div className="employees-right__field-label">Nazwa</div>
          <div className="employees-right__input">{data.name}</div>
        </div>
        <div className="employees-right__field-wrapper">
          <div className="employees-right__field-label">Adres email</div>
          <div className="employees-right__input">{data.email}</div>
        </div>
      </div>
    </>
  );
}

DeleteModalContent.propTypes = {
  data: object.isRequired,
};

export default DeleteModalContent;
