import * as Connector from 'shared/connectors/notificationConnector';
import { NOTIFICATION } from 'store/types';
import getSingleNotification from 'store/actions/notification/getSingleNotification';

const init = () => ({
  type: NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_INIT,
});

const failure = () => ({
  type: NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_FAILURE,
});

const changeSingleNotificationStatus = (status, uuid) => async dispatch => {
  dispatch(init());
  try {
    await Connector.changeStatus(status, uuid);
    dispatch(getSingleNotification(uuid));
    dispatch();
  } catch (err) {
    dispatch(failure());
  }
};

export default changeSingleNotificationStatus;
