import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_SECONDARY_SETTLEMENTS_INIT,
});

export const success = settlements => ({
  type: SETTLEMENTS.GET_SECONDARY_SETTLEMENTS_SUCCESS,
  payload: {
    settlements,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_SECONDARY_SETTLEMENTS_FAILURE,
});

const getSecondarySettlements = (params = {}) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getSecondarySettlements({ ...params, secondary: 1 });
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getSecondarySettlements;
