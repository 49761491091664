const PARTNER = {
  GET_PARTNERS_INIT: 'GET_PARTNERS_INIT',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  GET_PARTNERS_FAILURE: 'GET_PARTNERS_FAILURE',
  GET_BONUSES_OPTIONS_INIT: 'GET_BONUSES_OPTIONS_INIT',
  GET_BONUSES_OPTIONS_SUCCESS: 'GET_BONUSES_OPTIONS_SUCCESS',
  GET_BONUSES_OPTIONS_FAILURE: 'GET_BONUSES_OPTIONS_FAILURE',
  ADD_PARTNER_INIT: 'ADD_PARTNER_INIT',
  ADD_PARTNER_SUCCESS: 'ADD_PARTNER_SUCCESS',
  ADD_PARTNER_FAILURE: 'ADD_PARTNER_FAILURE',
  GET_PARTNERS_OPTIONS_INIT: 'GET_PARTNERS_OPTIONS_INIT',
  GET_PARTNERS_OPTIONS_SUCCESS: 'GET_PARTNERS_OPTIONS_SUCCESS',
  GET_PARTNERS_OPTIONS_FAILURE: 'GET_PARTNERS_OPTIONS_FAILURE',
  EDIT_PARTNER_INIT: 'EDIT_PARTNER_INIT',
  EDIT_PARTNER_SUCCESS: 'EDIT_PARTNER_SUCCESS',
  EDIT_PARTNER_FAILURE: 'EDIT_PARTNER_FAILURE',
  DELETE_PARTNER_INIT: 'DELETE_PARTNER_INIT',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
  DELETE_PARTNER_FAILURE: 'DELETE_PARTNER_FAILURE',
  GET_ATTACHED_DDIS_INIT: 'GET_ATTACHED_DDIS_INIT',
  GET_ATTACHED_DDIS_SUCCESS: 'GET_ATTACHED_DDIS_SUCCESS',
  GET_ATTACHED_DDIS_FAILURE: 'GET_ATTACHED_DDIS_FAILURE',
  GET_ATTACHED_ADVISERS_INIT: 'GET_ATTACHED_ADVISERS_INIT',
  GET_ATTACHED_ADVISERS_SUCCESS: 'GET_ATTACHED_ADVISERS_SUCCESS',
  GET_ATTACHED_ADVISERS_FAILURE: 'GET_ATTACHED_ADVISERS_FAILURE',
  ATTACH_DDI_TO_PARTNER_INIT: 'ATTACH_DDI_TO_PARTNER_INIT',
  ATTACH_DDI_TO_PARTNER_SUCCESS: 'ATTACH_DDI_TO_PARTNER_SUCCESS',
  ATTACH_DDI_TO_PARTNER_FAILURE: 'ATTACH_DDI_TO_PARTNER_FAILURE',
  GET_ARCHIVED_PARTNERS_INIT: 'GET_ARCHIVED_PARTNERS_INIT',
  GET_ARCHIVED_PARTNERS_SUCCESS: 'GET_ARCHIVED_PARTNERS_SUCCESS',
  GET_ARCHIVED_PARTNERS_FAILURE: 'GET_ARCHIVED_PARTNERS_FAILURE',
  GET_ARCHIVED_PARTNER_INIT: 'GET_ARCHIVED_PARTNER_INIT',
  GET_ARCHIVED_PARTNER_SUCCESS: 'GET_ARCHIVED_PARTNER_SUCCESS',
  GET_ARCHIVED_PARTNER_FAILURE: 'GET_ARCHIVED_PARTNER_FAILURE',
  RESTORE_PARTNER_INIT: 'RESTORE_PARTNER_INIT',
  RESTORE_PARTNER_SUCCESS: 'RESTORE_PARTNER_SUCCESS',
  RESTORE_PARTNER_FAILURE: 'RESTORE_PARTNER_FAILURE',
};

export default PARTNER;
