import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { RegularField, Button } from 'shared/components';
import * as Yup from 'yup';
import { VALIDATION_MESSAGES, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';
import Title from '../title';

const getResetPasswordSchema = () =>
  Yup.object().shape({
    email: Yup.string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED_FIELD),
  });

const ResetPassword = ({ sendResetPasswordEmail, isHomePagePreloaderVisible, history }) => {
  const [isWrongEmail, setIsWrongEmail] = useState(false);
  const handleOnSubmit = async values => {
    if (!(await sendResetPasswordEmail(values))) {
      setIsWrongEmail(true);
    } else {
      history.push(ROUTES.HOME_PAGE);
    }
  };
  return (
    <div className={`reset-password ${isHomePagePreloaderVisible ? 'reset-password--shadowed' : ''}`}>
      <Title title="Resetowanie hasła" />
      <div className="reset-password__form">
        <Formik initialValues={{ email: '' }} onSubmit={handleOnSubmit} validationSchema={getResetPasswordSchema}>
          {({ handleSubmit, errors }) => (
            <>
              <form>
                {isWrongEmail && !isHomePagePreloaderVisible && <div className="login__error">błędny adres email</div>}
                <Field component={RegularField} errors={errors} label="email" name="email" type="text" />
              </form>
              <div className="reset-password__description">
                Podaj adres email przypisany do konta.
                <br />
                Wyślemy na niego instrukcję resetowania hasła
              </div>
              {!isHomePagePreloaderVisible && (
                <div className="reset-password__actions">
                  <Link className="reset-password__back" to={ROUTES.HOME_PAGE}>
                    wróć
                  </Link>
                  <Button classNames="green reset-password__submit" label="Dalej" onClick={handleSubmit} />
                </div>
              )}
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  isHomePagePreloaderVisible: PropTypes.bool.isRequired,
  sendResetPasswordEmail: PropTypes.func.isRequired,
};

export default ResetPassword;
