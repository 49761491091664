import React from 'react';
import Modal from 'shared/components/modal/Modal';
import { MODAL_TYPES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { SelectField, ModalActions } from 'shared/components';
import './filter-modal.scss';
import PropTypes from 'prop-types';
import { options, utils } from './utils';

const FilterModal = ({ isOpened, onClose, onChange, secondaryFieldOptions, onSubmit, filters, fetchMethods }) => {
  function validateField(value) {
    let error;
    if (!value) {
      error = 'Wymagane';
    }
    return error;
  }

  return (
    <Modal title="Filtruj widok" type={MODAL_TYPES.MANAGE} opened={isOpened} onClose={onClose}>
      <Formik
        initialValues={
          filters && Object.keys(filters).length > 0
            ? {
                isFilters: true,
                category: filters.category,
                customer_uuid: filters.customer_uuid,
                ddi_uuid: filters.ddi_uuid,
                settlement_uuids: filters.settlement_uuids,
                company_uuids: filters.company_uuid,
                category2: filters.category2,
                customer_uuid2: filters.customer_uuid2,
                ddi_uuid2: filters.ddi_uuid2,
                settlement_uuids2: filters.settlement_uuids2,
                company_uuids2: filters.company_uuid2,
              }
            : {
                isFilters: true,
                category: null,
                customer_uuid: '',
                ddi_uuid: '',
                settlement_uuids: [],
                company_uuids: [],
                category2: null,
                customer_uuid2: '',
                ddi_uuid2: '',
                settlement_uuids2: [],
                company_uuids2: [],
              }
        }
        onChange={values => {
          onChange(values);
        }}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({ handleSubmit, errors, values: { category, category2 } }) => (
          <form className="form">
            <Field
              options={options(fetchMethods).filter(option => option.accessor !== category2)}
              component={SelectField}
              fullWidth
              name="category"
              placeholder="Kategoria"
              validate={validateField}
              errors={errors}
              onChange={onChange}
            />
            <Field
              options={secondaryFieldOptions.primary}
              component={SelectField}
              isMulti={utils[category]?.fieldName === 'settlement_uuids' || utils[category]?.fieldName === 'company_uuids'}
              fullWidth
              name={utils[category]?.fieldName}
              placeholder={utils[category]?.placeholder || 'Filtruj po'}
              errors={errors}
              validate={validateField}
              isCustomLabel
            />
            {/* <div className="filtersInfo">Filtry dodatkowe (nieobowiązkowe)</div>
            <Field
              options={options2(fetchMethods).filter(option => option.accessor !== category)}
              component={SelectField}
              fullWidth
              name="category2"
              placeholder="Kategoria"
              errors={errors}
              onChange={onChange}
            />
            <Field
              options={secondaryFieldOptions.secondary}
              component={SelectField}
              isMulti={utils[category2]?.fieldName === 'settlement_uuids2' || utils[category2]?.fieldName === 'company_uuids2'}
              fullWidth
              name={utils[category2]?.fieldName}
              placeholder={utils[category2]?.placeholder || 'Filtruj po'}
              errors={errors}
              isCustomLabel
            /> */}
            {/* <span className="form__counter">Wybrano 2/24</span> */}
            <ModalActions type={MODAL_TYPES.MANAGE} onClose={onClose} handleSubmit={handleSubmit} />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default FilterModal;

FilterModal.propTypes = {
  fetchMethods: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  isOpened: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  secondaryFieldOptions: PropTypes.func.isRequired,
};
