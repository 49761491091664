import * as React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const SelectField = ({
  field: { name, value },
  form,
  errors,
  placeholder,
  options,
  isCustomLabel,
  label,
  onChange,
  menuPlacement,
  isMulti,
}) => {
  const isError = !!(form.touched[name] && errors[name]);
  const fieldClasses = `select-field__input ${isError ? 'select-field__input--error' : ''}`;
  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <SVG type={SVG_TYPE.DROPDOWN_INDICATOR} />
    </components.DropdownIndicator>
  );

  const handleChange = tempValue => {
    // console.log(tempValue);
    form.setFieldValue(name, tempValue ? tempValue.value : '');
    if (name === 'role') {
      form.setFieldValue('position', '');
    }
    // console.log(tempValue);
    if (isMulti) {
      const uuids = tempValue.map(option => option.value);
      form.setFieldValue(name, uuids);
      // tempValue.map(option => onChange({ ...state, [name]: [...state[name], option.value] })); // requires external state handler, enables send data in array
    } else {
      onChange && onChange(tempValue, form.setFieldValue);
    }
  };

  const customFilter = (option, searchText) => {
    // console.log(option);
    // console.log(searchText);
    if (option.data.searchableLabel.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  };

  // console.log(options, 'jestem options');
  const getValue = () => (value || value == null || value === 0 ? options && options.find(option => option.value === value) : '');

  return (
    <div className="select-field__wrapper">
      {label && <div className="select-field__label">{label}</div>}
      <div className="select-field">
        <div className="select-field__container">
          <Select
            className={`react-select ${fieldClasses}`}
            classNamePrefix="react-select"
            components={{ DropdownIndicator }}
            filterOption={isCustomLabel && customFilter}
            isClearable
            isMulti={isMulti}
            isSearchable
            noOptionsMessage={() => 'Brak wyników'}
            onChange={handleChange}
            options={options}
            placeholder={placeholder}
            value={getValue()}
            menuPlacement={menuPlacement || 'bottom'}
          />
          {isError && <div className="select-field__error-label">{errors[name]}</div>}
        </div>
      </div>
    </div>
  );
};
SelectField.propTypes = {
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  isCustomLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  setSettlements: PropTypes.func,
  state: PropTypes.object,
};

SelectField.defaultProps = {
  isCustomLabel: false,
  isMulti: false,
  label: '',
  menuPlacement: '',
  onChange: null,
  options: [],
  placeholder: '',
  setSettlements: false,
  state: false,
};

export default SelectField;
