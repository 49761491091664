import React from 'react';
import animationData from 'assets/files/preloader.json';
import Lottie from 'react-lottie';

const options = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function Preloader() {
  return (
    <div className="preloader">
      <Lottie height={110} options={options} width={110} />
    </div>
  );
}

export default Preloader;
