import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MAP } from 'shared/components';
import { MAP_TYPE } from 'shared/consts';
import PlotDetails from './PlotDetails';

const Map = ({ openModal, isMobile, settlementId, plots, history, className }) => {
  const [plotInfo, setPlotInfo] = useState(false);
  useEffect(() => {
    plots.forEach(({ name, status }) => {
      const singlePlot = document.getElementById(name);
      singlePlot && singlePlot.classList.add(`map__plot-${status}`);
    });
  }, []);

  const handleClickPlot = e => {
    const activeElement = document.getElementsByClassName('map__plot--active');
    activeElement.length >= 1 && activeElement[0].classList.remove('map__plot--active');
    e.target.classList.add('map__plot--active');
    const activePlot = plots.filter(({ name }) => e.target.id === name);
    setPlotInfo(activePlot[0]);
  };
  return (
    <div className="map__wrapper settlement-plots__content employees-right__wrapper">
      <div className={`map ${isMobile ? 'map--mobile' : ''} ${className}`}>
        <div className={`map__item map__item--${settlementId}`}>
          <MAP
            plots={plots}
            onClick={e => handleClickPlot(e)}
            className={`map__settlement map__settlement--${settlementId}`}
            type={MAP_TYPE[settlementId]}
            isMobile={isMobile}
          />
        </div>
        {plotInfo && (
          <PlotDetails history={history} setPlotInfo={setPlotInfo} isMobile={isMobile} openModal={openModal} plot={plotInfo} />
        )}
      </div>
    </div>
  );
};

export default Map;

Map.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  plots: PropTypes.array.isRequired,
  settlementId: PropTypes.string.isRequired,
  settlementName: PropTypes.string.isRequired,
};
Map.defaultProps = {
  className: '',
};
