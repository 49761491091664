import React from 'react';
import * as Connector from 'shared/connectors/investorsConnector';
import { INVESTOR } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import { history } from 'store/store';
import getInvestors from './getInvestors';

const init = () => ({
  type: INVESTOR.DELETE_INVESTOR_INIT,
});

export const success = () => ({
  type: INVESTOR.DELETE_INVESTOR_SUCCESS,
});

const failure = () => ({
  type: INVESTOR.DELETE_INVESTOR_FAILURE,
});

const deleteInvestor = investorData => async dispatch => {
  dispatch(init());
  try {
    await Connector.deleteInvestor(investorData.uuid);
    await dispatch(getInvestors());
    const url = window.location.pathname.split('/');
    url.pop();
    history.push(url.join('/'));
    toast(
      <NewUser
        badgeType="Inwst"
        first_name={investorData.first_name}
        last_name={investorData.last_name}
        label="Usunięto inwestora"
      />,
    );
    dispatch(success());
  } catch (err) {
    dispatch(failure());
  }
};

export default deleteInvestor;
