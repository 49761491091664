import * as Connector from 'shared/connectors/offerConnector';
import { OFFER } from 'store/types';
import getOffers from 'store/actions/start/getOffers';
import { showPreloader, hidePreloader } from '../viewManagement';
import getWaitingOffers from './getWaitingOffers';
import getAcceptedOffers from './getAcceptedOffers';

const init = () => ({
  type: OFFER.OFFER_CHANGE_STATUS_INIT,
});

export const success = () => ({
  type: OFFER.OFFER_CHANGE_STATUS_SUCCESS,
});

const failure = () => ({
  type: OFFER.OFFER_CHANGE_STATUS_FAILURE,
});

const offerChangeStatus = ({ userRole, plotUuid, offerUuid, acceptance, admin_comment }) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const { data } = await Connector.offerChangeStatus(offerUuid, acceptance, admin_comment);
    dispatch(getWaitingOffers({ userRole, plotUuid }));
    dispatch(getAcceptedOffers({ userRole, plotUuid }));
    dispatch(getOffers());
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err;
  }
};

export default offerChangeStatus;
