import * as React from 'react';
import PropTypes from 'prop-types';

const Label = ({ label, errors, field: { name }, isError }) => {
  const labelClasses = `regular-field__label ${isError ? 'regular-field__label--error' : ''}`;
  return (
    <label className={labelClasses} htmlFor={name}>
      {isError ? errors[name] : label}
    </label>
  );
};
Label.propTypes = {
  errors: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default Label;
