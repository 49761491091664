import React, { useState } from 'react';
import { object, array, func, bool } from 'prop-types';
import { Button, ActiveWindow, DeleteModal } from 'shared/components';
import { FIELD_TYPE } from 'shared/components/active-window/consts';
import { AREA_TYPES, ACTION_TYPES, MODAL_TYPES, FORM_TYPES, SVG_TYPE } from 'shared/consts';
import { checkAccess } from 'shared/utils';
import Form from 'screens/partners/components/form';
import { getBonusLabel } from './utils';
import DeleteModalContent from '../delete-modal-content/DeleteModalContent';

const { INPUT, SELECT } = FIELD_TYPE;

function PartnerData({
  archived,
  data,
  data: { active, name, provision, email, regon, nip, krs, bonus_thresholds },
  bonusesOptions,
  editPartner,
  deletePartner,
  restorePartner,
}) {
  const [modalOpened, setModalOpened] = useState(null);
  const activePropertyLabel = active ? 'Aktywny' : 'Nieaktywny';
  const activePropertyCustomClassName = active ? 'active' : '';

  const processedProvisionValue = `% ${provision * 100 ?? ''}`; // modify two decimal places value to percentages
  const bonusValue = getBonusLabel(bonus_thresholds, bonusesOptions); // find and return label from {label,value} array
  const isEditEnabled = checkAccess(AREA_TYPES.PARTNERS, ACTION_TYPES.EDIT);
  const isDeleteEnabled = checkAccess(AREA_TYPES.PARTNERS, ACTION_TYPES.DELETE);

  const closeModal = () => setModalOpened(null);

  const handleDelete = () => {
    deletePartner(data);
    setModalOpened(null);
  };

  return (
    <div className="active-window__data">
      <Form
        data={{ ...data, provision: data.provision * 100 }}
        onClose={closeModal}
        bonusesOptions={bonusesOptions}
        onSubmit={editPartner}
        opened={modalOpened === MODAL_TYPES.EDIT}
        type={FORM_TYPES.EDIT}
      />
      <DeleteModal
        title="Czy chcesz usunąć partnera?"
        onClose={closeModal}
        onSubmit={handleDelete}
        opened={modalOpened === MODAL_TYPES.DELETE}
      >
        <DeleteModalContent data={data} />
      </DeleteModal>
      <div className="active-window__group-wrapper">
        <div className="active-window__group-label">Status</div>
        <div className="active-window__row-align">
          <ActiveWindow.Field type={SELECT} value={activePropertyLabel} customClassName={activePropertyCustomClassName} />
          <ActiveWindow.Field type={INPUT} label="Prowizja" value={processedProvisionValue} />
        </div>
        <div className="active-window__group-label">Dane konta</div>
        <ActiveWindow.Field type={INPUT} label="Nazwa" value={name} />
        <ActiveWindow.Field type={INPUT} label="Adres email" value={email} />
        <ActiveWindow.Field type={SELECT} label="Progi bonusowe" value={bonusValue} />
        <div className="active-window__group-label">Dane konta</div>
        <ActiveWindow.Field type={INPUT} label="REGON" value={regon || 'Brak'} />
        <ActiveWindow.Field type={INPUT} label="NIP" value={nip} />
        <ActiveWindow.Field type={INPUT} label="KRS" value={krs || 'Brak'} />
        {archived ? (
          <div className="employees-right__actions">
            <Button iconType={SVG_TYPE.RESTORE} classNames="orange" label="Przywróć" onClick={() => restorePartner(data)} />
          </div>
        ) : (
          <div className="active-window__actions">
            {isEditEnabled && <Button classNames="orange" label="Edytuj dane" onClick={() => setModalOpened(MODAL_TYPES.EDIT)} />}
            {isDeleteEnabled && <Button classNames="red" label="Usuń konto" onClick={() => setModalOpened(MODAL_TYPES.DELETE)} />}
          </div>
        )}
      </div>
    </div>
  );
}

PartnerData.defaultProps = {
  archived: false,
};

PartnerData.propTypes = {
  archived: bool,
  bonusesOptions: array.isRequired,
  data: object.isRequired,
  deletePartner: func.isRequired,
  editPartner: func.isRequired,
  restorePartner: func.isRequired,
};

export default PartnerData;
