import React from 'react';
import { array, func } from 'prop-types';
import { ActiveWindow, Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import List from '../list/List';

const NotificationsByDate = ({ activeDayNotifications, handleOpenModal, handleDoneStatus, handleToDoStatus }) => (
  <ActiveWindow.Wrapper customClassName="notificationsRight">
    <div className="notificationsRight__header">
      <div className="notificationsRight__title">Powiadomienia</div>
      <Button classNames="transparent no-padding right-icon" iconType={SVG_TYPE.ADD} label="Dodaj" onClick={handleOpenModal} />
    </div>
    <List
      activeDayNotifications={activeDayNotifications}
      handleDoneStatus={handleDoneStatus}
      handleToDoStatus={handleToDoStatus}
    />
  </ActiveWindow.Wrapper>
);

NotificationsByDate.propTypes = {
  activeDayNotifications: array.isRequired,
  handleDoneStatus: func.isRequired,
  handleOpenModal: func.isRequired,
  handleToDoStatus: func.isRequired,
};
export default NotificationsByDate;
