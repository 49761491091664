const STATUS = {
  CHANGE_STATUS_INIT: 'CHANGE_STATUS_INIT',
  CHANGE_STATUS_SUCCESS: 'CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILURE: 'CHANGE_STATUS_FAILURE',
  ADD_CONTRACTS_INIT: 'ADD_CONTRACTS_INIT',
  ADD_CONTRACTS_SUCCESS: 'ADD_CONTRACTS_SUCCESS',
  ADD_CONTRACTS_FAILURE: 'ADD_CONTRACTS_FAILURE',
  CONFIRM_DEPOSIT_PAYMENT_INIT: 'CONFIRM_DEPOSIT_PAYMENT_INIT',
  CONFIRM_DEPOSIT_PAYMENT_SUCCESS: 'CONFIRM_DEPOSIT_PAYMENT_SUCCESS',
  CONFIRM_DEPOSIT_PAYMENT_FAILURE: 'CONFIRM_DEPOSIT_PAYMENT_FAILURE',
};

export default STATUS;
