import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SettlementList } from 'screens/settlements/components';
import { Button } from 'shared/components';
import { AREA_TYPES, REQUEST_STATUS, ROUTES } from 'shared/consts';
import Skeleton from 'react-loading-skeleton';
import { generatePath, withRouter } from 'react-router-dom';
import PlotsList from './components/PlotList';
import EmployeeList from './components/EmployeeList';
import PartnerList from './components/PartnerList';
import NotificationList from './components/NotificationList';
import InvestorsList from './components/InvestorsList';

const DashboardSearch = ({ searchItems, items, history, closeSearchView, status, userSearchRequest }) => {
  const [isSomethingToShow, setIsSomeThingToShow] = useState();

  useEffect(async () => {
    if (userSearchRequest.length > 0) {
      await searchItems(userSearchRequest);
      setIsSomeThingToShow();
    }
  }, [userSearchRequest]);

  const [settlementsButtonLabel, setSettlementsButtonLabel] = useState('Pokaż więcej');
  const [plotsButtonLabel, setPlotsButtonLabel] = useState('Pokaż więcej');
  const [usersButtonLabel, setUsersButtonLabel] = useState('Pokaż więcej');
  const [partnerButtonLabel, setPartnerButtonLabel] = useState('Pokaż więcej');
  const [customerButtonLabel, setCustomerButtonLabel] = useState('Pokaż więcej');
  const [notificationsButtonLabel, setNotificationsButtonLabel] = useState('Pokaż więcej');

  const manage = (item, changeLabel, label) => {
    label === 'Pokaż więcej' ? changeLabel('Pokaż mniej') : changeLabel('Pokaż więcej');
    document.querySelector(`.searchView_container-${item}`).classList.toggle('show');
  };

  const isLoading = userSearchRequest.length === 0 || status === REQUEST_STATUS.INIT;
  const isElementFound = items?.map(item => Object.entries(item));

  const onSettlementActiveChange = idx => {
    const { uuid } = items[4]?.Settlements[idx];
    history.push(generatePath(ROUTES.SETTLEMENTS_ID, { uuid }));
    closeSearchView();
  };

  useEffect(() => {
    isElementFound
      .map(element => element.map(item => item[1].length > 0))
      .forEach(element => {
        element[0] ? setIsSomeThingToShow(true) : null;
      });
  }, [items]);

  return (
    <div className="searchView">
      <div className="searchView_container">
        {isLoading ? (
          <>
            <Skeleton count={10} height={50} />
          </>
        ) : (
          <>
            {items[4]?.Settlements.length > 0 ? (
              <>
                <div className="searchView_container-title">
                  Osady
                  {items[4]?.Settlements.length > 4 ? (
                    <Button
                      label={settlementsButtonLabel}
                      type="button"
                      classNames="transparent"
                      onClick={() => manage('settlements', setSettlementsButtonLabel, settlementsButtonLabel)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="searchView_container-settlements">
                  <SettlementList settlements={items[4]?.Settlements} onSettlementActiveChange={onSettlementActiveChange} />
                </div>
              </>
            ) : (
              ''
            )}
            {items[2]?.Plots.length > 0 ? (
              <>
                <div className="searchView_container-title">
                  Działki
                  {items[2]?.Plots.length > 4 ? (
                    <Button
                      label={plotsButtonLabel}
                      type="button"
                      classNames="transparent"
                      onClick={() => manage('plots', setPlotsButtonLabel, plotsButtonLabel)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="searchView_container-plots">
                  <PlotsList plots={items[2]?.Plots} />
                </div>
              </>
            ) : (
              ''
            )}
            {items[0]?.Users.length > 0 ? (
              <>
                <div className="searchView_container-title">
                  Uzytkownicy
                  {items[0]?.Users.length > 4 ? (
                    <Button
                      label="Pokaz więcej"
                      type="button"
                      classNames="transparent"
                      onClick={() => manage('users', setUsersButtonLabel, usersButtonLabel)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="searchView_container-users">
                  <EmployeeList users={items[0]?.Users} area={AREA_TYPES.EMPLOYEES} closeSearchView={closeSearchView} />
                </div>
              </>
            ) : (
              ''
            )}
            {items[5]?.BusinessPartners.length > 0 ? (
              <>
                <div className="searchView_container-title">
                  Partnerzy
                  {items[5]?.BusinessPartners.length > 4 ? (
                    <Button
                      label={partnerButtonLabel}
                      type="button"
                      classNames="transparent"
                      onClick={() => manage('business_partners', setPartnerButtonLabel, partnerButtonLabel)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="searchView_container-business_partners">
                  <PartnerList
                    area={AREA_TYPES.PARTNERS}
                    partners={items[5]?.BusinessPartners}
                    closeSearchView={closeSearchView}
                  />
                </div>
              </>
            ) : (
              ''
            )}
            {items[1]?.Customers.length > 0 ? (
              <>
                <div className="searchView_container-title">
                  Inwestorzy
                  {items[1]?.Customers.length > 4 ? (
                    <Button
                      label={customerButtonLabel}
                      type="button"
                      classNames="transparent"
                      onClick={() => manage('investors', setCustomerButtonLabel, customerButtonLabel)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="searchView_container-investors">
                  <InvestorsList area={AREA_TYPES.INVESTORS} partners={items[1]?.Customers} closeSearchView={closeSearchView} />
                </div>
              </>
            ) : (
              ''
            )}
            {items[3]?.Notification.length > 0 ? (
              <>
                <div className="searchView_container-title">
                  Powiadomienia
                  {items[3]?.Notification.length > 4 ? (
                    <Button
                      label={notificationsButtonLabel}
                      type="button"
                      classNames="transparent"
                      onClick={() => manage('notifications', setNotificationsButtonLabel, notificationsButtonLabel)}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <div className="searchView_container-notifications">
                  <NotificationList notifications={items[3]?.Notification} closeSearchView={closeSearchView} />
                </div>
              </>
            ) : (
              ''
            )}
          </>
        )}
        {!isLoading && !isSomethingToShow && <div className="list__empty">Brak elementów spełniających wyszukiwanie</div>}
      </div>
    </div>
  );
};

export default withRouter(DashboardSearch);

DashboardSearch.propTypes = {
  closeSearchView: PropTypes.func.isRequired,
  items: PropTypes.object.isRequired,
  searchItems: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userSearchRequest: PropTypes.string.isRequired,
};
