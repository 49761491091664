export const options = [
  {
    label: 'Osada',
    value: 'settlement',
    searchableLabel: 'Osada',
  },

  {
    label: 'Działka',
    value: 'plot_name',
    searchableLabel: 'Działka',
  },

  {
    label: 'Partner',
    value: 'partner',
    searchableLabel: 'Partner',
  },

  {
    label: 'Spółka',
    value: 'company',
    searchableLabel: 'Spółka',
  },

  {
    label: 'Inwestor',
    value: 'investor',
    searchableLabel: 'Inwestor',
  },

  {
    label: 'Data wpłaty pełnej kwoty',
    value: 'deadline_pay',
    searchableLabel: 'Data wpłaty pełnej kwoty',
  },

  {
    label: 'Data wstępnej rezerwacji',
    value: 'pre_booking_date',
    searchableLabel: 'Data wstępnej rezerwacji',
  },

  {
    label: 'Status działki',
    value: 'status',
    searchableLabel: 'Status działki',
  },

  {
    label: 'Data umowy przedwstępnej',
    value: 'preliminary_agreement',
    searchableLabel: 'Data umowy przedwstępnej',
  },

  // {
  //   label: 'Data i godzina aktu przyrzeczonego',
  //   value: '',
  //   searchableLabel: 'Data i godzina aktu przyrzeczonego',
  // },
  {
    label: 'Cena katalogowa',
    value: 'price',
    searchableLabel: 'Cena katalogowa',
  },
  {
    label: 'Udzielony rabat',
    value: 'gave_discount',
    searchableLabel: 'Udzielony rabat',
  },
  {
    label: 'Cena sprzedazy za całość brutto z częściami wspolnami',
    value: 'brutto',
    searchableLabel: 'Cena sprzedazy za całość brutto z częściami wspolnami',
  },
  {
    label: 'Cena sprzedazy za całość netto z częściami wspolnami',
    value: 'netto',
    searchableLabel: 'Cena sprzedazy za całość netto z częściami wspolnami',
  },
  {
    label: 'Zapłacony zadatek i udziały brutto',
    value: 'deposit_payed',
    searchableLabel: 'Zapłacony zadatek i udziały brutto',
  },
  {
    label: 'Kwota - wpłacona w II transzy brutto',
    value: 'payed_all_brutto',
    searchableLabel: 'Kwota - wpłacona w II transzy brutto',
  },
  {
    label: 'Reszta pozostała do zapłaty brutto',
    value: 'brutto_still_pay',
    searchableLabel: 'Reszta pozostała do zapłaty brutto',
  },
  {
    label: 'Reszta pozostała do zapłaty netto',
    value: 'netto_still_pay',
    searchableLabel: 'Reszta pozostała do zapłaty netto',
  },
];
