import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPlots, getPlotDetails, getPlotsByFilter } from 'store/actions/settlements';
import { changeStatus } from 'store/actions/status';
import {
  getPlotsSelector,
  getPlotDetails as getPlotDetailsSelector,
  getPlotFilters,
  getPlotStatus,
} from 'store/selectors/settlementsSelector';
import { getAcceptedOffers as getAcceptedOffersSelector } from 'store/selectors/offerSelector';
import { getAuthorizedUser } from 'store/selectors/authSelector';
import { getDdiOptions, getUsers, getAdvisersList } from 'store/selectors/userSelector';
import * as Actions from 'store/actions/user';
import * as InvestorActions from 'store/actions/investors';
import { getInvestorsLabel as getInvestorsLabelSelector } from 'store/selectors/investorSelector';
import { getInvestorsLabel } from 'store/actions/investors';
import { getAcceptedOffers } from 'store/actions/offer';
import { isMobile as checkIsMobile } from 'shared/utils';

import Main from './AllPlots.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsers: role => Actions.getUsers(role),
      getPlots: params => getPlots(params),
      getPlotDetails: id => getPlotDetails(id),
      changeStatus: (data, plot_uuid) => changeStatus(data, plot_uuid),
      getInvestorsLabel: id => getInvestorsLabel(id),
      getInvestors: () => InvestorActions.getInvestors(),
      getAcceptedOffers: data => getAcceptedOffers(data),
      getPlotsByFilter: params => getPlotsByFilter(params),
      getAdvisersList: uuid => Actions.getAdvisersList(uuid),
    },
    dispatch,
  ),
});
//
const mapStateToProps = state => ({
  users: getUsers(state),
  plots: getPlotsSelector(state),
  plot: getPlotDetailsSelector(state),
  authorizedUser: getAuthorizedUser(state),
  ddiOptions: getDdiOptions(state),
  investorsLabel: getInvestorsLabelSelector(state),
  acceptedOffers: getAcceptedOffersSelector(state),
  isMobile: checkIsMobile(),
  plotFilters: getPlotFilters(state),
  plotStatus: getPlotStatus(state),
  advisersList: getAdvisersList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
