import React, { useEffect, useState } from 'react';
import { Modal, SelectField, RegularField, ModalActions, OptionsLabel } from 'shared/components';
import {
  FORM_TYPES,
  MODAL_TYPES,
  POSITION_OPTIONS,
  ROLE_OPTIONS,
  VALIDATION_MESSAGES,
  USER_ROLES,
  EMPLOYEE_SHORTCUTS,
} from 'shared/consts';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';

const { ADMIN, DDI, ADVISER } = USER_ROLES;
const { EMAIL, REQUIRED_FIELD, REQUIRED_9_DIGITS } = VALIDATION_MESSAGES;

const getFormSchema = loggedUserRole =>
  object().shape({
    // business_partner_uuid: string().when('role', {
    //   is: USER_ROLES.ADVISER,
    //   then: string().required(REQUIRED_FIELD),
    // }),
    ...(loggedUserRole === ADMIN && {
      ddi_uuid: string().when('role', {
        is: USER_ROLES.ADVISER,
        then: string().required(REQUIRED_FIELD),
      }),
    }),
    email: string().email(EMAIL).required(REQUIRED_FIELD),
    first_name: string().required(REQUIRED_FIELD),
    last_name: string().required(REQUIRED_FIELD),
    phone: string().required(REQUIRED_FIELD).min(9, REQUIRED_9_DIGITS).max(9, REQUIRED_9_DIGITS),
    position: string().required(REQUIRED_FIELD),
    role: string().required(REQUIRED_FIELD),
  });

function Form({
  onClose,
  onSubmit,
  opened,
  type,
  data,
  userRole: loggedUserRole,
  partnersOptions,
  getPartnersOptions,
  getDdiOptions,
  ddiOptions,
  userUuid,
}) {
  const fetchData = async (pickedRole, fetchMethod) => {
    pickedRole === ADVISER && (await fetchMethod());
  };

  useEffect(() => {
    if (data && data.role === ADVISER && type === FORM_TYPES.EDIT) {
      loggedUserRole === ADMIN && fetchData(data.role, getDdiOptions);
      getPartnersOptions(data.ddi_uuid);
    }
  }, []);

  const handleAdviserSelected = pickedRole => {
    if (loggedUserRole === ADMIN) {
      fetchData(pickedRole?.value, getDdiOptions);
    } else if (loggedUserRole === DDI) {
      fetchData(pickedRole?.value, getPartnersOptions);
    }
  };

  const handleDdiSelected = async pickedDdi => {
    await getPartnersOptions(pickedDdi?.value);
  };

  const returnRoleOptions = () => (loggedUserRole !== DDI ? ROLE_OPTIONS : [ROLE_OPTIONS[1]]);
  const isDdiSelectVisible = role => loggedUserRole === ADMIN && role === ADVISER;
  const isBusinessPartnerSelectVisible = (role, ddi_uuid) => isDdiSelectVisible(role) && ddi_uuid;
  const isUserDdi = loggedUserRole === DDI;
  const DDI_OPTIONS_LIST = OptionsLabel(ddiOptions, EMPLOYEE_SHORTCUTS.DDI);
  const PARTNER_OPTIONS_LIST = OptionsLabel(partnersOptions, 'Partner');
  const [partnerError, setPartnerError] = useState(false);

  return (
    <Modal
      onClose={() => {
        onClose();
        setPartnerError(false);
      }}
      onSubmit={onSubmit}
      opened={opened}
      title={` ${type === FORM_TYPES.ADD ? 'Dodaj' : 'Edytuj'} użytkownika`}
      type={MODAL_TYPES.CONFIRM}
    >
      <Formik
        enableReinitialize
        initialValues={
          data
            ? { ...data, business_partner_uuid: data?.business_partner?.uuid }
            : {
                role: '',
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
                business_partner_uuid: '',
                ddi_uuid: isUserDdi ? userUuid : '',
              }
        }
        onSubmit={async (values, { setErrors }) => {
          console.log('test');
          const response = await onSubmit(values);
          if (!response) {
            onClose();
            setPartnerError(false);
          } else if (response.field === 'email') {
            setErrors({ email: VALIDATION_MESSAGES.UNIQUE_EMAIL });
          } else if (response.error) {
            console.log(response.error);
            setPartnerError(true);
          }
        }}
        validationSchema={getFormSchema(loggedUserRole)}
      >
        {({ handleSubmit, errors, values: { role, ddi_uuid } }) => (
          <>
            <form className="modal__form" onSubmit={handleSubmit}>
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                isCustomLabel
                label="Rola w systemie"
                name="role"
                options={returnRoleOptions()}
                placeholder="Wybierz rolę"
                onChange={handleAdviserSelected}
              />
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                label="Stanowisko"
                name="position"
                options={POSITION_OPTIONS.get(role)}
                placeholder="Wybierz stanowisko"
              />
              {isDdiSelectVisible(role) && (
                <Field
                  component={SelectField}
                  errors={errors}
                  fullWidth
                  label="Przypisz DDI"
                  name="ddi_uuid"
                  options={DDI_OPTIONS_LIST}
                  isCustomLabel
                  placeholder="Wybierz DDI"
                  onChange={handleDdiSelected}
                />
              )}
              {(isBusinessPartnerSelectVisible(role, ddi_uuid) || isUserDdi) && (
                <Field
                  component={SelectField}
                  errors={errors}
                  fullWidth
                  label="Przypisz partnera"
                  name="business_partner_uuid"
                  options={PARTNER_OPTIONS_LIST}
                  placeholder="Wybierz partnera"
                />
              )}
              <Field component={RegularField} errors={errors} fullWidth label="Imię" name="first_name" type="text" />
              <Field component={RegularField} errors={errors} fullWidth label="Nazwisko" name="last_name" type="text" />
              {type === FORM_TYPES.ADD && (
                <Field component={RegularField} errors={errors} fullWidth label="Adres email" name="email" type="email" />
              )}
              <Field
                className="regular-field__input--phone"
                component={RegularField}
                errors={errors}
                label="Numer tel"
                name="phone"
                type="number"
              />
              {partnerError && (
                <p className="regular-field__label--error regular-field__label--small">
                  Nie można dodać doradcy. <br />
                  Wybrany partner ma status nieaktywny
                </p>
              )}
              <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.CONFIRM} />
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

Form.defaultProps = {
  data: null,
};

Form.propTypes = {
  data: PropTypes.object,
  ddiOptions: PropTypes.array.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  type: PropTypes.oneOf([FORM_TYPES.ADD, FORM_TYPES.EDIT]).isRequired,
  userRole: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired,
};

export default Form;
