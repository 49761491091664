import React from 'react';
import { func, string } from 'prop-types';
import { SVG_TYPE, RESERVATION_STATUS, USER_ROLES, MODAL_TYPES } from 'shared/consts';
import { Button, InputComparison } from 'shared/components';

const StatusButtons = ({
  openReservationModal,
  plot,
  openPriceModal,
  loggedUser: { uuid, role },
  openModal,
  openDepositModal,
  // setIsConditionalActModal,
}) => {
  const isStatusFree = plot?.status === RESERVATION_STATUS.FREE;
  const isStatusReservation = plot?.status === RESERVATION_STATUS.RESERVATION;
  const isStatusPendingCashout = plot?.status === RESERVATION_STATUS.PENDING_CASHOUTS;
  const isStatusSignedContract = plot?.status === RESERVATION_STATUS.SIGNED_CONTRACT;
  const isStatusSold = plot?.status === RESERVATION_STATUS.SOLD;
  const isUserBooking = uuid === plot?.order_status?.ddi?.uuid;
  const isAdmin = role === USER_ROLES.ADMIN;
  const isAccounting = role === USER_ROLES.ACCOUNTING;
  const isPricePayed = plot?.order_status?.payed_price + plot?.order_status?.payed_deposit === plot?.price;
  const isArrangingTheActDone = plot?.arranging_the_act?.status === 'done';
  const isArrangingTheConditionalActDone = plot?.arranging_conditional_act?.status === 'done';
  const isConditionalAct = plot?.conditional_act;
  const isAdviser = role === 'adviser';

  const handleNavigation = link => {
    window.open(link, '_blank');
  };

  return (
    <div className="statusButtons">
      {isStatusReservation && !isUserBooking && isAdmin && (
        <Button
          classNames="light-blue left-icon left-icon--no-margin"
          iconType={SVG_TYPE.NAVIGATE}
          onClick={() => handleNavigation(plot?.settlement?.google_maps_link)}
        />
      )}
      {isStatusFree && (
        <>
          <Button
            classNames="light-blue left-icon"
            iconType={SVG_TYPE.NAVIGATE}
            label="Nawiguj"
            onClick={() => handleNavigation(plot?.settlement?.google_maps_link)}
          />
          {!isAdviser && (
            <Button
              classNames="orange left-icon"
              iconType={SVG_TYPE.DOT}
              label="Rezerwuj"
              onClick={() => openReservationModal(plot?.uuid)}
            />
          )}
        </>
      )}
      {isStatusReservation && isUserBooking && (
        <>
          <Button
            classNames="light-blue left-icon"
            iconType={SVG_TYPE.NAVIGATE}
            label="Nawiguj"
            onClick={() => handleNavigation(plot?.settlement?.google_maps_link)}
          />
          <Button
            classNames="orange right-icon"
            iconType={SVG_TYPE.CONFIRM}
            label="Potwierdź umowę"
            onClick={() => openModal(MODAL_TYPES.ADD_FILE_OFFER)}
          />
        </>
      )}
      {isStatusReservation && !isUserBooking && !isAdmin && !isAccounting && (
        <Button
          classNames="light-gray"
          label="Dodaj do oczekujących"
          onClick={() => console.log('podpinamy dodawanie do oczekujących ofert')}
        />
      )}
      {isStatusReservation && !isUserBooking && (isAdmin || isAccounting) && (
        <>
          <Button classNames="light-gray" label="Zmień status" onClick={() => openModal(MODAL_TYPES.CANCEL_RESERVATION)} />
          <Button
            classNames="orange right-icon"
            iconType={SVG_TYPE.CONFIRM}
            label="Potwierdź"
            onClick={() => openModal(MODAL_TYPES.ADD_FILE_OFFER)}
          />
        </>
      )}
      {isStatusPendingCashout && (isAccounting || isAdmin) && (
        <>
          <InputComparison
            label="Zadatek"
            first={Math.ceil(plot?.order_status?.payed_deposit)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}
            second={Math.ceil(plot?.order_status?.offer?.deposit)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}
            onClick={() => openModal(MODAL_TYPES.HISTORY_PAYMENT)}
          />
          {isAccounting && (
            <Button classNames="green" label="Dodaj wpłatę" onClick={() => openDepositModal(MODAL_TYPES.ADD_DEPOSIT)} />
          )}
        </>
      )}
      {isStatusPendingCashout && !isAccounting && (
        <Button
          classNames="light-blue left-icon"
          iconType={SVG_TYPE.NAVIGATE}
          label="Nawiguj"
          onClick={() => handleNavigation(plot?.settlement?.google_maps_link)}
        />
      )}

      {isStatusSignedContract && (isUserBooking || isAccounting || isAdmin) && (
        <>
          <InputComparison
            label="Wpłata całości"
            first={plot?.order_status?.payed_price + plot?.order_status?.payed_deposit}
            second={plot?.price}
            onClick={() => openModal(MODAL_TYPES.HISTORY_PAYMENT)}
          />
          {!isConditionalAct ? (
            <>
              {isUserBooking && isPricePayed && isArrangingTheActDone && (
                <Button classNames="red" label="Potwierdź sprzedaż" onClick={() => openModal(MODAL_TYPES.CONFIRM_SALE)} />
              )}
            </>
          ) : (
            <>
              {isUserBooking && isPricePayed && isArrangingTheActDone && isArrangingTheConditionalActDone && (
                <Button classNames="red" label="Potwierdź sprzedaż" onClick={() => openModal(MODAL_TYPES.CONFIRM_SALE)} />
              )}
            </>
          )}
          <div className="statusButtons__establishAct">
            {!isAccounting && !plot?.arranging_the_act && (
              <Button
                classNames="orange orange-calendar right-icon"
                iconType={SVG_TYPE.CALENDAR}
                label="Ustal akt przyrzeczony"
                onClick={() => openModal(MODAL_TYPES.ADD_APPOINTMENT_NOTE)}
              />
            )}
            {/* {!isAccounting && !plot?.arranging_conditional_act && isConditionalAct && (
              <Button
                classNames="orange orange-calendar right-icon item"
                iconType={SVG_TYPE.CALENDAR}
                label="Ustal akt warunkowy"
                onClick={() => {
                  openModal(MODAL_TYPES.ADD_APPOINTMENT_NOTE);
                  setIsConditionalActModal(true);
                }}
              />
            )} */}
          </div>
          {isAccounting && !isPricePayed && (
            <Button classNames="green" label="Dodaj wpłatę" onClick={() => openPriceModal(MODAL_TYPES.PAY_FOR_PLOT)} />
          )}
          {isAccounting && isPricePayed && <Button classNames="gray" label="Dodaj wpłatę" />}
        </>
      )}
      {isStatusSold && (isAdmin || isAccounting) && (
        <InputComparison
          label="Wpłata całości"
          first={plot?.order_status?.payed_price + plot?.order_status?.payed_deposit}
          second={plot?.price}
          onClick={() => openModal(MODAL_TYPES.HISTORY_PAYMENT)}
        />
      )}
    </div>
  );
};

StatusButtons.propTypes = {
  loggedUser: string.isRequired,
  openDepositModal: func.isRequired,
  openModal: func.isRequired,
  openPriceModal: func.isRequired,
  openReservationModal: func.isRequired,
  plot: string.isRequired,
  setIsConditionalActModal: func.isRequired,
};

export default StatusButtons;
