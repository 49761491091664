import { USER } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  users: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedEmployees: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedEmployee: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  allDdi: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  activeUser: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  usersAmount: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  ddiOptions: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  advisersList: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case USER.GET_USERS_INIT: {
      return {
        ...state,
        users: {
          // ...state.data,
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_USERS_FAILURE: {
      return {
        ...state,
        users: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case USER.GET_USERS_AMOUNT_INIT: {
      return {
        ...state,
        usersAmount: {
          ...state.data,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_USERS_AMOUNT_SUCCESS: {
      return {
        ...state,
        usersAmount: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_USERS_AMOUNT_FAILURE: {
      return {
        ...state,
        usersAmount: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case USER.GET_ACTIVE_USER_INIT: {
      return {
        ...state,
        activeUser: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        activeUser: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_ACTIVE_USER_FAILURE: {
      return {
        ...state,
        activeUser: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case USER.EDIT_USER_SUCCESS: {
      return {
        ...state,
        activeUser: {
          data: { ...state.activeUser.data, ...payload.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_DDI_OPTIONS_SUCCESS: {
      return {
        ...state,
        ddiOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_ARCHIVED_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        archivedEmployees: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_ARCHIVED_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        archivedEmployee: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_ALL_DDI_SUCCESS: {
      return {
        ...state,
        allDdi: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_ADVISERS_LIST_INIT: {
      return {
        ...state,
        advisersList: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case USER.GET_ADVISERS_LIST_SUCCESS: {
      return {
        ...state,
        advisersList: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case USER.GET_ADVISERS_LIST_FAILURE: {
      return {
        ...state,
        advisersList: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
