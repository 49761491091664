import React from 'react';
import * as Connector from 'shared/connectors/offerConnector';
import { OFFER } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import { showPreloader, hidePreloader } from '../viewManagement';
import getWaitingOffers from './getWaitingOffers';

const init = () => ({
  type: OFFER.ADD_OFFER_INIT,
});

export const success = () => ({
  type: OFFER.ADD_OFFER_SUCCESS,
});

const failure = () => ({
  type: OFFER.ADD_OFFER_FAILURE,
});

const addOffer = dataForm => async dispatch => {
  dispatch(init());
  const { userRole, plot_uuids, ddi_uuid, comment, investor_uuid, date_of_act } = dataForm;
  const modifiedObject = {
    comment,
    dedicated_offer: 1,
    percentage_reduction: dataForm.deposit_percentage,
    deposit_percentage: dataForm.percentage_reduction,
    plot_uuids: [plot_uuids[0]],
    investor_uuid,
    date_of_act,
  };
  try {
    dispatch(showPreloader());
    const { data } = await Connector.addOffer(modifiedObject);
    await dispatch(getWaitingOffers({ userRole, plotUuid: plot_uuids[0], ddiUuid: ddi_uuid }));
    await toast(<NewUser first_name="dodano ofertę" label="oferta" />);
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err;
  }
};

export default addOffer;
