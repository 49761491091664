import React, { useState, useRef, useEffect } from 'react';
import { SVG, Button, Modal, SelectField, RegularField, ModalActions } from 'shared/components';
import { FORM_TYPES, MODAL_TYPES, VALIDATION_MESSAGES, STATUS_OPTIONS, SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { boolean, object, string, number } from 'yup';
import { isValidNip, isValidRegon } from 'shared/utils';

const getFormSchema = () =>
  object().shape({
    active: boolean().nullable().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    bonus_thresholds: number().nullable().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    email: string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    krs: number().nullable(),
    name: string().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    nip: number()
      .nullable()
      .test({ message: VALIDATION_MESSAGES.WRONG_NIP, test: value => isValidNip(value) })
      .required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    provision: number().nullable().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    regon: number()
      .test({ message: VALIDATION_MESSAGES.WRONG_REGON, test: value => isValidRegon(value) })
      .nullable(),
  });

function Form({ onClose, onSubmit, opened, type, data, bonusesOptions }) {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [fileInputValue, setFileInputValue] = useState(null);
  const fileInput = useRef(null);
  const isAddForm = type === FORM_TYPES.ADD;

  useEffect(() => {
    const [singleFile] = data?.files || [];
    // If user has file, then set name property to display added file block in UI
    setFile(singleFile && !isAddForm ? { name: `${singleFile.file_name}.${singleFile.file_format}` } : null);
  }, [data]);

  const handleStepChange = () => {
    if (step === 1) {
      setStep(2);
    } else setStep(1);
  };

  const handleFileChange = e => {
    setFileInputValue(e.target.files[0]);
  };

  const handleOnClose = () => {
    onClose();
    setStep(1);
    setFileInputValue(null);
  };

  return (
    <Modal
      onClose={handleOnClose}
      onSubmit={onSubmit}
      opened={opened}
      title={` ${isAddForm ? 'Dodaj' : 'Edytuj'} partnera`}
      type={MODAL_TYPES.CONFIRM}
      isMultiStep
      step={step}
      handleStepChange={handleStepChange}
    >
      <Formik
        enableReinitialize
        initialValues={
          data || {
            active: null,
            provision: null,
            name: '',
            email: '',
            bonus_thresholds: '',
            krs: null,
            regon: null,
            nip: null,
          }
        }
        onSubmit={async (values, { setErrors }) => {
          const response = await onSubmit(values, fileInputValue);
          if (!response) {
            handleOnClose();
          } else if (response.field === 'email') {
            setErrors({ email: VALIDATION_MESSAGES.UNIQUE_EMAIL });
          }
        }}
        validationSchema={getFormSchema()}
      >
        {({ handleSubmit, errors }) => (
          <>
            <form className="modal__form" onSubmit={handleSubmit}>
              {step === 1 ? (
                <>
                  <div className="group-label">Status</div>
                  <div className="form_investor__top-fields">
                    <Field
                      component={SelectField}
                      errors={errors}
                      fullWidth
                      isCustomLabel
                      name="active"
                      options={STATUS_OPTIONS}
                      placeholder="Wybierz status"
                    />

                    <Field
                      className="regular-field__input--provision"
                      component={RegularField}
                      errors={errors}
                      label="Prowizja"
                      name="provision"
                      type="number"
                    />
                  </div>

                  <div className="group-label">Dane konta</div>
                  <Field component={RegularField} errors={errors} fullWidth label="Nazwa" name="name" type="text" />
                  {isAddForm && (
                    <Field component={RegularField} errors={errors} fullWidth label="Adres email" name="email" type="text" />
                  )}
                  <Field
                    component={SelectField}
                    errors={errors}
                    fullWidth
                    label="Progi bonusowe"
                    name="bonus_thresholds"
                    options={bonusesOptions}
                    placeholder="Wybierz próg bonusowy"
                    menuPlacement="top"
                  />
                  <Field component={RegularField} errors={errors} fullWidth label="NIP" name="nip" type="number" />
                </>
              ) : (
                <>
                  <div className="group-label">Status</div>
                  <Field component={RegularField} errors={errors} fullWidth label="REGON" name="regon" type="number" />
                  <Field component={RegularField} errors={errors} fullWidth label="KRS" name="krs" type="number" />
                  <div className="group-wrapper">
                    <div className="group-label">Umowa współpracy</div>
                    <input
                      ref={fileInput}
                      type="file"
                      id="file"
                      name="file"
                      accept="image/png,image/gif,image/jpeg, application/pdf"
                      onChange={handleFileChange}
                    />
                    {!file && (
                      <Button
                        classNames="transparent no-padding right-icon"
                        iconType={SVG_TYPE.ADD}
                        label="Dodaj"
                        onClick={() => fileInput.current && fileInput.current.click()}
                      />
                    )}
                  </div>
                  {(file || fileInputValue) && (
                    <div className="single-file">
                      <div className="single-file__left">
                        <SVG type={SVG_TYPE.FILE} /> <p>{file ? file.name : fileInputValue.name}</p>
                      </div>
                      <div
                        className="single-file__right"
                        onClick={() => {
                          setFile(null);
                          setFileInputValue(null);
                        }}
                      >
                        {/* <span
                          onClick={() => {
                            setFile(null);
                            setFileInputValue(null);
                          }}
                        > */}
                        <SVG type={SVG_TYPE.EXIT} />
                        {/* </span> */}
                      </div>
                    </div>
                  )}
                </>
              )}
              {console.log(
                step === 2 &&
                  (errors.active || errors.bonus_thresholds || errors.email || errors.name || errors.nip || errors.provision),
              )}
              <ModalActions
                handleSubmit={() => {
                  step === 1 && errors.regon && setStep(2);
                  step === 2 &&
                    (errors.active || errors.bonus_thresholds || errors.email || errors.name || errors.nip || errors.provision) &&
                    setStep(1);
                  handleSubmit();
                }}
                onClose={onClose}
                type={MODAL_TYPES.CONFIRM}
              />
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

Form.defaultProps = {
  data: null,
};

Form.propTypes = {
  authorizedUser: PropTypes.object.isRequired,
  bonusesOptions: PropTypes.array.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([FORM_TYPES.ADD, FORM_TYPES.EDIT]).isRequired,
  // userRole: PropTypes.string.isRequired,
};

export default Form;
