const VIEW_MANAGEMENT = {
  SHOW_HOME_PAGE_PRELOADER: 'SHOW_HOME_PAGE_PRELOADER',
  HIDE_HOME_PAGE_PRELOADER: 'HIDE_HOME_PAGE_PRELOADER',
  SET_PREV_PATH: 'SET_PREV_PATH',
  SHOW_PRELOADER: 'SHOW_PRELOADER',
  HIDE_PRELOADER: 'HIDE_PRELOADER',
  MANAGE_NOTIFICATION_DIALOG: 'MANAGE_NOTIFICATION_DIALOG',
  SHOW_NOTIFICATION_DIALOG: 'SHOW_NOTIFICATION_DIALOG,',
};

export default VIEW_MANAGEMENT;
