import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';

const AppointmentNote = ({
  info: { time, title, uuid, date },
  plot: {
    order_status: {
      investor: { last_name, phone_number, first_name },
    },
  },
  history,
}) => (
  <div className="notificationsRight__list">
    <div className="notificationsRight__list-item">
      <div className="notificationsRight__left">
        <div className="notificationsRight__hour">{time.slice(0, 5)}</div>
      </div>
      <div className="notificationsRight__right">
        <div className="notificationsRight__row">
          <div className="notificationsRight__user">
            <SVG type={SVG_TYPE.INVESTORS} />
            {first_name.slice(0, 1)}. {last_name}
          </div>
          <a className="employees-right__phone" href={`tel:${phone_number}`}>
            <SVG type={SVG_TYPE.PHONE} />
          </a>
        </div>
        <div
          className="notificationsRight__description"
          onClick={() =>
            history.push(generatePath(ROUTES.CALENDAR), {
              previous: 'appointment',
              uuid,
              date,
            })
          }
        >
          {' '}
          {title}
        </div>
      </div>
    </div>
  </div>
);

export default withRouter(AppointmentNote);

AppointmentNote.propTypes = {
  info: PropTypes.object.isRequired,
  plot: PropTypes.object.isRequired,
};
