import { createSelector } from 'reselect';

const getSettlementStore = state => state.settlementStore;
const getSettlements = createSelector(getSettlementStore, ({ settlements: { data = {} } }) => data);
const getSecondarySettlements = createSelector(getSettlementStore, ({ secondarySettlements: { data = {} } }) => data);
const getSettlementsStatus = createSelector(getSettlementStore, ({ settlements: { status } }) => status);
const getPlotsSelector = createSelector(getSettlementStore, ({ plots: { data } }) => data);
const getPlotDetails = createSelector(getSettlementStore, ({ plot: { data } }) => data);
const getPlotsByFilter = createSelector(getSettlementStore, ({ plotsByFilter: { data } }) => data);
const getPlotsOptions = createSelector(getSettlementStore, ({ plotsOptions: { data } }) => data);
const getSettlementsOptions = createSelector(getSettlementStore, ({ settlementsOptions: { data = {} } }) => data);
const getPlotFilters = createSelector(getSettlementStore, ({ filters: { data = {} } }) => data);
const getPlotStatus = createSelector(getSettlementStore, ({ plots: { status } }) => status);
const getFilesByStatus = createSelector(getSettlementStore, ({ filesByStatus: { data = [] } }) => data);
const getArchivedSettlementsSelector = createSelector(getSettlementStore, ({ archivedSettlements: { data = {} } }) => data);

export {
  getSettlements,
  getSettlementsStatus,
  getSecondarySettlements,
  getPlotsSelector,
  getPlotDetails,
  getPlotsByFilter,
  getPlotsOptions,
  getSettlementsOptions,
  getPlotFilters,
  getPlotStatus,
  getFilesByStatus,
  getArchivedSettlementsSelector
};
