const SUBMENU = {
  DATA: 'Dane',
  PLOTS: 'Działki',
};

const PLOTS_SUBMENU = {
  PLOTS: 'Działki',
  DATA: 'Dane',
  DOCS: 'Dokumenty',
};

const SINGLE_PLOT_SUBMENU = {
  STATUS: 'Status',
  DATA: 'Dane',
  DOCS: 'Dokumenty',
};

const INVESTOR_SUBMENU = {
  BASIC: 'Podstawowe',
  SPECIFIC: 'Szczegółowe',
  PLOT: 'Działki',
};

const PLOT_OFFER_SUBMENU = {
  WAITING_OFFER: 'Oczekujące oferty',
  ACCPET_OFFER: 'Zaakceptowane oferty',
};

const PARTNER_SUBMENU = {
  DATA: 'Dane',
  DDI: 'DDI',
  FILES: 'Pliki',
  ADVISER: 'Doradca',
};
const SUBMENU_ARR = [SUBMENU.DATA, SUBMENU.PLOTS];

const PLOTS_SUBMENU_ARR = [PLOTS_SUBMENU.PLOTS, PLOTS_SUBMENU.DATA, PLOTS_SUBMENU.DOCS];

const SINGLE_PLOT_SUBMENU_ARR = [SINGLE_PLOT_SUBMENU.STATUS, SINGLE_PLOT_SUBMENU.DATA, SINGLE_PLOT_SUBMENU.DOCS];

const SINGLE_PLOT_OFFER_ARR = [PLOT_OFFER_SUBMENU.WAITING_OFFER, PLOT_OFFER_SUBMENU.ACCPET_OFFER];

const PARTNER_SUBMENU_ARR = [PARTNER_SUBMENU.DATA, PARTNER_SUBMENU.DDI, PARTNER_SUBMENU.FILES, PARTNER_SUBMENU.ADVISER];

const FIELD_TYPE = {
  INPUT: 'input',
  SELECT: 'select',
};

export {
  SUBMENU,
  SUBMENU_ARR,
  PLOTS_SUBMENU_ARR,
  PLOTS_SUBMENU,
  PARTNER_SUBMENU,
  PARTNER_SUBMENU_ARR,
  FIELD_TYPE,
  SINGLE_PLOT_SUBMENU,
  SINGLE_PLOT_SUBMENU_ARR,
  PLOT_OFFER_SUBMENU,
  SINGLE_PLOT_OFFER_ARR,
  INVESTOR_SUBMENU,
};
