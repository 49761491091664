import { PARTNER } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  partners: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedPartner: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedPartners: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  partnersOptions: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  bonusesOptions: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  attachedDdis: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  attachedAdvisers: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const partnerReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case PARTNER.GET_PARTNERS_INIT: {
      return {
        ...state,
        partners: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PARTNER.GET_PARTNERS_SUCCESS: {
      return {
        ...state,
        partners: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_PARTNERS_FAILURE: {
      return {
        ...state,
        partners: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case PARTNER.GET_BONUSES_OPTIONS_SUCCESS: {
      return {
        ...state,
        bonusesOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_PARTNERS_OPTIONS_SUCCESS: {
      return {
        ...state,
        partnersOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_ATTACHED_DDIS_INIT: {
      return {
        ...state,
        attachedDdis: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PARTNER.GET_ATTACHED_DDIS_SUCCESS: {
      return {
        ...state,
        attachedDdis: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_ATTACHED_ADVISERS_INIT: {
      return {
        ...state,
        attachedAdvisers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case PARTNER.GET_ATTACHED_ADVISERS_SUCCESS: {
      return {
        ...state,
        attachedAdvisers: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_ARCHIVED_PARTNER_SUCCESS: {
      return {
        ...state,
        archivedPartner: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_ARCHIVED_PARTNERS_SUCCESS: {
      return {
        ...state,
        archivedPartners: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case PARTNER.GET_ATTACHED_DDIS_FAILURE: {
      return {
        ...state,
        attachedDdis: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default:
      return state;
  }
};

export default partnerReducer;
