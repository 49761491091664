import React from 'react';
import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { NewUser } from 'shared/components';
import { history } from 'store/store';
import getArchivedPartners from './getArchivedPartners';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: PARTNER.RESTORE_PARTNER_INIT,
});

export const success = () => ({
  type: PARTNER.RESTORE_PARTNER_SUCCESS,
});

const failure = () => ({
  type: PARTNER.RESTORE_PARTNER_FAILURE,
});

const restorePartner = userData => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const { data } = await Connector.restorePartner(userData.uuid);
    await dispatch(getArchivedPartners());
    history.push(ROUTES.ARCHIVED_PARTNERS);
    await toast(<NewUser badgeType="Partner" first_name={userData.name} label="Przywrócono partnera" phone={userData.phone} />);
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default restorePartner;
