import React, { useState } from 'react';
import { Modal, ModalActions } from 'shared/components';
import { FORM_TYPES, MODAL_TYPES, VALIDATION_MESSAGES } from 'shared/consts';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import INVESTOR_STATUS from 'shared/consts/investorStatus';
import { number, object, string } from 'yup';
import { FORM_STEPS } from './consts';
import { BasicInformationForm } from './components';

function AddSettlementsForm({ onClose, onSubmit,opened, type, userRole: loggedUserRole }) {
  const [formStep, setFormStep] = useState(FORM_STEPS.BASIC);
  const [fileInputValue, setFileInputValue] = useState(null);
  const returnRoleOptions = () => INVESTOR_STATUS;

  const handleFormSubmit = async values => {
    const response = await onSubmit(values, fileInputValue);
    onClose();
    console.log(response);
    if (!response) {
      onClose();
      setFileInputValue('');
    }
  };

  const handleFileChange = e => {
    setFileInputValue(e.target.files[0]);
  };

  const renderFormTitle = () => (
    <div className="form_investor__header">
      <span className="form_investor__title">Dodaj Osadę</span>
    </div>
  );
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const validation = () =>
    object().shape({
      joint_payment: number()
      .required(REQUIRED_FIELD)
        ,
      name: string().trim().required(REQUIRED_FIELD),
    });

  return (
    <Modal onClose={onClose} opened={opened} title={renderFormTitle()} type={MODAL_TYPES.CONFIRM}>
      <Formik
        validationSchema={validation()}
        enableReinitialize
        initialValues={{
          name: '',
          joint_payment: '',
          file: '',
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, errors, setFieldValue }) => (
          <>
            <form className="modal__form form_investor" onSubmit={handleSubmit}>
              {formStep === FORM_STEPS.BASIC && (
                <BasicInformationForm
                  formType={type}
                  returnRoleOptions={returnRoleOptions}
                  errors={errors}
                  loggedUserRole={loggedUserRole}
                  handleFileChange={handleFileChange}
                  setFileInputValue={setFileInputValue}
                  setFieldValue={setFieldValue}
                  fileInputValue={fileInputValue}
                />
              )}
              <ModalActions
                handleSubmit={() => {
                  errors && setFormStep(FORM_STEPS.BASIC);
                  handleSubmit();
                }}
                onClose={onClose}
                type={MODAL_TYPES.CONFIRM}
              />
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

AddSettlementsForm.defaultProps = {
  data: null,
};

AddSettlementsForm.propTypes = {
  data: PropTypes.object,
  ddiOptions: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  getAllDdi: PropTypes.func.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getEmployees: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  type: PropTypes.oneOf([FORM_TYPES.ADD, FORM_TYPES.EDIT]).isRequired,
  userRole: PropTypes.string.isRequired,
  usersDdi: PropTypes.array.isRequired,
};

export default AddSettlementsForm;
