import { USER_ROLES, USER_POSITIONS } from 'shared/consts';

const {
  DIRECTOR,
  SUPERVISOR,
  MANAGEMENT,
  SPECIALIST,
  MANAGER,
  TRADER,
  MAIN_ACCOUNTING_WORKER,
  ACCOUNTING_WORKER,
  TECHNICAL,
  SECRETARIAT,
  SELLER,
} = USER_POSITIONS;

const POSITION_OPTIONS = new Map([
  [USER_ROLES.ADMIN, [DIRECTOR, SUPERVISOR, MANAGEMENT]],
  [USER_ROLES.DIR, [SPECIALIST, MANAGER]],
  [USER_ROLES.DP, [SPECIALIST, MANAGER]],
  [USER_ROLES.DDI, [TRADER]],
  [USER_ROLES.ACCOUNTING, [MAIN_ACCOUNTING_WORKER, ACCOUNTING_WORKER]],
  [USER_ROLES.ADMINISTRATION, [TECHNICAL, SECRETARIAT, SPECIALIST, MANAGER]],
  [USER_ROLES.MARKETING, [SPECIALIST, MANAGER]],
  [USER_ROLES.ADVISER, [SELLER]],
]);

export default POSITION_OPTIONS;
