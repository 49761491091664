import React from 'react';

const STATUS_OPTIONS = [
  {
    label: <div className="select-field__option--active">Aktywny</div>,
    value: 1,
    searchableLabel: 'Aktywny',
  },
  {
    label: <div className="select-field__option--inactive">Nieaktywny</div>,
    value: 0,
    searchableLabel: 'Nieaktywny',
  },
];

export default STATUS_OPTIONS;
