import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../badge';

const OptionsLabel = (list, badgeLabel) =>
  list.map(item => ({
    label: (
      <div className="select-field__option">
        {badgeLabel && <Badge label={badgeLabel} type={badgeLabel} />}
        {item.first_name && `${item.first_name} ${item.last_name}`} {item.label && item.label}
      </div>
    ),
    value: item.uuid || item.value,
    searchableLabel: (item.first_name && `${item.first_name} ${item.last_name}`) || item.label,
  }));

export default OptionsLabel;

OptionsLabel.propTypes = {
  list: PropTypes.array.isRequired,
};
