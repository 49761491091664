import { createSelector } from 'reselect';

const getStartStore = state => state.startStore;

const getNews = createSelector(getStartStore, ({ newsData: { data = [] } }) => data);
const getSingleNews = createSelector(getStartStore, ({ singleNewsData: { data = {} } }) => data);
const getStatuses = createSelector(getStartStore, ({ statuses: { data = {} } }) => data);
const getOffers = createSelector(getStartStore, ({ offers: { data = {} } }) => data);

export { getNews, getSingleNews, getStatuses, getOffers };
