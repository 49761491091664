import React from 'react';
import { object, string, func, bool, array } from 'prop-types';
import { ActiveWindow } from 'shared/components';
// import { USER_ROLES } from 'shared/consts';
import { INVESTOR_SUBMENU } from 'shared/components/active-window/consts';
import { history } from 'store/store';
import { ROUTES } from 'shared/consts';
import SettlementsName from './SettlementsName';
import SettlementData from '../settlements-data/SettlementData';

const SettlementByUuid = ({
  setActiveMenu,
  activeMenu,
  archived,
  settlement,
  deleteInvestor,
  editInvestor,
  authorizedUserRole,
  getAllDdi,
  usersDdi,
  restoreSettlement,
  employees,
  getEmployees,
}) => {
  console.log(settlement)
  !settlement && history.push(ROUTES.ARCHIVED_SETTLEMENTS);
  const { name, investor_type } = settlement;
  console.log(settlement)
  const submenuArr = investor_type
    ? [INVESTOR_SUBMENU.BASIC, INVESTOR_SUBMENU.SPECIFIC, INVESTOR_SUBMENU.PLOT]
    : [INVESTOR_SUBMENU.BASIC, INVESTOR_SUBMENU.PLOT];
  return (
    <ActiveWindow.Wrapper>
      <ActiveWindow.Header>
        <SettlementsName name={`${name}`} />
        <ActiveWindow.SubMenu menu={submenuArr} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      </ActiveWindow.Header>
      <ActiveWindow.Content>
        {activeMenu === INVESTOR_SUBMENU.BASIC && (
          <SettlementData
            archived={archived}
            authorizedUserRole={authorizedUserRole}
            getAllDdi={getAllDdi}
            usersDdi={usersDdi}
            data={settlement}
            editInvestor={editInvestor}
            deleteInvestor={deleteInvestor}
            restoreSettlement={restoreSettlement}
            employees={employees}
            getEmployees={getEmployees}
          />
        )}
      </ActiveWindow.Content>
    </ActiveWindow.Wrapper>
  );
};

SettlementByUuid.defaultProps = {
  archived: false,
};

SettlementByUuid.propTypes = {
  activeMenu: string.isRequired,
  archived: bool,
  authorizedUserRole: string.isRequired,
  deleteInvestor: func.isRequired,
  editInvestor: func.isRequired,
  employees: array.isRequired,
  getAllDdi: func.isRequired,
  getEmployees: func.isRequired,
  restoreSettlement: func.isRequired,
  setActiveMenu: func.isRequired,
  settlement: object.isRequired,
  usersDdi: func.isRequired,
};
export default SettlementByUuid;
