import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPlotsByFilter, sortedPlotList } from 'store/actions/settlements';
import { getPlotsSelector } from 'store/selectors/settlementsSelector';

import Filters from './Filters.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPlotsByFilter: partnerData => getPlotsByFilter(partnerData),
      sortedPlotList: sorted => sortedPlotList(sorted),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  filteredPlots: getPlotsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
