import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.GET_DDI_OPTIONS_INIT,
});

export const success = data => ({
  type: USER.GET_DDI_OPTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.GET_DDI_OPTIONS_FAILURE,
});

const getDdiOptions = (withPartnersOnly = true) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getDdiOptions(withPartnersOnly);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getDdiOptions;
