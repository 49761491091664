import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { history } from 'store/store';
import { ROUTES } from 'shared/consts';
import { showHomePagePreloader, hideHomePagePreloader } from '../viewManagement';

const init = () => ({
  type: USER.ACTIVATE_ACCOUNT_INIT,
});

export const success = () => ({
  type: USER.ACTIVATE_ACCOUNT_SUCCESS,
});

const failure = () => ({
  type: USER.ACTIVATE_ACCOUNT_FAILURE,
});

const activateAccount = userData => async dispatch => {
  dispatch(init());
  try {
    dispatch(showHomePagePreloader());
    await Connector.activateAccount(userData);
    dispatch(success());
    history.push(ROUTES.LOGIN);
    sessionStorage.removeItem('activateToken');
    dispatch(hideHomePagePreloader());
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hideHomePagePreloader());
  }
};

export default activateAccount;
