const MAP_TYPE = {
  22: 'Osada nad jeziorem Skolickim',
  3: 'MYGR-Brzozowa Dolina',
  4: 'Osada Harsz przy polu golfowym',
  16: 'Osada nad jeziorem Święcajty',
  20: 'Kanał Elbląski',
  7: 'Osada nad jeziorem Kiersztanowskim',
  9: 'Osada nad jeziorem Narie',
  19: 'Harsz 11',
  6: 'Dąbrowa Mała II',
  10: 'Pozezdrze',
  17: 'Osada nad jeziorem luterskim',
  18: 'RI - Osada nad jeziorem Dargin-01 1',
  5: 'RI - Harsz - teren inwestycyjny (pod pensjonat) 1',
  15: 'nie ma nic',
  21: 'Jezioro Święcajty',
  23: 'Mierki',
  24: 'Niebrzydowo',
  25: 'Lewałd Wielki Dąbrowa Mała',
};

export default MAP_TYPE;
