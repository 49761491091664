import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions, SVG } from 'shared/components';
import { MODAL_TYPES, SVG_TYPE } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function LoadModal({ onClose, opened, title, onSubmit, reportsFiltersList, deleteSettingOpened }) {
  const isMobileDevice = isMobile();
  const [chosen, setChosen] = useState('');
  const [docName, setDocName] = useState('');
  const setAsActive = (className, uuid, name) => {
    const activeElem = document.querySelector('.active-tile');
    activeElem?.classList.remove('active-tile');
    document.querySelector(`#${className}`).classList.toggle('active-tile');
    setChosen(uuid);
    setDocName(name);
  };
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.SHOW}>
            <>
              {reportsFiltersList.map(({ name, uuid, className }) => (
                <div>
                  <div
                    className="employees-right__select setting-row"
                    id={className}
                    onClick={() => setAsActive(className, uuid, name)}
                  >
                    <span>{name}</span>
                    <div onClick={() => deleteSettingOpened(uuid, name)}>
                      <SVG type={SVG_TYPE.EXIT} />
                    </div>
                  </div>
                </div>
              ))}
            </>
            <ModalActions onClose={onClose} type={MODAL_TYPES.LOAD} handleSubmit={() => onSubmit(chosen, docName)} />
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

LoadModal.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  deleteSettingOpened: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  reportsFiltersList: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default LoadModal;
