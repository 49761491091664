import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPlotDetails as getPlotDetailsSelector,
  getFilesByStatus as getFilesByStatusSelector,
} from 'store/selectors/settlementsSelector';
import { getUsers as getUsersSelector, getAdvisersList } from 'store/selectors/userSelector';
import { getPlotDetails, getPdfContract, setPurchaseDate, getFilesByStatus } from 'store/actions/settlements';
import { changeStatus, addContracts, confirmDepositPayment } from 'store/actions/status';
import { addOffer, offerChangeStatus, getWaitingOffers, getAcceptedOffers, getStatusPlot } from 'store/actions/offer';
import { getInvestorsLabel } from 'store/actions/investors';
import { isMobile as checkIsMobile } from 'shared/utils';
import * as Actions from 'store/actions/user';
import { getAuthorizedUser } from 'store/selectors/authSelector';
import { getInvestorsLabel as getInvestorsLabelSelector } from 'store/selectors/investorSelector';
import {
  getWaitingOffers as getWaitingOffersSelector,
  getAcceptedOffers as getAcceptedOffersSelector,
  getStatusPlot as getStatusPlotSelector,
} from 'store/selectors/offerSelector';
import { arrangingTheAct } from 'store/actions/notification';
import Plot from './Plot.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addOffer: data => addOffer(data),
      offerChangeStatus: dataForm => offerChangeStatus(dataForm),
      getInvestorsLabel: data => getInvestorsLabel(data),
      getUsers: role => Actions.getUsers(role),
      getPlotDetails: id => getPlotDetails(id),
      getPdfContract: (id, customDate) => getPdfContract(id, customDate),
      getStatusPlot: id => getStatusPlot(id),
      changeStatus: (data, plot_uuid) => changeStatus(data, plot_uuid),
      getWaitingOffers: data => getWaitingOffers(data),
      getAcceptedOffers: data => getAcceptedOffers(data),
      addContracts: (data, files) => addContracts(data, files),
      confirmDepositPayment: (data, file) => confirmDepositPayment(data, file),
      arrangingTheAct: (data, plotUuid) => arrangingTheAct(data, plotUuid),
      setPurchaseDate: (statusUuid, date) => setPurchaseDate(statusUuid, date),
      getFilesByStatus: statusUuid => getFilesByStatus(statusUuid),
      getAdvisersList: uuid => Actions.getAdvisersList(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  users: getUsersSelector(state),
  plot: getPlotDetailsSelector(state),
  isMobile: checkIsMobile(),
  authorizedUser: getAuthorizedUser(state),
  investorsLabel: getInvestorsLabelSelector(state),
  waitingOffers: getWaitingOffersSelector(state),
  acceptedOffers: getAcceptedOffersSelector(state),
  statusPlot: getStatusPlotSelector(state),
  filesByStatus: getFilesByStatusSelector(state),
  advisersList: getAdvisersList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plot);
