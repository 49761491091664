import React from 'react';
import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import getPartners from './getPartners';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: PARTNER.ADD_PARTNER_INIT,
});

export const success = data => ({
  type: PARTNER.ADD_PARTNER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PARTNER.ADD_PARTNER_FAILURE,
});

const addPartner = (partnerData, file) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    partnerData.provision /= 100;
    const { data } = await Connector.addPartner(partnerData);
    if (file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      console.log(file);
      await Connector.addFile(fileForm, {
        business_partner_uuid: data.uuid,
        file_format: file.type.split('/').pop().split('.')[0],
        file_name: file.name.split('.')[0],
      });
    }
    dispatch(getPartners());
    await toast(<NewUser badgeType="Partner" first_name={partnerData.name} label="Dodano partnera" />);
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default addPartner;
