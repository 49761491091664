import * as Connector from 'shared/connectors/notificationConnector';
import { NOTIFICATION } from 'store/types';
import { getPlotDetails } from 'store/actions/settlements';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: NOTIFICATION.ARRANGING_THE_ACT_INIT,
});

export const success = data => ({
  type: NOTIFICATION.ARRANGING_THE_ACT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: NOTIFICATION.ARRANGING_THE_ACT_FAILURE,
});

const arrangingTheAct = (data, plotUuid) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    await Connector.arrangingTheAct(data);
    plotUuid && (await dispatch(getPlotDetails(plotUuid)));
    dispatch(success(data));
    dispatch(hidePreloader());
    return true;
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
    return false;
  }
};

export default arrangingTheAct;
