import { bool, func, shape } from 'prop-types';
import { generatePath } from 'react-router';
import React from 'react';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'shared/components';
import { SettlementList } from './components';
import AddSettlementsForm from './components/AddSettlementsForm';

class Settlements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryActive: false,
      idClick: null,
      isModalOpened: false,
      isArchivedFilterOn: true,
      // page:1,
    };
  }

  async componentDidMount() {
    const {
      actions: { getSettlements, getSecondarySettlements, getArchivedSettlements },
    } = this.props;
    await getSettlements(1);
    await getSecondarySettlements();
    await getArchivedSettlements();
  }

  // handleScroll = e => {
  //   if (e.currentTarget.scrollTop === 0) {
  //     // this.setState({ page: this.state.page + 1 });
  //     this.props.actions.getSettlements(this.state.page+1)
  //   }
  // };

  onSettlementActiveChange = async (id, isSecondary = false) => {
    this.setState({ isSecondaryActive: isSecondary });
    const {
      actions: { getPlotsBySettlementId },
      settlements: { data: settlements = [] },
      secondarySettlements: { data: secondaySettlements = [] },
    } = this.props;
    const { uuid } = isSecondary ? secondaySettlements[id] : settlements[id];
    await getPlotsBySettlementId(uuid);
    if (this.state.idClick === id) {
      this.setState({ idClick: null });
      this.props.history.push(ROUTES.SETTLEMENTS);
    } else {
      localStorage.setItem('SettlementId', id);
      this.props.history.push(generatePath(ROUTES.SETTLEMENTS_ID, { uuid }));
      this.setState({ idClick: id });
    }
  };

  render() {
    const {
      settlements: { data: settlements = [] },
      archivedSettlements: { data: archivedSettlements = [] },
      secondarySettlements: { data: secondaySettlements = [] },
      isMobile,
      location: { pathname },
    } = this.props;
    const settlementUuid = pathname.split('/')[3];
    let indexRowElement = null;
    settlements &&
      settlements.forEach((item, idx) => {
        if (item.uuid === settlementUuid) {
          indexRowElement = idx;
        }
      });
    const { isSecondaryActive, isModalOpened } = this.state;
    const { authorizedUser } = this.props;
    return (
      <>
        <div id="settlements__top" className="partners__header settlements__top">
          <span className="investors__title">Osady</span>
          <div className="select__filter--wrapper">
            {authorizedUser && authorizedUser.role === 'admin' && authorizedUser.position === 'director' && (
              <Button
                classNames="transparent no-padding right-icon"
                iconType={SVG_TYPE.ADD}
                label="Dodaj"
                onClick={() => this.setState({ isModalOpened: true })}
              />
            )}
          </div>
        </div>
        <div className="settlements">
          {isModalOpened && (
            <AddSettlementsForm
              onClose={() => this.setState({ isModalOpened: false })}
              opened={isModalOpened}
              onSubmit={this.props.actions.addSettlement}
            />
          )}
          <div className="settlements__list">
            <div className="settlementList__wrapper">
              {settlements.length > 1 ? (
                <>
                  <SettlementList
                    isArchivedFilterOn={this.state.isArchivedFilterOn}
                    isMobile={isMobile}
                    settlements={settlements}
                    archivedSettlements={archivedSettlements}
                    onSettlementActiveChange={this.onSettlementActiveChange}
                    isActive={!isSecondaryActive}
                    rowActiveByLink={indexRowElement}
                    // handleScroll={handleScroll}
                  />
                  <div className="settlements__title settlements__title--marginTop">Oferty wtórne</div>
                  <SettlementList
                    settlements={secondaySettlements}
                    onSettlementActiveChange={id => this.onSettlementActiveChange(id, true)}
                    isActive={isSecondaryActive}
                  />
                </>
              ) : (
                <>
                  <Skeleton count={5} height={100} />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Settlements.propTypes = {
  actions: shape({
    getSettlements: func.isRequired,
    getPlotsBySettlementId: func.isRequired,
    getSecondarySettlements: func.isRequired,
  }).isRequired,
  archivedSettlements: shape({}).isRequired,
  authorizedUser: shape({}).isRequired,
  isMobile: bool.isRequired,
  secondarySettlements: shape({}).isRequired,
  settlements: shape({}).isRequired,
};

export default Settlements;
