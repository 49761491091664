import React from 'react';
import { useTable } from 'react-table';
import './data-managment-table.scss';
import PropTypes from 'prop-types';
import { useSticky } from 'react-table-sticky';
import styled from 'styled-components';

const Styles = styled.div`
  .table {
    .th {
      background-color: #fff;
    }

    .td {
      background-color: #fcfcfc;
      overflow: hidden;
      border-bottom: 5px solid #fff;
      border-right: 5px solid #fff;
    }

    &.sticky {
      overflow: scroll;

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;
const DataManagmentTable = ({ data, columns, hiddenColumns }) => {
  const initialState = { hiddenColumns };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data,
      columns,
      initialState,
    },
    useSticky,
  );

  return (
    <Styles>
      <div className="tableWrapper">
        <table {...getTableProps()} className="table sticky">
          <thead className="header">
            {
              // Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(column => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()} className="th">
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()} className="body">
            {
              // Loop over the table rows
              rows.map(row => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className="tr">
                    {
                      // Loop over the rows cells
                      row.cells.map(cell => (
                        // Apply the cell props
                        <td {...cell.getCellProps()} className="td">
                          <span>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </span>
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </Styles>
  );
};

export default DataManagmentTable;

DataManagmentTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
};
