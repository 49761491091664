import * as Connector from 'shared/connectors/startConnector';
import getNews from './getNews';

const deleteNews = uuid => async dispatch => {
  try {
    await Connector.deleteNews(uuid);
    dispatch(getNews());
    // dispatch(success(status));
  } catch (err) {
    // dispatch(failure());
  }
};

export default deleteNews;
