import { connect } from 'react-redux';
import * as Actions from 'store/actions/notification';
import { getSingleNotification } from 'store/selectors/notificationSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import SingleNotificationRight from './SingleNotificationRight.main';

const mapDispatchToProps = {
  getSingleNotification: uuid => Actions.getSingleNotification(uuid),
  changeSingleNotificationStatus: (status, uuid) => Actions.changeSingleNotificationStatus(status, uuid),
};

const mapStateToProps = state => ({
  singleNotification: getSingleNotification(state),
  authorizedUser: getAuthorizedUserRole(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleNotificationRight);
