/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SVG, Badge } from 'shared/components';
import { SVG_TYPE, AREA_TYPES } from 'shared/consts';
import * as moment from 'moment';

const stopPropagation = e => e.stopPropagation();
function Right({ item, area, isMobile }) {
  const renderContent = () => {
    switch (area) {
      case AREA_TYPES.EMPLOYEES: {
        return (
          <div className="list__right">
            <PhoneListIcon phone={item.phone} />
          </div>
        );
      }
      case AREA_TYPES.PARTNERS: {
        return (
          <div className="list__right">
            <MailListIcon email={item.email} />
          </div>
        );
      }
      case AREA_TYPES.INVESTORS: {
        return (
          <>
            <div className="list__right">
              {!isMobile && <div className="list__column">{moment(item.created_at).format('DD.MM.YYYY')}</div>}
              <Badge label={item.status} type={item.status} isMobile={isMobile} />
              {!isMobile && (
                <>
                  <MailListIcon email={item.email} />
                  <PhoneListIcon phone={item.phone} />
                </>
              )}
            </div>
            {isMobile && (
              <>
                <MailListIcon email={item.email} />
                <PhoneListIcon phone={item.phone} />
              </>
            )}
          </>
        );
      }
      default:
        return null;
    }
  };
  return renderContent();
}

const PhoneListIcon = ({ phone }) => (
  <a className="list__phone" href={`tel:${phone}`} onClick={stopPropagation}>
    <SVG type={SVG_TYPE.PHONE} />
  </a>
);

const MailListIcon = ({ email }) => (
  <a className="list__phone" href={`mailto:${email}`} onClick={stopPropagation}>
    <SVG type={SVG_TYPE.MAIL} />
  </a>
);
Right.propTypes = {
  area: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

export default Right;
