import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { generatePath, withRouter } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import moment from 'moment';

function ListItem({ notification: { title, date, uuid, investor, plot, status, type }, history, closeSearchView }) {
  const TYPE = {
    contact: SVG_TYPE.PHONE,
    meeting: SVG_TYPE.APPOINTMENT,
    note: SVG_TYPE.NOTE,
    arranging_the_act: SVG_TYPE.ACT,
  };
  const handleClick = () => {
    history.push(generatePath(ROUTES.SINGLE_NOTIFICATION, { uuid }));
    closeSearchView();
  };
  const isToDo = status === 'to_do';
  return (
    <div className="notificationsRight__container">
      <div className={`notificationsRight__list-item ${!isToDo && 'isDone'}`}>
        <div className="notificationsRight__list-item-click" onClick={handleClick} />
        <div className="notificationsRight__left">
          <div className="notificationsRight__hour">{moment(date).format('HH:MM')}</div>
          <span className="notificationsRight__icon">
            <SVG type={TYPE[type]} />
          </span>
        </div>
        <div className="notificationsRight__right">
          {type === 'contact' && investor ? (
            <div className="notificationsRight__row">
              <div className="notificationsRight__user">
                <SVG type={SVG_TYPE.INVESTORS} />
                {investor?.first_name} {investor?.last_name}
              </div>
              <a className="employees-right__phone" href={`tel:+48${investor?.phone_number}`}>
                <SVG type={SVG_TYPE.PHONE} />
              </a>
            </div>
          ) : (
            ''
          )}
          {type === 'arranging_the_act' && investor ? (
            <div className="notificationsRight__row">
              <div className="notificationsRight__user">
                <SVG type={SVG_TYPE.INVESTORS} />
                {investor?.first_name} {investor?.last_name}
              </div>
              <a className="employees-right__phone" href={`tel:+48${investor?.phone_number}`}>
                <SVG type={SVG_TYPE.PHONE} />
              </a>
            </div>
          ) : (
            ''
          )}
          <div className="notificationsRight__row">
            <div className="notificationsRight__description">{title}</div>
            <div className="change__status">
              {isToDo && <div className="toDo">Do zrobienia</div>}
              {!isToDo && (
                <div className="done">
                  <SVG type={SVG_TYPE.CHECKED} />
                </div>
              )}
            </div>
          </div>
          {status === 'to_do' && plot ? (
            <div className="notificationsRight__plot">
              <SVG type={SVG_TYPE.BLUE_HOME} />
              {plot?.name}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

ListItem.propTypes = {
  closeSearchView: func.isRequired,
  generatePath: func.isRequired,
  handleDoneStatus: func.isRequired,
  handleToDoStatus: func.isRequired,
  notification: shape({
    title: string,
    date: string,
  }).isRequired,
  pathname: string.isRequired,
  replace: func.isRequired,
};

export default withRouter(ListItem);
