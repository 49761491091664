import { connect } from 'react-redux';
import * as Actions from 'store/actions/user';
import { getActiveUser, getDdiOptions, getArchivedEmployee } from 'store/selectors/userSelector';
import { getPrevPath } from 'store/selectors/viewManagementSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import * as PartnerActions from 'store/actions/partner';
import { getPartnersOptions } from 'store/selectors/partnerSelector';
import EmployeesRight from './EmployeesRight.main';

const mapDispatchToProps = {
  getActiveUser: uuid => Actions.getActiveUser(uuid),
  editUser: data => Actions.editUser(data),
  deleteUser: data => Actions.deleteUser(data),
  resendActivationEmail: uuid => Actions.resendActivationEmail(uuid),
  getPartnersOptions: ddi_uuid => PartnerActions.getPartnersOptions(ddi_uuid),
  getDdiOptions: withPartnersOnly => Actions.getDdiOptions(withPartnersOnly),
  getArchivedEmployee: uuid => Actions.getArchivedEmployee(uuid),
  restoreEmployee: uuid => Actions.restoreEmployee(uuid),
};

const mapStateToProps = state => ({
  activeUser: getActiveUser(state),
  archivedEmployee: getArchivedEmployee(state),
  prevPath: getPrevPath(state),
  authorizedUserRole: getAuthorizedUserRole(state),
  partnersOptions: getPartnersOptions(state),
  ddiOptions: getDdiOptions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesRight);
