import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions, SelectField, SVG, RegularField } from 'shared/components';
import { MODAL_TYPES, SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import moment from 'moment';

function AddOfferModal({ onClose, opened, title, onSubmit, plot, investorsLabel, authorizedUser }) {
  const isMobileDevice = isMobile();
  const [price, setPrice] = useState('');
  const [commentary, setCommentary] = useState('');
  const [priceReduction, setPriceReduction] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [depositPercent, setDepositPercent] = useState(10);
  const [invest, setInvest] = useState('');
  const [isChangePrice, setIsChangePrice] = useState(false);
  const [date, setDate] = useState(moment(new Date()).add('months', 1).format('YYYY-MM-DD'));

  useEffect(() => {
    plot && setPrice(plot?.price);
    setDeposit((10 / 100) * plot?.price);
  }, [plot]);

  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;

  const addOfferSchema = () =>
    object().shape({
      comment: string().required(REQUIRED_FIELD),
      dedicated_offer: string().required(REQUIRED_FIELD),
      deposit_percentage: string().required(REQUIRED_FIELD),
      investor_uuid: string().required(REQUIRED_FIELD),
      percentage_reduction: string().required(REQUIRED_FIELD),
    });

  const calcPrice = value => {
    value !== plot?.price.toString() ? setIsChangePrice(true) : setIsChangePrice(false);
    setPriceReduction(100 - (value / plot?.price) * 100);
    setDeposit((depositPercent / 100) * value);
    setPrice(value);
  };

  const calcPercentOfAmount = value => {
    setPriceReduction(value);
    setPrice(plot?.price - plot?.price * (value / 100));
    setDeposit((depositPercent / 100) * (plot?.price - plot?.price * (value / 100)));
    price !== plot?.price.toString() ? setIsChangePrice(true) : setIsChangePrice(false);
  };

  const calcDeposit = value => {
    setDepositPercent(value);
    setDeposit((value / 100) * price);
  };

  const { role, uuid } = authorizedUser;

  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
            <Formik
              enableReinitialize
              initialValues={{
                dedicated_offer: 1,
                deposit_percentage: priceReduction,
                percentage_reduction: depositPercent,
                plot_uuids: [plot?.uuid],
                investor_uuid: invest,
                value: price,
                userRole: role,
                ddi_uuid: uuid,
                comment: commentary,
                date_of_act: date,
              }}
              validationSchema={addOfferSchema()}
              onSubmit={values => {
                onClose();
                console.log(values);
                onSubmit(values);
              }}
            >
              {({ handleSubmit, errors }) => (
                <form className="modal__form addOfferModal" onSubmit={handleSubmit}>
                  <Field
                    component={SelectField}
                    errors={errors}
                    fullWidth
                    name="investor_uuid"
                    options={investorsLabel}
                    placeholder="Wybierz Inwestora"
                    onChange={values => {
                      setInvest(values.value);
                    }}
                  />
                  <div className="addOfferModal__header">
                    <div className="addOfferModal__header--top">
                      <SVG className="addOfferModal--icon" type={SVG_TYPE.BLUE_HOME} />
                      <p className="addOfferModal--number" />
                      <p className="addOfferModal--plotName"> {plot?.settlement?.name} </p>
                    </div>
                    <div className="addOfferModal__header--bottom">
                      <div className="addOfferModal__plot-number">
                        <p className="addOfferModal--dot" />
                        {plot?.name}
                      </div>
                      <p className="addOfferModal__yardage">
                        <p className="addOfferModal__yardage--dot" /> {plot?.plot_size} m2
                      </p>
                      <div className="addOfferModal__price">
                        <p className="addOfferModal__oldPrice">{isChangePrice && `${plot?.price} zł`}</p>
                        <Field
                          component={RegularField}
                          className="addOfferModal__header--input addOfferModal__header--input120"
                          errors={errors}
                          name="value"
                          type="text"
                          inputOnChange={values => {
                            calcPrice(values.target.value);
                          }}
                        />
                      </div>
                      <Field
                        className="addOfferModal__header--input addOfferModal__header--percentage addOfferModal__header--percentage--green"
                        component={RegularField}
                        errors={errors}
                        name="deposit_percentage"
                        type="text"
                        maxLength={2}
                        inputOnChange={values => calcPercentOfAmount(values.target.value)}
                      />
                    </div>
                    <div className="addOfferModal__header--bottom">
                      <p className="addOfferModal__yardage">zadatek:</p>
                      <p className="addOfferModal__yardage addOfferModal__yardage--left">
                        {deposit.toFixed().toString().replace(/.{3}$/, ' $&')} zł
                      </p>
                      <Field
                        className="addOfferModal__header--input addOfferModal__header--percentage"
                        component={RegularField}
                        errors={errors}
                        name="percentage_reduction"
                        maxLength={2}
                        type="text"
                        inputOnChange={values => {
                          calcDeposit(values.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="addOfferModal__header--date">
                    <Field
                      component={RegularField}
                      errors={errors}
                      name="date_of_act"
                      type="date"
                      label="Przewidywana data podpisania aktu"
                      inputOnChange={e => setDate(e.target.value)}
                    />
                  </div>
                  <div className="addOfferModal__header--comment">
                    <Field
                      component={RegularField}
                      errors={errors}
                      name="comment"
                      type="textarea"
                      inputOnChange={values => {
                        setCommentary(values.target.value);
                      }}
                    />
                  </div>
                  <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.ADD_OFFER} />
                </form>
              )}
            </Formik>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

AddOfferModal.propTypes = {
  authorizedUser: PropTypes.string.isRequired,
  investorsLabel: PropTypes.array.isRequired,
  modalOpened: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  plot: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddOfferModal;
