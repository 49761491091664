import React, { useState, useEffect } from 'react';
import { useHistory, generatePath, useLocation } from 'react-router-dom';
import { array, func } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ListItem from '../list-item/ListItem';

const WEEKDAYS = {
  Monday: 'Poniedziałek',
  Tuesday: 'Wtorek',
  Wednesday: 'Środa',
  Thursday: 'Czwartek',
  Friday: 'Piątek',
  Saturday: 'Sobota',
  Sunday: 'Niedziela',
};

function List({ activeDayNotifications, handleDoneStatus, handleToDoStatus }) {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const keys = Object.keys(activeDayNotifications);
  // console.log(activeDayNotifications);
  const done = [];
  const to_do = [];

  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => setShow(true), 1000);
  }, []);

  // console.log(keys, 'kluczyki');

  keys.forEach(day =>
    activeDayNotifications[day].forEach(notification =>
      notification.status === 'done' ? done.push(notification) : to_do.push(notification),
    ),
  );
  console.log(activeDayNotifications);

  return (
    <div className="notificationsRight__list">
      {to_do.length > 0 ? (
        <div>
          {keys.map(day => (
            <div className="notificationsRight__weekday">{WEEKDAYS[day]}</div>
          ))}
          {/* <div className="notificationsRight__weekday">Do zrobienia</div> */}
          {to_do.map(notification => (
            <CSSTransition in={show} appear timeout={300} classNames="fade">
              <ListItem
                notification={notification}
                replace={replace}
                generatePath={generatePath}
                pathname={pathname}
                handleDoneStatus={handleDoneStatus}
                handleToDoStatus={handleToDoStatus}
              />
            </CSSTransition>
          ))}
        </div>
      ) : (
        <div className="notificationsRight__empty">Brak powiadomień</div>
      )}
      {done.length > 0 ? <div className="notificationsRight__weekday">Zakończone</div> : ''}
      {done.map(notification => (
        <CSSTransition in={show} appear timeout={300} classNames="fade">
          <ListItem
            notification={notification}
            replace={replace}
            generatePath={generatePath}
            pathname={pathname}
            handleDoneStatus={handleDoneStatus}
            handleToDoStatus={handleToDoStatus}
          />
        </CSSTransition>
      ))}
    </div>
  );
}

List.propTypes = {
  activeDayNotifications: array.isRequired,
  handleDoneStatus: func.isRequired,
  handleToDoStatus: func.isRequired,
};

export default List;
