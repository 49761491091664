import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_SETTLEMENTS_OPTIONS_INIT,
});

export const success = data => ({
  type: SETTLEMENTS.GET_SETTLEMENTS_OPTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_SETTLEMENTS_OPTIONS_FAILURE,
});

const getSettlementsOptions = () => async dispatch => {
  dispatch(init());
  try {
    const data = await Connector.getSettlementsOptions();
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getSettlementsOptions;
