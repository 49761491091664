const MODAL_TYPES = {
  CONFIRM: 'CONFIRM',
  DELETE: 'DELETE',
  RESERVATION: 'RESERVATION',
  SINGLE_OFFER: 'SINGLE_OFFER',
  ADD_OFFER: 'ADD_OFFER',
  ADD_FILE_OFFER: 'ADD_FILE_OFFER',
  ADD_DEPOSIT: 'ADD_DEPOSIT',
  MANAGE: 'MANAGE',
  RANGE: 'RANGE',
  SORT: 'SORT',
  GROUP: 'GROUP',
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  HISTORY_PAYMENT: 'HISTORY_PAYMENT',
  ADD_APPOINTMENT_NOTE: 'ADD_APPOINTMENT_NOTE',
  NOTIFICATION: 'NOTIFICATION',
  SHOW: 'SHOW',
  SAVE: 'SAVE',
  LOAD: 'LOAD',
  MAP: 'MAP',
  CONFIRM_SALE: 'CONFIRM_SALE',
  PAY_FOR_PLOT: 'PAY_FOR_PLOT',
  INACTIVE: 'INACTIVE',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  ADD_FILES: 'ADD_FILES',
};

export default MODAL_TYPES;
