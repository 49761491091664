import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { showHomePagePreloader, hideHomePagePreloader } from '../viewManagement';

const init = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_INIT,
});

export const success = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
});

const failure = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_FAILURE,
});

const sendResetPasswordEmail = email => async dispatch => {
  dispatch(init());
  try {
    dispatch(showHomePagePreloader());
    await Connector.sendResetPasswordEmail(email);
    toast('wysłano maila do resetu hasła- sprawdź skrzynkę mailową');
    dispatch(success());
    dispatch(hideHomePagePreloader());
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hideHomePagePreloader());
    return false;
  }
};

export default sendResetPasswordEmail;
