import { ROUTES } from 'shared/consts';

const matchUrlToRoute = url => {
  if (url.includes(ROUTES.EMPLOYEES)) {
    return ROUTES.EMPLOYEES;
  }
  if (url.includes(ROUTES.PARTNERS)) {
    return ROUTES.PARTNERS;
  }
  if (url.includes(ROUTES.ARCHIVED_EMPLOYEES)) {
    return ROUTES.ARCHIVED_EMPLOYEES;
  }
  if (url.includes(ROUTES.ARCHIVED_PARTNERS)) {
    return ROUTES.ARCHIVED_PARTNERS;
  }
  if (url.includes(ROUTES.ARCHIVED_INVESTORS)) {
    return ROUTES.ARCHIVED_INVESTORS;
  }
  if (url.includes(ROUTES.ARCHIVED_SETTLEMENTS)) {
    return ROUTES.ARCHIVED_SETTLEMENTS;
  }
  if (url.includes(ROUTES.INVESTORS)) {
    return ROUTES.INVESTORS;
  }
  if (url.includes(ROUTES.CALENDAR)) {
    return ROUTES.CALENDAR;
  }
  return url;
};

export default matchUrlToRoute;
