const USER_POSITIONS = {
  DIRECTOR: {
    label: 'Dyrektor',
    value: 'director',
  },
  SUPERVISOR: {
    label: 'Kierownik',
    value: 'supervisor',
  },
  MANAGEMENT: {
    label: 'Zarząd',
    value: 'management',
  },
  SPECIALIST: {
    label: 'Specjalista',
    value: 'specialist',
  },
  MANAGER: {
    label: 'Manager',
    value: 'manager',
  },
  TRADER: {
    label: 'Handlowiec',
    value: 'trader',
  },
  MAIN_ACCOUNTING_WORKER: {
    label: 'Główny księgowy',
    value: 'main_accounting_worker',
  },
  ACCOUNTING_WORKER: {
    label: 'Pracownik księgowości',
    value: 'accounting_worker',
  },
  TECHNICAL: {
    label: 'Techniczny',
    value: 'technical',
  },
  SECRETARIAT: {
    label: 'Sekretariat',
    value: 'secretariat',
  },
  SELLER: {
    label: 'Sprzedawca',
    value: 'seller',
  },
};

export default USER_POSITIONS;
