import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';

function Button({ label, type, classNames, onClick, iconType }) {
  // const buttonClasses = `btn ${classNames} ${iconType ? 'btn--with-icon' : ''}`;
  const buttonClasses = `btn ${classNames}`;
  return (
    // eslint-disable-next-line react/button-has-type
    <button className={buttonClasses} onClick={onClick} type={type}>
      {label}
      {iconType && <SVG type={iconType} />}
    </button>
  );
}

Button.propTypes = {
  classNames: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  iconType: '',
  onClick: null,
  type: 'button',
};

export default Button;
