import { createSelector } from 'reselect';

const getOfferStore = state => state.offerStore;
const getAllWaitingOffer = createSelector(getOfferStore, ({ waitingAllOffer: { data = [] } }) => data);
const getDdiWaitingOffer = createSelector(getOfferStore, ({ waitingDdiOffer: { data = [] } }) => data);
const getWaitingOffers = createSelector(getOfferStore, ({ waitingOffers: { data = [] } }) => data);
const getAcceptedOffers = createSelector(getOfferStore, ({ acceptedOffers: { data = [] } }) => data);
const getStatusPlot = createSelector(getOfferStore, ({ statusPlot: { data = [] } }) => data);

export { getAllWaitingOffer, getDdiWaitingOffer, getWaitingOffers, getAcceptedOffers, getStatusPlot };
