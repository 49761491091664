import { connect } from 'react-redux';
import * as Actions from 'store/actions/investors';
import { getArchivedInvestors, getArchivedInvestorsStatus } from 'store/selectors/investorSelector';
import ArchivedInvestors from './ArchivedInvestors.main';

const mapDispatchToProps = {
  getArchivedInvestors: () => Actions.getArchivedInvestors(),
};

const mapStateToProps = state => ({
  archivedInvestors: getArchivedInvestors(state),
  archivedInvestorsStatus: getArchivedInvestorsStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedInvestors);
