import React, { useEffect, useState } from 'react';
import { ActiveWindow, Button, List } from 'shared/components';
import { AREA_TYPES, EMPLOYEE_SHORTCUTS, ROUTES, ACTION_TYPES } from 'shared/consts';
import { func, array, string, object } from 'prop-types';
import { checkAccess } from 'shared/utils';
import Form from './components/Form';

function Ddi({
  data,
  data: { uuid },
  getAttachedDdis,
  attachedDdis,
  attachedDdisStatus,
  getDdiOptions,
  ddiOptions,
  attachDdiToPartner,
}) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  useEffect(() => {
    getAttachedDdis(uuid);
  }, [uuid]);
  useEffect(() => {
    getDdiOptions(false);
  }, []);

  const isEditEnabled = checkAccess(AREA_TYPES.PARTNERS, ACTION_TYPES.EDIT);

  return (
    <div>
      {isModalOpened && (
        <Form
          onClose={() => {
            setIsModalOpened(false);
          }}
          onSubmit={attachDdiToPartner}
          opened={isModalOpened}
          data={attachedDdis}
          partnerData={data}
          ddiOptions={ddiOptions}
        />
      )}
      <ActiveWindow.ContentHeader
        title="Przypisani DDI"
        button={
          isEditEnabled && (
            <Button classNames="transparent no-padding right-icon" label="Edytuj listę" onClick={() => setIsModalOpened(true)} />
          )
        }
      />
      <List
        area={AREA_TYPES.EMPLOYEES}
        path={ROUTES.EMPLOYEES_DDI}
        data={attachedDdis}
        dataStatus={attachedDdisStatus}
        shortcut={EMPLOYEE_SHORTCUTS.DDI}
      />
    </div>
  );
}

Ddi.propTypes = {
  attachDdiToPartner: func.isRequired,
  attachedDdis: array.isRequired,
  attachedDdisStatus: string.isRequired,
  data: object.isRequired,
  ddiOptions: array.isRequired,
  getAttachedDdis: func.isRequired,
  getDdiOptions: func.isRequired,
};

export default Ddi;
