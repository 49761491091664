import * as Connector from 'shared/connectors/reportConnector';
import { REPORT } from 'store/types';

const init = () => ({
  type: REPORT.REPORTS_EXCEL_INIT,
});

export const success = data => ({
  type: REPORT.REPORTS_EXCEL_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: REPORT.REPORTS_EXCEL_FAILURE,
});

const getReportsExcel = (reports_data, options) => async dispatch => {
  dispatch(init());
  try {
    await Connector.exportReports(reports_data, options).then(({ data }) => {
      const link = document.createElement('a');
      link.href = data.file_src;
      document.body.appendChild(link);
      link.click();
    });
  } catch (err) {
    dispatch(failure());
  }
};

export default getReportsExcel;
