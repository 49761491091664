import { USER_ROLES } from 'shared/consts';

const { ADMIN, ADMINISTRATION, ADVISER, MARKETING, ACCOUNTING, DDI, DIR, DP } = USER_ROLES;

const matchUrlToRole = url => {
  if (url.includes(ADMINISTRATION)) {
    return ADMINISTRATION;
  }
  if (url.includes(ADMIN)) {
    return ADMIN;
  }
  if (url.includes(ADVISER)) {
    return ADVISER;
  }
  if (url.includes(MARKETING)) {
    return MARKETING;
  }
  if (url.includes(ACCOUNTING)) {
    return ACCOUNTING;
  }
  if (url.includes(DDI)) {
    return DDI;
  }
  if (url.includes(DIR)) {
    return DIR;
  }
  if (url.includes(DP)) {
    return DP;
  }
  return null;
};

export default matchUrlToRole;
