import React from 'react';
import { node, string } from 'prop-types';

function Header({ children, customClassName }) {
  return <div className={`active-window__header ${customClassName}`}>{children}</div>;
}

Header.defaultProps = {
  customClassName: '',
};

Header.propTypes = {
  children: node.isRequired,
  customClassName: string,
};

export default Header;
