import { REPORT } from 'store/types';
import { getReportsData } from 'store/actions/report';

const init = () => ({
  type: REPORT.REPORTS_FILTERS_INIT,
});

export const success = data => ({
  type: REPORT.REPORTS_FILTERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: REPORT.REPORTS_FILTERS_FAILURE,
});

const updateFilters = values => async (dispatch, getState) => {
  values === false ? dispatch(init()) : '';
  try {
    dispatch(success(values));
    const { reportsFilters } = getState().reportStore;
    dispatch(getReportsData(reportsFilters.data));
  } catch (err) {
    dispatch(failure());
  }
};

export default updateFilters;
