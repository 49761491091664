import { connect } from 'react-redux';
import * as InvestorsActions from 'store/actions/investors';
import * as UsersActions from 'store/actions/user';
import { getInvestors } from 'store/selectors/investorSelector';
import { isMobile } from 'shared/utils';
import { getAllDdi, getUsers as getUsersList } from 'store/selectors/userSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import Investors from './Investors.main';

const mapDispatchToProps = {
  getInvestors: () => InvestorsActions.getInvestors(),
  getAllDdi: () => UsersActions.getAllDdi(),
  getEmployees: () => UsersActions.getUsers(),
  addInvestor: (formData, file) => InvestorsActions.addInvestor(formData, file),
  getInvestorsByFilter: uuid => InvestorsActions.getInvestorsByFilter(uuid),
};

const mapStateToProps = state => ({
  investors: getInvestors(state),
  isMobile: isMobile(),
  usersDdi: getAllDdi(state),
  authorizedUserRole: getAuthorizedUserRole(state),
  allDdi: getAllDdi(state),
  employees: getUsersList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Investors);
