import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';

const init = () => ({
  type: PARTNER.GET_PARTNERS_INIT,
});

export const success = data => ({
  type: PARTNER.GET_PARTNERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PARTNER.GET_PARTNERS_FAILURE,
});

const getPartnersByFilter = uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getPartnersByFilter(uuid);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getPartnersByFilter;
