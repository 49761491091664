import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';
import getPlotsBySettlementId from './getPlotsBySettlementId';

export const remove = uuid => ({
  type: SETTLEMENTS.DELETE_FILE_SUCCESS,
  payload: {
    uuid,
  },
});

const failure = () => ({
  type: SETTLEMENTS.DELETE_FILE_FAILURE,
});

const deleteFile = (fileUuid, settlementUuid) => async dispatch => {
  try {
    await Connector.deleteFile(fileUuid);
    await dispatch(getPlotsBySettlementId(settlementUuid));
    dispatch(remove());
  } catch (err) {
    dispatch(failure());
  }
};

export default deleteFile;
