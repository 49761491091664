import * as Connector from 'shared/connectors/notificationConnector';
// import { NOTIFICATION } from 'store/types';
import getActiveDayNotifications from 'store/actions/notification/getActiveDayNotifications';

// const init = () => ({
//   type: NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_INIT,
// });

// export const success = data => ({
//   type: NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_SUCCESS,
//   payload: {
//     data,
//   },
// });

// const failure = () => ({
//   type: NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_FAILURE,
// });

const changeStatus = (status, uuid, params) => async dispatch => {
  // dispatch(init());
  try {
    await Connector.changeStatus(status, uuid);
    dispatch(getActiveDayNotifications(params));
    // dispatch(success(status));
  } catch (err) {
    // dispatch(failure());
  }
};

export default changeStatus;
