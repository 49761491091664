import { array, string } from 'prop-types';
import React from 'react';
import { SVG, ActiveWindow } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { SINGLE_PLOT_SUBMENU_ARR } from 'shared/components/active-window/consts';
import PlotName from './PlotName';

const PlotHead = ({ plot, activeMenu, setActiveMenu }) => (
  <div className="plot__header">
    <div className="plot--icons">
      <div className="svg-square-wrapper">
        <SVG type={SVG_TYPE.LOUPE} className="white" />
        <SVG type={SVG_TYPE.MAP} className="white plot--icons--map" />
        <SVG type={SVG_TYPE.DOWNLOAD} className="white" />
      </div>
    </div>
    <PlotName plot={plot} />
    <ActiveWindow.SubMenu menu={SINGLE_PLOT_SUBMENU_ARR} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
  </div>
);

PlotHead.propTypes = {
  activeMenu: string.isRequired,
  plot: array.isRequired,
  setActiveMenu: string.isRequired,
};

export default PlotHead;
