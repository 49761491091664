import React from 'react';
import { MODAL_TYPES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { ModalActions, RegularField, TextAreaField } from 'shared/components';
import Modal from 'shared/components/modal/Modal';
import { func, object } from 'prop-types';

const EditNewsModal = ({ isOpened, onClose, onSubmit, currentEditted: { uuid, title, text } }) => (
  <Modal title="Edytuj wiadomość" type={MODAL_TYPES.CONFIRM} opened={isOpened} onClose={onClose}>
    <Formik
      initialValues={{
        uuid,
        title,
        text,
      }}
      onSubmit={values => {
        onSubmit(values.uuid, values.title, values.text);
        onClose();
      }}
    >
      {({ handleSubmit, errors }) => (
        <form className="form">
          {/* 
            <div className="radio__wrapper">
              <Field component={RadioField} errors={errors} name="sort" />
            </div> */}
          <Field
            className="regular-field__input"
            component={RegularField}
            label="Tytuł"
            name="title"
            type="text"
            errors={errors}
            fullWidth
          />
          <Field component={TextAreaField} label="Opis" name="text" fullWidth errors={errors} />
          <ModalActions type={MODAL_TYPES.SORT} onClose={onClose} handleSubmit={handleSubmit} />
        </form>
      )}
    </Formik>
  </Modal>
);
export default EditNewsModal;

EditNewsModal.propTypes = {
  currentEditted: object.isRequired,
  isOpened: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};
