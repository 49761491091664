import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions } from 'shared/components';
import { MODAL_TYPES, ROLE_OPTIONS } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function DeleteModal({ onClose, opened, onSubmit, data }) {
  const isMobileDevice = isMobile();
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title="Czy chcesz usunąć pracownika?" type={MODAL_TYPES.DELETE}>
            <div className="employees-right__group-wrapper">
              <div className="employees-right__group-label">Dane konta</div>
              <div className="employees-right__select">
                {ROLE_OPTIONS.find(({ value }) => value === data.role)?.label}
              </div>
            </div>
            <div className="employees-right__container">
              <div className="employees-right__field-wrapper">
                <div className="employees-right__field-label">Imię</div>
                <div className="employees-right__input">{data.first_name}</div>
              </div>
              <div className="employees-right__field-wrapper">
                <div className="employees-right__field-label">Nazwisko</div>
                <div className="employees-right__input">{data.last_name}</div>
              </div>
            </div>
            <ModalActions handleSubmit={onSubmit} onClose={onClose} type={MODAL_TYPES.DELETE} />
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

DeleteModal.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default DeleteModal;
