import React from 'react';
import * as Connector from 'shared/connectors/investorsConnector';
import { INVESTOR } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import { addFile } from 'shared/connectors/partnerConnector';
import getInvestors from './getInvestors';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: INVESTOR.EDIT_INVESTOR_INIT,
});

export const success = data => ({
  type: INVESTOR.EDIT_INVESTOR_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INVESTOR.EDIT_INVESTOR_FAILURE,
});

const addInvestorRequestBuilder = investorData => {
  const additionalInvestorInfo = Object.keys(investorData.investorAdditionalInformation).reduce(
    (acc, current) => ({
      ...acc,
      [current]: investorData.investorAdditionalInformation[current] === true ? 1 : 0,
    }),
    {},
  );
  const rq = {
    ...additionalInvestorInfo,
    ...investorData,
  };
  delete rq.investorAdditionalInformation;
  delete rq.investorCard;
  return rq;
};

const editInvestor = (investorData, file) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const { data } = await Connector.editInvestor(addInvestorRequestBuilder({...investorData,ddi_uuid:investorData.sales_department_uuid}));
    if (file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      await addFile(fileForm, {
        investor_uuid: investorData.uuid,
        file_format: file.type.split('/').pop().split('.')[0],
        file_name: file.name.split('.')[0],
      });
    }
    await dispatch(getInvestors());
    await toast(
      <NewUser
        badgeType="Inwst"
        first_name={investorData.first_name}
        last_name={investorData.last_name}
        label="Edytowano inwestora"
      />,
    );
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default editInvestor;
