import React, { useEffect } from 'react';
import { PageContent, List } from 'shared/components';
import { AREA_TYPES, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';

function ArchivedInvestors({ archivedInvestors, getArchivedInvestors, archivedInvestorsStatus }) {
  useEffect(async () => {
    await getArchivedInvestors();
  }, []);

  const archivedInvestorsAmount = archivedInvestors.length || null;

  return (
    <PageContent className="partners">
      <div className="partners__header">
        <div className="partners__title">
          <span className="partners__amount">{archivedInvestorsAmount}</span> Zarchiwizowani
        </div>
      </div>
      <div className="partners__list">
        <List
          activeRow="uuid"
          area={AREA_TYPES.ARCHIVED_INVESTORS}
          data={archivedInvestors}
          dataStatus={archivedInvestorsStatus}
          path={ROUTES.ARCHIVED_INVESTORS}
        />
      </div>
    </PageContent>
  );
}

ArchivedInvestors.propTypes = {
  archivedInvestors: PropTypes.array.isRequired,
  archivedInvestorsStatus: PropTypes.string.isRequired,
  getArchivedInvestors: PropTypes.func.isRequired,
};

export default ArchivedInvestors;
