import { createSelector } from 'reselect';

const getInvestorStore = state => state.investorStore;
const getInvestors = createSelector(getInvestorStore, ({ investors: { data = [] } }) => data);
const getSingleInvestor = createSelector(getInvestorStore, ({ singleInvestor: { data = [] } }) => data);
const getInvestorsOptions = createSelector(getInvestorStore, ({ investorsOptions: { data = [] } }) => data);
const getArchivedInvestors = createSelector(getInvestorStore, ({ archivedInvestors: { data = [] } }) => data);
const getArchivedInvestor = createSelector(getInvestorStore, ({ archivedInvestor: { data = {} } }) => data);
const getArchivedInvestorsStatus = createSelector(getInvestorStore, ({ archivedInvestors: { data = [] } }) => data);
const getInvestorsLabel = createSelector(getInvestorStore, ({ investorsLabel: { data = [] } }) => data);

export {
  getInvestors,
  getInvestorsOptions,
  getSingleInvestor,
  getArchivedInvestors,
  getArchivedInvestorsStatus,
  getArchivedInvestor,
  getInvestorsLabel,
};
