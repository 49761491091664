import React from 'react';
import PropTypes from 'prop-types';
import { Badge, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const UserBar = ({ phone, name, type }) => (
  <div className="userBar__wrapper">
    <div className="userBar">
      <Badge type={type} label={type} />
      <p className="userBar--name">{name}</p>
    </div>
    {phone && (
      <a className="employees-right__phone" href={`tel:${phone}`}>
        <SVG type={SVG_TYPE.PHONE} />
      </a>
    )}
  </div>
);

UserBar.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default UserBar;
