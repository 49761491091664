import { createSelector } from 'reselect';

const getReportStore = state => state.reportStore;
const getReportsData = createSelector(getReportStore, ({ reportsData: { data = [] } }) => data);
const updateFilters = createSelector(getReportStore, ({ reportsFilters: { data = {} } }) => data);
const getReportsFiltersList = createSelector(getReportStore, ({ reportsFiltersList: { data = [] } }) => data);
const getReportsExcel = createSelector(getReportStore, ({ reportsExcel: { data = [] } }) => data);
const getReportsDataStatus = createSelector(getReportStore, ({ reportsData: { status } }) => status);
const getCompaniesLabels = createSelector(getReportStore, ({ companies: { data = [] } }) => data);

export { getReportsData, updateFilters, getReportsFiltersList, getReportsExcel, getReportsDataStatus, getCompaniesLabels };
