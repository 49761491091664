import React from 'react';
import PropTypes from 'prop-types';

function PageContent({ children, className }) {
  return (
    <div className={`page-content ${className}`}>
      <div className="page-content__wrapper">{children}</div>
    </div>
  );
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default PageContent;
