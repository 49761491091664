import React, { useEffect } from 'react';
import { PageContent, List } from 'shared/components';
import { AREA_TYPES, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';

function ArchivedPartners({ archivedPartners, getArchivedPartners, archivedPartnersStatus }) {
  useEffect(async () => {
    await getArchivedPartners();
  }, []);

  const archivedPartnersAmount = archivedPartners.length || null;

  return (
    <PageContent className="partners">
      <div className="partners__header">
        <div className="partners__title">
          <span className="partners__amount">{archivedPartnersAmount}</span> Zarchiwizowani
        </div>
      </div>
      <div className="partners__list">
        <List
          activeRow="uuid"
          area={AREA_TYPES.ARCHIVED_PARTNERS}
          data={archivedPartners}
          dataStatus={archivedPartnersStatus}
          path={ROUTES.ARCHIVED_PARTNERS}
        />
      </div>
    </PageContent>
  );
}

ArchivedPartners.propTypes = {
  archivedPartners: PropTypes.array.isRequired,
  archivedPartnersStatus: PropTypes.string.isRequired,
  getArchivedPartners: PropTypes.func.isRequired,
};

export default ArchivedPartners;
