import React from 'react';
import { string, array, func } from 'prop-types';
import { PROTECTED_ROUTES } from 'shared/consts';

function Group({ label, items, handleItemClick, authorizedUserRole }) {
  return (
    <div className="settings__group">
      <div className="settings__group-label">{label}</div>
      {items.map(({ label: itemLabel, path }) => {
        if (path === '#' || PROTECTED_ROUTES.get(path)?.includes(authorizedUserRole)) {
          return (
            <div className="settings__group-item" onClick={() => handleItemClick(path)}>
              {itemLabel}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

Group.propTypes = {
  authorizedUserRole: string.isRequired,
  handleItemClick: func.isRequired,
  items: array.isRequired,
  label: string.isRequired,
};

export default Group;
