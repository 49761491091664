import React from 'react';
import PropTypes from 'prop-types';
import { SVG_TYPE, MODAL_TYPES } from 'shared/consts';
import { Button } from 'shared/components';

function Actions({ type, handleSubmit, onClose, cancel }) {
  const {
    CONFIRM,
    DELETE,
    RESERVATION,
    ADD_FILE_OFFER,
    ADD_OFFER,
    MANAGE,
    RANGE,
    SORT,
    GROUP,
    SINGLE_OFFER,
    ACCEPT_OFFER,
    NOTIFICATION,
    SHOW,
    SAVE,
    LOAD,
    CONFIRM_SALE,
    INACTIVE,
  } = MODAL_TYPES;
  return (
    <div className="modal__actions">
      {type === CONFIRM && (
        <>
          <Button classNames="transparent" label="wróć" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}
      {type === DELETE && (
        <>
          <Button classNames="transparent" label="wróć" onClick={onClose} />
          <Button classNames="red right-icon" iconType={SVG_TYPE.CONFIRM} label="Tak, usuń" onClick={handleSubmit} />
        </>
      )}
      {type === RESERVATION && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="orange" label="Rezerwuj na 3 dni" onClick={handleSubmit} />
        </>
      )}
      {type === ADD_OFFER && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="green" label="Utwórz ofertę" onClick={handleSubmit} />
        </>
      )}
      {type === MANAGE && (
        <>
          <Button classNames="transparent" label="wyczyść" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}

      {type === RANGE && (
        <>
          <Button classNames="transparent" label="wyczyść" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}

      {type === SORT && (
        <>
          <Button classNames="transparent" label="wróć" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}

      {type === GROUP && (
        <>
          <Button classNames="transparent" label="wyczyść" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}
      {type === SINGLE_OFFER && (
        <>
          <Button classNames="transparent" label="Zamknij" onClick={onClose} />
          {!cancel && <Button classNames="gray" label="Anuluj ofertę" onClick={handleSubmit} />}
        </>
      )}
      {type === ACCEPT_OFFER && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}
      {type === ADD_FILE_OFFER && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Potwierdź" onClick={handleSubmit} />
        </>
      )}
      {type === NOTIFICATION && (
        <>
          <Button classNames="orange" label="Potwierdź" onClick={handleSubmit} />
          <Button classNames="red" label="Usuń" onClick={handleSubmit} />
        </>
      )}
      {type === SHOW && (
        <>
          <Button classNames="transparent" label="wróć" onClick={onClose} />
        </>
      )}
      {type === SAVE && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="orange" label="Potwierdź" onClick={handleSubmit} />
        </>
      )}
      {type === LOAD && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="orange" label="Wczytaj" onClick={handleSubmit} />
        </>
      )}
      {type === CONFIRM_SALE && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="orange right-icon" iconType={SVG_TYPE.CONFIRM} label="Dodaj" onClick={handleSubmit} />
        </>
      )}
      {type === INACTIVE && (
        <>
          <Button classNames="transparent" label="Anuluj" onClick={onClose} />
          <Button classNames="gray" label="Dodaj" />
        </>
      )}
    </div>
  );
}

Actions.defaultProps = {
  cancel: false,
};
Actions.propTypes = {
  cancel: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf([MODAL_TYPES.CONFIRM]).isRequired,
};

export default Actions;
