import { createSelector } from 'reselect';

const getAuthStore = state => state.authStore;
const getToken = createSelector(getAuthStore, ({ token }) => token);
const getCounter = createSelector(getAuthStore, ({ counter }) => counter);
const getUserToVerify = createSelector(getAuthStore, ({ userToVerify: { data = {} } }) => data);
const getAuthorizedUser = createSelector(getAuthStore, ({ authorizedUser: { data = {} } }) => data);
const getAuthorizedUserRole = createSelector(getAuthStore, ({ authorizedUser: { data: { role } = {} } }) => role);

export { getToken, getCounter, getUserToVerify, getAuthorizedUser, getAuthorizedUserRole };
