import { OFFER } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  waitingAllOffer: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  waitingDdiOffer: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  waitingOffers: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  acceptedOffers: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  statusPlot: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const offerReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case OFFER.GET_ALL_WAITING_OFFER_INIT: {
      return {
        ...state,
        waitingAllOffer: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case OFFER.GET_ALL_WAITING_OFFER_SUCCESS: {
      return {
        ...state,
        waitingAllOffer: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case OFFER.GET_ALL_WAITING_OFFER_FAILURE: {
      return {
        ...state,
        waitingAllOffer: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case OFFER.GET_STATUS_PLOT_INIT: {
      return {
        ...state,
        statusPlot: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case OFFER.GET_STATUS_PLOT_SUCCESS: {
      return {
        ...state,
        statusPlot: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case OFFER.GET_STATUS_PLOT_FAILURE: {
      return {
        ...state,
        statusPlot: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case OFFER.GET_ACCEPTED_OFFERS_INIT: {
      return {
        ...state,
        acceptedOffers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case OFFER.GET_ACCEPTED_OFFERS_SUCCESS: {
      return {
        ...state,
        acceptedOffers: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case OFFER.GET_ACCEPTED_OFFERS_FAILURE: {
      return {
        ...state,
        acceptedOffers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case OFFER.GET_DDI_WAITING_OFFER_INIT: {
      return {
        ...state,
        waitingDdiOffer: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case OFFER.GET_DDI_WAITING_OFFER_SUCCESS: {
      return {
        ...state,
        waitingDdiOffer: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case OFFER.GET_DDI_WAITING_OFFER_FAILURE: {
      return {
        ...state,
        waitingDdiOffer: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case OFFER.GET_WAITING_OFFERS_INIT: {
      return {
        ...state,
        waitingOffers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case OFFER.GET_WAITING_OFFERS_SUCCESS: {
      return {
        ...state,
        waitingOffers: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case OFFER.GET_WAITING_OFFERS_FAILURE: {
      return {
        ...state,
        waitingOffers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default:
      return state;
  }
};

export default offerReducer;
