import React from 'react';
import { object, array, func, bool } from 'prop-types';
import { Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

function SettlementData({ archived, data, restoreSettlement }) {

  return (
    <div className="active-window__data">
      <div className="active-window__group-wrapper">
        {archived && (
          <div className="employees-right__actions">
            <Button
              iconType={SVG_TYPE.RESTORE}
              classNames="orange"
              label="Przywróć"
              onClick={() => restoreSettlement(data.uuid)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

SettlementData.defaultProps = {
  archived: false,
};
SettlementData.propTypes = {
  archived: bool,
  authorizedUserRole: array.isRequired,
  bonusesOptions: array.isRequired,
  data: object.isRequired,
  deleteInvestor: func.isRequired,
  editInvestor: func.isRequired,
  employees: array.isRequired,
  getAllDdi: func.isRequired,
  getEmployees: func.isRequired,
  restoreSettlement: func.isRequired,
  usersDdi: array.isRequired,
  
};

export default SettlementData;
