import React from 'react';
import { arrayOf, shape, string, func, object } from 'prop-types';

const Switch = ({ field: { name, value }, form: { setFieldValue }, label, options, errors }) => (
  <div className="switch-field">
    {label && <div className="switch-field__label">{label}</div>}
    <div className="switch-field__options">
      {options.map(option => (
        <div
          key={option.value}
          className={`switch-field__option ${value === option.value ? 'switch-field__option--active' : ''}`}
          onClick={() => setFieldValue(name, option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
    {errors.meeting_notification && <div className="switch-field--error">Wybierz rodzaj spotkania</div>}
  </div>
);
Switch.defaultProps = {
  label: '',
};
Switch.propTypes = {
  errors: object.isRequired,
  field: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({}).isRequired,
  label: string,
  options: arrayOf(shape({})).isRequired,
};

export default Switch;
