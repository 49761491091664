import { bool, func, shape } from 'prop-types';
import { generatePath } from 'react-router';
import React from 'react';
import { ROUTES } from 'shared/consts';
import Skeleton from 'react-loading-skeleton';
import store from 'store/store';
import { SettlementList } from './components';

class ArchivedSettlements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idClick: null,
    };
  }

  async componentDidMount() {
    const {
      actions: { getSettlements, getSecondarySettlements, getArchivedSettlements },
    } = this.props;
    await getSettlements();
    await getSecondarySettlements();
    await getArchivedSettlements();
  }

  onSettlementActiveChange = async id => {
    const {
      actions: { getPlotsBySettlementId },
      archivedSettlements: { data: archivedSettlements = [] },
    } = this.props;
    console.log(archivedSettlements[id]);
    const { uuid } = archivedSettlements[id];
    await getPlotsBySettlementId(uuid);
    if (this.state.idClick === id) {
      this.setState({ idClick: null });
      this.props.history.push(ROUTES.ARCHIVED_SETTLEMENTS);
    } else {
      localStorage.setItem('SettlementId', id);
      this.props.history.push(generatePath(ROUTES.ARCHIVED_SETTLEMENTS_ID, { uuid }));
      this.setState({ idClick: id });
    }
  };

  render() {
    const {
      settlements: { data: settlements = [] },
      archivedSettlements: { data: archivedSettlements = [] },
      isMobile,
      location: { pathname },
    } = this.props;
    const settlementUuid = pathname.split('/')[3];
    let indexRowElement = null;
    settlements &&
      settlements.forEach((item, idx) => {
        if (item.uuid === settlementUuid) {
          indexRowElement = idx;
        }
      });

    return (
      <>
        <div id="settlements__top" className="partners__header settlements__top">
          <span className="investors__title">Osady zarchiwizowane</span>
        </div>
        <div className="settlements">
          <div className="settlements__list">
            <div className="settlementList__wrapper">
              {archivedSettlements.length >= 1 ? (
                <>
                  <SettlementList
                    isMobile={isMobile}
                    archivedSettlements={archivedSettlements}
                    onSettlementActiveChange={this.onSettlementActiveChange}
                    // isActive={!isSecondaryActive}
                    rowActiveByLink={indexRowElement}
                  />
                </>
              ) : (
                <>
                  {store.getState().settlementStore.archivedSettlements.status === 'SUCCESS' ? (
                    <h2>Brak zaarchiwizowanych osad</h2>
                  ) : (
                    <Skeleton count={5} height={100} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

ArchivedSettlements.propTypes = {
  actions: shape({
    getSettlements: func.isRequired,
    getPlotsBySettlementId: func.isRequired,
    getSecondarySettlements: func.isRequired,
  }).isRequired,
  archivedSettlements: shape({}).isRequired,
  isMobile: bool.isRequired,
  secondarySettlements: shape({}).isRequired,
  settlements: shape({}).isRequired,
};

export default ArchivedSettlements;
