const USER_ROLES = {
  ADMIN: 'admin',
  DDI: 'sales_department',
  ADMINISTRATION: 'administration',
  ACCOUNTING: 'accounting_department',
  MARKETING: 'marketing_department',
  ADVISER: 'adviser',
  DIR: 'dir',
  DP: 'dp',
};

export default USER_ROLES;
