import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { isMobile as checkIsMobile } from 'shared/utils';

function PartnerName({ name }) {
  const isMobile = checkIsMobile();
  return (
    <div className="employees-right__title">
      <div className="employees-right__title-wrapper">
        {isMobile && (
          <Link className="employees-right__back" to={ROUTES.PARTNERS}>
            <SVG type={SVG_TYPE.DROPDOWN_INDICATOR} />
          </Link>
        )}
        <div className="employees-right__fullname">{name}</div>
      </div>
    </div>
  );
}

PartnerName.propTypes = {
  name: string.isRequired,
};

export default PartnerName;
