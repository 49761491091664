import React from 'react';
import Logo from 'assets/files/logo.png';
import { NavLink } from 'react-router-dom';
import { SVG } from 'shared/components';
import { MENU_ITEMS, PROTECTED_ROUTES } from 'shared/consts';
import { string } from 'prop-types';

function DashboardSidebar({ authorizedUserRole }) {
  return (
    <div className="dashboard-sidebar">
      <div className="dashboard-sidebar__menu">
        {MENU_ITEMS.map(({ svgType, label, route }) => {
          if (PROTECTED_ROUTES.get(route)?.includes(authorizedUserRole))
            return (
              <NavLink
                key={label}
                activeClassName="dashboard-sidebar__item--active"
                className="dashboard-sidebar__item"
                to={route}
              >
                <div className="dashboard-sidebar__icon">
                  <SVG type={svgType} />
                </div>
                <div className="dashboard-sidebar__label">{label}</div>
              </NavLink>
            );
          return null;
        })}
      </div>
      <div className="dashboard-sidebar__logo">
        <img alt="Rodzinne inwestycje" src={Logo} />
      </div>
    </div>
  );
}

DashboardSidebar.propTypes = {
  authorizedUserRole: string.isRequired,
};

export default DashboardSidebar;
