import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Button, PageContent, SelectField, Badge } from 'shared/components';
import { EMPLOYEE_SHORTCUTS, AREA_TYPES, ACTION_TYPES, SVG_TYPE, FORM_TYPES, ROUTES } from 'shared/consts';
import { checkAccess } from 'shared/utils';
import { Formik, Field } from 'formik';
import { generatePath } from 'react-router';
import Form from './components/form';

function Partners({
  partners,
  getPartners,
  partnersStatus,
  getBonusesOptions,
  bonusesOptions,
  addPartner,
  getEmployees,
  employees,
  getPartnersByFilter,
  history,
  location: { pathname },
}) {
  useEffect(async () => {
    await getPartners();
    await getBonusesOptions();
    await getEmployees();
  }, []);

  const utils = {
    adviser: 'DO',
    sales_department: 'DDI',
  };

  const hadnleFilterOptions = () =>
    employees.length > 0 &&
    employees
      .filter(el => el.role === 'adviser' || el.role === 'sales_department')
      .map(({ role, value, searchableLabel, label }) => ({
        label: (
          <div className="select-field__option">
            <Badge label={utils[role]} type={utils[role]} />
            {label}
          </div>
        ),
        value,
        searchableLabel,
      }));

  const [isModalOpened, setIsModalOpened] = useState(false);
  const partnersAmount = partners.length || null;
  const isAddEnabled = checkAccess(AREA_TYPES.PARTNERS, ACTION_TYPES.ADD);
  const uuid = pathname.split('/')[3];
  const handleFilteringPartners = e => {
    if (e) {
      history.push(generatePath(ROUTES.PARTNERS));
      getPartnersByFilter(e.value);
    } else {
      getPartners();
    }
  };
  return (
    <PageContent className="partners">
      <Form
        onClose={() => {
          setIsModalOpened(false);
        }}
        onSubmit={addPartner}
        opened={isModalOpened}
        type={FORM_TYPES.ADD}
        bonusesOptions={bonusesOptions}
      />
      <div className="partners__header">
        <div className="partners__title">
          <span className="partners__amount">{partnersAmount}</span> Partnerzy
        </div>
        <div className="select__filter--wrapper">
          <Formik
            initialValues={{
              filterBy: '',
            }}
          >
            <Field
              component={SelectField}
              field={{ name: 'filterBy' }}
              placeholder="Filtruj"
              fullWidth
              options={hadnleFilterOptions()}
              isCustomLabel
              onChange={handleFilteringPartners}
            />
          </Formik>

          {isAddEnabled && (
            <Button
              classNames="transparent no-padding right-icon"
              iconType={SVG_TYPE.ADD}
              label="Dodaj"
              onClick={() => setIsModalOpened(true)}
            />
          )}
        </div>
      </div>
      <div className="partners__list">
        <List
          activeRow={uuid}
          area={AREA_TYPES.PARTNERS}
          data={partners}
          dataStatus={partnersStatus}
          path={ROUTES.PARTNERS}
          shortcut={EMPLOYEE_SHORTCUTS.ADVISER}
        />
      </div>
    </PageContent>
  );
}

Partners.propTypes = {
  addPartner: PropTypes.func.isRequired,
  attachedAdvisers: PropTypes.array.isRequired,
  authorizedUser: PropTypes.object.isRequired,
  bonusesOptions: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  getAttachedAdvisers: PropTypes.func.isRequired,
  getBonusesOptions: PropTypes.func.isRequired,
  getEmployees: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  getPartnersByFilter: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  partners: PropTypes.array.isRequired,
  partnersStatus: PropTypes.string.isRequired,
};

export default Partners;
