import React from 'react';
import { string, node } from 'prop-types';

function ContentHeader({ button, title }) {
  return (
    <div className="active-window__content-header">
      <div className="active-window__content-header--title">{title}</div>
      {button && <div className="active-window__content-header--button">{button}</div>}
    </div>
  );
}

ContentHeader.propTypes = {
  button: node.isRequired,
  title: string.isRequired,
};

export default ContentHeader;
