import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { UserBar, File, AppointmentNote, RegularField, Button } from 'shared/components';
import moment from 'moment';

const SigneContract = ({ plot, isUserBooking, isAccountant, isAdmin, setPurchaseDate }) => {
  const {
    order_status: {
      investor: { first_name, last_name, phone },
      ddi,
    },
    arranging_the_act,
    arranging_conditional_act,
  } = plot;
  const [date, setDate] = useState(moment(plot?.order_status?.date_of_purchase).format('YYYY-MM-DD'));
  return (
    <>
      {(isUserBooking || isAccountant || isAdmin) && (
        <div className="signedContract__investor">
          <UserBar type="INWST" name={`${first_name} ${last_name}`} phone={phone} />
        </div>
      )}
      {(isAccountant || isAdmin) && (
        <div className="signedContract__ddi">
          <UserBar type="DDI" name={`${ddi.first_name} ${ddi.last_name}`} phone={ddi.phone} />
        </div>
      )}
      {(isUserBooking || isAccountant || isAdmin) && arranging_the_act && (
        <div className="signedContract__appointment">
          <AppointmentNote info={arranging_the_act} plot={plot} />
        </div>
      )}
      {(isUserBooking || isAccountant || isAdmin) && arranging_conditional_act && (
        <div className="signedContract__appointment">
          <AppointmentNote info={arranging_conditional_act} plot={plot} />
        </div>
      )}
      {isUserBooking && (
        <div className={`signedContract__files ${isUserBooking && 'signedContract__files--top'}`}>
          <File label={plot?.order_status?.files[0].file_name} path={plot?.order_status?.files[0].access_path} />
          <File label={plot?.order_status?.files[1].file_name} path={plot?.order_status?.files[1].access_path} />
        </div>
      )}
      {isAccountant && (
        <Formik
          initialValues={{
            statusUuid: plot?.order_status?.uuid,
            date,
          }}
          onSubmit={values => {
            setPurchaseDate(values.statusUuid, values.date);
          }}
        >
          {({ handleSubmit, errors }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Field
                  component={RegularField}
                  errors={errors}
                  label="Przewidywana data spłaty"
                  name="date"
                  inputOnChange={e => setDate(e.target.value)}
                  type="date"
                  fullWidth
                />
                <Button label="Zmień datę" type="button" classNames="orange" onClick={handleSubmit} />
              </form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

SigneContract.propTypes = {
  isAccountant: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isUserBooking: PropTypes.bool.isRequired,
  plot: PropTypes.array.isRequired,
  setPurchaseDate: PropTypes.func.isRequired,
};

export default SigneContract;
