// import React from 'react';
import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'store/store';
import { showPreloader, hidePreloader } from '../viewManagement';
import getArchivedSettlements from './getArchivedSettlements';

export const success = data => ({
  type: SETTLEMENTS.CHANGE_ARCHVING_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SETTLEMENTS.CHANGE_ARCHVING_FAILURE,
});

const changeArchving = (uuid, is_archiving) => async dispatch => {
  try {
    //  file = formData.investorCard;
    dispatch(showPreloader());
    // console.log({ ...formData, file_name: file.name, file_format: file.type })
    const data = await Connector.changeArchving(uuid, is_archiving);
    dispatch(hidePreloader());
    history.push(ROUTES.ARCHIVED_SETTLEMENTS);
    await dispatch(getArchivedSettlements());
    toast('Zmieniono status osady');
    dispatch(success(data));
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default changeArchving;
