import * as Connector from 'shared/connectors/notificationConnector';
import { NOTIFICATION } from 'store/types';
import { showPreloader, hidePreloader } from '../viewManagement';
import getNotifications from './getNotifications';

const init = () => ({
  type: NOTIFICATION.ADD_NOTIFICATION_INIT,
});

export const success = data => ({
  type: NOTIFICATION.ADD_NOTIFICATION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: NOTIFICATION.ADD_NOTIFICATION_FAILURE,
});

const addNotification = (notificationData, file) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const date = `${notificationData.date} ${notificationData.time}`;
    delete notificationData.time;
    const { data } = await Connector.addNotification({ ...notificationData, date });
    if (file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      console.log(file);
      await Connector.addFile(fileForm, {
        notification_uuid: data.uuid,
        file_format: file.type.split('/').pop().split('.')[0],
        file_name: file.name.split('.')[0],
      });
    }
    dispatch(success(data));
    await dispatch(getNotifications());
    dispatch(hidePreloader());
    return true;
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
    return false;
  }
};

export default addNotification;
