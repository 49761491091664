import React from 'react';
import { MODAL_TYPES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { ModalActions, RegularField, TextAreaField } from 'shared/components';
import Modal from 'shared/components/modal/Modal';
import { func } from 'prop-types';

const CreateNewsModal = ({ isOpened, onClose, onSubmit }) => (
  <Modal title="Dodaj wiadomość" type={MODAL_TYPES.CONFIRM} opened={isOpened} onClose={onClose}>
    <Formik
      initialValues={{
        title: '',
        text: '',
      }}
      onSubmit={values => {
        onSubmit(values);
        onClose();
      }}
    >
      {({ handleSubmit, errors }) => (
        <form className="form">
          {/* 
            <div className="radio__wrapper">
              <Field component={RadioField} errors={errors} name="sort" />
            </div> */}
          <Field className="regular-field__input" component={RegularField} label="Tytuł" errors={errors} name="title" fullWidth />
          <Field component={TextAreaField} label="Opis" errors={errors} name="text" fullWidth />
          <ModalActions type={MODAL_TYPES.SORT} onClose={onClose} handleSubmit={handleSubmit} />
        </form>
      )}
    </Formik>
  </Modal>
);

export default CreateNewsModal;

CreateNewsModal.propTypes = {
  isOpened: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};
