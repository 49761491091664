import { createSelector } from 'reselect';

const getPartnerStore = state => state.partnerStore;
const getPartners = createSelector(getPartnerStore, ({ partners: { data = [] } }) => data);
const getPartnersStatus = createSelector(getPartnerStore, ({ partners: { status } }) => status);
const getBonusesOptions = createSelector(getPartnerStore, ({ bonusesOptions: { data = [] } }) => data);
const getPartnersOptions = createSelector(getPartnerStore, ({ partnersOptions: { data = [] } }) => data);
const getAttachedDdis = createSelector(getPartnerStore, ({ attachedDdis: { data = [] } }) => data);
const getAttachedDdisStatus = createSelector(getPartnerStore, ({ attachedDdis: { status } }) => status);
const getArchivedPartner = createSelector(getPartnerStore, ({ archivedPartner: { data = {} } }) => data);
const getArchivedPartners = createSelector(getPartnerStore, ({ archivedPartners: { data = [] } }) => data);
const getArchivedPartnersStatus = createSelector(getPartnerStore, ({ archivedPartners: { status } }) => status);
const getAttachedAdvisers = createSelector(getPartnerStore, ({ attachedAdvisers: { data = [] } }) => data);

export {
  getPartners,
  getPartnersStatus,
  getBonusesOptions,
  getPartnersOptions,
  getAttachedDdis,
  getAttachedDdisStatus,
  getArchivedPartner,
  getArchivedPartners,
  getArchivedPartnersStatus,
  getAttachedAdvisers,
};
