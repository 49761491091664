import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, UserBar, ModalActions, RegularField, SVG, TextAreaField } from 'shared/components';
import { MODAL_TYPES, SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Field, Formik } from 'formik';
import TimePicker from 'react-times';
import moment from 'moment';
import { object, string } from 'yup';
import { PlotInfo } from './components';

function AddAppointmentNotaryModal({ onClose, opened, title, plot, onSubmit, isConditionalActModal }) {
  const isMobileDevice = isMobile();
  const [timeVal, setTimeVal] = useState(moment().format('HH:MM'));
  const onTimeChange = options => {
    const { hour, minute } = options;
    setTimeVal(`${hour}:${minute}`);
  };
  const getFormSchema = () =>
    object().shape({
      date: string().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
      description: string().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    });
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.ADD_APPOINTMENT_NOTE}>
            <Formik
              enableReinitialize
              initialValues={{
                date: '',
                time: timeVal,
                title: 'Umówienie aktu',
                status_uuid: plot?.order_status.uuid,
                description: '',
                arranging_condition_act: !!isConditionalActModal,
              }}
              onSubmit={async values => onSubmit(values)}
              validationSchema={getFormSchema()}
            >
              {({ handleSubmit, errors }) => (
                <>
                  <form className="modal__form appointmentNote" onSubmit={handleSubmit}>
                    <div className="appointmentNote__calendary">
                      <TimePicker time={timeVal} onTimeChange={onTimeChange} />
                      <Field component={RegularField} errors={errors} label="Data" name="date" type="date" fullWidth />
                      <div className="submenu">
                        <div className="submenu__item submenu__item--meeting">
                          <SVG type={SVG_TYPE.MEETING} />
                        </div>
                      </div>
                    </div>
                    <Field component={RegularField} errors={errors} label="Tytuł" name="title" type="text" fullWidth />
                    <Field
                      component={TextAreaField}
                      errors={errors}
                      label="Wpisz nazwisko notariusza oraz miasto kancelarii"
                      name="description"
                      fullWidth
                    />

                    <UserBar
                      type="INWST"
                      name={`${plot?.order_status?.investor?.first_name} ${plot?.order_status?.investor?.last_name}`}
                      phone={plot?.order_status?.investor?.phone}
                    />
                    <PlotInfo plot={plot} />
                  </form>
                  <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.CONFIRM} />
                </>
              )}
            </Formik>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

AddAppointmentNotaryModal.propTypes = {
  authorizedUser: PropTypes.string.isRequired,
  isConditionalActModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  plot: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddAppointmentNotaryModal;
