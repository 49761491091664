import API from 'shared/connectors/config';

export function changeStatus(data) {
  return API.put(`status/${data.order_status}`, data);
}

export function addContracts(file, { file_format, file_name, status_uuid }) {
  return API.post(
    `files?${status_uuid ? `status_uuid=${status_uuid}` : ''}&file_format=${file_format}&file_name=${file_name}`,
    file,
  );
}

export function confirmDepositPayment(data) {
  return API.post('down_payment', data);
}
