import { VIEW_MANAGEMENT } from 'store/types';

function setPrevPath(path) {
  return {
    type: VIEW_MANAGEMENT.SET_PREV_PATH,
    payload: {
      data: path,
    },
  };
}

export default setPrevPath;
