import API from 'shared/connectors/config';

export function getInvestors() {
  return API.get(`/investors/search`);
}

export function addInvestor(data) {
  return API.post(`/investors`, data);
}

export function restoreInvestor(uuid) {
  return API.post(`/investors/restore`, { investor_uuid: uuid });
}

export function deleteInvestor(uuid) {
  return API.delete(`/investors/${uuid}`);
}

export function editInvestor(data) {
  return API.put(`/investors/${data.uuid}`, data);
}

export function getInvestorsOptions() {
  return API.get(`/investors/label`);
}

export function getSingleInvestor(uuid) {
  return API.get(`/investors/show/${uuid}`);
}

export function getArchivedInvestors() {
  return API.get(`investors/deleted`);
}

export function getArchivedInvestor(uuid) {
  return API.get(`investors/deleted/${uuid}`);
}
export function getInvestorsLabel(data) {
  return API.get(`/investors/label?ddi_uuid=${data.id}&full_data=${data.full_data}`);
}
export function getInvestorsByFilter(uuid) {
  return API.get(`/investors/filter/${uuid}`);
}
