import React from 'react';
import { object } from 'prop-types';
import { ActiveWindow } from 'shared/components';
import { FIELD_TYPE } from 'shared/components/active-window/consts';
import ToggleTabs from '../toggle-tabs/ToggleTabs';

const { INPUT } = FIELD_TYPE;

function InvestorDetails({ data }) {
  const {
    pesel,
    house_number,
    street,
    city,
    investor_type,
    id_card_number,
    zip_code,
    property_separation,
    interested,
    competitors,
    no_contact,
    lack_of_funds,
    company_name,
    nip,
  } = data;
  return (
    <div className="active-window__data">
      {investor_type === 'person' && (
        <>
          <div className="active-window__group-label">Osoba fizyczna</div>
          <ActiveWindow.Field type={INPUT} label="Pesel" value={pesel} />
          <ActiveWindow.Field type={INPUT} label="Nr dowodu osobistego" value={id_card_number} />
          <div className="employees-right__field-wrapper">
            <div className="employees-right__field-label">Adres</div>
            <div className="employees-right__input">
              ul. {street} {house_number}, {city} {zip_code}
            </div>
          </div>
          <div className="employees-right__field-wrapper">
            <div className="employees-right__field-label">Rozdzielność majątkowa</div>
            <div className="employees-right__input">
              {property_separation === 1 && 'Tak'}
              {property_separation === 0 && 'Nie'}
              {property_separation === null && 'Nie dotyczy'}
            </div>
          </div>
          <ToggleTabs interested={interested} competitors={competitors} no_contact={no_contact} lack_of_funds={lack_of_funds} />
        </>
      )}
      {investor_type === 'company' && (
        <>
          <div className="active-window__group-label">Firma</div>
          <ActiveWindow.Field type={INPUT} label="Nip" value={nip} />
          <ActiveWindow.Field type={INPUT} label="Nazwa firmy" value={company_name} />
          <div className="employees-right__field-wrapper">
            <div className="employees-right__field-label">Adres</div>
            <div className="employees-right__input">
              ul. {street} {house_number}, {city} {zip_code}
            </div>
          </div>
          <ActiveWindow.Field type={INPUT} label="Notatka" value={nip} />

          <ToggleTabs interested={interested} competitors={competitors} no_contact={no_contact} lack_of_funds={lack_of_funds} />
        </>
      )}
    </div>
  );
}

InvestorDetails.propTypes = {
  data: object.isRequired,
};

export default InvestorDetails;
