const options = fetchMethods => [
  {
    label: 'Osady',
    value: 'settlements',
    accessor: 'settlements2',
    searchableLabel: 'Osady',
    isFieldPrimary: true,
    fetchMethod: fetchMethods.getSettlementsOptions,
  },

  {
    label: 'DDI',
    value: 'ddi',
    accessor: 'ddi2',
    searchableLabel: 'DDI',
    isFieldPrimary: true,
    fetchMethod: fetchMethods.getUsers,
  },

  {
    label: 'Inwestorzy',
    value: 'investors',
    accessor: 'investors2',
    searchableLabel: 'Inwestorzy',
    isFieldPrimary: true,
    fetchMethod: fetchMethods.getInvestorsOptions,
  },

  {
    label: 'Partnerzy',
    value: 'partners',
    accessor: 'partners2',
    searchableLabel: 'Partnerzy',
    isFieldPrimary: true,
    fetchMethod: fetchMethods.getPartnersOptions,
  },
  // {
  //   label: 'Spółka',
  //   value: 'company',
  //   accessor: 'company2',
  //   searchableLabel: 'Spółka',
  //   isFieldPrimary: true,
  //   fetchMethod: fetchMethods.getCompaniesLabels,
  // },
];

const options2 = fetchMethods => [
  {
    label: 'Osady',
    value: 'settlements2',
    accessor: 'settlements',
    searchableLabel: 'Osady',
    isFieldPrimary: false,
    fetchMethod: fetchMethods.getSettlementsOptions,
  },

  {
    label: 'DDI',
    value: 'ddi2',
    accessor: 'ddi',
    searchableLabel: 'DDI',
    isFieldPrimary: false,
    fetchMethod: fetchMethods.getUsers,
  },

  {
    label: 'Inwestorzy',
    value: 'investors2',
    accessor: 'investors',
    searchableLabel: 'Inwestorzy',
    isFieldPrimary: false,
    fetchMethod: fetchMethods.getInvestorsOptions,
  },

  {
    label: 'Partnerzy',
    value: 'partners2',
    accessor: 'partners',
    searchableLabel: 'Partnerzy',
    isFieldPrimary: false,
    fetchMethod: fetchMethods.getPartnersOptions,
  },
  {
    label: 'Spółka',
    value: 'company2',
    accessor: 'company',
    searchableLabel: 'Spółka',
    isFieldPrimary: false,
    fetchMethod: fetchMethods.getCompaniesLabels,
  },
];

const utils = {
  settlements: {
    fieldName: 'settlement_uuids',
    placeholder: 'Wybierz osady',
  },
  ddi: {
    fieldName: 'ddi_uuid',
    placeholder: 'Wybierz DDI',
  },
  investors: {
    fieldName: 'customer_uuid',
    placeholder: 'Wybierz inwestora',
  },
  partners: {
    fieldName: 'partner_uuid',
    placeholder: 'Wybierz partnera',
  },
  company: {
    fieldName: 'company_uuids',
    placeholder: 'Wybierz spółkę',
  },
  settlements2: {
    fieldName: 'settlement_uuids2',
    placeholder: 'Wybierz osady',
  },
  ddi2: {
    fieldName: 'ddi_uuid2',
    placeholder: 'Wybierz DDI',
  },
  investors2: {
    fieldName: 'customer_uuid2',
    placeholder: 'Wybierz inwestora',
  },
  partners2: {
    fieldName: 'partner_uuid2',
    placeholder: 'Wybierz partnera',
  },
  company2: {
    fieldName: 'company_uuids2',
    placeholder: 'Wybierz spółkę',
  },
};

export { options, options2, utils };
