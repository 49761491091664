import React, { useEffect } from 'react';
import { PageContent, List } from 'shared/components';
import { AREA_TYPES, ROUTES } from 'shared/consts';
import PropTypes from 'prop-types';

function ArchivedEmployees({ archivedEmployees, getArchivedEmployees, archivedEmployeesStatus }) {
  useEffect(async () => {
    await getArchivedEmployees();
  }, []);

  const archivedEmployeesAmount = archivedEmployees.length || null;

  return (
    <PageContent className="partners">
      <div className="partners__header">
        <div className="partners__title">
          <span className="partners__amount">{archivedEmployeesAmount}</span> Zarchiwizowani
        </div>
      </div>
      <div className="partners__list">
        <List
          activeRow="uuid"
          area={AREA_TYPES.ARCHIVED_EMPLOYEES}
          data={archivedEmployees}
          dataStatus={archivedEmployeesStatus}
          path={ROUTES.ARCHIVED_EMPLOYEES}
        />
      </div>
    </PageContent>
  );
}

ArchivedEmployees.propTypes = {
  archivedEmployees: PropTypes.array.isRequired,
  archivedEmployeesStatus: PropTypes.string.isRequired,
  getArchivedEmployees: PropTypes.func.isRequired,
};

export default ArchivedEmployees;
