import { connect } from 'react-redux';
import * as Actions from 'store/actions/user';
import * as PartnerActions from 'store/actions/partner';
import { getUsers, getUsersAmount, getUsersStatus, getDdiOptions } from 'store/selectors/userSelector';
import { getAuthorizedUser } from 'store/selectors/authSelector';
import { getPartnersOptions } from 'store/selectors/partnerSelector';
import { isMobile } from 'shared/utils';
import Employees from './Employees.main';

const mapDispatchToProps = {
  getUsers: role => Actions.getUsers(role),
  getUsersAmount: () => Actions.getUsersAmount(),
  addUser: data => Actions.addUser(data),
  getPartnersOptions: ddi_uuid => PartnerActions.getPartnersOptions(ddi_uuid),
  getDdiOptions: withPartnersOnly => Actions.getDdiOptions(withPartnersOnly),
};

const mapStateToProps = state => ({
  users: getUsers(state),
  usersStatus: getUsersStatus(state),
  usersAmount: getUsersAmount(state),
  isMobile: isMobile(),
  partnersOptions: getPartnersOptions(state),
  ddiOptions: getDdiOptions(state),
  authorizedUser: getAuthorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
