import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { isMobile as checkIsMobile } from 'shared/utils';

function InvestorName({ name, phone }) {
  const isMobile = checkIsMobile();
  return (
    <div className="employees-right__title">
      <div className="employees-right__title-wrapper">
        {isMobile && (
          <Link className="employees-right__back" to={ROUTES.INVESTORS}>
            <SVG type={SVG_TYPE.DROPDOWN_INDICATOR} />
          </Link>
        )}
        <div className="employees-right__fullname">{name}</div>
      </div>
      {phone && (
        <a className="employees-right__phone" href={`tel:${phone}`}>
          <SVG type={SVG_TYPE.PHONE} />
        </a>
      )}
    </div>
  );
}

InvestorName.defaultProps = {
  phone: '',
};

InvestorName.propTypes = {
  name: string.isRequired,
  phone: string,
};

export default InvestorName;
