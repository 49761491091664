import React from 'react';
import { oneOf, string, func } from 'prop-types';
import { Badge, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { FIELD_TYPE } from './consts';

function Field({ type, label, value, customClassName, badgeType, onDelete }) {
  const wrapperClassName = `active-window__field-wrapper ${customClassName}`;
  const labelClassName = type === FIELD_TYPE.SELECT ? 'active-window__group-label' : 'active-window__field-label';
  return (
    <div className={wrapperClassName}>
      {label && <div className={labelClassName}>{label}</div>}
      <div className={`active-window__${type}`}>
        {badgeType && <Badge label={badgeType} type={badgeType} />}
        {value}
        {onDelete && (
          <div className="active-window__delete" onClick={onDelete}>
            <SVG type={SVG_TYPE.EXIT} />
          </div>
        )}
      </div>
    </div>
  );
}

Field.defaultProps = {
  badgeType: '',
  customClassName: '',
  label: '',
};

Field.propTypes = {
  badgeType: string,
  customClassName: string,
  label: string,
  onDelete: func.isRequired,
  type: oneOf(['input', 'select']).isRequired,
  value: string.isRequired,
};

export default Field;
