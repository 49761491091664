import { func } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

function Filter({ onClick }) {
  return (
    <div className="dashboard-header__filter" onClick={onClick}>
      <SVG className="svg--filter-icon svg--filter-icon--gray" type={SVG_TYPE.FILTER} />
    </div>
  );
}

Filter.propTypes = {
  onClick: func.isRequired,
};

export default Filter;
