import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { showHomePagePreloader, hideHomePagePreloader } from '../viewManagement';

const init = () => ({
  type: USER.SET_NEW_PASSWORD_INIT,
});

export const success = () => ({
  type: USER.SET_NEW_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: USER.SET_NEW_PASSWORD_FAILURE,
});

const sendResetPasswordEmail = data => async dispatch => {
  dispatch(init());
  try {
    dispatch(showHomePagePreloader());
    await Connector.setNewPassword(data);
    toast('hasło zostało zmienione- zaloguj się');
    dispatch(success());
    sessionStorage.removeItem('resetToken');
    dispatch(hideHomePagePreloader());
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hideHomePagePreloader());
    return false;
  }
};

export default sendResetPasswordEmail;
