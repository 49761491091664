import API from 'shared/connectors/config';

export function getPartners() {
  return API.get(`buisness_partners`);
}

export function getBonusesOptions() {
  return API.get(`bonus_thresholds`);
}

export function addPartner(data) {
  return API.post(`buisness_partners`, data);
}

export function addFile(data, { business_partner_uuid, file_format, file_name, investor_uuid, down_payment_uuid }) {
  return API.post(
    `files?${business_partner_uuid ? `business_partner_uuid=${business_partner_uuid}` : ''}${
      down_payment_uuid ? `down_payment_uuid=${down_payment_uuid}` : ''
    }${investor_uuid ? `investor_uuid=${investor_uuid}` : ''}&file_format=${file_format}&file_name=${file_name}`,
    data,
  );
}

export function getPartnersOptions(ddi_uuid) {
  return API.get(`business_partners/list${ddi_uuid ? `?ddi_uuid=${ddi_uuid}` : ''}`);
}

export function editPartner(data) {
  return API.put(`buisness_partners/${data.uuid}`, data);
}

export function deletePartner(data) {
  return API.delete(`buisness_partners/${data.uuid}`);
}

export function getAttachedDdis(partnerUuid) {
  return API.get(`buisness_partners/ddi/${partnerUuid}`);
}

export function getAttchedAdvisers(uuid) {
  return API.get(`buisness_partners/adviser/${uuid}`);
}

export function getArchivedPartners() {
  return API.get(`buisness_partners/deleted`);
}

export function getArchivedPartner(uuid) {
  return API.get(`buisness_partner/deleted/${uuid}`);
}

export function restorePartner(uuid) {
  return API.post(`buisness_partners/restore`, { partner_uuid: uuid });
}

export function attachDdiToPartner(partnerUuid, ddiList) {
  return API.put(`buisness_partners/ddi/${partnerUuid}`, { ddi_uuids: ddiList });
}

export function getPartnersByFilter(uuid) {
  return API.get(`buisness_partners/filter/${uuid}`);
}
