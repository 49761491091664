import React, { useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import PropTypes from 'prop-types';
// import Skeleton from 'react-loading-skeleton';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { Route } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';
import { isMobile } from 'shared/utils';
import MobileCalendar from 'react-scroll-calendar';
import Notifications from 'screens/notifications/Notifications.container';
import 'react-scroll-calendar/build/react-scroll-calendar.css';

const EVENT_TYPES = {
  contact: SVG_TYPE.PHONE,
  meeting: SVG_TYPE.INVESTORS,
  note: SVG_TYPE.FILE,
  arranging_the_act: SVG_TYPE.ACT,
};

function Calendar({ notifications, getNotifications, notificationsDialogVisible, showNotificationDialog, history }) {
  const calendarRef = useRef();
  const checkIsMobile = isMobile();
  const today = new Date();
  const minDate = moment(today).subtract(1, 'month').format('YYYY-MM');
  const isPreviousViewRedirect = history.location.state?.previous === 'appointment';
  const uuid = history.location.state?.uuid;
  const appointment_date = moment(history.location.state?.date).format('YYYY-MM-DD');

  console.log(history);

  useEffect(async () => {
    await getNotifications();
    const date = moment().format('YYYY-MM-DD');
    if (isPreviousViewRedirect) {
      history.push(generatePath(ROUTES.SINGLE_NOTIFICATION, { uuid }), {
        pathname: `/dashboard/calendar/${appointment_date}/${appointment_date}`,
      });
      document.querySelector(`.fc-daygrid-day[data-date="${appointment_date}"]`)?.classList.add('fc-day-active');
    } else {
      history.replace(generatePath(ROUTES.CALENDAR_START_END, { startDate: date, endDate: date }));
      document.querySelector(`.fc-daygrid-day[data-date="${date}"]`)?.classList.add('fc-day-active');
    }
  }, []);

  const MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'Septemeber',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  useEffect(() => {
    const date = new Date();
    let month = date.getMonth();
    month += 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const listOfDays = document.querySelector(`#${MONTHS[month]}-${year} ul[class="date"]`);
    if (listOfDays) {
      const days = Array.from(listOfDays.querySelectorAll('li'));
      let counter = 0;
      days.forEach(item => {
        if (item.classList.contains('visible-hidden')) {
          counter += 1;
        }
      });
      days.splice(0, counter);
      days[day - 1].classList.add('activeDayBorder');
    }
  }, []);

  useEffect(() => {
    window.scroll(0, 250);
  }, []);

  useEffect(() => {
    const datesOfNotifications = notifications.map(({ date }) => date);
    const monthsWithNotifications = [];
    const yearsWithNotifications = [];
    const notificationsArray = [];
    const test = [];
    datesOfNotifications.forEach(date => {
      const yearWithNotifications = parseInt(moment(date).format('YYYY'), 10);
      yearsWithNotifications.push(yearWithNotifications);
    });
    datesOfNotifications.forEach(date => {
      const monthWithNotifications = parseInt(moment(date).format('MM'), 10);
      monthsWithNotifications.push(monthWithNotifications);
    });
    const uniqYear = [...new Set(yearsWithNotifications)];
    const uniqMonth = [...new Set(monthsWithNotifications)];
    datesOfNotifications.forEach(date => {
      const notificationDate = {
        year: parseInt(moment(date).format('YYYY'), 10),
        month: parseInt(moment(date).format('MM'), 10),
        day: parseInt(moment(date).format('DD'), 10),
      };
      test.push(notificationDate);
    });
    uniqYear.forEach(year => {
      uniqMonth.forEach(month => {
        const listOfDays = document.querySelector(`#${MONTHS[month]}-${year} ul[class="date"]`);
        if (listOfDays) {
          const days = Array.from(listOfDays.querySelectorAll('li'));
          let counter = 0;
          days.forEach(item => {
            if (item.classList.contains('visible-hidden')) {
              counter += 1;
            }
          });
          days.splice(0, counter);
          const test1 = test.filter(elem => elem.month === month && elem.year === year);
          test1.forEach(item => {
            const badge = document.getElementById(`${month}-${item.day}-${year}`);
            if (days[item.day - 1].contains(badge)) {
              notificationsArray.push(`${month}-${item.day}-${year}`);
            } else {
              const element = document.createElement('span');
              days[item.day - 1].appendChild(element);
              element.classList.add('notificationCounter');
              element.setAttribute('id', `${month}-${item.day}-${year}`);
              notificationsArray.push(`${month}-${item.day}-${year}`);
            }
          });
          const counts = {};
          notificationsArray.forEach(i => {
            counts[i] = (counts[i] || 0) + 1;
          });
          Object.keys(counts).forEach(key => {
            const elem = document.getElementById(key);
            elem.innerHTML = counts[key];
          });
        }
      });
    });
  }, [notifications]);

  const onSelect = async ({ startStr }) => {
    // console.log(startStr);
    history.replace(generatePath(ROUTES.CALENDAR_START_END, { startDate: startStr, endDate: startStr }));
    document.querySelector('.fc-daygrid-day.fc-day-active')?.classList.remove('fc-day-active');
    document.querySelector(`.fc-daygrid-day[data-date="${startStr}"]`)?.classList.add('fc-day-active');
  };

  const selectMobileCalendar = date => {
    const properFormat = moment(date).format('YYYY-MM-DD');
    history.replace(generatePath(ROUTES.CALENDAR_START_END, { startDate: properFormat, endDate: properFormat }));
    showNotificationDialog();
  };

  const date = new Date();
  const currentDate = moment(date).format('YYYY-MM-DD');

  return (
    <div className="calendar">
      {!checkIsMobile ? (
        <FullCalendar
          ref={calendarRef}
          eventOverlap={false}
          dayMaxEvents={3}
          businessHours={false}
          // defaultTimedEventDuration={false}
          // defaultView="dayGridMonth"
          dayHeaderFormat={{ weekday: 'long' }}
          plugins={[dayGridPlugin, interactionPlugin]}
          locale="pl"
          timeZone="UTC"
          firstDay="1"
          eventContent={({ event }) => (
            <span className="calendar__event-cell">
              <SVG type={EVENT_TYPES[event.extendedProps.type]} />
              <div
                className={
                  moment(event.startStr).format('YYYY-MM-DD') < currentDate && event.extendedProps.status === 'to_do'
                    ? 'calendar__event-cell-title'
                    : ''
                }
              >
                {event.title}
              </div>
            </span>
          )}
          selectable
          select={onSelect}
          // select={info => setNow(moment(info).format('YYYY-MM-DD'))}
          eventClick={info => info.jsEvent.preventDefault()}
          events={notifications}
          stickyHeaderDates
          displayEventTime={false}
          displayEventEnd={false}
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev,next',
          }}
        />
      ) : (
        <div className="mobile__calendar--wrapper">
          <MobileCalendar
            minDate={moment(`${minDate}-01`, 'YYYY-MM-DD')}
            maxDate={moment('2025-10-14', 'YYYY-MM-DD')}
            className="changeBg"
            onSelect={selectMobileCalendar}
            monthFormat="MMMM"
          />
          {notificationsDialogVisible && <Route component={Notifications} exact path={ROUTES.CALENDAR_START_END} />}
        </div>
      )}
      {/* {notifications.length > 0 ? (
       
      ) : (
        <div className="calendar__skeleton-container">
          <div className="calendar__skeleton-header">
            <Skeleton height={50} width={155} />
            <Skeleton height={50} width={80} />
          </div>
          <Skeleton count={30} height={150} width={155} />
        </div>
      )} */}
    </div>
  );
}

Calendar.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  manageNotificationsDialog: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  notificationsDialogVisible: PropTypes.bool.isRequired,
  showNotificationDialog: PropTypes.func.isRequired,
};

export default Calendar;
