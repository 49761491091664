import React from 'react';
import * as Connector from 'shared/connectors/investorsConnector';
import { INVESTOR } from 'store/types';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { NewUser } from 'shared/components';
import { history } from 'store/store';
import getArchivedInvestors from './getArchivedInvestors';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: INVESTOR.RESTORE_INVESTOR_INIT,
});

export const success = () => ({
  type: INVESTOR.RESTORE_INVESTOR_SUCCESS,
});

const failure = () => ({
  type: INVESTOR.RESTORE_INVESTOR_FAILURE,
});

const restoreInvestor = userData => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const { data } = await Connector.restoreInvestor(userData.uuid);
    await dispatch(getArchivedInvestors());
    history.push(ROUTES.ARCHIVED_INVESTORS);
    await toast(
      <NewUser
        badgeType="Inwst"
        first_name={userData.first_name}
        last_name={userData.last_name}
        label="Przywrócono inwestora"
        phone={userData.phone}
      />,
    );
    dispatch(success(data));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default restoreInvestor;
