import React from 'react';
import { node, string } from 'prop-types';

function Wrapper({ children, customClassName }) {
  return <div className={`active-window__wrapper ${customClassName}`}>{children}</div>;
}

Wrapper.defaultProps = {
  customClassName: '',
};

Wrapper.propTypes = {
  children: node.isRequired,
  customClassName: string,
};

export default Wrapper;
