import React from 'react';
import { bool, shape } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import EmployeesRight from 'screens/employees-right/EmployeesRight.container';
import SettlementPlots from 'screens/settlement-plots/SettlementPlots.container';
import AllPlots from 'screens/allPlots/AllPlots.container';
import PartnersRight from 'screens/partners-right/PartnersRight.container';
import { ROUTES } from 'shared/consts';
import InvestorsRight from 'screens/investors-right/InvestorsRight.container';
import SettlementsRight from 'screens/settlements-right/SettlementsRight.container';
import Plot from 'screens/plot/Plot.container';
import NotificationsRight from 'screens/notifications-right/NotificationsRight.container';
import { RoleRoute } from 'shared/components';
import SingleNotificationRight from 'screens/single-notification-right/SingleNotificationRight.container';
import EMPLOYEES_ROUTES_ARR from '../employees/consts';

const {
  SETTLEMENTS,
  SETTLEMENTS_ID,
  PARTNERS_UUID,
  ARCHIVED_EMPLOYEES,
  ARCHIVED_PARTNERS,
  ARCHIVED_INVESTORS,
  ARCHIVED_SETTLEMENTS,
  INVESTORS,
  CALENDAR_START_END,
  SETTLEMENTS_PLOT_ID,
  SINGLE_NOTIFICATION,
} = ROUTES;

const ActiveWindow = ({ isMobile, authorizedUserRole }) => {
  const role = authorizedUserRole;
  if (isMobile) {
    return (
      <>
        <Route component={SettlementPlots} exact path={SETTLEMENTS_ID} />
        <Route component={PartnersRight} exact path={PARTNERS_UUID} />
        <Route exact path={`${ARCHIVED_EMPLOYEES}/:uuid`} render={props => <EmployeesRight archived {...props} />} />
        <Route exact path={`${ARCHIVED_PARTNERS}/:uuid`} render={props => <PartnersRight archived {...props} />} />
        <Route exact path={`${ARCHIVED_INVESTORS}/:uuid`} render={props => <InvestorsRight archived {...props} />} />
        <Route exact path={`${ARCHIVED_SETTLEMENTS}/:uuid`} render={props => <SettlementsRight archived {...props} />} />
        <Route component={InvestorsRight} exact path={`${INVESTORS}/:uuid`} />
        {/* <Route component={SettlementsRight} exact path={`${SETTLEMENTS}/:uuid`} /> */}
        <Route component={Plot} exact path={SETTLEMENTS_PLOT_ID} />
        <Route component={SingleNotificationRight} exact path={SINGLE_NOTIFICATION} />
        {EMPLOYEES_ROUTES_ARR.map(({ path }) => (
          <Route key={path} exact path={`${path}/:uuid`} render={props => <EmployeesRight {...props} />} />
        ))}
      </>
    );
  }
  return (
    <div className="dashboard-routes__right">
      <Switch>
        <RoleRoute authorizedUserRole={role} component={AllPlots} exact path={SETTLEMENTS} />
        <Route component={Plot} exact path={SETTLEMENTS_PLOT_ID} />
        <Route component={SettlementPlots} exact path={SETTLEMENTS_ID} />
        <Route exact path={`${ARCHIVED_EMPLOYEES}/:uuid`} render={props => <EmployeesRight archived {...props} />} />
        <Route exact path={`${ARCHIVED_PARTNERS}/:uuid`} render={props => <PartnersRight archived {...props} />} />
        <Route exact path={`${ARCHIVED_INVESTORS}/:uuid`} render={props => <InvestorsRight archived {...props} />} />
        <Route exact path={`${ARCHIVED_SETTLEMENTS}/:uuid`} render={props => <SettlementsRight archived {...props} />} />
        <Route component={PartnersRight} exact path={PARTNERS_UUID} />
        <Route component={InvestorsRight} exact path={`${INVESTORS}/:uuid`} />
        {/* <Route component={SettlementsRight} exact path={`${SETTLEMENTS}/:uuid`} /> */}
        <Route component={SingleNotificationRight} exact path={SINGLE_NOTIFICATION} />
        <Route component={NotificationsRight} exact path={CALENDAR_START_END} />
        {EMPLOYEES_ROUTES_ARR.map(({ path }) => (
          <Route key={path} exact path={`${path}/:uuid`} render={props => <EmployeesRight {...props} />} />
        ))}
      </Switch>
    </div>
  );
};

ActiveWindow.propTypes = {
  authorizedUserRole: shape({}).isRequired,
  isMobile: bool.isRequired,
};

export default ActiveWindow;
