import React, { useState, useEffect } from 'react';
import {
  Modal,
  RegularField,
  ModalActions,
  TextAreaField,
  SelectField,
  FieldFileInput,
  Switch,
  OptionsLabel,
} from 'shared/components';
import { FORM_TYPES, MODAL_TYPES, USER_ROLES, VALIDATION_MESSAGES } from 'shared/consts';
import PropTypes, { array } from 'prop-types';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
// import TimePicker from 'react-times';
import moment from 'moment';
import * as Yup from 'yup';
import { SubMenu } from './components';
import { SUBMENU_TYPES } from './const';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import Badge from '../badge';

const { ADMIN } = USER_ROLES;
const { REQUIRED_FIELD } = VALIDATION_MESSAGES;

const initialTitle = {
  [SUBMENU_TYPES.CONTACT]: 'Kontakt',
  [SUBMENU_TYPES.MEETING]: 'Spotkanie',
  [SUBMENU_TYPES.NOTE]: 'Notatka',
};

const employeeBadge = {
  administration: 'ADM',
  admin: 'AD',
  sales_department: 'DDI',
  accounting_department: 'KS',
  marketing_department: 'MA',
  adviser: 'DO',
  dp: 'DP',
};

function Form({
  onClose,
  onSubmit,
  opened,
  type,
  getInvestorsOptions,
  investorsOptions,
  getPlotsOptions,
  plotsOptions,
  getSettlementsOptions,
  settlementsOptions,
  loggedUserRole,
  employeesOptions,
}) {
  const today = new Date();
  const [submenuType, setSubmenuType] = useState(SUBMENU_TYPES.CONTACT);
  const [fileInputValue, setFileInputValue] = useState(null);
  const [timeVal, setTimeVal] = useState(moment(today).format('HH:MM'));
  const [appointmentDate, setAppointmentDate] = useState(moment(today).format('YYYY-MM-DD'));

  const INVESTORS_SELECT = OptionsLabel(investorsOptions, 'INWST');
  const PLOTS_SELECT = plotsOptions && OptionsLabel(plotsOptions);
  const SETTLEMENTS_SELECT = settlementsOptions.data && OptionsLabel(settlementsOptions.data);

  const employeesLabels = () =>
    employeesOptions?.map(({ role, value, searchableLabel, label }) => ({
      label: (
        <div className="select-field__option">
          <Badge label={employeeBadge[role]} type={employeeBadge[role]} />
          {label}
        </div>
      ),
      value,
      searchableLabel,
    }));

  useEffect(() => {
    getInvestorsOptions();
    getPlotsOptions();
    getSettlementsOptions();
  }, []);

  const handleFileChange = e => {
    setFileInputValue(e.target.files[0]);
  };

  const onTimeChange = options => {
    const { hour, minute } = options;
    setTimeVal(`${hour}:${minute}`);
  };

  const getFormSchema = () =>
    object().shape({
      building_plot_uuid: string(),
      business_partner_uuid: string().when('role', {
        is: USER_ROLES.ADVISER,
        then: string().required(REQUIRED_FIELD),
      }),
      ...(loggedUserRole === ADMIN && {
        ddi_uuid: string().when('role', {
          is: USER_ROLES.ADVISER,
          then: string().required(REQUIRED_FIELD),
        }),
      }),
      ...(submenuType === SUBMENU_TYPES.CONTACT && {
        investor_uuid: string(),
      }),
      ...(submenuType === SUBMENU_TYPES.NOTE && {
        settlement_uuid: string(),
      }),
      ...(submenuType === SUBMENU_TYPES.MEETING && {
        meeting_notification: object().shape({
          place: string().required(REQUIRED_FIELD),
        }),
      }),
      date: Yup.date().min(today, 'Powiadomienie w przeszłości!').required(REQUIRED_FIELD),
      time: string().required(REQUIRED_FIELD),
      title: string().required(REQUIRED_FIELD),
    });

  return (
    <Modal
      onClose={onClose}
      onSubmit={onSubmit}
      opened={opened}
      title={` ${type === FORM_TYPES.ADD ? 'Dodaj' : 'Edytuj'} powiadomienie`}
      type={MODAL_TYPES.CONFIRM}
    >
      <Formik
        initialValues={{
          date: appointmentDate,
          time: timeVal,
          title: initialTitle[submenuType],
          investor_uuid: '',
          settlement_uuid: '',
          building_plot_uuid: '',
          investor_type: true,
          employee_uuid: '',
          meeting_notification: {
            place: '',
          },
        }}
        onSubmit={async (values, { setErrors }) => {
          const response = await onSubmit({ ...values, type: submenuType }, fileInputValue);
          if (response) {
            onClose();
          } else if (response.field === 'email') {
            setErrors({ email: VALIDATION_MESSAGES.UNIQUE_EMAIL });
          }
        }}
        validationSchema={getFormSchema(loggedUserRole)}
      >
        {({ handleSubmit, errors }) => (
          <>
            <form className="modal__form" onSubmit={handleSubmit}>
              <Field
                component={RegularField}
                errors={errors}
                label="Data"
                name="date"
                inputOnChange={e => setAppointmentDate(e.target.value)}
                type="date"
                fullWidth
              />
              <div className="submenu__wrapper">
                {/* <TimePicker time={timeVal} onTimeChange={onTimeChange} /> */}
                <Field
                  component={RegularField}
                  errors={errors}
                  label="Godzina"
                  name="time"
                  inputOnChange={onTimeChange}
                  type="time"
                  fullWidth
                />
                <SubMenu submenuType={submenuType} setSubmenuType={setSubmenuType} />
              </div>
              {submenuType === SUBMENU_TYPES.MEETING && (
                <>
                  <Field
                    component={Switch}
                    errors={errors}
                    name="meeting_notification.place"
                    options={[
                      {
                        label: 'Na miejscu',
                        value: 'conference_room',
                      },
                      {
                        label: 'W terenie',
                        value: 'in_field',
                      },
                    ]}
                  />
                </>
              )}
              <Field component={RegularField} errors={errors} label="Tytuł" name="title" type="text" fullWidth />
              <Field component={TextAreaField} errors={errors} label="Opis" name="description" fullWidth />
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                isCustomLabel
                name="settlement_uuid"
                options={SETTLEMENTS_SELECT}
                placeholder="Wybierz osade"
              />
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                name="building_plot_uuid"
                options={PLOTS_SELECT}
                isCustomLabel
                placeholder="Wybierz działkę"
              />
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                isCustomLabel
                name="investor_uuid"
                options={INVESTORS_SELECT}
                placeholder="Wybierz inwestora"
              />
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                name="employee_uuid"
                options={employeesLabels()}
                isCustomLabel
                placeholder="Przypisz pracownika"
              />
              <Field
                component={FieldFileInput}
                handleChange={handleFileChange}
                errors={errors}
                name="investorCard"
                type="file"
                label="+ Dodaj plik"
              />
              <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.CONFIRM} />
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

Form.defaultProps = {
  data: null,
};

Form.propTypes = {
  data: PropTypes.object,
  employeesOptions: array.isRequired,
  getInvestorsOptions: PropTypes.func.isRequired,
  getPlotsOptions: PropTypes.func.isRequired,
  getSettlementsOptions: PropTypes.func.isRequired,
  investorsOptions: PropTypes.func.isRequired,
  loggedUserRole: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  plotsOptions: PropTypes.array.isRequired,
  settlementsOptions: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['ADD', 'EDIT']).isRequired,
};

export default Form;
