import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteInvestor, editInvestor, getArchivedInvestor, restoreInvestor } from 'store/actions/investors';
import { getInvestors, getArchivedInvestor as getArchivedInvestorSelector } from 'store/selectors/investorSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import * as UsersActions from 'store/actions/user';
import { getAllDdi, getUsers as getUsersList } from 'store/selectors/userSelector';
import InvestorsRight from './InvestorsRight.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getArchivedInvestor: uuid => getArchivedInvestor(uuid),
      editInvestor: (data, file) => editInvestor(data, file),
      deleteInvestor: data => deleteInvestor(data),
      getAllDdi: () => UsersActions.getAllDdi(),
      getEmployees: () => UsersActions.getUsers(),
      restoreInvestor: uuid => restoreInvestor(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  archivedInvestor: getArchivedInvestorSelector(state),
  investors: getInvestors(state),
  usersDdi: getAllDdi(state),
  authorizedUserRole: getAuthorizedUserRole(state),
  employees: getUsersList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestorsRight);
