import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions, FieldFileInput } from 'shared/components';
import { MODAL_TYPES, VALIDATION_MESSAGES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Formik, Field } from 'formik';
import { object, array } from 'yup';

function AddFilesModal({ onClose, opened, title, onSubmit, settlementUuid }) {
  const { UPLOAD_2_FILES, REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const [fileInputValue, setFileInputValue] = useState('');
  const isMobileDevice = isMobile();

  useEffect(() => {
    fileInputValue.length > 10 && setFileInputValue('');
  }, [fileInputValue]);

  const addOfferSchema = () =>
    object().shape({
      file: array().min(1, UPLOAD_2_FILES).required(REQUIRED_FIELD),
    });

  const handleFormSubmit = async values => {
    onSubmit(values, fileInputValue);
    onClose();
  };

  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
            <Formik
              enableReinitialize
              initialValues={{
                file: '',
                settlement_uuid: settlementUuid,
              }}
              validationSchema={addOfferSchema()}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, errors }) => {
                const handleFileChange = e => {
                  const { files } = e.target;
                  const { length } = files;
                  length === 1 && setFileInputValue([...fileInputValue, files[0]]);
                  length === 2 && setFileInputValue([...fileInputValue, files[0], files[1]]);
                  length === 3 && setFileInputValue([...fileInputValue, files[0], files[1], files[2]]);
                  length === 4 && setFileInputValue([...fileInputValue, files[0], files[1], files[2], files[3]]);
                  length === 5 && setFileInputValue([...fileInputValue, files[0], files[1], files[2], files[3], files[4]]);
                  length === 6 &&
                    setFileInputValue([...fileInputValue, files[0], files[1], files[2], files[3], files[4], files[5]]);
                  length === 7 &&
                    setFileInputValue([...fileInputValue, files[0], files[1], files[2], files[3], files[4], files[5], files[6]]);
                  length === 8 &&
                    setFileInputValue([
                      ...fileInputValue,
                      files[0],
                      files[1],
                      files[2],
                      files[3],
                      files[4],
                      files[5],
                      files[6],
                      files[7],
                    ]);
                  length === 9 &&
                    setFileInputValue([
                      ...fileInputValue,
                      files[0],
                      files[1],
                      files[2],
                      files[3],
                      files[4],
                      files[5],
                      files[6],
                      files[7],
                      files[8],
                    ]);
                  length === 10 &&
                    setFileInputValue([
                      ...fileInputValue,
                      files[0],
                      files[1],
                      files[2],
                      files[3],
                      files[4],
                      files[5],
                      files[6],
                      files[7],
                      files[8],
                      files[9],
                    ]);
                  // length > 2 && setFileInputValue('');
                };
                return (
                  <form className="modal__form " onSubmit={handleSubmit}>
                    <p className="modal__form--section-name">Dołącz dokumenty</p>
                    <Field
                      component={FieldFileInput}
                      handleChange={handleFileChange}
                      errors={errors}
                      name="file"
                      type="file"
                      label="+ Dodaj"
                      multiple
                    />
                    <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.ADD_FILE_OFFER} />
                  </form>
                );
              }}
            </Formik>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

AddFilesModal.propTypes = {
  authorizedUser: PropTypes.string.isRequired,
  investorsLabel: PropTypes.array.isRequired,
  modalOpened: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  plot: PropTypes.array.isRequired,
  settlementUuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddFilesModal;
