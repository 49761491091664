import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AuthActions from 'store/actions/auth';
import * as ViewManagement from 'store/actions/viewManagement';
import HomePage from 'screens/home-page/HomePage.container';
import DashboardRoutes from 'screens/DashboardRoutes';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/consts';
import { AuthRoute, Preloader } from 'shared/components';
import PropTypes from 'prop-types';
import { getAuthorizedUser } from '../store/selectors/authSelector';
import { getIsPreloaderVisible } from '../store/selectors/viewManagementSelector';

class MainRoutes extends Component {
  async componentDidMount() {
    const {
      authorizedUser,
      getAuthUser,
      location: { search },
      history,
    } = this.props;
    if (!authorizedUser && localStorage.getItem('isUserLogged')) {
      await getAuthUser();
    }

    if (search.split('=')[0] === '?token') {
      sessionStorage.setItem('activateToken', JSON.stringify(search.split('=')[1]));
      history.push(ROUTES.ACTIVATE_ACCOUNT);
    } else if (search.split('=')[0] === '?reset-token') {
      sessionStorage.setItem('resetToken', JSON.stringify(search.split('=')[1]));
      history.push(ROUTES.NEW_PASSWORD);
    }
  }

  // To do - usunąć
  shouldComponentUpdate({ location: { pathname: paramLocation } }) {
    const {
      location: { pathname },
      setPrevPath,
    } = this.props;
    if (paramLocation !== pathname) {
      setPrevPath(pathname);
    }
    return true;
  }

  render() {
    const { isPreloaderVisible } = this.props;
    return (
      <div className="main-wrapper">
        {isPreloaderVisible && <Preloader />}
        <div className="page__inner">
          <Switch>
            <Route component={HomePage} exact path={ROUTES.HOME_PAGE} />
            <AuthRoute component={DashboardRoutes} path={ROUTES.DASHBOARD} />
            <Route component={HomePage} />
          </Switch>
        </div>
      </div>
    );
  }
}

MainRoutes.defaultProps = {
  authorizedUser: null,
};

MainRoutes.propTypes = {
  authorizedUser: PropTypes.object,
  getAuthUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isPreloaderVisible: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  setPrevPath: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getAuthUser: AuthActions.getAuthorizedUser,
  setPrevPath: ViewManagement.setPrevPath,
};

function mapStateToProps(state) {
  return {
    authorizedUser: getAuthorizedUser(state),
    isPreloaderVisible: getIsPreloaderVisible(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
