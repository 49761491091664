import API from 'shared/connectors/config';

export function addOffer(data) {
  console.log(data);
  return API.post(`/offers`, data);
}

export function offerChangeStatus(offerUuid, status, admin_comment) {
  return API.post(`/offers/${offerUuid}`, { acceptance: status, admin_comment });
}

export function getAllWaitingOffer(plotUuid) {
  return API.get(`/offers/plot/waiting/${plotUuid}`);
}

export function getDdiWaitingOffer(plot_uuid, ddi_uuid) {
  return API.get(`/offers/waiting/ddi/${plot_uuid}`, { ddi_uuid });
}

export function getAllAcceptedOffer(plotUuid) {
  return API.get(`/offers/plot/accepted/${plotUuid}`);
}

export function getDdiAcceptedOffer(plot_uuid, ddi_uuid) {
  return API.get(`/offers/accepted/ddi/${plot_uuid}?ddi_uuid=${ddi_uuid}`);
}

export function getStatusPlot(plotUuid) {
  return API.get(`/status/plot/${plotUuid}`);
}
