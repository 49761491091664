import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { bool, func, string } from 'prop-types';
import { GROUP_ARR } from './consts';
import { Group, Header } from './components';

function Settings({ isOpened, onClose, logout, authorizedUserRole }) {
  const history = useHistory();
  const handleItemClick = path => {
    history.push(path);
    onClose();
  };
  const handleChildClick = e => e.stopPropagation();
  if (isOpened) {
    return (
      <div className="settings" onClick={onClose}>
        <div className="settings__inner" onClick={handleChildClick}>
          <div className="settings__icon" onClick={onClose}>
            <SVG type={SVG_TYPE.SETTINGS} />
          </div>
          <Header />
          <div className="settings__content">
            {GROUP_ARR.map(item => (
              <Group {...item} handleItemClick={handleItemClick} authorizedUserRole={authorizedUserRole} />
            ))}
            <div className="settings__group-item settings__group-item--logout" onClick={logout}>
              Wyloguj
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

Settings.propTypes = {
  authorizedUserRole: string.isRequired,
  isOpened: bool.isRequired,
  logout: func.isRequired,
  onClose: func.isRequired,
};

export default props => ReactDOM.createPortal(<Settings {...props} />, document.querySelector('body'));
