import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

function SubpageLink({ subpage: { label, route }, amount }) {
  return (
    <NavLink key={label} activeClassName="subpage-link--active" className="subpage-link" to={route}>
      <div className="subpage-link__amount">{amount}</div>
      <div className="subpage-link__label">{label}</div>
    </NavLink>
  );
}

SubpageLink.defaultProps = {
  amount: null,
};

SubpageLink.propTypes = {
  amount: PropTypes.number,
  subpage: PropTypes.object.isRequired,
};

export default SubpageLink;
