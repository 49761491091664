import { string } from 'prop-types';
import React from 'react';

const HorizontalStatusBar = ({ status }) => (
  <div className="HorizontalStatusBar">
    <div className="HorizontalStatusBar__content">
      <div
        className={`HorizontalStatusBar__item HorizontalStatusBar__item--${status === 2 ? 'gray' : ''}${
          status === 3 ? 'orange' : ''
        }${status === 4 ? 'red' : ''}${status === 5 ? 'black' : ''}`}
      >
        <span
          className={`HorizontalStatusBar__dot HorizontalStatusBar__dot--${
            status === 1 ? 'current HorizontalStatusBar__dot--current--green' : 'gray'
          }`}
        />
      </div>
      <div
        className={`HorizontalStatusBar__item HorizontalStatusBar__item--${status === 3 ? 'orange' : ''}${
          status === 4 ? 'red' : ''
        }${status === 5 ? 'black' : ''}`}
      >
        <span
          className={`HorizontalStatusBar__dot HorizontalStatusBar__dot--${
            status === 2 ? 'current HorizontalStatusBar__dot--current--orange' : ''
          }${status === 3 ? 'orange' : ''}${status === 4 ? 'red' : ''}${status === 5 ? 'black' : ''}`}
        />
      </div>
      <div
        className={`HorizontalStatusBar__item HorizontalStatusBar__item--${status === 4 ? 'red' : ''}${
          status === 5 ? 'black' : ''
        }`}
      >
        <span
          className={`HorizontalStatusBar__dot HorizontalStatusBar__dot--${
            status === 3 ? 'current HorizontalStatusBar__dot--current--orange' : ''
          }${status === 4 ? 'red' : ''}${status === 5 ? 'black' : ''}`}
        />
      </div>
      <div className={`HorizontalStatusBar__item HorizontalStatusBar__item--${status === 5 ? 'black' : ''}`}>
        <span
          className={`HorizontalStatusBar__dot HorizontalStatusBar__dot--${
            status === 4 ? 'current HorizontalStatusBar__dot--current--orange' : ''
          }${status === 5 ? 'black' : ''}`}
        />
      </div>
      <div className={`HorizontalStatusBar__item HorizontalStatusBar__item--${status === 5 ? 'black' : ''}`}>
        <span
          className={`HorizontalStatusBar__dot HorizontalStatusBar__dot--${
            status === 5 ? 'current HorizontalStatusBar__dot--current--black' : ''
          }`}
        />
      </div>
    </div>
  </div>
);

HorizontalStatusBar.propTypes = {
  status: string.isRequired,
};

export default HorizontalStatusBar;
