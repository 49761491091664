import { ROUTES, EMPLOYEE_LABELS, USER_ROLES } from 'shared/consts';

const EMPLOYEES_SUBPAGES = [
  {
    role: USER_ROLES.ADMIN,
    label: EMPLOYEE_LABELS.ADMIN,
    route: ROUTES.EMPLOYEES_ADMIN,
  },
  {
    role: USER_ROLES.DDI,
    label: EMPLOYEE_LABELS.DDI,
    route: ROUTES.EMPLOYEES_DDI,
  },
  {
    role: USER_ROLES.ADVISER,
    label: EMPLOYEE_LABELS.ADVISER,
    route: ROUTES.EMPLOYEES_ADVISER,
  },
  {
    role: USER_ROLES.ACCOUNTING,
    label: EMPLOYEE_LABELS.ACCOUNTING,
    route: ROUTES.EMPLOYEES_ACCOUNTING,
  },
  {
    role: USER_ROLES.ADMINISTRATION,
    label: EMPLOYEE_LABELS.ADMINISTRATION,
    route: ROUTES.EMPLOYEES_ADMINISTRATION,
  },
  {
    role: USER_ROLES.MARKETING,
    label: EMPLOYEE_LABELS.MARKETING,
    route: ROUTES.EMPLOYEES_MARKETING,
  },
  {
    role: USER_ROLES.DIR,
    label: EMPLOYEE_LABELS.DIR,
    route: ROUTES.EMPLOYEES_DIR,
  },
  {
    role: USER_ROLES.DP,
    label: EMPLOYEE_LABELS.DP,
    route: ROUTES.EMPLOYEES_DP,
  },
];

export default EMPLOYEES_SUBPAGES;
