import { Connector } from 'modules/authModule';
import { AUTH } from 'store/types';
import { ROUTES } from 'shared/consts';
import { history } from 'store/store';
import { showHomePagePreloader, hideHomePagePreloader } from 'store/actions/viewManagement';
import { getAuthorizedUser } from 'store/actions/auth';

const init = () => ({
  type: AUTH.LOGIN_INIT,
});

export const success = userToVerify => ({
  type: AUTH.LOGIN_SUCCESS,
  payload: {
    userToVerify,
  },
});

const failure = () => ({
  type: AUTH.LOGIN_FAILURE,
});

const login = loginData => async dispatch => {
  dispatch(init());
  try {
    dispatch(showHomePagePreloader());
    const { data } = await Connector.login(loginData);
    dispatch(success({ ...loginData, ...data.data, is_2FA_code: data.is_2FA_code }));
    if (data.is_2FA_code) {
      history.push(ROUTES.CODE_VERIFICATION);
    } else {
      await dispatch(getAuthorizedUser());
      // history.push(ROUTES.START);
      let redirection = localStorage.getItem('redirection');
      localStorage.removeItem('redirection');
      redirection && history.push(redirection);
      redirection = undefined;
    }
    dispatch(hideHomePagePreloader());
    return true;
  } catch ({ response: { status } }) {
    dispatch(failure());
    dispatch(hideHomePagePreloader());
    return false;
  }
};

export default login;
