import React, { useState, useEffect } from 'react';
import 'assets/sass/screens/reports/reports.scss';
import PropTypes from 'prop-types';
import { USER_ROLES, EMPLOYEE_SHORTCUTS, MODAL_TYPES } from 'shared/consts';
import { Badge, SaveModal, LoadModal, DeleteModal } from 'shared/components';
import { FilterModal, RangeModal, SortModal, GroupModal, ActionsTile, Footer } from './components';
import { columns, handleSortingContent, handleFilterByRange } from './utils';
import DataManagmentTable from './components/dataManagmentTable/DataManagmentTable';

const Reports = ({
  getReportsData,
  getSettlementsOptions,
  getUsers,
  getInvestorsOptions,
  getPartnersOptions,
  partnersOptions,
  investorsOptions,
  reportsData,
  settlementsOptions,
  users,
  updateFilters,
  filters,
  saveReportsFilters,
  getReportsFiltersList,
  reportsFiltersList,
  uploadReportsSettings,
  exportReports,
  // excel,
  deleteReportSave,
  status,
  getCompaniesLabels,
  companiesLabels,
}) => {
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const handleOptionChange = (values, id) => {
    const isHideColumnOption = values.value === 'chocolate';
    isHideColumnOption && setHiddenColumns([...hiddenColumns, id]);
  };
  const columns2 = [console.log()];

  const fetchMethods = {
    getSettlementsOptions,
    getInvestorsOptions,
    getPartnersOptions,
    getCompaniesLabels,
    getUsers,
  };

  const [secondaryFieldOptions, setSecondaryFieldOptions] = useState({ primary: false, secondary: false });
  const [isAdditionalFields, setIsAdditionalFields] = useState(false);
  const [isOpened, isSetOpened] = useState(false);
  const [isRangeModalOpen, isSetRangeModalOpen] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isSortModalOpen, isSetSortModalOpen] = useState(false);
  const [isGroupModalOpen, isSetGroupModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(null);
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(null);
  const [settingDetails, setSettingDetails] = useState({ uuid: '', label: '' });
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [groupChoice, setGroupChoice] = useState(null);
  const [newReportsData, setNewReportsData] = useState({ data: reportsData, group: 'old' });
  const [loadedDocument, setLoadedDocument] = useState('Brak obecnie wczytanego');
  const [reportsDataLength, setReportsDataLength] = useState('');

  useEffect(() => {
    const DDI_USER_OPTIONS = users.map(person => ({
      label: (
        <div className="select-field__option">
          <Badge label={EMPLOYEE_SHORTCUTS.DDI} type={EMPLOYEE_SHORTCUTS.DDI} />
          {person.first_name} {person.last_name}
        </div>
      ),
      value: person.uuid,
      searchableLabel: `${person.first_name}' '${person.last_name}`,
    }));
    !isAdditionalFields
      ? setSecondaryFieldOptions({ primary: DDI_USER_OPTIONS, secondary: secondaryFieldOptions.secondary })
      : setSecondaryFieldOptions({ secondary: DDI_USER_OPTIONS, primary: secondaryFieldOptions.primary });
  }, [users]);

  useEffect(() => {
    const companiesOptions = companiesLabels.map(company => ({
      label: <div className="select-field__option">{company.company_name}</div>,
      value: company.uuid,
      searchableLabel: company.company_name,
    }));
    !isAdditionalFields
      ? setSecondaryFieldOptions({ primary: companiesOptions, secondary: secondaryFieldOptions.secondary })
      : setSecondaryFieldOptions({ secondary: companiesOptions, primary: secondaryFieldOptions.primary });
  }, [companiesLabels]);

  useEffect(() => {
    if (settlementsOptions.data) {
      const settlementLabel = settlementsOptions.data.map(settlement => ({
        label: <div className="select-field__option">{settlement.label}</div>,
        value: settlement.value,
        searchableLabel: settlement.label,
      }));
      !isAdditionalFields
        ? setSecondaryFieldOptions({ primary: settlementLabel, secondary: secondaryFieldOptions.secondary })
        : setSecondaryFieldOptions({ secondary: settlementLabel, primary: secondaryFieldOptions.primary });
    }
  }, [settlementsOptions.data]);

  useEffect(() => {
    const investorLabel = investorsOptions.map(investor => ({
      label: (
        <div className="select-field__option">
          <Badge label="Inwst" type="INWST" />
          {investor.label}
        </div>
      ),
      value: investor.value,
      searchableLabel: investor.label,
    }));
    !isAdditionalFields
      ? setSecondaryFieldOptions({ primary: investorLabel, secondary: secondaryFieldOptions.secondary })
      : setSecondaryFieldOptions({ secondary: investorLabel, primary: secondaryFieldOptions.primary });
  }, [investorsOptions]);

  useEffect(() => {
    const partnersLabel = partnersOptions.map(partners => ({
      label: (
        <div className="select-field__option">
          <Badge label="Partner" type="Partner" />
          {partners.label}
        </div>
      ),
      value: partners.value,
      searchableLabel: partners.label,
    }));
    !isAdditionalFields
      ? setSecondaryFieldOptions({ primary: partnersLabel, secondary: secondaryFieldOptions.secondary })
      : setSecondaryFieldOptions({ secondary: partnersLabel, primary: secondaryFieldOptions.primary });
  }, [partnersOptions]);

  useEffect(() => {
    getReportsData();
    setDateStart('10.10.2020');
    setDateEnd('10.11.2020');
    getReportsFiltersList();
  }, []);

  useEffect(() => {
    setNewReportsData({ data: reportsData, group: 'old' });
  }, [reportsData]);

  useEffect(() => {
    setReportsDataLength('414');
  }, [newReportsData]);

  useEffect(() => {
    reportsData?.hidden_columns && setHiddenColumns(reportsData?.hidden_columns);
  }, [reportsData]);

  useEffect(() => {
    if (groupChoice === USER_ROLES.DDI) {
      const ddiList = users.map(({ uuid, first_name, last_name }) => ({ uuid, last_name, first_name }));
      const ddiListWithReports = ddiList.map(ddi => ({
        ...ddi,
        items: reportsData.filter(report => report?.ddi?.uuid === ddi.uuid),
      }));
      setNewReportsData({ data: ddiListWithReports, group: 'new' });
    }
  }, [users]);

  // useEffect(() => {
  //   console.log(filters);
  // }, [filters]);

  const handleOpening = () => isSetOpened(prevValue => !prevValue);
  const handleRangeModalOpening = () => isSetRangeModalOpen(dupa => !dupa);
  const handleSortModalOpening = () => isSetSortModalOpen(prevValue => !prevValue);
  const handleGroupModalOpening = () => isSetGroupModalOpen(prevValue => !prevValue);
  const handleSaveModalClose = () => setIsSaveModalOpen(null);
  const handleLoadModalClose = () => setIsLoadModalOpen(null);
  const handleDeleteModalClose = () => setIsDeleteModalOpened(null);

  const handleFiltering = async values => {
    if (values) {
      const { fetchMethod, isFieldPrimary, value } = values;
      isFieldPrimary ? setIsAdditionalFields(false) : setIsAdditionalFields(true);
      fetchMethod && (value === 'ddi' ? await fetchMethod(USER_ROLES.DDI) : await fetchMethod());
    }
  };

  const deleteSettingOpened = (uuid, label) => {
    setIsDeleteModalOpened(MODAL_TYPES.DELETE);
    setSettingDetails({ uuid, label });
  };

  const handleDeleteSetting = () => {
    deleteReportSave(settingDetails.uuid);
    setIsDeleteModalOpened(false);
  };

  const getReportsExcel = () => {
    exportReports(reportsData, hiddenColumns);
  };

  const handleFilteringContent = values => {
    isSetOpened(false);
    updateFilters(values);
  };

  // useEffect(() => {
  //   console.log(excel);
  // }, [excel]);

  const handleGroupContent = async ({ group }) => {
    setGroupChoice(group);
    if (group === USER_ROLES.DDI) {
      await getUsers(USER_ROLES.DDI);
    }
  };

  const handleClearFilters = () => {
    updateFilters(false);
    getReportsData();
  };

  const handleSaveDocmument = value => {
    saveReportsFilters(value, filters, hiddenColumns);
    setIsSaveModalOpen(false);
  };

  const handleLoadDocument = (value, name) => {
    uploadReportsSettings(value);
    setIsLoadModalOpen(false);
    setLoadedDocument(name);
  };

  const handelHideSold = () => {
    updateFilters({ hide_sold: true });
  };

  const handleHidePaid = () => {
    updateFilters({ hide_paid: true });
  };

  const hideZeroFirstTranche = () => {
    updateFilters({ show_zero_first_tranche: true });
  };

  const fixPrice = data =>
    data &&
    String(data % 1 !== 0 ? data.toFixed(2) : data)
      .replace(/(?=(?:.{3})*$)/g, ' ')
      .replace(/\s+\./g, '.')
      .trim();
  const fixedPriceData = [
    newReportsData?.data?.reports?.map(item => ({
      ...item,
      brutto: fixPrice(item.brutto),
      netto: fixPrice(item.netto),
      brutto_price_with_discount: fixPrice(item.brutto_price_with_discount),
      brutto_still_pay: fixPrice(item.brutto_still_pay),
      deposit: fixPrice(item.deposit),
      deposit_payed: fixPrice(item.deposit_payed),
      payed_deposit: fixPrice(item.payed_deposit),
      payed_price: fixPrice(item.payed_price),
      netto_still_pay: fixPrice(item.netto_still_pay),
      gave_discount: fixPrice(item.gave_discount),
      second_tranche: fixPrice(item.second_tranche),
    })),
  ];
  // console.log(fixedPriceData[0]);
  return (
    <>
      <FilterModal
        isOpened={isOpened}
        onClose={handleOpening}
        onChange={handleFiltering}
        onSubmit={values => handleFilteringContent(values, updateFilters, isSetSortModalOpen)}
        secondaryFieldOptions={secondaryFieldOptions}
        filters={filters}
        fetchMethods={fetchMethods}
      />
      <RangeModal
        isOpened={isRangeModalOpen}
        onClose={handleRangeModalOpening}
        onSubmit={handleFilterByRange}
        filters={filters}
        isSetRangeModalOpen={isSetRangeModalOpen}
        updateFilters={updateFilters}
      />
      <SortModal
        isOpened={isSortModalOpen}
        onClose={handleSortModalOpening}
        onSubmit={handleSortingContent}
        filters={filters}
        updateFilters={updateFilters}
        isSetSortModalOpen={isSetSortModalOpen}
      />
      <GroupModal isOpened={isGroupModalOpen} onClose={handleGroupModalOpening} onSubmit={handleGroupContent} />
      <ActionsTile
        handleRangeModalOpening={handleRangeModalOpening}
        dateStart={dateStart}
        dateEnd={dateEnd}
        handleOpening={handleOpening}
        handleSortModalOpening={handleSortModalOpening}
        handleGroupModalOpening={handleGroupModalOpening}
        setIsSaveModalOpen={setIsSaveModalOpen}
        setIsLoadModalOpen={setIsLoadModalOpen}
        deleteSettingOpened={deleteSettingOpened}
        handleClearFilters={handleClearFilters}
        filters={filters}
        loadedDocument={loadedDocument}
        reportsDataLength={reportsDataLength}
        handelHideSold={handelHideSold}
        handleHidePaid={handleHidePaid}
        hideZeroFirstTranche={hideZeroFirstTranche}
        getReportsExcel={getReportsExcel}
      />
      <SaveModal
        title="Zapisz dokument"
        onClose={handleSaveModalClose}
        onSubmit={handleSaveDocmument}
        opened={isSaveModalOpen === MODAL_TYPES.SAVE}
      />
      <LoadModal
        title="Zapisane dokumenty"
        onClose={handleLoadModalClose}
        onSubmit={handleLoadDocument}
        opened={isLoadModalOpen === MODAL_TYPES.LOAD}
        reportsFiltersList={reportsFiltersList}
        deleteSettingOpened={deleteSettingOpened}
      />
      <DeleteModal
        title="Czy chcesz usunąć ten zapis ?"
        opened={isDeleteModalOpened === MODAL_TYPES.DELETE}
        onClose={handleDeleteModalClose}
        onSubmit={handleDeleteSetting}
        label={settingDetails.label}
      />
      {/* {console.log(newReportsData?.data)} */}
      {newReportsData?.data?.reports?.length > 0 && (
        <main className="dataManagmentTableWrapper">
          <div className="reports__table--wrapper">
            <DataManagmentTable
              data={fixedPriceData[0]}
              columns={newReportsData.group === 'old' ? columns(handleOptionChange) : columns2}
              hiddenColumns={hiddenColumns}
            />
          </div>
        </main>
      )}
      {newReportsData?.data?.reports?.length === 0 && status !== 'INIT' && (
        <div className="empty">Brak danych spełniających kryteria</div>
      )}
      {status !== 'INIT' && <Footer tranche={newReportsData.data} />}
    </>
  );
};

export default Reports;

Reports.propTypes = {
  columns: PropTypes.array.isRequired,
  companiesLabels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  deleteReportSave: PropTypes.func.isRequired,
  excel: PropTypes.array.isRequired,
  exportReports: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getCompaniesLabels: PropTypes.func.isRequired,
  getInvestorsOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  getReportsData: PropTypes.func.isRequired,
  getReportsFiltersList: PropTypes.func.isRequired,
  getSettlementsOptions: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  investorsOptions: PropTypes.array.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  reportsData: PropTypes.array.isRequired,
  reportsFiltersList: PropTypes.object.isRequired,
  saveReportsFilters: PropTypes.func.isRequired,
  settlementsOptions: PropTypes.func.isRequired,
  sortedReportsData: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
  uploadReportsSettings: PropTypes.func.isRequired,
  users: PropTypes.func.isRequired,
  usersDdi: PropTypes.array.isRequired,
};
