import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const PlotInfo = ({ plot }) => (
  <div className="reservationModal__header--bottom appointmentNote__plotInfo">
    <div className="reservationModal__plot-number">
      <p className="reservationModal--dot" />
      {plot?.name}
    </div>
    <div className="media__list">
      <span className={`media__list--item ${plot?.water ? '' : 'disabled'}`}>
        <SVG type={SVG_TYPE.MEDIA_WATER} />
      </span>
      <span className={`media__list--item ${plot?.electrocity ? '' : 'disabled'}`}>
        <SVG type={SVG_TYPE.MEDIA_POWER} />
      </span>
      <span className={`media__list--item ${plot?.sewers ? '' : 'disabled'}`}>
        <SVG type={SVG_TYPE.MEDIA_HEAT} />
      </span>
    </div>
    <p className="reservationModal__yardage">
      <p className="reservationModal__yardage--dot" /> {plot?.plot_size} m2
    </p>
  </div>
);

PlotInfo.propTypes = {
  plot: PropTypes.array.isRequired,
};

export default PlotInfo;
