import { array } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const SettlementName = ({ plot }) => (
  <div className="plotName">
    <div onClick={() => window.history.back()} className="plotName__icon--back">
      <SVG type={SVG_TYPE.BACK} />
    </div>
    <div className="plotName__name">{plot?.name}</div>
    <div className="plotName__subName">{plot?.settlement.name} </div>
    <div className="plotName__icon--map">
      <div className="svg-square-wrapper">
        <SVG type={SVG_TYPE.BLUE_HOME} />
      </div>
    </div>
  </div>
);

SettlementName.propTypes = {
  plot: array.isRequired,
};

export default SettlementName;
