import React from 'react';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { SVG, Badge } from 'shared/components';
import { array, bool, object } from 'prop-types';
import { generatePath } from 'react-router';

const StatusesMobile = ({ arrow, list, authorizedUser: { role }, history }) => (
  <>
    {list?.map(listItem => (
      <li className="employees-right__select statuses__mobileContent--listItem">
        <div className="list__content--investor">
          {role === 'admin' ? (
            <>
              <Badge type="INWST" label="Inwst" />
              <div
                className="list__content--name"
                onClick={() => history.push(generatePath(ROUTES.INVESTORS_ID, { uuid: listItem?.investor?.uuid }))}
              >
                {listItem?.investor?.last_name}
              </div>
            </>
          ) : (
            <>
              <Badge type="DDI" label="DDI" />
              <div
                className="list__content--name"
                onClick={() => history.push(generatePath(ROUTES.EMPLOYEES_DDI_UUID, { uuid: listItem?.ddi.uuid }))}
              >
                {listItem?.ddi.last_name}
              </div>
            </>
          )}
        </div>
        <div>
          {arrow ? (
            <SVG type={SVG_TYPE.ARROW_GRAY} className="arrow" />
          ) : (
            // <span className="statuses__mobileContent--listItem-days">{listItem?.days} dni</span>
            ''
          )}
          <span
            className="notificationsRight__plot"
            onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid: listItem?.plot.uuid }))}
          >
            <SVG type={SVG_TYPE.BLUE_HOME} className="blue_home" />
            {listItem?.plot.name}
          </span>
        </div>
      </li>
    ))}
  </>
);

export default StatusesMobile;

StatusesMobile.propTypes = {
  arrow: bool.isRequired,
  authorizedUser: object.isRequired,
  list: array.isRequired,
};
