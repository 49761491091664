import React from 'react';
import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import { history } from 'store/store';
import getPartners from './getPartners';

const init = () => ({
  type: PARTNER.DELETE_PARTNER_INIT,
});

export const success = () => ({
  type: PARTNER.DELETE_PARTNER_SUCCESS,
});

const failure = () => ({
  type: PARTNER.DELETE_PARTNER_FAILURE,
});

const deletePartner = partnerData => async dispatch => {
  dispatch(init());
  try {
    await Connector.deletePartner(partnerData);
    await dispatch(getPartners());
    const url = window.location.pathname.split('/');
    url.pop();
    history.push(url.join('/'));
    toast(<NewUser badgeType="Partner" first_name={partnerData.name} label="Usunięto partnera" />);
    dispatch(success());
  } catch (err) {
    dispatch(failure());
  }
};

export default deletePartner;
