import { arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';
import { Table } from 'shared/components';
import { useHistory } from 'react-router-dom';
import { getColumns, sortByStatus } from './utils';

const InvestorPlot = ({ plots, isMobile }) => {
  const history = useHistory();
  console.log(plots);
  const { free = [], reservation = [], pending_cashouts = [], signed_contract = [], bought = [] } = React.useMemo(
    () => sortByStatus(plots),
    [plots],
  );

  return (
    <div className="plotList">
      <TableHeader title="Wolne" count={free.length} color="green" />
      <Table isMobile={isMobile} className="table--sm" data={free} columns={getColumns(history)} />
      <TableHeader title="Rezerwacja" count={reservation.length} color="orange" />
      <Table isMobile={isMobile} className="table--sm" data={reservation} columns={getColumns(history)} />
      <TableHeader title="Oczekujące na wpłatę" count={pending_cashouts.length} color="orange" />
      <Table isMobile={isMobile} className="table--sm" data={pending_cashouts} columns={getColumns(history)} />
      <TableHeader title="Rezerwacja / Umowa podpisana" count={signed_contract.length} color="orange" />
      <Table isMobile={isMobile} className="table--sm" data={signed_contract} columns={getColumns(history)} />
      <TableHeader title="Sprzedano" count={bought.length} color="orange" />
      <Table isMobile={isMobile} className="table--sm" data={bought} columns={getColumns(history)} />
    </div>
  );
};

const TableHeader = ({ title, count, color }) => (
  <div className={`table-header table-header--${color}`}>
    <span className="table-header__count">{count}</span>
    <span className="table-header__title">{title}</span>
  </div>
);

TableHeader.propTypes = {
  color: string.isRequired,
  count: string.isRequired,
  title: string.isRequired,
};

InvestorPlot.propTypes = {
  isMobile: bool.isRequired,
  plots: arrayOf(shape({})).isRequired,
};

export default InvestorPlot;
