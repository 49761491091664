import React from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from 'shared/consts';
import Trigger from '../triggers/Trigger';

const ActionsTile = ({
  handleRangeModalOpening,
  dateStart,
  dateEnd,
  handleOpening,
  handleSortModalOpening,
  // handleGroupModalOpening,
  setIsSaveModalOpen,
  setIsLoadModalOpen,
  handleClearFilters,
  filters,
  loadedDocument,
  reportsDataLength,
  handelHideSold,
  getReportsExcel,
  handleHidePaid,
  hideZeroFirstTranche,
}) => (
  <nav className="wrapper">
    <section className="wrapper__left">
      <Trigger
        svgName="LOAD"
        type="button"
        className="button wrapper__left--trigger"
        content="Wczytaj"
        onClick={() => {
          setIsLoadModalOpen(MODAL_TYPES.LOAD);
        }}
      />
      <Trigger
        svgName="SAVE"
        type="button"
        className="button wrapper__left--trigger"
        content="Zapisz"
        onClick={() => setIsSaveModalOpen(MODAL_TYPES.SAVE)}
      />
      <section className="wrapper__left--info">
        <div className="wrapper__left--info--whichDoc">{loadedDocument}</div>
        <div className="wrapper__left--info--entries">{reportsDataLength} wpisów</div>
        <div className="wrapper__left--info--hiddenColumns">4 Ukryte kolumny</div>
      </section>
    </section>

    <section className="wrapper__right">
      <div className="wrapper__right--singleTile">
        <Trigger
          svgName="CLEAR"
          className="wrapper__right--box--trigger"
          content="Ukryj opłacone"
          svgWrapperClass="vertical"
          onClick={handleHidePaid}
        />
        <Trigger
          svgName="ZERO"
          className="wrapper__right--box--trigger"
          content="Pokaż 0 w pierwszej transzy"
          svgWrapperClass="vertical"
          onClick={hideZeroFirstTranche}
          extend="extend"
        />
      </div>
      <section className="wrapper__right--box">
        <Trigger
          svgName="SOLD"
          className="wrapper__right--box--trigger"
          content="Ukryj sprzedane"
          svgWrapperClass="vertical"
          onClick={handelHideSold}
        />
        <Trigger
          svgName="SMALL_FILTER"
          className="wrapper__right--box--trigger"
          content="Wyczyść filtry"
          svgWrapperClass="vertical"
          onClick={handleClearFilters}
        />
      </section>
      <Trigger
        onClick={handleRangeModalOpening}
        svgWrapperClass="noSvg"
        id="range"
        className="button wrapper__right--trigger"
        content="Zakres"
        dateStart={dateStart}
        dateEnd={dateEnd}
        filters={filters}
      />
      <Trigger
        svgName="FILTERING"
        id="filter"
        className="button wrapper__right--trigger"
        content="Filtruj"
        onClick={handleOpening}
        filters={filters}
      />
      <Trigger
        svgName="SORT"
        id="sort"
        className="button wrapper__right--trigger"
        content="Sortuj"
        onClick={handleSortModalOpening}
        filters={filters}
      />
      {/* <Trigger
        svgName="GROUP"
        id="group"
        className="button wrapper__right--trigger"
        content="Grupuj"
        onClick={handleGroupModalOpening}
      /> */}
      <Trigger
        svgName="GENERATE"
        id="green"
        className="button wrapper__right--trigger"
        content="Generuj"
        onClick={getReportsExcel}
      />
    </section>
  </nav>
);

ActionsTile.propTypes = {
  dateEnd: PropTypes.string.isRequired,
  dateStart: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  getReportsExcel: PropTypes.func.isRequired,
  handelHideSold: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  handleGroupModalOpening: PropTypes.func.isRequired,
  handleHidePaid: PropTypes.func.isRequired,
  handleOpening: PropTypes.func.isRequired,
  handleRangeModalOpening: PropTypes.func.isRequired,
  handleSortModalOpening: PropTypes.func.isRequired,
  hideZeroFirstTranche: PropTypes.func.isRequired,
  loadedDocument: PropTypes.string.isRequired,
  reportsDataLength: PropTypes.number.isRequired,
  setIsLoadModalOpen: PropTypes.func.isRequired,
  setIsSaveModalOpen: PropTypes.func.isRequired,
};

export default ActionsTile;
