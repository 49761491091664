import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';

const init = () => ({
  type: PARTNER.GET_BONUSES_OPTIONS_INIT,
});

export const success = data => ({
  type: PARTNER.GET_BONUSES_OPTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PARTNER.GET_BONUSES_OPTIONS_FAILURE,
});

const getBonusesOptions = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getBonusesOptions();
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getBonusesOptions;
