import React from 'react';
import Slider from 'react-slick';
import { array, object } from 'yup';
import { Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { func } from 'prop-types';
import SingleNews from '../single-news';

const settings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  infinite: false,
  variableWidth: true,
  swipeToSlide: true,
};

function News({ news, handleOpenCreateModal, handleOpenEditModal, deleteNews, authorizedUser: { role, position } }) {
  return (
    <div className="news">
      <div className="news__title">
        Aktualności
        {role === 'admin' && position === 'director' ? (
          <Button
            classNames="transparent no-padding right-icon addNews"
            iconType={SVG_TYPE.ADD}
            label="Dodaj"
            onClick={handleOpenCreateModal}
          />
        ) : (
          ''
        )}
      </div>
      <Slider className="news__items-wrapper" {...settings}>
        {news.map(singleNews => (
          <SingleNews
            key={singleNews.title}
            news={singleNews}
            deleteNews={deleteNews}
            handleOpenModal={handleOpenEditModal}
            role={role}
            position={position}
          />
        ))}
      </Slider>
    </div>
  );
}

export default News;

News.propTypes = {
  authorizedUser: object.isRequired,
  deleteNews: func.isRequired,
  handleOpenCreateModal: func.isRequired,
  handleOpenEditModal: func.isRequired,
  handleOpenModal: func.isRequired,
  news: array.isRequired,
};
