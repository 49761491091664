import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPartners,
  editPartner,
  deletePartner,
  getAttachedDdis,
  attachDdiToPartner,
  getArchivedPartner,
  restorePartner,
  getAttachedAdvisers,
} from 'store/actions/partner';
import { getDdiOptions } from 'store/actions/user';
import {
  getPartners as getPartnersSelector,
  getBonusesOptions,
  getAttachedDdis as getAttachedDdisSelector,
  getAttachedDdisStatus as getAttachedDdisStatusSelector,
  getArchivedPartner as getArchivedPartnerSelector,
  getAttachedAdvisers as getAttachedAdvisersSelector,
} from 'store/selectors/partnerSelector';
import { getDdiOptions as getDdiOptionsSelector } from 'store/selectors/userSelector';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import PartnersRight from './PartnersRight.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getArchivedPartner: uuid => getArchivedPartner(uuid),
      getPartners: () => getPartners(),
      editPartner: (data, file) => editPartner(data, file),
      deletePartner: data => deletePartner(data),
      getAttachedDdis: partnerUuid => getAttachedDdis(partnerUuid),
      getDdiOptions: withPartnersOnly => getDdiOptions(withPartnersOnly),
      attachDdiToPartner: (partnerData, ddiList) => attachDdiToPartner(partnerData, ddiList),
      restorePartner: uuid => restorePartner(uuid),
      getAttachedAdvisers: uuid => getAttachedAdvisers(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  archivedPartner: getArchivedPartnerSelector(state),
  partners: getPartnersSelector(state),
  bonusesOptions: getBonusesOptions(state),
  attachedDdis: getAttachedDdisSelector(state),
  attachedDdisStatus: getAttachedDdisStatusSelector(state),
  ddiOptions: getDdiOptionsSelector(state),
  authorizedUserRole: getAuthorizedUserRole(state),
  attachedAdvisers: getAttachedAdvisersSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersRight);
