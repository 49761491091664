import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'shared/consts';
import { Badge } from 'shared/components';
import Right from 'shared/components/list/components/right';
import { withRouter, generatePath } from 'react-router-dom';

const InvestorsList = ({ partners, history, area, isMobile, closeSearchView }) => {
  const handleClick = item => {
    history.push(generatePath(ROUTES.INVESTORS_ID, { uuid: item?.uuid }));
    closeSearchView();
  };

  return (
    <div className="usersContainer">
      {' '}
      <div className={`list ${isMobile ? 'list--mobile' : ''}`}>
        {partners?.map(item => (
          <div key={item.uuid} className="list__item-wrapper">
            <div className="list__item" onClick={() => handleClick(item)}>
              {/* {console.log('id aktywnej strony', activeRow)}
            {console.log('uuid itemu', item.uuid)} */}
              <div className="list__left-wrapper">
                <Badge label="Inwst" type="INWST" /> {item.firs_name} {item.last_name}
              </div>
              <Right area={area} item={item} isMobile={isMobile} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(InvestorsList);

InvestorsList.propTypes = {
  area: PropTypes.string.isRequired,
  closeSearchView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  partners: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  shortcut: PropTypes.string.isRequired,
};
