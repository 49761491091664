import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PROTECTED_ROUTES, ROUTES } from 'shared/consts';
import { matchUrlToRoute } from 'shared/utils';
import PropTypes from 'prop-types';

const RoleRoute = ({ component: Component, exact, authorizedUserRole, location: { pathname }, ...rest }) => {
  const protectedRouteRoles = PROTECTED_ROUTES.get(pathname) || PROTECTED_ROUTES.get(matchUrlToRoute(pathname));
  const isAccessGranted = protectedRouteRoles.includes(authorizedUserRole);
  return (
    <Route
      {...rest}
      exact={exact}
      render={routeProps =>
        isAccessGranted ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.START,
            }}
          />
        )
      }
    />
  );
};

RoleRoute.defaultProps = {
  exact: false,
  location: {},
};

RoleRoute.propTypes = {
  authorizedUserRole: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
  location: PropTypes.object,
  path: PropTypes.string.isRequired,
};

export default RoleRoute;
