import React from 'react';
import Modal from 'shared/components/modal/Modal';
import { MODAL_TYPES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { SelectField, ModalActions } from 'shared/components';
import './sort-modal.scss';
import PropTypes from 'prop-types';
// import RadioField from './radioField/RadioField';
import { options } from './utils';

const SortModal = ({ isOpened, onClose, onSubmit, updateFilters, isSetSortModalOpen, filters }) => {
  function validateField(value) {
    let error;
    if (!value) {
      error = 'Wymagane';
    }
    return error;
  }

  return (
    <Modal title="Sortuj" type={MODAL_TYPES.SORT} opened={isOpened} onClose={onClose}>
      <Formik
        initialValues={
          filters && Object.keys(filters).length > 0
            ? {
                isSort: true,
                field: filters.field,
                order: filters.order,
              }
            : {
                isSort: true,
                field: '',
                order: '',
              }
        }
        onSubmit={values => {
          onSubmit(values, updateFilters, isSetSortModalOpen);
        }}
      >
        {({ handleSubmit, errors }) => (
          <form className="form">
            <Field
              options={options}
              component={SelectField}
              fullWidth
              name="field"
              placeholder="Wybierz kolumnę"
              errors={errors}
              validate={validateField}
              isCustomLabel
            />

            {/* 
            <div className="radio__wrapper">
              <Field component={RadioField} errors={errors} name="sort" />
            </div> */}

            <Field
              options={[
                {
                  label: 'Rosnąco',
                  value: 'increase',
                },
                {
                  label: 'Malejąco',
                  value: 'decrease',
                },
              ]}
              component={SelectField}
              fullWidth
              name="order"
              placeholder="Sortuj"
              errors={errors}
              validate={validateField}
            />

            {/* <div className="checkbox__wrapper">
              <Field
                component={TabsToggle}
                name="hide"
                options={[
                  {
                    label: 'Ukryj puste pola',
                    value: 'hide_blanks',
                  },
                ]}
                errors={errors}
              />

              <Field
                component={TabsToggle}
                name="hide"
                options={[
                  {
                    label: 'Ukryj zera',
                    value: 'hide_zeros',
                  },
                ]}
                errors={errors}
              />
            </div> */}
            <ModalActions type={MODAL_TYPES.SORT} onClose={onClose} handleSubmit={handleSubmit} />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default SortModal;

SortModal.propTypes = {
  filters: PropTypes.func.isRequired,
  isOpened: PropTypes.func.isRequired,
  isSetSortModalOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};
