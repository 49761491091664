import React, { useState, useMemo, useEffect } from 'react';
import { shape, string, array, func, bool } from 'prop-types';
import { PARTNER_SUBMENU } from 'shared/components/active-window/consts';
import { getPartnerDetails } from './utils';
import PartnerByUuid from './components/partner-by-uuid/PartnerByUuid';

const PartnersRight = ({
  authorizedUserRole,
  attachedDdisStatus,
  attachedDdis,
  attachedAdvisers,
  partners,
  archived,
  match: {
    params: { uuid },
  },
  bonusesOptions,
  ddiOptions,
  archivedPartner,
  actions: {
    editPartner,
    restorePartner,
    deletePartner,
    getAttachedDdis,
    getDdiOptions,
    attachDdiToPartner,
    getArchivedPartner,
    getAttachedAdvisers,
  },
}) => {
  if (uuid) {
    const [activeMenu, setActiveMenu] = useState(PARTNER_SUBMENU.DATA);
    let partnerInfo;
    if (!archived) {
      partnerInfo = useMemo(() => getPartnerDetails(uuid, partners) ?? {}, [uuid, partners]);
    } else {
      useEffect(async () => {
        await getArchivedPartner(uuid);
      }, [uuid]);
      partnerInfo = archivedPartner;
    }

    return (
      <PartnerByUuid
        archived={archived}
        partner={partnerInfo}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
        bonusesOptions={bonusesOptions}
        editPartner={editPartner}
        deletePartner={deletePartner}
        getAttachedDdis={getAttachedDdis}
        attachedDdis={attachedDdis}
        attachedDdisStatus={attachedDdisStatus}
        getDdiOptions={getDdiOptions}
        ddiOptions={ddiOptions}
        attachDdiToPartner={attachDdiToPartner}
        authorizedUserRole={authorizedUserRole}
        restorePartner={restorePartner}
        getAttachedAdvisers={getAttachedAdvisers}
        attachedAdvisers={attachedAdvisers}
      />
    );
  }
  return null;
};

PartnersRight.defaultProps = {
  archived: false,
  archivedPartner: '',
};

PartnersRight.propTypes = {
  actions: shape({
    editPartner: func.isRequired,
    deletePartner: func.isRequired,
    getAttachedDdis: func.isRequired,
    getDdiOptions: func.isRequired,
    attachDdiToPartner: func.isRequired,
    getAttachedAdvisers: func.isRequired,
  }).isRequired,
  archived: bool,
  archivedPartner: shape({}),
  attachedAdvisers: array.isRequired,
  attachedDdis: array.isRequired,
  attachedDdisStatus: string.isRequired,
  authorizedUserRole: string.isRequired,
  bonusesOptions: array.isRequired,
  ddiOptions: array.isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
  partners: shape({}).isRequired,
  restorePartner: func.isRequired,
};

export default PartnersRight;
