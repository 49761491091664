import React from 'react';
import PropTypes from 'prop-types';

function Status({ active }) {
  return (
    <div className={`list__column ${active ? 'list__column--status-active' : 'list__column--status-inactive'}`}>
      {active ? 'Aktywny' : 'Nieaktywny'}
    </div>
  );
}

Status.propTypes = {
  active: PropTypes.number.isRequired,
};

export default Status;
