import * as React from 'react';
import RTable from 'react-table-v6';
import { arrayOf, shape, string, bool, func, number } from 'prop-types';

const Table = ({
  className = '',
  columns,
  data,
  expanded = {},
  SubComponent,
  filterable,
  activeRowId,
  onActiveUpdate,
  isMobile,
  // handleScroll,
  ...rest
}) => (
  <div>
    <RTable
      className={className}
      columns={columns}
      data={data}
      defaultFilterMethod={({ id, value }, row) => row[id].toLowerCase().indexOf(value.toLowerCase()) > -1}
      expanded={expanded}
      defaultPageSize={isMobile ? 20 : 9999}
      filterable={filterable}
      loadingText="Ładowanie..."
      minRows={1}
      // onScroll={handleScroll}
      nextText="Następna"
      noDataText="Brak danych"
      ofText="z"
      pageText="Strona"
      previousText="Poprzednia"
      rowsText="rzędów"
      showPagination={data?.length > 20 && !!isMobile}
      sortable
      SubComponent={SubComponent}
      TheadComponent={() => null}
      getTrProps={(_, rowInfo) => {
        if (rowInfo && rowInfo.row && onActiveUpdate) {
          return {
            onClick: () => {
              onActiveUpdate?.(rowInfo.index);
            },
            style: {
              background: rowInfo.index === activeRowId ? '#fff' : '',
              borderColor: rowInfo.index === activeRowId ? 'rgba(0,0,0, 0.1)' : '',
            },
          };
        }
        return {};
      }}
      {...rest}
    />
  </div>
);

Table.propTypes = {
  activeRowId: number,
  className: string,
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  expanded: shape({}),
  filterable: bool,
  isMobile: bool,
  onActiveUpdate: func,
  SubComponent: shape({}),
};
Table.defaultProps = {
  activeRowId: null,
  className: '',
  expanded: {},
  filterable: false,
  isMobile: false,
  onActiveUpdate: undefined,
  SubComponent: undefined,
};
export default Table;
