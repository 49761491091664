import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const Header = ({ plot: { water, electrocity, sewers, name, price, plot_size, settlement } }) => (
  <div className="reservationModal__header">
    <div className="reservationModal__header--top">
      <SVG className="reservationModal--icon" type={SVG_TYPE.BLUE_HOME} />
      <p className="reservationModal--number" />
      <p className="reservationModal--plotName">{settlement.name}</p>
    </div>
    <div className="reservationModal__header--bottom">
      <div className="reservationModal__plot-number">
        <p className="reservationModal--dot" />
        {name}
      </div>
      <div className="media__list">
        <span className={`media__list--item ${water ? '' : 'disabled'}`}>
          <SVG type={SVG_TYPE.MEDIA_WATER} />
        </span>
        <span className={`media__list--item ${electrocity ? '' : 'disabled'}`}>
          <SVG type={SVG_TYPE.MEDIA_POWER} />
        </span>
        <span className={`media__list--item ${sewers ? '' : 'disabled'}`}>
          <SVG type={SVG_TYPE.MEDIA_HEAT} />
        </span>
      </div>
      <p className="reservationModal__yardage">
        <p className="reservationModal__yardage--dot" /> {plot_size} m2
      </p>
      <p className="reservationModal--price">{price} zł</p>
    </div>
  </div>
);

Header.propTypes = {
  plot: PropTypes.shape({
    electrocity: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    plot_size: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    settlement: PropTypes.string.isRequired,
    sewers: PropTypes.string.isRequired,
    water: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
