import { AUTH } from 'store/types';

const setRefreshToken = data => ({
  type: AUTH.SET_REFRESH_TOKEN,
  payload: {
    data,
  },
});

export default setRefreshToken;
