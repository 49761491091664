import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import store, { history } from 'store/store';
import MainRoutes from './screens/MainRoutes';

const RootApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={MainRoutes} path="/" />
    </ConnectedRouter>
  </Provider>
);

export default RootApp;
