import React from 'react';
import PropTypes from 'prop-types';
import { Modal, UserBar, InputComparison, ModalActions, FieldFileInput } from 'shared/components';
import { MODAL_TYPES, VALIDATION_MESSAGES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { Formik, Field } from 'formik';
import { string, object } from 'yup';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function ConfirmSaleModal({ onClose, opened, title, openModal, plot, onSubmit }) {
  const isMobileDevice = isMobile();
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const [fileInputValue1, setFileInputValue1] = React.useState(null);
  const [fileInputValue2, setFileInputValue2] = React.useState(null);

  const soldStatusSchema = () =>
    object().shape({
      act: string().required(REQUIRED_FIELD),
      contract: string().required(REQUIRED_FIELD),
    });

  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.CONFIRM_SALE}>
            <Formik
              enableReinitialize
              initialValues={{
                status_uuid: plot?.order_status?.uuid,
                plot_uuid: plot?.uuid,
                order_status: plot?.order_status?.uuid,
                sell_status: 'sold',
              }}
              validationSchema={soldStatusSchema()}
              onSubmit={values => {
                const files = [fileInputValue1, fileInputValue2];
                onSubmit(values, files);
              }}
            >
              {({ handleSubmit, errors }) => {
                const handleFileChange = e => {
                  const { files } = e.target;
                  setFileInputValue1(files[0]);
                  setFileInputValue2(files[0]);
                };
                return (
                  <form className="modal__form ">
                    <div className="signedContract__investor">
                      <UserBar
                        type="INWST"
                        name={`${plot?.order_status?.investor?.first_name} ${plot?.order_status?.investor?.last_name}`}
                        phone={plot?.order_status?.investor?.phone_number}
                      />
                    </div>
                    <div className="signedContract__ddi">
                      <UserBar
                        type="DDI"
                        name={`${plot?.order_status?.ddi?.first_name} ${plot?.order_status?.ddi?.last_name}`}
                        phone={plot?.order_status?.ddi?.phone}
                      />
                      <div className="signedContract__inputComparison">
                        <InputComparison
                          label="Wpłata całości"
                          first={plot?.order_status?.payed_deposit + plot?.order_status?.payed_price}
                          second={plot?.price}
                          onClick={() => openModal(MODAL_TYPES.HISTORY_PAYMENT)}
                        />
                      </div>
                    </div>
                    <span className="signedContract__text">Dołącz wymagane dokumenty</span>
                    <Field
                      component={FieldFileInput}
                      handleChange={handleFileChange}
                      errors={errors}
                      name="act"
                      type="file"
                      label="+ Dodaj akt"
                    />
                    <Field
                      component={FieldFileInput}
                      handleChange={handleFileChange}
                      errors={errors}
                      name="contract"
                      type="file"
                      label="+ Dodaj umowę"
                    />

                    <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.CONFIRM_SALE} />
                  </form>
                );
              }}
            </Formik>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

ConfirmSaleModal.propTypes = {
  authorizedUser: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  plot: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmSaleModal;
