import React from 'react';
import { string, func, array, bool } from 'prop-types';
import { SingleOfferModal, ActiveWindow } from 'shared/components';
import { SINGLE_PLOT_OFFER_ARR, PLOT_OFFER_SUBMENU } from 'shared/components/active-window/consts';
import { MODAL_TYPES } from 'shared/consts';
import AcceptanceOfferModal from './AcceptanceOfferModal';
import AddOffer from './AddOffer';
import OfferBar from './OfferBar';

const Offers = ({ user, offerChangeStatus, waitingOffer, plotUuid, openAddOffer, acceptedOffers, isUserBooking }) => {
  const [modalOpened, setModalOpened] = React.useState(null);
  const [offerData, setOfferData] = React.useState(null);
  const [activeSubMenu, setActiveSubMenu] = React.useState(PLOT_OFFER_SUBMENU.WAITING_OFFER);
  const { RESERVATION, SINGLE_OFFER } = MODAL_TYPES;
  const isAcceptModalOpened = modalOpened === RESERVATION;
  const isSingleOfferOpened = modalOpened === SINGLE_OFFER;

  const openModal = (type, item) => {
    setModalOpened(type);
    setOfferData(item);
  };

  const ifAcceptOffers = activeSubMenu === PLOT_OFFER_SUBMENU.ACCPET_OFFER;
  let offers;
  ifAcceptOffers ? (offers = acceptedOffers) : (offers = waitingOffer);

  return (
    <>
      <AddOffer user={user} openAddOffer={openAddOffer} />
      <ActiveWindow.SubMenu menu={SINGLE_PLOT_OFFER_ARR} activeMenu={activeSubMenu} setActiveMenu={setActiveSubMenu} />
      <AcceptanceOfferModal
        title="Potwierdź ofertę dedykowaną"
        opened={isAcceptModalOpened}
        onClose={() => setModalOpened(null)}
        offerData={offerData}
        offerChangeStatus={offerChangeStatus}
        plotUuid={plotUuid}
        userRole={user.role}
      />
      <SingleOfferModal
        offerChangeStatus={offerChangeStatus}
        title="Podgląd oferty"
        opened={isSingleOfferOpened}
        onClose={() => setModalOpened(null)}
        offerData={offerData}
        plotUuid={plotUuid}
        userRole={user.role}
        isUserBooking={isUserBooking}
      />
      <div className="offerBar__wrapper">
        {offers.data?.map(item => (
          <OfferBar
            item={item}
            userRole={user.role}
            plotUuid={plotUuid}
            openModal={openModal}
            offerChangeStatus={offerChangeStatus}
            ifAcceptOffers={ifAcceptOffers}
          />
        ))}
      </div>
    </>
  );
};

Offers.propTypes = {
  acceptedOffers: array.isRequired,
  isUserBooking: bool.isRequired,
  offerChangeStatus: func.isRequired,
  openAddOffer: func.isRequired,
  plotUuid: string.isRequired,
  user: string.isRequired,
  waitingOffer: array.isRequired,
};

export default Offers;
