import { Connector } from 'modules/authModule';
import { AUTH } from 'store/types';
import { history } from 'store/store';
import { ROUTES } from 'shared/consts';
import { showPreloader, hidePreloader } from 'store/actions/viewManagement';

// const init = () => ({
//   type: AUTH.LOGOUT_INIT,
// });

export const success = () => ({
  type: AUTH.LOGOUT_SUCCESS,
});

// const failure = () => ({
//   type: AUTH.LOGIN_FAILURE,
// });

const logout = () => async dispatch => {
  try {
    dispatch(showPreloader());
    await Connector.logout();
    history.push(ROUTES.LOGIN);
    dispatch(success());
    dispatch(hidePreloader());
  } catch (err) {
    dispatch(hidePreloader());
  }
};

export default logout;
