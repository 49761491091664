import React from 'react';
import { SVG, Badge } from 'shared/components';
import { SVG_TYPE, ROUTES, MODAL_TYPES, USER_ROLES } from 'shared/consts';
import { array, bool, string, object, func } from 'prop-types';
import { generatePath } from 'react-router';

const OffersList = ({
  title,
  counterClassName,
  list,
  upload,
  history,
  acceptModalOpen,
  offerChangeStatus,
  authorizedUser,
  count,
  deleteAcceptedOffer,
}) => (
  <>
    <div className="list__header">
      <div className="list__header--item">
        <div className={`list__header--counter ${counterClassName}`}>{count}</div>
        <div className="list__header--title">{title}</div>
      </div>
    </div>
    <div className="list__content">
      <ul>
        {list?.map(listItem => (
          <li className="offerListItem">
            <div className="offerListItem__wrapper-left" onClick={() => acceptModalOpen(MODAL_TYPES.SINGLE_OFFER, listItem)}>
              <span className="list__content--button">
                {Math.round(listItem?.price)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
                zł
              </span>
              <span className="list__content--item customColor">{listItem?.percentage_reduction}%</span>
              {authorizedUser.role === USER_ROLES.ADMIN ? (
                <>
                  <Badge type="DDI" label="DDI" />
                  <div className="list__content--name">{listItem?.ddi?.last_name} </div>
                </>
              ) : (
                <>
                  <Badge type="INWST" label="INWST" />
                  <div className="list__content--name">{listItem?.investor?.last_name} </div>
                </>
              )}
            </div>
            <div className="list__content--itemWrapper">
              <span
                className="list__content--arrow"
                onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid: listItem?.plot?.uuid }))}
              >
                <span className="list__content--settlement--name">{listItem?.plot?.settlement.name}</span>
                <SVG type={SVG_TYPE.BLUE_HOME} className="blue_home" />
                <span className="notificationsRight__plot list__content--plot">{listItem?.plot?.name}</span>
              </span>
              {upload ? (
                <>
                  <button
                    type="button"
                    className="list__header--show list__content--button fullWidth"
                    onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid: listItem?.plot?.uuid }))}
                  >
                    Rezerwuj
                  </button>
                  {console.log(listItem?.uuid)}
                  <button
                    type="button"
                    className="list__header--show list__content--button "
                    onClick={() => deleteAcceptedOffer(listItem?.uuid)}
                  >
                    Anuluj
                  </button>
                </>
              ) : (
                <>
                  {authorizedUser.role === 'admin' && authorizedUser.position === 'director' ? (
                    <button
                      type="button"
                      className="list__header--show list__content--button fullWidth"
                      onClick={() => acceptModalOpen(MODAL_TYPES.RESERVATION, listItem)}
                    >
                      Akceptuj
                    </button>
                  ) : (
                    ''
                  )}
                  <button
                    type="button"
                    className="list__header--show list__content--button"
                    onClick={() =>
                      offerChangeStatus({
                        offerUuid: listItem?.uuid,
                        acceptance: 'no',
                        plotUuid: listItem?.plot.uuid,
                        userRole: authorizedUser.role,
                      })
                    }
                  >
                    anuluj
                  </button>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  </>
);

export default OffersList;

OffersList.propTypes = {
  acceptModalOpen: func.isRequired,
  arrow: bool.isRequired,
  authorizedUser: object.isRequired,
  count: array.isRequired,
  counterClassName: string.isRequired,
  deleteAcceptedOffer: func.isRequired,
  history: object.isRequired,
  list: array.isRequired,
  offerChangeStatus: func.isRequired,
  offers: object.isRequired,
  title: string.isRequired,
  upload: bool.isRequired,
};
