const USER = {
  GET_USERS_INIT: 'GET_USERS_INIT',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  GET_USERS_AMOUNT_INIT: 'GET_USERS_AMOUNT_INIT',
  GET_USERS_AMOUNT_SUCCESS: 'GET_USERS_AMOUNT_SUCCESS',
  GET_USERS_AMOUNT_FAILURE: 'GET_USERS_AMOUNT_FAILURE',
  GET_ACTIVE_USER_INIT: 'GET_ACTIVE_USER_INIT',
  GET_ACTIVE_USER_SUCCESS: 'GET_ACTIVE_USER_SUCCESS',
  GET_ACTIVE_USER_FAILURE: 'GET_ACTIVE_USER_FAILURE',
  ADD_USER_INIT: 'ADD_USER_INIT',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',
  EDIT_USER_INIT: 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',
  DELETE_USER_INIT: 'DELETE_USER_INIT',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  ACTIVATE_ACCOUNT_INIT: 'ACTIVATE_ACCOUNT_INIT',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILURE: 'ACTIVATE_ACCOUNT_FAILURE',
  SEND_RESET_PASSWORD_EMAIL_INIT: 'SEND_RESET_PASSWORD_EMAIL_INIT',
  SEND_RESET_PASSWORD_EMAIL_SUCCESS: 'SEND_RESET_PASSWORD_EMAIL_SUCCESS',
  SEND_RESET_PASSWORD_EMAIL_FAILURE: 'SEND_RESET_PASSWORD_EMAIL_FAILURE',
  SET_NEW_PASSWORD_INIT: 'SET_NEW_PASSWORD_INIT',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAILURE: 'SET_NEW_PASSWORD_FAILURE',
  RESEND_ACTIVATION_EMAIL_INIT: 'RESEND_ACTIVATION_EMAIL_INIT',
  RESEND_ACTIVATION_EMAIL_SUCCESS: 'RESEND_ACTIVATION_EMAIL_SUCCESS',
  RESEND_ACTIVATION_EMAIL_FAILURE: 'RESEND_ACTIVATION_EMAIL_FAILURE',
  GET_DDI_OPTIONS_INIT: 'GET_DDI_OPTIONS_INIT',
  GET_DDI_OPTIONS_SUCCESS: 'GET_DDI_OPTIONS_SUCCESS',
  GET_DDI_OPTIONS_FAILURE: 'GET_DDI_OPTIONS_FAILURE',
  GET_ARCHIVED_EMPLOYEES_INIT: 'GET_ARCHIVED_EMPLOYEES_INIT',
  GET_ARCHIVED_EMPLOYEES_SUCCESS: 'GET_ARCHIVED_EMPLOYEES_SUCCESS',
  GET_ARCHIVED_EMPLOYEES_FAILURE: 'GET_ARCHIVED_EMPLOYEES_FAILURE',
  GET_ARCHIVED_EMPLOYEE_INIT: 'GET_ARCHIVED_EMPLOYEE_INIT',
  GET_ARCHIVED_EMPLOYEE_SUCCESS: 'GET_ARCHIVED_EMPLOYEE_SUCCESS',
  GET_ARCHIVED_EMPLOYEE_FAILURE: 'GET_ARCHIVED_EMPLOYEE_FAILURE',
  RESTORE_EMPLOYEE_INIT: 'RESTORE_EMPLOYEE_INIT',
  RESTORE_EMPLOYEE_SUCCESS: 'RESTORE_EMPLOYEE_SUCCESS',
  RESTORE_EMPLOYEE_FAILURE: 'RESTORE_EMPLOYEE_FAILURE',
  GET_ALL_DDI_INIT: 'GET_ALL_DDI_INIT',
  GET_ALL_DDI_SUCCESS: 'GET_ALL_DDI_SUCCESS',
  GET_ALL_DDI_FAILURE: 'GET_ALL_DDI_FAILURE',
  GET_ADVISERS_LIST_INIT: 'GET_ADVISERS_LIST_INIT',
  GET_ADVISERS_LIST_SUCCESS: 'GET_ADVISERS_LIST_SUCCESS',
  GET_ADVISERS_LIST_FAILURE: 'GET_ADVISERS_LIST_FAILURE',
};

export default USER;
