import React from 'react';
import { SVG_TYPE } from 'shared/consts';
import { SVG } from 'shared/components';
import { bool } from 'prop-types';

function ToggleTabs({ interested, competitors, no_contact, lack_of_funds }) {
  return (
    <div className="tabs-toggle">
      <div className="tabs-toggle__label">Informacje</div>
      <div className="tabs-toggle__options">
        <div className="tabs-toggle__option">
          <span>zainteresowany</span>
          {interested && (
            <span className="tabs-toggle__option--check">
              <SVG type={SVG_TYPE.CONFIRM} />
            </span>
          )}
        </div>
        <div className="tabs-toggle__option">
          <span>konkurencja</span>
          {competitors && (
            <span className="tabs-toggle__option--check">
              <SVG type={SVG_TYPE.CONFIRM} />
            </span>
          )}
        </div>
        <div className="tabs-toggle__option">
          <span>brak środków</span>
          {no_contact && (
            <span className="tabs-toggle__option--check">
              <SVG type={SVG_TYPE.CONFIRM} />
            </span>
          )}
        </div>
        <div className="tabs-toggle__option">
          <span>brak kontraktu</span>
          {lack_of_funds && (
            <span className="tabs-toggle__option--check">
              <SVG type={SVG_TYPE.CONFIRM} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

ToggleTabs.propTypes = {
  competitors: bool.isRequired,
  interested: bool.isRequired,
  lack_of_funds: bool.isRequired,
  no_contact: bool.isRequired,
};

export default ToggleTabs;
