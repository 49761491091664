/* eslint-disable react/prop-types */
import * as React from 'react';
import { Field } from 'formik';
import { SelectField, RegularField, FieldFileInput, SVG, OptionsLabel } from 'shared/components';
import { FORM_TYPES, SVG_TYPE } from 'shared/consts';

const BasicInformationForm = ({
  returnRoleOptions,
  handleAdviserSelected,
  errors,
  formType,
  usersDdi,
  loggedUserRole,
  handleFileChange,
  data,
  setFileInputValue,
  setFieldValue,
  fileInputValue,
  isDdi,
  adviserOptions,
}) => {
  const DDI_LIST = OptionsLabel(usersDdi, 'DDI');
  return (
    <>
      {loggedUserRole === 'admin' && (
        <Field
          component={SelectField}
          errors={errors}
          isCustomLabel
          label="Wybierz DDI"
          name="sales_department_uuid"
          options={DDI_LIST}
          placeholder="Wybierz DDI"
        />
      )}
      <div className="form_investor__adviser-field">
        {isDdi && (
          <Field
            component={SelectField}
            errors={errors}
            fullWidth
            isCustomLabel
            label="Doradca"
            name="adviser_uuid"
            options={adviserOptions()}
            placeholder="Przypisz doradcę (opcjonalnie)"
          />
        )}
      </div>
      <div className="form_investor__top-fields">
        <Field
          component={SelectField}
          errors={errors}
          fullWidth
          isCustomLabel
          label="Status"
          name="status"
          options={returnRoleOptions()}
          placeholder="Wybierz status"
          onChange={handleAdviserSelected}
        />
        <Field
          className="regular-field__input--phone"
          component={RegularField}
          errors={errors}
          pattern="[A-Za-z]{2}"
          label="Numer tel"
          name="phone_number"
          type="number"
        />
      </div>
      <p className="select-field__label">Dane konta</p>
      <Field component={RegularField} errors={errors} fullWidth label="Imię" name="first_name" type="text" />
      <Field component={RegularField} errors={errors} fullWidth label="Nazwisko" name="last_name" type="text" />
      {formType === FORM_TYPES.ADD && (
        <Field component={RegularField} errors={errors} fullWidth label="Adres email" name="email" type="email" />
      )}
      <p className="select-field__label">Wymagane pliki </p>
      {data.investorCard && !fileInputValue ? (
        <div className="active-window__files">
          <div className="active-window__files--container">
            <SVG type={SVG_TYPE.LIGHT_FILE} />
            {/* <a href={data.files && data.files[0]?.access_path}> .</a> */}
            {/* <span> {data.files && data.files[0]?.file_name}</span> */}
            <span> {data.investorCard}</span>
          </div>
          <span
            onClick={() => {
              setFileInputValue('');
              setFieldValue('investorCard', '');
            }}
          >
            <SVG type={SVG_TYPE.EXIT} />
          </span>
        </div>
      ) : (
        <Field
          component={FieldFileInput}
          handleChange={handleFileChange}
          errors={errors}
          name="investorCard"
          type="file"
          label="+ Dodaj kartę inwestora"
        />
      )}
    </>
  );
};

export default BasicInformationForm;
