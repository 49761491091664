import * as React from 'react';
import PropTypes from 'prop-types';
import './select-list.scss';
import { Formik, Field } from 'formik';
import HeaderSelect from './HeaderSelect/HeaderSelect';

const options = [
  { value: 'chocolate', label: 'ukryj kolumnę' },
  { value: 'strawberry', label: 'ukryj puste' },
];

const SelectList = ({ placeholder, onChange, id }) => (
  <div className="selectListWrapper">
    <div className="selectListContainer">
      <Formik
        enableReinitialize
        initialValues={{
          manage: '',
          placeholder,
        }}
      >
        <Field component={HeaderSelect} placeholder={placeholder} options={options} name="manage" onChange={onChange} id={id} />
      </Formik>
    </div>
  </div>
);

export default SelectList;

SelectList.propTypes = {
  id: PropTypes.string.isRequired,
  menuPlacement: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
