import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkAccess, matchUrlToRole } from 'shared/utils';
import { AREA_TYPES, ACTION_TYPES, USER_ROLES } from 'shared/consts';
import { ActiveWindow } from 'shared/components';
import { SUBMENU } from 'shared/components/active-window/consts';
import InvestorPlot from 'screens/investors-right/components/investor-plot/InvestorPlot';
import Header from './components/header';
import Data from './components/data';

function EmployeesRight({
  activeUser,
  deleteUser,
  getActiveUser,
  prevPath,
  editUser,
  match: {
    url,
    params: { uuid },
  },
  authorizedUserRole,
  resendActivationEmail,
  getPartnersOptions,
  partnersOptions,
  getDdiOptions,
  ddiOptions,
  archived,
  archivedEmployee,
  getArchivedEmployee,
  restoreEmployee,
}) {
  useEffect(async () => {
    archived ? await getArchivedEmployee(uuid) : await getActiveUser(uuid);
  }, [uuid]);

  const { EDIT, DELETE, SEND_ACTIVATION_EMAIL } = ACTION_TYPES;
  const { EMPLOYEES } = AREA_TYPES;
  const { ADVISER, DDI } = USER_ROLES;

  const [activeMenu, setActiveMenu] = useState(SUBMENU.DATA);
  const isActivationEmailEnabled = !archived && !activeUser.is_active && checkAccess(EMPLOYEES, SEND_ACTIVATION_EMAIL);
  const isEditEnabled =
    !archived && (checkAccess(EMPLOYEES, EDIT) || (matchUrlToRole(url) === ADVISER && authorizedUserRole === DDI));
  const isDeleteEnabled =
    !archived && (checkAccess(EMPLOYEES, DELETE) || (matchUrlToRole(url) === ADVISER && authorizedUserRole === DDI));

  const activeData = archived ? archivedEmployee : activeUser;
  console.log(activeData);

  return (
    <div className="employees-right">
      <div className="employees-right__header">
        {Object.entries(activeData).length > 0 && (
          <>
            <Header activeUser={activeData} prevPath={prevPath} />
            <ActiveWindow.SubMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
          </>
        )}
      </div>
      <div className="employees-right__wrapper">
        {activeMenu === SUBMENU.DATA && (
          <Data
            activeUser={activeData}
            authorizedUserRole={authorizedUserRole}
            deleteUser={deleteUser}
            editUser={editUser}
            isActivationEmailEnabled={isActivationEmailEnabled}
            isDeleteEnabled={isDeleteEnabled}
            isEditEnabled={isEditEnabled}
            resendActivationEmail={resendActivationEmail}
            partnersOptions={partnersOptions}
            getPartnersOptions={getPartnersOptions}
            getDdiOptions={getDdiOptions}
            ddiOptions={ddiOptions}
            archived={archived}
            restoreEmployee={restoreEmployee}
          />
        )}
        {activeMenu === SUBMENU.PLOTS && activeData.plots && <InvestorPlot plots={activeData?.plots} />}
      </div>
    </div>
  );
}

EmployeesRight.defaultProps = {
  archived: false,
};

EmployeesRight.propTypes = {
  activeUser: PropTypes.object.isRequired,
  archived: PropTypes.bool,
  archivedEmployee: PropTypes.object.isRequired,
  authorizedUserRole: PropTypes.string.isRequired,
  ddiOptions: PropTypes.array.isRequired,
  deleteUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  getActiveUser: PropTypes.func.isRequired,
  getArchivedEmployee: PropTypes.func.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  partnersOptions: PropTypes.array.isRequired,
  prevPath: PropTypes.string.isRequired,
  resendActivationEmail: PropTypes.func.isRequired,
  restoreEmployee: PropTypes.func.isRequired,
};

export default EmployeesRight;
