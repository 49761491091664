import USER_ROLES from './userRoles';
import USER_POSITIONS from './userPositions';
import AREA_TYPES from './areaTypes';
import ACTION_TYPES from './actionTypes';

const { DIRECTOR, SUPERVISOR, TRADER, MANAGER } = USER_POSITIONS;
const { EMPLOYEES, PARTNERS, INVESTORS } = AREA_TYPES;
const { ADD, EDIT, DELETE, SEND_ACTIVATION_EMAIL } = ACTION_TYPES;

const ACCESS_ADMIN_POSITION_TYPES = new Map([
  [
    DIRECTOR.value,
    new Map([
      [EMPLOYEES, [ADD, EDIT, DELETE, SEND_ACTIVATION_EMAIL]],
      [PARTNERS, [ADD, EDIT, DELETE]],
      [INVESTORS, [ADD, EDIT, DELETE]],
    ]),
  ],
  [SUPERVISOR.value, new Map([[EMPLOYEES, [ADD, EDIT, DELETE, SEND_ACTIVATION_EMAIL]]])],
]);

const ACCESS_DDI_POSITION_TYPES = new Map([
  [
    TRADER.value,
    new Map([
      [EMPLOYEES, [ADD]],
      [INVESTORS, [ADD, EDIT, DELETE]],
      [PARTNERS, [ADD, EDIT]],
    ]),
  ],
]);

const ACCESS_DP_POSITION_TYPES = new Map([
  [
    MANAGER.value,
    new Map([
      [INVESTORS, [EDIT, DELETE]],
      [PARTNERS, [EDIT, DELETE]],
    ]),
  ],
]);

const ACCESS_TYPES = new Map([
  [USER_ROLES.ADMIN, ACCESS_ADMIN_POSITION_TYPES],
  [USER_ROLES.DDI, ACCESS_DDI_POSITION_TYPES],
  [USER_ROLES.DP, ACCESS_DP_POSITION_TYPES],
]);

export default ACCESS_TYPES;
