import { NOTIFICATION } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  notifications: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  activeDayNotifications: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  singleNotification: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  employees: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case NOTIFICATION.GET_NOTIFICATIONS_INIT: {
      return {
        ...state,
        notifications: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case NOTIFICATION.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case NOTIFICATION.GET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notifications: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case NOTIFICATION.GET_EMPLOYEES_INIT: {
      return {
        ...state,
        employees: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case NOTIFICATION.GET_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employees: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case NOTIFICATION.GET_EMPLOYEES_FAILURE: {
      return {
        ...state,
        employees: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    // case NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_INIT: {
    //   return {
    //     ...state,
    //     activeDayNotifications: {
    //       data: undefined,
    //       status: REQUEST_STATUS.INIT,
    //     },
    //   };
    // }
    case NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        activeDayNotifications: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case NOTIFICATION.GET_ACTIVE_DAY_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        activeDayNotifications: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case NOTIFICATION.GET_SINGLE_NOTIFICATION_INIT: {
      return {
        ...state,
        singleNotification: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case NOTIFICATION.GET_SINGLE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        singleNotification: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case NOTIFICATION.GET_SINGLE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        singleNotification: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case NOTIFICATION.CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        activeDayNotifications: {
          data: { ...state.activeDayNotifications, ...payload.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
