import React from 'react';
import PropTypes from 'prop-types';
import { UserBar, File } from 'shared/components';

const Sold = ({ plot, isUserBooking, isAccountant, isAdmin }) => {
  const {
    order_status: {
      investor: { first_name, last_name, phone_number },
      files,
    },
  } = plot;
  return (
    <>
      {(isUserBooking || isAdmin) && (
        <div className="signedContract__investor">
          <UserBar type="INWST" name={`${first_name} ${last_name}`} phone={phone_number} />
        </div>
      )}
      {(isUserBooking || isAccountant || isAdmin) && (
        <div className={`signedContract__files ${isUserBooking && 'signedContract__files--top'}`}>
          {files?.map(file => (
            <>
              <File label={file.file_name} path={file?.access_path} />
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default Sold;

Sold.propTypes = {
  isAccountant: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isUserBooking: PropTypes.bool.isRequired,
  plot: PropTypes.array.isRequired,
};
