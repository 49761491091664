import * as Connector from 'shared/connectors/reportConnector';
import { REPORT } from 'store/types';
import { getReportsFiltersList } from 'store/actions/report';
import { NewUser } from 'shared/components';
import { toast } from 'react-toastify';
import React from 'react';

export const success = data => ({
  type: REPORT.GET_REPORTS_DATA_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: REPORT.GET_REPORTS_DATA_FAILURE,
});

const saveReportsFilters = (value, filters, hiddenColumns) => async dispatch => {
  try {
    const modifiedFilters = {
      ...filters,
      ...value,
      hidden_columns: hiddenColumns,
      className: value.name.replace(/ +/g, ''),
    };
    await Connector.saveReportsFilters(modifiedFilters);
    dispatch(getReportsFiltersList());
    await toast(<NewUser first_name="Zapisano ustawienia" label="Wykonano akcje" />);
  } catch (err) {
    dispatch(failure());
  }
};

export default saveReportsFilters;
