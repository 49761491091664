import { AUTH } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  token: '',
  userToVerify: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  authorizedUser: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
};

const authReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case AUTH.LOGIN_INIT: {
      return {
        ...state,
        userToVerify: {
          ...state.userToVerify,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case AUTH.LOGIN_SUCCESS: {
      if (!payload.userToVerify.is_2FA_code) {
        localStorage.setItem('isUserLogged', JSON.stringify(true));
        return {
          ...state,
          token: payload.userToVerify.token,
          userToVerify: {
            data: {},
            status: REQUEST_STATUS.IDLE,
          },
        };
      }
      return {
        ...state,
        userToVerify: {
          data: payload.userToVerify,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case AUTH.LOGIN_FAILURE: {
      return {
        ...state,
        userToVerify: {
          data: {},
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case AUTH.CODE_VERIFICATION_INIT: {
      return {
        ...state,
        token: {
          ...state.token,
        },
      };
    }
    case AUTH.CODE_VERIFICATION_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: payload.data.token,
        userToVerify: {
          data: {},
          status: REQUEST_STATUS.IDLE,
        },
      };
    }
    case AUTH.CODE_VERIFICATION_FAILURE: {
      return {
        ...state,
        token: '',
      };
    }
    case AUTH.GET_AUTHORIZED_USER_INIT: {
      return {
        ...state,
        authorizedUser: {
          ...state.authorizedUser,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case AUTH.GET_AUTHORIZED_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case AUTH.GET_AUTHORIZED_USER_FAILURE: {
      localStorage.removeItem('isUserLogged');
      return {
        ...state,
        authorizedUser: {
          data: null,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case AUTH.SET_REFRESH_TOKEN: {
      return {
        ...state,
        token: payload.data.token,
      };
    }
    case AUTH.LOGOUT_SUCCESS: {
      localStorage.removeItem('isUserLogged');
      return {
        ...state,
        token: '',
        authorizedUser: {
          data: null,
          status: REQUEST_STATUS.IDLE,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
