import React, { useEffect } from 'react';
import { ActiveWindow, List } from 'shared/components';
import { AREA_TYPES, EMPLOYEE_SHORTCUTS, ROUTES } from 'shared/consts';
import { func, array, object } from 'prop-types';

function Adviser({ data: { uuid }, getAttachedAdvisers, attachedAdvisers }) {
  useEffect(() => {
    getAttachedAdvisers(uuid);
  }, [uuid]);
  return (
    <div>
      <ActiveWindow.ContentHeader title="Przypisani Doradcy" />
      <List
        area={AREA_TYPES.EMPLOYEES}
        path={ROUTES.EMPLOYEES_ADVISER}
        data={attachedAdvisers}
        shortcut={EMPLOYEE_SHORTCUTS.ADVISER}
      />
    </div>
  );
}

Adviser.propTypes = {
  attachedAdvisers: array.isRequired,
  data: object.isRequired,
  getAttachedAdvisers: func.isRequired,
};

export default Adviser;
