const OFFER = {
  ADD_OFFER_INIT: 'ADD_OFFER_INIT',
  ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS',
  ADD_OFFER_FAILURE: 'ADD_OFFER_FAILURE',
  OFFER_CHANGE_STATUS_INIT: 'OFFER_CHANGE_STATUS_INIT',
  OFFER_CHANGE_STATUS_SUCCESS: 'OFFER_CHANGE_STATUS_SUCCESS',
  OFFER_CHANGE_STATUS_FAILURE: 'OFFER_CHANGE_STATUS_FAILURE',
  GET_ALL_WAITING_OFFER_INIT: 'GET_ALL_WAITING_OFFER_INIT',
  GET_ALL_WAITING_OFFER_SUCCESS: 'GET_ALL_WAITING_OFFER_SUCCESS',
  GET_ALL_WAITING_OFFER_FAILURE: 'GET_ALL_WAITING_OFFER_FAILURE',
  GET_DDI_WAITING_OFFER_INIT: 'GET_DDI_WAITING_OFFER_INIT',
  GET_DDI_WAITING_OFFER_SUCCESS: 'GET_DDI_WAITING_OFFER_SUCCESS',
  GET_DDI_WAITING_OFFER_FAILURE: 'GET_DDI_WAITING_OFFER_FAILURE',
  GET_WAITING_OFFERS_INIT: 'GET_WAITING_OFFERS_INIT',
  GET_WAITING_OFFERS_SUCCESS: 'GET_WAITING_OFFERS_SUCCESS',
  GET_WAITING_OFFERS_FAILURE: 'GET_WAITING_OFFERS_FAILURE',
  GET_ACCEPTED_OFFERS_INIT: 'GET_ACCEPTED_OFFERS_INIT',
  GET_ACCEPTED_OFFERS_SUCCESS: 'GET_ACCEPTED_OFFERS_SUCCESS',
  GET_ACCEPTED_OFFERS_FAILURE: 'GET_ACCEPTED_OFFERS_FAILURE',
  GET_STATUS_PLOT_INIT: 'GET_STATUS_PLOT_INIT',
  GET_STATUS_PLOT_SUCCESS: 'GET_STATUS_PLOT_SUCCESS',
  GET_STATUS_PLOT_FAILURE: 'GET_STATUS_PLOT_FAILURE',
};

export default OFFER;
