import ROUTES from './routes';
import USER_ROLES from './userRoles';

const { ADMIN, ADMINISTRATION, MARKETING, ACCOUNTING, DDI, DIR, DP, ADVISER } = USER_ROLES;

const PROTECTED_ROUTES = new Map([
  [ROUTES.START, [ADMIN, ADMINISTRATION, MARKETING, ACCOUNTING, DDI, DIR, DP, ADVISER]],
  [ROUTES.SETTLEMENTS, [ADMIN, ADMINISTRATION, MARKETING, ACCOUNTING, DDI, DIR, DP, ADVISER]],
  [ROUTES.CALENDAR, [ADMIN, ADMINISTRATION, MARKETING, ACCOUNTING, DDI, DIR, DP, ADVISER]],
  [ROUTES.EMPLOYEES, [ADMIN, ADMINISTRATION, MARKETING, ACCOUNTING, DDI, DIR, DP]],
  // [ROUTES.SETTLEMENTS_PLOT_ID, [ADMIN, ADMINISTRATION, MARKETING, ACCOUNTING, DDI, DIR, DP]],
  [ROUTES.PARTNERS, [ADMIN, DDI, DP]],
  [ROUTES.ARCHIVED_PARTNERS, [ADMIN]],
  [ROUTES.ARCHIVED_EMPLOYEES, [ADMIN]],
  [ROUTES.ARCHIVED_INVESTORS, [ADMIN]],
  [ROUTES.ARCHIVED_SETTLEMENTS, [ADMIN]],
  [ROUTES.INVESTORS, [ADMIN, DDI, DP, ADVISER]],
  [ROUTES.REPORTS, [ADMIN, DDI]],
]);

export default PROTECTED_ROUTES;
