import { SVG_TYPE, ROUTES } from 'shared/consts';

const MENU_ITEMS = [
  {
    svgType: SVG_TYPE.START,
    route: ROUTES.START,
    label: 'Start',
  },
  {
    svgType: SVG_TYPE.SETTLEMENTS,
    route: ROUTES.SETTLEMENTS,
    label: 'Osady',
  },
  {
    svgType: SVG_TYPE.INVESTORS,
    route: ROUTES.INVESTORS,
    label: 'Inwestorzy',
  },
  {
    svgType: SVG_TYPE.EMPLOYEES,
    route: ROUTES.EMPLOYEES,
    label: 'Użytkownicy',
  },
  {
    svgType: SVG_TYPE.PARTNERS,
    route: ROUTES.PARTNERS,
    label: 'Partnerzy',
  },
  {
    svgType: SVG_TYPE.CALENDAR,
    route: ROUTES.CALENDAR,
    label: 'Kalendarz',
  },

  {
    svgType: SVG_TYPE.REPORTS,
    route: ROUTES.REPORTS,
    label: 'Raporty',
  },
];

export default MENU_ITEMS;
