const START = {
  GET_NEWS_INIT: 'GET_NEWS_INIT',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_FAILURE: 'GET_NEWS_FAILURE',
  GET_SINGLE_NEWS_INIT: 'GET_SINGLE_NEWS_INIT',
  GET_SINGLE_NEWS_SUCCESS: 'GET_SINGLE_NEWS_SUCCESS',
  GET_SINGLE_NEWS_FAILURE: 'GET_SINGLE_NEWS_FAILURE',
  GET_STATUSES_INIT: 'GET_STATUSES_INIT',
  GET_STATUSES_SUCCESS: 'GET_STATUSES_SUCCESS',
  GET_STATUSES_FAILURE: 'GET_STATUSES_FAILURE',
  GET_OFFERS_INIT: 'GET_OFFERS_INIT',
  GET_OFFERS_SUCCESS: 'GET_OFFERS_SUCCESS',
  GET_OFFERS_FAILURE: 'GET_OFFERS_FAILURE',
};

export default START;
