import { func, object } from 'prop-types';
import React from 'react';
import OffersList from './OffersList';

const Offers = ({
  authorizedUser,
  history,
  offers: { offer_accepted, offer_waiting, offer_accepted_count, offer_waiting_count },
  acceptModalOpen,
  offerChangeStatus,
  deleteAcceptedOffer,
}) => (
  <div className="statuses">
    <div className="statuses__title">Oferty dedykowane</div>
    <div className="statuses__content">
      <div className="statuses__content--offer">
        <OffersList
          title="Oczekujące"
          counterClassName="waiting"
          authorizedUser={authorizedUser}
          history={history}
          list={offer_waiting}
          acceptModalOpen={acceptModalOpen}
          offerChangeStatus={offerChangeStatus}
          count={offer_waiting_count}
          deleteAcceptedOffer={deleteAcceptedOffer}
        />
      </div>
      <div className="statuses__content--offer">
        <OffersList
          title="Zaakceptowane"
          counterClassName="accepted"
          upload
          authorizedUser={authorizedUser}
          history={history}
          list={offer_accepted}
          acceptModalOpen={acceptModalOpen}
          offerChangeStatus={offerChangeStatus}
          count={offer_accepted_count}
          deleteAcceptedOffer={deleteAcceptedOffer}
        />
      </div>
    </div>
  </div>
);
export default Offers;

Offers.propTypes = {
  acceptModalOpen: func.isRequired,
  authorizedUser: object.isRequired,
  deleteAcceptedOffer: func.isRequired,
  history: object.isRequired,
  offerChangeStatus: func.isRequired,
  offers: object.isRequired,
};
