import * as Connector from 'shared/connectors/reportConnector';
import { REPORT } from 'store/types';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: REPORT.GET_REPORTS_DATA_INIT,
});

export const success = data => ({
  type: REPORT.GET_REPORTS_DATA_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: REPORT.GET_REPORTS_DATA_FAILURE,
});

const getReportsData = values => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    console.log(values);
    const { data } = await Connector.getReportsData(values);
    dispatch(success(data));
    dispatch(hidePreloader());
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
  }
};

export default getReportsData;
