import React from 'react';
import { node, string } from 'prop-types';

function Content({ children, customClassName }) {
  return <div className={`active-window__content ${customClassName}`}>{children}</div>;
}

Content.defaultProps = {
  customClassName: '',
};

Content.propTypes = {
  children: node.isRequired,
  customClassName: string,
};

export default Content;
