const INVESTOR = {
  GET_INVESTOR_INIT: 'GET_INVESTOR_INIT',
  GET_INVESTOR_SUCCESS: 'GET_INVESTOR_SUCCESS',
  GET_INVESTOR_FAILURE: 'GET_INVESTOR_FAILURE',
  GET_SINGLE_INVESTOR_INIT: 'GET_SINGLE_INVESTOR_INIT',
  GET_SINGLE_INVESTOR_SUCCESS: 'GET_SINGLE_INVESTOR_SUCCESS',
  GET_SINGLE_INVESTOR_FAILURE: 'GET_SINGLE_INVESTOR_FAILURE',
  ADD_INVESTOR_INIT: 'ADD_INVESTOR_INIT',
  ADD_INVESTOR_SUCCESS: 'ADD_INVESTOR_SUCCESS',
  ADD_INVESTOR_FAILURE: 'ADD_INVESTOR_FAILURE',
  DELETE_INVESTOR_INIT: 'DELETE_INVESTOR_INIT',
  DELETE_INVESTOR_SUCCESS: 'DELETE_INVESTOR_SUCCESS',
  DELETE_INVESTOR_FAILURE: 'DELETE_INVESTOR_FAILURE',
  GET_INVESTORS_OPTIONS_INIT: 'GET_INVESTORS_OPTIONS_INIT',
  GET_INVESTORS_OPTIONS_SUCCESS: 'GET_INVESTORS_OPTIONS_SUCCESS',
  GET_INVESTORS_OPTIONS_FAILURE: 'GET_INVESTORS_OPTIONS_FAILURE',
  EDIT_INVESTOR_INIT: 'EDIT_INVESTOR_INIT',
  EDIT_INVESTOR_SUCCESS: 'EDIT_INVESTOR_SUCCESS',
  EDIT_INVESTOR_FAILURE: 'EDIT_INVESTOR_FAILURE',
  GET_ARCHIVED_INVESTORS_INIT: 'GET_ARCHIVED_INVESTORS_INIT',
  GET_ARCHIVED_INVESTORS_SUCCESS: 'GET_ARCHIVED_INVESTORS_SUCCESS',
  GET_ARCHIVED_INVESTORS_FAILURE: 'GET_ARCHIVED_INVESTORS_FAILURE',
  GET_ARCHIVED_INVESTOR_INIT: 'GET_ARCHIVED_INVESTOR_INIT',
  GET_ARCHIVED_INVESTOR_SUCCESS: 'GET_ARCHIVED_INVESTOR_SUCCESS',
  GET_ARCHIVED_INVESTOR_FAILURE: 'GET_ARCHIVED_INVESTOR_FAILURE',
  RESTORE_INVESTOR_INIT: 'RESTORE_INVESTOR_INIT',
  RESTORE_INVESTOR_SUCCESS: 'RESTORE_INVESTOR_SUCCESS',
  RESTORE_INVESTOR_FAILURE: 'RESTORE_INVESTOR_FAILURE',
  GET_DDI_INVESTORS_INIT: 'GET_DDI_INVESTORS_INIT',
  GET_DDI_INVESTORS_SUCCESS: 'GET_DDI_INVESTORS_SUCCESS',
  GET_DDI_INVESTORS_FAILURE: 'GET_DDI_INVESTORS_FAILURE',
  GET_INVESTORS_LABEL_INIT: 'GET_INVESTORS_LABEL_INIT',
  GET_INVESTORS_LABEL_SUCCESS: 'GET_INVESTORS_LABEL_SUCCESS',
  GET_INVESTORS_LABEL_FAILURE: 'GET_INVESTORS_LABEL_FAILURE',
};

export default INVESTOR;
