import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_ARCHIVED_SETTLEMENTS_INIT,
});

export const success = archivedSettlements => ({
  type: SETTLEMENTS.GET_ARCHIVED_SETTLEMENTS_SUCCESS,
  payload: {
    archivedSettlements,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_ARCHIVED_SETTLEMENTS_FAILURE,
});

const getArchivedSettlements = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getArchivedSettlements();
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getArchivedSettlements;
