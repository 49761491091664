import React from 'react';
import { SelectList } from 'shared/components';
import moment from 'moment';

const columnTypes = {
  id: 'id',
  netto: 'netto',
  deposit: 'deposit',
  gave_discount: 'gave_discount',
  list_price: 'list_price',
  conditional_agreement_date: 'conditional_agreement_date',
  conditional: 'conditional',
  deadline_payment: 'deadline_payment',
  company: 'company',
  plot_name: 'plot_name',
  settlement: 'settlement',
  ddi: 'ddi_id',
  investor: 'investor',
};

const columns = handleOptionChange => [
  // {
  //   id: 'id',
  //   accessor: 'id',
  //   Header: () => <SelectList id="id" onChange={handleOptionChange} placeholder="ID" />,
  //   fixed: 'left',
  // },

  {
    id: 'investor',
    accessor: 'investor',
    sticky: 'left',
    Header: () => <SelectList id="investor" onChange={handleOptionChange} placeholder="Inwestor" />,
  },

  {
    id: 'settlement',
    accessor: 'settlement',
    sticky: 'left',
    Header: () => <SelectList id="settlement" onChange={handleOptionChange} placeholder="Osada" />,
  },

  {
    id: 'plot_name',
    accessor: 'plot_name',
    sticky: 'left',
    Header: () => <SelectList id="plot_name" onChange={handleOptionChange} placeholder="Działka" />,
  },

  {
    id: 'ddi',
    accessor: 'ddi',
    Header: () => <SelectList id="ddi" onChange={handleOptionChange} placeholder="DDI" />,
  },

  {
    id: 'status',
    accessor: 'status',
    Header: () => <SelectList id="status" onChange={handleOptionChange} placeholder="Status działki" />,
  },

  {
    id: 'reservation_date',
    accessor: 'reservation_date',
    Header: () => <SelectList id="reservation_date" onChange={handleOptionChange} placeholder="Data wstępnej rezerwacji" />,
  },

  {
    id: 'agreement_date',
    accessor: 'agreement_date',
    Header: () => <SelectList id="agreement_date" onChange={handleOptionChange} placeholder="Data umowy przedwstępnej" />,
  },

  {
    id: 'conditional',
    accessor: 'conditional',
    Header: () => <SelectList id="conditional" onChange={handleOptionChange} placeholder="Warunkowa" />,
  },

  {
    id: 'law_office',
    accessor: 'law_office',
    Header: () => <SelectList id="law_office" onChange={handleOptionChange} placeholder="Kancelaria notarialna" />,
  },

  {
    id: 'conditional_agreement_date',
    accessor: 'conditional_agreement_date',
    Header: () => (
      <SelectList id="conditional_agreement_date" onChange={handleOptionChange} placeholder="Data i godzina umowy warunkowej" />
    ),
  },

  {
    id: 'promised_contract',
    accessor: 'promised_contract',
    Header: () => <SelectList id="promised_contract" onChange={handleOptionChange} placeholder="Umowa przyrzeczona" />,
  },

  {
    id: 'office',
    accessor: 'office',
    Header: () => <SelectList id="office" onChange={handleOptionChange} placeholder="Kancelaria" />,
  },

  {
    id: 'date_of_promised_act',
    accessor: 'date_of_promised_act',
    Header: () => (
      <SelectList id="date_of_promised_act" onChange={handleOptionChange} placeholder="Data i godzina aktu przyrzeczonego" />
    ),
  },

  {
    id: 'brutto',
    accessor: 'brutto',
    Header: () => <SelectList id="brutto" onChange={handleOptionChange} placeholder="Cena katalogowa" />,
  },

  {
    id: 'gave_discount',
    accessor: 'gave_discount',
    Header: () => <SelectList id="gave_discount" onChange={handleOptionChange} placeholder="Udzielony rabat" />,
  },

  {
    id: 'brutto_price_with_discount',
    accessor: 'brutto_price_with_discount',
    Header: () => (
      <SelectList
        id="brutto_price_with_discount"
        onChange={handleOptionChange}
        placeholder="Cena sprzedaży za całość brutto z cz. wspólnymi"
      />
    ),
  },

  {
    id: 'netto',
    accessor: 'netto',
    Header: () => (
      <SelectList id="netto" onChange={handleOptionChange} placeholder="Cena sprzedaży za całość netto z cz. wspólnymi" />
    ),
  },

  {
    id: 'deposit_payed',
    accessor: 'deposit_payed',
    Header: () => (
      <SelectList id="deposit_payed" onChange={handleOptionChange} placeholder="Kwota - wpłacona w I transzy brutto" />
    ),
  },

  {
    id: 'second_tranche',
    accessor: 'second_tranche',
    Header: () => (
      <SelectList id="second_tranche" onChange={handleOptionChange} placeholder="Kwota - wpłacona w II transzy brutto" />
    ),
  },

  {
    id: 'brutto_still_pay',
    accessor: 'brutto_still_pay',
    Header: () => (
      <SelectList id="brutto_still_pay" onChange={handleOptionChange} placeholder="Reszta pozostała do zapłaty brutto" />
    ),
  },

  {
    id: 'netto_still_pay',
    accessor: 'netto_still_pay',
    Header: () => (
      <SelectList id="netto_still_pay" onChange={handleOptionChange} placeholder="Reszta pozostała do zapłaty netto" />
    ),
  },

  {
    id: 'date_of_purchase',
    accessor: 'date_of_purchase',
    Header: () => <SelectList id="date_of_purchase" onChange={handleOptionChange} placeholder="Przewidywana data spłaty" />,
  },

  {
    id: 'company_name',
    accessor: 'company_name',
    Header: () => <SelectList id="company_name" onChange={handleOptionChange} placeholder="Spółka" />,
  },

  {
    id: 'partner',
    accessor: 'partner',
    Header: () => <SelectList id="partner" onChange={handleOptionChange} placeholder="Partner" />,
  },

  {
    id: 'partner_provision',
    accessor: 'partner_provision',
    Header: () => (
      <SelectList id="partner_provision" onChange={handleOptionChange} placeholder="Wysokość prowizji firmy doradczej" />
    ),
  },

  {
    id: 'price_per_m2',
    accessor: 'price_per_m2',
    Header: () => <SelectList id="price_per_m2" onChange={handleOptionChange} placeholder="Cena sprzedaży za metr netto" />,
  },

  {
    id: 'dead_line_pay',
    accessor: 'dead_line_pay',
    Header: () => <SelectList id="dead_line_pay" onChange={handleOptionChange} placeholder="Data wpłaty pełnej kwoty" />,
  },
];

const handleSortingContent = (values, updateFilters, isSetSortModalOpen) => {
  updateFilters(values);
  isSetSortModalOpen(false);
};

const handleFilterByRange = (values, isSetRangeModalOpen, updateFilters) => {
  let date = {};
  const date_start = {
    day: values.selected_day_start.day,
    month: values.selected_day_start.month - 1,
    year: values.selected_day_start.year,
  };

  const date_end = {
    day: values.selected_day_end.day,
    month: values.selected_day_end.month - 1,
    year: values.selected_day_end.year,
  };
  const selected_day_start = moment(date_start).format('YYYY-MM-DD');
  const selected_day_end = moment(date_end).format('YYYY-MM-DD');
  const isReservation = values.isReservation.value === 'date_of_reservation';
  const isAgreement = values.isReservation.value === 'conditional_agreement_date';
  const isPromisedAct = values.isReservation.value === 'date-of-promised-act';
  const isPurchase = values.isReservation.value === 'date_of_purchase';
  if (isReservation) {
    date = {
      isRange: true,
      reservation_date_start: selected_day_start,
      reservation_date_end: selected_day_end,
    };
  } else if (isAgreement) {
    date = {
      isRange: true,
      conditional_agreement_date_start: selected_day_start,
      conditional_agreement_date_end: selected_day_end,
    };
  } else if (isPromisedAct) {
    date = {
      isRange: true,
      promised_contract_date_start: selected_day_start,
      promised_contract_date_end: selected_day_end,
    };
  } else if (isPurchase) {
    date = {
      isRange: true,
      date_of_purchase_start: selected_day_start,
      date_of_purchase_end: selected_day_end,
    };
  } else {
    date = {
      isRange: true,
      prebooking_date_start: selected_day_start,
      prebooking_date_end: selected_day_end,
    };
  }
  console.log(date);
  updateFilters(date);
  isSetRangeModalOpen(false);
};

export { handleSortingContent, columnTypes, columns, handleFilterByRange };
