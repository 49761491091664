const FORM_STEPS = {
  BASIC: 'BASIC',
  DETAILS: 'DETAILS',
};

const INVESTOR__DETAILS_TYPE = {
  COMPANY: 'company',
  LEGAL_PERSON: 'person',
};

const INVESTOR_TYPE_OPTIONS = [
  {
    label: 'Firma',
    value: INVESTOR__DETAILS_TYPE.COMPANY,
  },
  {
    label: 'Osoba fizyczna',
    value: INVESTOR__DETAILS_TYPE.LEGAL_PERSON,
  },
];

const INVESTOR_ADDITIONAL_INFORMATION_OPTIONS = [
  {
    label: 'zainteresowany',
    value: 'interested',
  },
  {
    label: 'konkurencja',
    value: 'competitors',
  },
  {
    label: 'brak środków',
    value: 'lack_of_funds',
  },
  {
    label: 'brak kontaktu',
    value: 'no_contact',
  },
];
export { FORM_STEPS, INVESTOR__DETAILS_TYPE, INVESTOR_TYPE_OPTIONS, INVESTOR_ADDITIONAL_INFORMATION_OPTIONS };
