import React from 'react';
import PropTypes from 'prop-types';

const InputComparison = ({ first, second, label, onClick }) => (
  <div className={`inputComparison__wrapper ${onClick && 'inputComparison__wrapper--pointer'}`} onClick={onClick}>
    <div className="inputComparison">
      <p className="inputComparison--label">{label}</p>
      <p className="inputComparison--first">{first || 0} </p>
      <p className="inputComparison--second">/ {second} zł</p>
    </div>
  </div>
);

InputComparison.propTypes = {
  first: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  second: PropTypes.string.isRequired,
};

export default InputComparison;
