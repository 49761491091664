import { number } from 'prop-types';
import React from 'react';
import { string } from 'yup/lib/locale';

const AvailabilityBadge = ({ type, count }) => (
  <div className={`availabilityBadge availabilityBadge--${type}`}>
    <span className="availabilityBadge__icon" />
    <span className="availabilityBadge__count">{count}</span>
  </div>
);

AvailabilityBadge.propTypes = {
  count: number.isRequired,
  type: string.isRequired,
};

export default AvailabilityBadge;
