import React, { useEffect } from 'react';
import { UserBar, Button, SingleOfferModal } from 'shared/components';
import { SVG_TYPE, MODAL_TYPES } from 'shared/consts';
import PropTypes from 'prop-types';
import Tile from 'screens/filters/components/Tile';
import OfferBar from './OfferBar';

const PreBooking = ({
  isUserBooking,
  plotUuid,
  plot,
  loggedUser,
  getStatusPlot,
  statusPlot,
  isAdmin,
  isAccountant,
  getPdfContract,
}) => {
  const { role } = loggedUser;
  const [modalOpened, setModalOpened] = React.useState(null);
  const [customDepositDate, setCustomDespositDate] = React.useState(null);
  const [offerData, setOfferData] = React.useState(null);
  const isSingleOfferOpened = modalOpened === MODAL_TYPES.SINGLE_OFFER;

  useEffect(async () => {
    await getStatusPlot(plot?.uuid);
  }, []);

  const openModal = async type => {
    await setModalOpened(type);
    setOfferData(statusPlot.data);
  };

  const {
    order_status: {
      investor: { first_name, last_name, phone },
      ddi,
    },
  } = plot;

  console.log(customDepositDate);

  return (
    <>
      <SingleOfferModal
        title="Podgląd oferty"
        opened={isSingleOfferOpened}
        onClose={() => setModalOpened(null)}
        offerData={offerData}
        plotUuid={plotUuid}
        userRole={role}
        isUserBooking={isUserBooking}
        cancel
      />
      <div className="preBooking">
        {isUserBooking && (
          <>
            <UserBar type="INWST" name={`${first_name} ${last_name}`} phone={phone} />
            <p className="preBooking--paragraph">
              Po wygenerowaniu umowy klient powinien wpłacić pełną kwotę zobowiązania zawartą na umowie.
            </p>
            <div className="preBooking__customDate">
              <Tile
                field={{
                  name: 'custom_deposit_date',
                  value: customDepositDate,
                  onChange: () => setCustomDespositDate(state => !state),
                }}
                label="Wyjątkowo inny termin na wpłatę zadatku"
                icon=""
                customLabel
              />
            </div>
            <div className="preBooking__actions">
              <p className="preBooking__actions--paragraph">Przesłana Oferta</p>
              <Button
                classNames="green right-icon svg--file--green"
                iconType={SVG_TYPE.FILE}
                label="Generuj umowę"
                onClick={() => getPdfContract(plot?.order_status.uuid)}
              />
            </div>
            <div className="offerBar__wrapper">
              <OfferBar item={plot} userRole={role} plotUuid={plotUuid} openModal={openModal} download />
            </div>
          </>
        )}
        {!isUserBooking && !isAdmin && !isAccountant && (
          <>
            <UserBar type="DDI" name={`${ddi?.first_name.charAt(0)}. ${ddi?.last_name}`} phone={ddi?.phone} />
            <p className="preBooking--paragraph">
              Po anulowaniu wstępnej rezerwacji, działka może trafic do kolejnego DDI na liście oczekujących
            </p>
          </>
        )}
        {(isAdmin || isAccountant) && (
          <>
            <UserBar type="INWST" name={`${first_name} ${last_name}`} phone={phone} />
            <UserBar type="DDI" name={`${ddi?.first_name.charAt(0)}. ${ddi?.last_name}`} phone={ddi?.phone} />
            <div className="preBooking__customDate">
              <Tile
                field={{
                  name: 'custom_deposit_date',
                  value: customDepositDate,
                  onChange: () => setCustomDespositDate(state => !state),
                }}
                label="Wyjątkowo inny termin na wpłatę zadatku"
                icon=""
                customLabel
              />
            </div>
            <div className="preBooking__actions">
              <p className="preBooking__actions--paragraph">Przesłana Oferta</p>
              <Button
                classNames="green right-icon svg--file--green"
                iconType={SVG_TYPE.FILE}
                label="Generuj umowę"
                onClick={() => getPdfContract(plot?.order_status.uuid, customDepositDate)}
              />
            </div>
            <div className="offerBar__wrapper">
              <OfferBar item={plot} userRole={role} plotUuid={plotUuid} openModal={openModal} download />
            </div>
          </>
        )}
      </div>
    </>
  );
};

PreBooking.propTypes = {
  getPdfContract: PropTypes.func.isRequired,
  getStatusPlot: PropTypes.func.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isUserBooking: PropTypes.bool.isRequired,
  loggedUser: PropTypes.array.isRequired,
  plot: PropTypes.array.isRequired,
  plotUuid: PropTypes.string.isRequired,
  statusPlot: PropTypes.array.isRequired,
};

export default PreBooking;
