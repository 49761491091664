/* eslint-disable react/prop-types */
import React from 'react';
import { SVG, Button, ReservationSurname, AvailabilityBadge } from 'shared/components';
import { SVG_TYPE, ROUTES, RESERVATION_STATUS } from 'shared/consts';
import { generatePath } from 'react-router';

const filterBy = {
  status_pre_booking: { reservation: true },
  status_put_a_down_payment: { pending_cashouts: true },
  status_signed_contract: { signed_contract: true },
};

const getColumns = (history, openModal, authorizedUser, userRole) => [
  {
    id: 'name',
    accessor: 'name',
    Header: '',
    width: 85,
    Cell: ({ original: { name, status } }) => <AvailabilityBadge type={status} count={name} />,
  },
  {
    id: 'water',
    accessor: 'water',
    Header: '',
    width: 65,
    Cell: ({ original: { sewers, electrocity, water, uuid, status } }) => (
      <>
        <div
          className={`plot__singleElementBar ${status !== RESERVATION_STATUS.FREE && 'plot__singleElementBar--fullWidth'}`}
          onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid }))}
        />
        <div className="media__list">
          <span className={`media__list--item ${water ? '' : 'disabled'}`}>
            <SVG type={SVG_TYPE.MEDIA_WATER} />
          </span>
          <span className={`media__list--item ${electrocity ? '' : 'disabled'}`}>
            <SVG type={SVG_TYPE.MEDIA_POWER} />
          </span>
          <span className={`media__list--item ${sewers ? '' : 'disabled'}`}>
            <SVG type={SVG_TYPE.MEDIA_HEAT} />
          </span>
        </div>
      </>
    ),
  },
  {
    id: 'plot_size',
    accessor: 'plot_size',
    Header: '',
    width: 55,
    Cell: ({ value }) => `${value} ㎡`,
  },
  {
    id: 'price',
    accessor: 'price',
    Header: '',
    width: 50,
    isVisible: false,
    Cell: ({ original: { price, status } }) => <>{status === RESERVATION_STATUS.FREE ? `${price} zł` : ''}</>,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: '',
    Cell: ({ original }) => (
      <>
        {original?.status === RESERVATION_STATUS.FREE && userRole !== 'adviser' ? (
          <Button label="Rezerwuj" classNames="btn--orange" onClick={() => openModal(original.uuid)} />
        ) : (
          <>
            {original?.status !== RESERVATION_STATUS.FREE && (
              <ReservationSurname
                bookedOn={original.order_status}
                authorizedUserUuid={authorizedUser}
                name={original.order_status?.investor?.first_name}
                surname={original.order_status?.investor?.last_name}
              />
            )}
          </>
        )}
      </>
    ),
  },
];

export { getColumns, filterBy };
