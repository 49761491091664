import { USER_ROLES } from 'shared/consts';

const ROUTES = {
  HOME_PAGE: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CODE_VERIFICATION: '/code-verification',
  ACTIVATE_ACCOUNT: '/activate-account',
  NEW_PASSWORD: '/new-password',
  DASHBOARD: '/dashboard',
  SEARCH: '/search',
  START: '/dashboard/start',
  SETTLEMENTS: '/dashboard/settlements',
  PLOTS: '/dashboard/plots',
  INVESTORS: '/dashboard/investors',
  INVESTORS_ID: '/dashboard/investors/:uuid',
  EMPLOYEES: '/dashboard/employees',
  PARTNERS: '/dashboard/partners',
  CALENDAR: '/dashboard/calendar',
  CALENDAR_START_END: `/dashboard/calendar/:startDate/:endDate`,
  SINGLE_NOTIFICATION: `/dashboard/calendar/single-notification/:uuid`,
  EMPLOYEES_DDI: `/dashboard/employees/${USER_ROLES.DDI}`,
  INVESTORS_DDI: `/dashboard/employees/${USER_ROLES.DDI}`,
  EMPLOYEES_ADVISER: `/dashboard/employees/${USER_ROLES.ADVISER}`,
  EMPLOYEES_ACCOUNTING: `/dashboard/employees/${USER_ROLES.ACCOUNTING}`,
  EMPLOYEES_ADMINISTRATION: `/dashboard/employees/${USER_ROLES.ADMINISTRATION}`,
  EMPLOYEES_MARKETING: `/dashboard/employees/${USER_ROLES.MARKETING}`,
  EMPLOYEES_ADMIN: `/dashboard/employees/${USER_ROLES.ADMIN}`,
  EMPLOYEES_DIR: `/dashboard/employees/${USER_ROLES.DIR}`,
  EMPLOYEES_DP: `/dashboard/employees/${USER_ROLES.DP}`,
  EMPLOYEES_DDI_UUID: `/dashboard/employees/${USER_ROLES.DDI}/:uuid`,
  EMPLOYEES_ADVISER_UUID: `/dashboard/employees/${USER_ROLES.ADVISER}/:uuid`,
  EMPLOYEES_ACCOUNTING_UUID: `/dashboard/employees/${USER_ROLES.ACCOUNTING}/:uuid`,
  EMPLOYEES_ADMINISTRATION_UUID: `/dashboard/employees/${USER_ROLES.ADMINISTRATION}/:uuid`,
  EMPLOYEES_MARKETING_UUID: `/dashboard/employees/${USER_ROLES.MARKETING}/:uuid`,
  EMPLOYEES_ADMIN_UUID: `/dashboard/employees/${USER_ROLES.ADMIN}/:uuid`,
  EMPLOYEES_DIR_UUID: `/dashboard/employees/${USER_ROLES.DIR}/:uuid`,
  EMPLOYEES_DP_UUID: `/dashboard/employees/${USER_ROLES.DP}/:uuid`,
  SETTLEMENTS_ID: `/dashboard/settlements/:uuid`,
  ARCHIVED_SETTLEMENTS_ID: `/dashboard/archived-settlements/:uuid`,
  SETTLEMENTS_PLOT_ID: '/dashboard/settlements/plot/:uuid',
  PARTNERS_UUID: '/dashboard/partners/:uuid',
  ARCHIVED_EMPLOYEES: '/dashboard/archived-employees',
  ARCHIVED_PARTNERS: '/dashboard/archived-partners',
  ARCHIVED_INVESTORS: '/dashboard/archived-investors',
  ARCHIVED_SETTLEMENTS: '/dashboard/archived-settlements',
  REPORTS: '/dashboard/reports',
  STATUSES_PRELIMINARY: 'dashboard/start/preliminary',
  STATUSES_RESERVATIONS: 'dashboard/start/reservations',
  STATUSES_ASSIGNED: 'dashboard/start/assigned',
};

export default ROUTES;
