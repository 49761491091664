import { func } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

function Head({ onClose }) {
  return (
    <div className="filters__head">
      <div onClick={onClose}>
        <SVG className="filters__filter-icon svg--filter-icon svg--filter-icon--black" type={SVG_TYPE.FILTER} />
      </div>
    </div>
  );
}

Head.propTypes = {
  onClose: func.isRequired,
};

export default Head;
