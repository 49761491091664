import React, { useEffect } from 'react';
import { shape, string, func, array } from 'prop-types';
import { FORM_TYPES } from 'shared/consts';
import { AddNotification } from 'shared/components';
import { useModal } from 'shared/hooks';
// import { useParams } from 'react-router-dom';
import NotificationsByDate from '../notifications-right/components/notifications-by-date/NotificationsByDate';

const Notifications = ({
  match: {
    params: { startDate, endDate },
  },
  getActiveDayNotifications,
  getInvestorsOptions,
  addNotification,
  investorsOptions,
  activeDayNotifications,
  getPlotsOptions,
  plotsOptions,
  getSettlementsOptions,
  settlementsOptions,
  authorizedUserRole,
  changeStatus,
  getEmployees,
  employees,
}) => {
  const [isModalOpened, showModal, hideModal] = useModal(false);

  //   const {startDate, endDate} = useParams();

  useEffect(() => {
    getActiveDayNotifications({ startDate, endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    getEmployees();
  }, []);

  const handleSubmit = async (values, file) => {
    const response = await addNotification(values, file);
    getActiveDayNotifications({ startDate, endDate });
    return response;
  };

  const handleDoneStatus = uuid => {
    const status = {
      status: 'done',
    };
    // console.log(status);
    changeStatus(status, uuid, { startDate, endDate });
    // console.log(uuid);
  };

  const handleToDoStatus = uuid => {
    const status = {
      status: 'to_do',
    };
    // console.log(status);
    changeStatus(status, uuid, { startDate, endDate });
    // console.log(uuid);
  };

  return (
    <>
      <AddNotification
        onClose={hideModal}
        onSubmit={handleSubmit}
        opened={isModalOpened}
        type={FORM_TYPES.ADD}
        investorsOptions={investorsOptions}
        getInvestorsOptions={getInvestorsOptions}
        getPlotsOptions={getPlotsOptions}
        plotsOptions={plotsOptions}
        settlementsOptions={settlementsOptions}
        getSettlementsOptions={getSettlementsOptions}
        loggedUserRole={authorizedUserRole}
        employeesOptions={employees}
        // userRole={authorizedUserRole}
        // partnersOptions={partnersOptions}
        // getPartnersOptions={getPartnersOptions}
        // getDdiOptions={getDdiOptions}
        // ddiOptions={ddiOptions}
      />
      <NotificationsByDate
        activeDayNotifications={activeDayNotifications}
        handleOpenModal={showModal}
        handleDoneStatus={handleDoneStatus}
        handleToDoStatus={handleToDoStatus}
      />
    </>
  );
};

Notifications.propTypes = {
  actions: shape({
    deleteInvestor: func.isRequired,
  }).isRequired,
  activeDayNotifications: array.isRequired,
  addNotification: func.isRequired,
  authorizedUserRole: string.isRequired,
  changeStatus: func.isRequired,
  employees: array.isRequired,
  getActiveDayNotifications: func.isRequired,
  getEmployees: func.isRequired,
  getInvestorsOptions: func.isRequired,
  getPlotsOptions: func.isRequired,
  getSettlementsOptions: func.isRequired,
  investors: shape({}).isRequired,
  investorsOptions: func.isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
  plotsOptions: array.isRequired,
  settlementsOptions: array.isRequired,
};

export default Notifications;
