const isValidRegon = regon => {
  if (!regon) return true;
  const reg = /^[0-9]{9}$/;
  if (!reg.test(regon)) return false;

  const digits = `${regon}`.split('');
  let checksum =
    (8 * parseInt(digits[0], 10) +
      9 * parseInt(digits[1], 10) +
      2 * parseInt(digits[2], 10) +
      3 * parseInt(digits[3], 10) +
      4 * parseInt(digits[4], 10) +
      5 * parseInt(digits[5], 10) +
      6 * parseInt(digits[6], 10) +
      7 * parseInt(digits[7], 10)) %
    11;
  if (checksum === 10) checksum = 0;

  return parseInt(digits[8], 10) === checksum;
};

export default isValidRegon;
