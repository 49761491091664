import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const TabsToggle = ({ field: { name, value }, form: { setFieldValue }, label, options }) => (
  <div className="tabs-toggle">
    {label && <div className="tabs-toggle__label">{label}</div>}
    <div className="tabs-toggle__options">
      {options.map(option => (
        <div
          key={option.value}
          className={`tabs-toggle__option ${value[option.value] === true ? 'tabs-toggle__option--active' : ''}`}
          onClick={() =>
            setFieldValue(name, {
              ...value,
              [option.value]: !value[option.value],
            })
          }
        >
          <span>{option.label}</span>
          {value[option.value] === true && (
            <span className="tabs-toggle__option--check">
              <SVG type={SVG_TYPE.CONFIRM} />
            </span>
          )}
        </div>
      ))}
    </div>
  </div>
);
TabsToggle.defaultProps = {
  label: '',
};
TabsToggle.propTypes = {
  field: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({}).isRequired,
  label: string,
  options: arrayOf(shape({})).isRequired,
};

export default TabsToggle;
