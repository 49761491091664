import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Modal, UserBar, OfferBar, ModalActions } from 'shared/components';
import { MODAL_TYPES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import PropTypes from 'prop-types';
import moment from 'moment';

const SingleOfferModal = ({
  opened,
  onClose,
  children,
  title,
  offerChangeStatus,
  plotUuid,
  offerData,
  userRole,
  isUserBooking,
}) => {
  const isMobileDevice = isMobile();
  return (
    <TransitionGroup component={null}>
      <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
        <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
          {children}
          <div className="acceptOffer">
            {offerData?.investor && (
              <UserBar type="INWST" name={`${offerData?.investor?.first_name} ${offerData?.investor?.last_name}`} />
            )}
            {!isUserBooking && (
              <UserBar
                type="DDI"
                name={`${offerData?.ddi?.first_name} ${offerData?.ddi?.last_name}`}
                phone={offerData?.ddi?.phone}
              />
            )}
            <OfferBar
              number={offerData?.plot_id}
              SettlementName={offerData?.plot.settlement.name}
              plotNumber={offerData?.plot?.name}
              size={offerData?.plot?.plot_size}
              priceOld={offerData?.plot?.price}
              priceNew={offerData?.price || offerData?.offer?.price || offerData?.offer?.price}
              percentageReduction={offerData?.percentage_reduction}
            />

            <div className="acceptOffer__comment acceptOffer__comment--inwst">
              {moment(offerData?.created_at).format('DD.MM.YYYY')}
              <p>{offerData?.comment || offerData?.offer?.comment}</p>
            </div>
            {offerData?.admin_comment && (
              <div className="acceptOffer__comment acceptOffer__comment--admin">
                <p className="acceptOffer__comment--admin--data">{moment(offerData?.updated_at).format('DD.MM.YYYY')}</p>
                <p>{offerData?.admin_comment || offerData?.offer?.admin_comment}</p>
                <span>Admin</span>
                <span>
                  {offerData?.admin?.first_name} {offerData?.admin?.last_name}
                </span>
              </div>
            )}
          </div>

          <ModalActions
            cancel
            handleSubmit={() => {
              offerChangeStatus({ offerUuid: offerData?.uuid, acceptance: 'no', plotUuid, userRole });
              onClose();
            }}
            onClose={onClose}
            type={MODAL_TYPES.SINGLE_OFFER}
          />
        </Modal>
      </CSSTransition>
    </TransitionGroup>
  );
};

SingleOfferModal.defaultProps = {
  isUserBooking: false,
  offerChangeStatus: null,
};

SingleOfferModal.propTypes = {
  children: PropTypes.any.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  isUserBooking: PropTypes.bool,
  offerChangeStatus: PropTypes.func,
  offerData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.func.isRequired,
  plotUuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default SingleOfferModal;
