import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

function NewUser({ badgeType, first_name, last_name, phone, label }) {
  return (
    <div className="alert">
      <div className="alert__title">{label}</div>
      <div className="alert__content">
        <div className="alert__left">
          {badgeType && <Badge label={badgeType} type={badgeType} />}
          {first_name} {last_name}
        </div>
        {phone && (
          <div className="alert__right">
            <Button
              classNames="green small left-icon"
              iconType={SVG_TYPE.PHONE}
              label="Zadzwoń"
              onClick={() => window.open(`tel:${phone}}`)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

NewUser.propTypes = {
  badgeType: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default NewUser;
