import * as Connector from 'shared/connectors/startConnector';
import getNews from './getNews';

const createNews = (title, text) => async dispatch => {
  try {
    await Connector.addNews(title, text);
    dispatch(getNews());
    // dispatch(success(status));
  } catch (err) {
    // dispatch(failure());
  }
};

export default createNews;
