import React from 'react';
import { object, string, func, bool, array } from 'prop-types';
import { ActiveWindow } from 'shared/components';
// import { USER_ROLES } from 'shared/consts';
import { INVESTOR_SUBMENU } from 'shared/components/active-window/consts';
import InvestorName from './InvestorName';
import InvestorData from '../investor-data/InvestorData';
import InvestorDetails from '../investor-details/InvestorDetails';
import InvestorPlot from '../investor-plot/InvestorPlot';

const InvestorByUuid = ({
  setActiveMenu,
  activeMenu,
  archived,
  investor,
  deleteInvestor,
  editInvestor,
  authorizedUserRole,
  getAllDdi,
  usersDdi,
  restoreInvestor,
  employees,
  getEmployees,
}) => {
  const { first_name, last_name, phone_number, investor_type } = investor;
  const submenuArr = investor_type
    ? [INVESTOR_SUBMENU.BASIC, INVESTOR_SUBMENU.SPECIFIC, INVESTOR_SUBMENU.PLOT]
    : [INVESTOR_SUBMENU.BASIC, INVESTOR_SUBMENU.PLOT];
  return (
    <ActiveWindow.Wrapper>
      <ActiveWindow.Header>
        <InvestorName name={`${first_name} ${last_name}`} phone={phone_number} />
        <ActiveWindow.SubMenu menu={submenuArr} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      </ActiveWindow.Header>
      <ActiveWindow.Content>
        {activeMenu === INVESTOR_SUBMENU.BASIC && (
          <InvestorData
            archived={archived}
            authorizedUserRole={authorizedUserRole}
            getAllDdi={getAllDdi}
            usersDdi={usersDdi}
            data={investor}
            editInvestor={editInvestor}
            deleteInvestor={deleteInvestor}
            restoreInvestor={restoreInvestor}
            employees={employees}
            getEmployees={getEmployees}
          />
        )}
        {activeMenu === INVESTOR_SUBMENU.SPECIFIC && (
          <InvestorDetails
            authorizedUserRole={authorizedUserRole}
            getAllDdi={getAllDdi}
            usersDdi={usersDdi}
            data={investor}
            editInvestor={editInvestor}
          />
        )}
        {console.log(investor.plots)}
        {activeMenu === INVESTOR_SUBMENU.PLOT && investor.plots && <InvestorPlot plots={investor.plots} />}
      </ActiveWindow.Content>
    </ActiveWindow.Wrapper>
  );
};

InvestorByUuid.defaultProps = {
  archived: false,
};

InvestorByUuid.propTypes = {
  activeMenu: string.isRequired,
  archived: bool,
  authorizedUserRole: string.isRequired,
  deleteInvestor: func.isRequired,
  editInvestor: func.isRequired,
  employees: array.isRequired,
  getAllDdi: func.isRequired,
  getEmployees: func.isRequired,
  investor: object.isRequired,
  restoreInvestor: func.isRequired,
  setActiveMenu: func.isRequired,
  usersDdi: func.isRequired,
};
export default InvestorByUuid;
