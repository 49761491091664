import { INVESTOR } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  investors: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  singleInvestor: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  investorsOptions: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedInvestors: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  archivedInvestor: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  investorsLabel: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const investorReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case INVESTOR.GET_INVESTOR_INIT: {
      return {
        ...state,
        investors: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case INVESTOR.GET_INVESTOR_SUCCESS: {
      return {
        ...state,
        investors: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case INVESTOR.GET_INVESTOR_FAILURE: {
      return {
        ...state,
        investors: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case INVESTOR.GET_SINGLE_INVESTOR_INIT: {
      return {
        ...state,
        singleInvestor: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case INVESTOR.GET_SINGLE_INVESTOR_SUCCESS: {
      return {
        ...state,
        singleInvestor: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case INVESTOR.GET_SINGLE_INVESTOR_FAILURE: {
      return {
        ...state,
        singleInvestor: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case INVESTOR.GET_INVESTORS_OPTIONS_SUCCESS: {
      return {
        ...state,
        investorsOptions: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case INVESTOR.GET_ARCHIVED_INVESTORS_SUCCESS: {
      return {
        ...state,
        archivedInvestors: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case INVESTOR.GET_ARCHIVED_INVESTOR_SUCCESS: {
      return {
        ...state,
        archivedInvestor: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case INVESTOR.GET_INVESTORS_LABEL_SUCCESS: {
      return {
        ...state,
        investorsLabel: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    default:
      return state;
  }
};

export default investorReducer;
