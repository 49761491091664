/* eslint-disable react/prop-types */
import * as React from 'react';
import { Field } from 'formik';
import { SelectField, RegularField, TabsToggle, Switch } from 'shared/components';
import { INVESTOR_TYPE_OPTIONS, INVESTOR__DETAILS_TYPE, INVESTOR_ADDITIONAL_INFORMATION_OPTIONS } from '../consts';

const DetailedInformationForm = ({ errors, values }) => {
  const renderAddressFields = () => (
    <>
      <div className="address_field-halfs">
        <Field component={RegularField} errors={errors} label="Ulica" name="street" type="text" />
        <Field component={RegularField} errors={errors} label="Nr domu" name="house_number" type="text" />
      </div>
      <div className="address_field-halfs">
        <Field component={RegularField} errors={errors} label="Kod pocztowy" name="zip_code" type="text" />
        <Field component={RegularField} errors={errors} label="Miasto" name="city" type="text" />
      </div>
    </>
  );
  return (
    <>
      <p className="select-field__label">Dane szczegółowe</p>
      <Field component={Switch} errors={errors} name="investor_type" options={INVESTOR_TYPE_OPTIONS} />
      {/* {console.log(values.investor_type)} */}
      {values.investor_type === INVESTOR__DETAILS_TYPE.COMPANY && (
        <>
          <Field component={RegularField} errors={errors} fullWidth label="NIP" name="nip" type="text" />
          <Field component={RegularField} errors={errors} fullWidth label="Nazwa firmy" name="company_name" type="text" />
          {renderAddressFields()}
          <Field component={RegularField} errors={errors} fullWidth label="Notatka" name="note" type="text" />
        </>
      )}
      {values.investor_type === INVESTOR__DETAILS_TYPE.LEGAL_PERSON && (
        <>
          <Field component={RegularField} errors={errors} fullWidth label="Pesel" name="pesel" type="text" />
          <Field
            component={RegularField}
            errors={errors}
            fullWidth
            label="Nr dowodu osobistego"
            name="id_card_number"
            type="text"
          />
          {renderAddressFields()}
          <Field
            component={SelectField}
            errors={errors}
            name="property_separation"
            options={[
              { label: 'Rozdzielność majątkowa - TAK', value: '1' },
              { label: 'Rozdzielność majątkowa - NIE', value: '0' },
              { label: 'Nie dotyczy', value: '0' },
            ]}
            placeholder="Wybierz status"
          />
        </>
      )}
      <p className="select-field__label">Informacje</p>
      <Field
        component={TabsToggle}
        errors={errors}
        name="investorAdditionalInformation"
        options={INVESTOR_ADDITIONAL_INFORMATION_OPTIONS}
      />
    </>
  );
};

export default DetailedInformationForm;
