import API from 'shared/connectors/config';

export function addNews(title, text) {
  console.log(text, title);
  return API.post(`/news`, { title, text });
}

export function getNews() {
  return API.get(`/news`);
}

export function getSingleNews(uuid) {
  return API.get(`/news/${uuid}`);
}

export function editNews(uuid, title, text) {
  return API.put(`/news/${uuid}`, { title, text });
}

export function deleteNews(uuid) {
  return API.delete(`/news/${uuid}`);
}

export function getStatuses() {
  return API.get(`/last_status`);
}

export function getOffers() {
  return API.get(`/last_offer`);
}

export function deleteAcceptedOffer(uuid) {
  return API.delete(`/offers/accepted/cancel/${uuid}`);
}
