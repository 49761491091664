import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { FORM_TYPES, ROUTES, SVG_TYPE, EMPLOYEES_SUBPAGES, AREA_TYPES, ACTION_TYPES, USER_ROLES } from 'shared/consts';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Button, PageContent } from 'shared/components';
import { checkAccess } from 'shared/utils';
import SubpageLink from './components/subpage-link';
import Form from './components/form';
import Subpage from './components/subpage';
import EMPLOYEES_ROUTES_ARR from './consts';

const { EMPLOYEES_DDI } = ROUTES;

function Employees({
  users,
  getUsers,
  isMobile,
  getUsersAmount,
  usersAmount,
  addUser,
  usersStatus,
  authorizedUser,
  getPartnersOptions,
  partnersOptions,
  getDdiOptions,
  ddiOptions,
}) {
  useEffect(async () => {
    await getUsersAmount();
  }, []);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 6,
    initialSlide: isMobile,
    focusOnSelect: isMobile,
    infinite: isMobile,
    variableWidth: true,
    swipeToSlide: isMobile,
    centerMode: isMobile,
  };
  const isAddEnabled = checkAccess(AREA_TYPES.EMPLOYEES, ACTION_TYPES.ADD);
  const authorizedUserRole = authorizedUser.role;
  const authorizedUserUuid = authorizedUser.uuid;

  return (
    <PageContent className="employees">
      <Form
        onClose={() => {
          setIsModalOpened(false);
        }}
        userUuid={authorizedUserUuid}
        onSubmit={addUser}
        opened={isModalOpened}
        type={FORM_TYPES.ADD}
        userRole={authorizedUserRole}
        partnersOptions={partnersOptions}
        getPartnersOptions={getPartnersOptions}
        getDdiOptions={getDdiOptions}
        ddiOptions={ddiOptions}
      />
      <div className="employees__header">
        <div className="employees__title">Użytkownicy</div>
        {isAddEnabled && (
          <Button
            classNames="transparent no-padding right-icon"
            iconType={SVG_TYPE.ADD}
            label="Dodaj"
            onClick={() => setIsModalOpened(true)}
          />
        )}
      </div>
      {Object.keys(usersAmount).length > 0 ? (
        <Slider className="employees__subpages" {...settings}>
          {authorizedUserRole === USER_ROLES.ADMIN
            ? EMPLOYEES_SUBPAGES.map(
                subpage =>
                  usersAmount[subpage.role] !== undefined && (
                    <SubpageLink key={subpage.route} amount={usersAmount[subpage.role]} subpage={subpage} />
                  ),
              )
            : EMPLOYEES_SUBPAGES.slice(1).map(
                subpage =>
                  usersAmount[subpage.role] !== undefined && (
                    <SubpageLink key={subpage.route} amount={usersAmount[subpage.role]} subpage={subpage} />
                  ),
              )}
        </Slider>
      ) : (
        <div className="employees__skeleton-subpage">
          <Skeleton count={6} height={30} width={100} />
        </div>
      )}
      <div className={`employees__routes ${users.length > 0 ? '' : 'employees__routes--no-scroll'}`}>
        <Switch>
          {EMPLOYEES_ROUTES_ARR.map(({ path, shortcut, userRole }) => (
            <Route
              key={path}
              exact
              path={path}
              render={props => (
                <Subpage
                  {...props}
                  getUsers={getUsers}
                  path={path}
                  shortcut={shortcut}
                  userRole={userRole}
                  users={users}
                  usersStatus={usersStatus}
                />
              )}
            />
          ))}
          {EMPLOYEES_ROUTES_ARR.map(({ path, shortcut, userRole }) => (
            <Route
              key={`${path}/:uuid`}
              exact
              path={`${path}/:uuid`}
              render={props => (
                <Subpage
                  {...props}
                  getUsers={getUsers}
                  path={path}
                  shortcut={shortcut}
                  userRole={userRole}
                  users={users}
                  usersStatus={usersStatus}
                />
              )}
            />
          ))}
          <Redirect to={EMPLOYEES_DDI} />
        </Switch>
      </div>
    </PageContent>
  );
}

Employees.propTypes = {
  addUser: PropTypes.func.isRequired,
  authorizedUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  ddiOptions: PropTypes.array.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUsersAmount: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  usersAmount: PropTypes.object.isRequired,
  usersStatus: PropTypes.string.isRequired,
};

export default Employees;
