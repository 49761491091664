import { ACCESS_TYPES } from 'shared/consts';
import store from 'store/store';
import { getAuthorizedUser } from 'store/selectors/authSelector';

const getRoleAccessTypes = role => ACCESS_TYPES.get(role);

const getPositionAccessTypes = (map, position) => map?.get(position);

const checkAccess = (area, action) => {
  const { role, position } = getAuthorizedUser(store.getState());
  return getPositionAccessTypes(getRoleAccessTypes(role), position)?.get(area)?.includes(action) || false;
};

export default checkAccess;
