import * as Connector from 'shared/connectors/reportConnector';
import { REPORT } from 'store/types';

export const deleteSave = uuid => ({
  type: REPORT.DELETE_REPORTS_SAVE,
  payload: {
    uuid,
  },
});

const deleteReportSave = uuid => async dispatch => {
  try {
    await Connector.deleteSave(uuid);
    dispatch(deleteSave(uuid));
  } catch (err) {
    console.log(err);
  }
};

export default deleteReportSave;
