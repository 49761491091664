import * as Connector from 'shared/connectors/statusConnector';
import { STATUS } from 'store/types';
import { showPreloader, hidePreloader } from '../viewManagement';
import changeStatus from './changeStatus';

const init = () => ({
  type: STATUS.ADD_CONTRACTS_INIT,
});

export const success = data => ({
  type: STATUS.ADD_CONTRACTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: STATUS.ADD_CONTRACTS_FAILURE,
});

const addContracts = (data, files) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    if (files[0]) {
      const fileForm = new FormData();
      fileForm.append('file', files[0]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[0].type.split('/').pop().split('.')[0],
        file_name: files[0].name.split('.')[0],
      });
    }
    if (files[1]) {
      const fileForm = new FormData();
      fileForm.append('file', files[1]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[1].type.split('/').pop().split('.')[0],
        file_name: files[1].name.split('.')[0],
      });
    }

    if (files[2]) {
      const fileForm = new FormData();
      fileForm.append('file', files[2]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[2].type.split('/').pop().split('.')[0],
        file_name: files[2].name.split('.')[0],
      });
    }

    if (files[3]) {
      const fileForm = new FormData();
      fileForm.append('file', files[3]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[3].type.split('/').pop().split('.')[0],
        file_name: files[3].name.split('.')[0],
      });
    }

    if (files[4]) {
      const fileForm = new FormData();
      fileForm.append('file', files[4]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[4].type.split('/').pop().split('.')[0],
        file_name: files[4].name.split('.')[0],
      });
    }

    if (files[5]) {
      const fileForm = new FormData();
      fileForm.append('file', files[5]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[5].type.split('/').pop().split('.')[0],
        file_name: files[5].name.split('.')[0],
      });
    }

    if (files[6]) {
      const fileForm = new FormData();
      fileForm.append('file', files[6]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[6].type.split('/').pop().split('.')[0],
        file_name: files[6].name.split('.')[0],
      });
    }

    if (files[7]) {
      const fileForm = new FormData();
      fileForm.append('file', files[7]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[7].type.split('/').pop().split('.')[0],
        file_name: files[7].name.split('.')[0],
      });
    }

    if (files[8]) {
      const fileForm = new FormData();
      fileForm.append('file', files[8]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[8].type.split('/').pop().split('.')[0],
        file_name: files[8].name.split('.')[0],
      });
    }

    if (files[9]) {
      const fileForm = new FormData();
      fileForm.append('file', files[9]);
      await Connector.addContracts(fileForm, {
        status_uuid: data.status_uuid,
        file_format: files[9].type.split('/').pop().split('.')[0],
        file_name: files[9].name.split('.')[0],
      });
    }

    let dataModified;
    if (data.sell_status === 'sold') {
      dataModified = {
        ...data,
        sell_status: 'sold',
      };
    } else {
      dataModified = {
        ...data,
        sell_status: 'put_a_down_payment',
      };
    }
    await dispatch(changeStatus(dataModified, data.plot_uuid));
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
    // return err.response.data;
    return null;
  }
};

export default addContracts;
