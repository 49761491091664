import { useState } from 'react';

const useModal = initialValue => {
  const [value, setValue] = useState(Boolean(initialValue));

  const showModal = () => setValue(true);
  const hideModal = () => setValue(false);

  return [value, showModal, hideModal];
};

export default useModal;
