import React from 'react';
import { SVG_TYPE } from 'shared/consts';
import { SVG } from 'shared/components';
import PropTypes from 'prop-types';
import { SUBMENU_TYPES } from '../const';

const items = [
  {
    type: SUBMENU_TYPES.CONTACT,
    icon: SVG_TYPE.LIGHT_PHONE,
    activeIcon: SVG_TYPE.PHONE,
  },
  {
    type: SUBMENU_TYPES.MEETING,
    icon: SVG_TYPE.LIGHT_MEETING,
    activeIcon: SVG_TYPE.MEETING,
  },
  {
    type: SUBMENU_TYPES.NOTE,
    icon: SVG_TYPE.LIGHT_FILE,
    activeIcon: SVG_TYPE.LIGHT_FILE,
  },
];

function SubMenu({ submenuType, setSubmenuType }) {
  return (
    <div className="submenu">
      {items.map(({ icon, activeIcon, type }) => (
        <div className={`submenu__item submenu__item--${type === submenuType ? type : ''}`} onClick={() => setSubmenuType(type)}>
          <SVG type={type === submenuType ? activeIcon : icon} />
        </div>
      ))}
    </div>
  );
}

SubMenu.propTypes = {
  setSubmenuType: PropTypes.func.isRequired,
  submenuType: PropTypes.string.isRequired,
};

export default SubMenu;
