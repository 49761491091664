import API from 'shared/connectors/config';

export function getNotifications(params) {
  return API.get(`notifications${params ? `/?start_date=${params.startDate}&end_date=${params.endDate}` : ''}`);
}

// TO DO mine
export function getActiveDayNotifications(params) {
  return API.get(`notifications${params ? `/?start_date=${params.startDate}&end_date=${params.endDate}` : ''}`);
}

export function getSingleNotification(uuid) {
  return API.get(`notifications/show/${uuid}`);
}

export function addNotification(notificationData) {
  return API.post(`notifications`, notificationData);
}

export function arrangingTheAct(data) {
  return API.post(`notifications/arranging_the_act`, data);
}

export function changeStatus(status, uuid) {
  return API.put(`notifications/${uuid}`, status);
}

export function addFile(data, { file_format, file_name, notification_uuid }) {
  return API.post(
    `files?${
      notification_uuid ? `notification_uuid=${notification_uuid}` : ''
    }&file_format=${file_format}&file_name=${file_name}`,
    data,
  );
}

export function getEmployees() {
  return API.get(`/employees/list`);
}
