import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, PageContent, List, SelectField, Badge } from 'shared/components';
import 'assets/sass/screens/investors/investors.scss';
import { checkAccess } from 'shared/utils';
import { FORM_TYPES, SVG_TYPE, AREA_TYPES, ACTION_TYPES, ROUTES } from 'shared/consts';
import Skeleton from 'react-loading-skeleton';
import { Formik, Field } from 'formik';
import { generatePath } from 'react-router';
import AddInvestorForm from './components/AddInvestorForm';
import INVESTORS_ROUTES from './consts';

function Investors({
  usersDdi,
  investors,
  getInvestors,
  getAllDdi,
  getEmployees,
  addInvestor,
  isMobile,
  authorizedUserRole,
  allDdi,
  employees,
  getInvestorsByFilter,
  history,
  location: { pathname },
}) {
  useEffect(async () => {
    await getInvestors();
    await getAllDdi();
  }, []);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const isAddEnabled = checkAccess(AREA_TYPES.EMPLOYEES, ACTION_TYPES.ADD);
  const isAdmin = authorizedUserRole === 'admin';
  const isAdviser = authorizedUserRole === 'adviser';
  const uuid = pathname.split('/')[3];
  const handleFilteringInvestors = e => {
    e ? (history.push(generatePath(ROUTES.INVESTORS)), getInvestorsByFilter(e.value)) : getInvestors();
  };
  const hadnleFilterOptions = () =>
    allDdi.length > 0 &&
    allDdi.map(({ value, label }) => ({
      label: (
        <div className="select-field__option">
          <Badge label="DDI" type="DDI" />
          {label}
        </div>
      ),
      value,
      searchableLabel: label,
    }));

  return (
    <PageContent className="investors">
      {isModalOpened && (
        <AddInvestorForm
          onClose={() => setIsModalOpened(false)}
          onSubmit={addInvestor}
          opened={isModalOpened}
          type={FORM_TYPES.ADD}
          userRole={authorizedUserRole}
          usersDdi={usersDdi}
          getAllDdi={getAllDdi}
          getEmployees={getEmployees}
          employees={employees}
        />
      )}
      <div className="partners__header">
        <span className="investors__counter">
          {investors.length} <span className="investors__title">Inwestorzy</span>
        </span>
        <div className="select__filter--wrapper">
          <Formik
            initialValues={{
              filterBy: '',
            }}
          >
            {isAdmin && (
              <Field
                component={SelectField}
                field={{ name: 'filterBy' }}
                placeholder="Filtruj"
                fullWidth
                options={hadnleFilterOptions()}
                isCustomLabel
                onChange={handleFilteringInvestors}
              />
            )}
          </Formik>
          {(isAddEnabled || isAdviser) && (
            <Button
              classNames="transparent no-padding right-icon"
              iconType={SVG_TYPE.ADD}
              label="Dodaj"
              onClick={() => setIsModalOpened(true)}
            />
          )}
        </div>
      </div>
      <div className={`employees__routes ${investors.length > 0 ? '' : 'employees__routes--no-scroll'}`}>
        {investors.length > 0 ? (
          <div className="subpage">
            <List
              activeRow={uuid}
              area={AREA_TYPES.INVESTORS}
              data={investors}
              shortcut={INVESTORS_ROUTES.shortcut}
              path={ROUTES.INVESTORS}
              isMobile={isMobile}
            />
          </div>
        ) : (
          <>
            <Skeleton count={10} height={30} />
          </>
        )}
      </div>
    </PageContent>
  );
}

Investors.defaultProps = {
  match: {
    params: { uuid: '' },
  },
};
Investors.propTypes = {
  addInvestor: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  allDdi: PropTypes.array.isRequired,
  authorizedUserRole: PropTypes.string.isRequired,
  ddiOptions: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  getAllDdi: PropTypes.array.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getEmployees: PropTypes.func.isRequired,
  getInvestors: PropTypes.func.isRequired,
  getInvestorsByFilter: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUsersAmount: PropTypes.func.isRequired,
  investors: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }),
  partnersOptions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  usersAmount: PropTypes.object.isRequired,
  usersDdi: PropTypes.array.isRequired,
  usersStatus: PropTypes.string.isRequired,
};

export default Investors;
