import { connect } from 'react-redux';
import * as Actions from 'store/actions/notification';
import * as ViewAction from 'store/actions/viewManagement';
import { getNotifications } from 'store/selectors/notificationSelector';
import * as ViewSelectors from 'store/selectors/viewManagementSelector';
import Calendar from './Calendar.main';

const mapDispatchToProps = {
  getNotifications: () => Actions.getNotifications(),
  showNotificationDialog: () => ViewAction.showNotificationsDialog(),
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  notificationsDialogVisible: ViewSelectors.manageNotificationsDialog(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
