import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';
import { savePlotFilters } from 'store/actions/settlements';

const init = () => ({
  type: SETTLEMENTS.GET_PLOTS_INIT,
});

export const success = plots => ({
  type: SETTLEMENTS.GET_PLOTS_SUCCESS,
  payload: {
    plots,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_PLOTS_FAILURE,
});

const getPlots = params => async dispatch => {
  dispatch(init());
  try {
    console.log(params, 'pokaz kryteria');
    dispatch(savePlotFilters(params));
    const { data } = await Connector.getPlotsByFilter(params);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getPlots;
