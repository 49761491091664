import React from 'react';
import PropTypes from 'prop-types';

function Badge({ type, label, isMobile }) {
  return (
    <div className={`badge ${type ? `badge--${type}` : ''} ${isMobile ? `badge--mobile` : ''}`} title={label}>
      {isMobile ? <span className="badge--innerShadow" /> : label}
    </div>
  );
}
Badge.defaultProps = {
  isMobile: false,
};
Badge.propTypes = {
  isMobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Badge;
