// import React from 'react';
import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';
import { toast } from 'react-toastify';
// import { NewUser } from 'shared/components';
// import getInvestors from './getInvestors';
import { showPreloader, hidePreloader } from '../viewManagement';

export const success = data => ({
  type: SETTLEMENTS.ADD_SETTLEMENTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: SETTLEMENTS.ADD_SETTLEMENTS_FAILURE,
});

// const addInvestorRequestBuilder = investorData => {
//   const additionalInvestorInfo = Object.keys(investorData.investorAdditionalInformation).reduce(
//     (acc, current) => ({
//       ...acc,
//       [current]: investorData.investorAdditionalInformation[current] === true ? 1 : 0,
//     }),
//     {},
//   );
//   const rq = {
//     ...additionalInvestorInfo,
//     ...investorData,
//   };
//   delete rq.investorAdditionalInformation;
//   delete rq.investorCard;
//   return rq;
// };

const addSettlements = formData => async dispatch => {
  try {
    dispatch(showPreloader());
    const  data  = await Connector.addSettlement(formData);
    dispatch(hidePreloader());
    // dispatch(getSettlements());
    toast('Dodano osadę');
   dispatch(success(data));
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default addSettlements;
