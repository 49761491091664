import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as AuthActions from 'store/actions/auth';
import PropTypes from 'prop-types';
import { DashboardHeader, DashboardSidebar, DashboardBottomMenu, RoleRoute } from 'shared/components';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/consts';
import { isMobile as checkIsMobile } from 'shared/utils';
import Start from 'screens/start/Start.container';
import Settlements from 'screens/settlements/Settlements.container';
import AllPlots from 'screens/allPlots/AllPlots.container';
import Employees from 'screens/employees/Employees.container';
import Partners from 'screens/partners/Partners.container';
import Calendar from 'screens/calendar/Calendar.container';
import Investors from 'screens/investors/Investors.container';
import ArchivedEmployees from 'screens/archived-employees/ArchivedEmployees.container';
import ArchivedPartners from 'screens/archived-partners/ArchivedPartners.container';
import ArchivedInvestors from 'screens/archived-investors/ArchivedInvestors.container';
import { ToastContainer } from 'react-toastify';
import ActiveWindow from 'screens/active-window/ActiveWindow.container';
import * as ViewSelector from 'store/selectors/viewManagementSelector';
import * as Actions from 'store/actions/viewManagement';
import { getAuthorizedUser } from '../store/selectors/authSelector';
import Reports from './reports/Reports.container';
import DashboardSearch from './dashboard-search/DashboardSearch.container';
import ArchivedSettlements from './archived-settlements/ArchivedSettlements.container';

class DashboardRoutes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSearchView: false,
      userSearchInput: '',
      userSearchRequest: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen(() => {
      this.setState({ isSearchView: false, userSearchInput: '' });
    });
  }

  render() {
    const {
      authorizedUser,
      logout,
      isMobile,
      notificationsDialogVisible,
      manageNotificationsDialog,
      location: { pathname },
    } = this.props;
    const {
      START,
      SETTLEMENTS,
      INVESTORS,
      EMPLOYEES,
      PARTNERS,
      CALENDAR,
      ARCHIVED_EMPLOYEES,
      ARCHIVED_PARTNERS,
      PLOTS,
      ARCHIVED_INVESTORS,
      ARCHIVED_SETTLEMENTS,
      REPORTS,
    } = ROUTES;

    const fullWidth = pathname === ROUTES.REPORTS || pathname === ROUTES.START;

    let timer;
    const search = () => {
      const { userSearchInput } = this.state;
      this.setState({ userSearchRequest: userSearchInput });
    };

    const handleSearchView = e => {
      this.setState({ isSearchView: true, userSearchRequest: '' });
      if (e.target.value.length === 0) {
        this.setState({ isSearchView: false, userSearchInput: '' });
      } else {
        this.setState({ userSearchInput: e.target.value });
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        search();
      }, 2000);
    };

    const handleCloseSearchView = () => {
      this.setState({ isSearchView: false, userSearchInput: '' });
    };

    if (authorizedUser) {
      const { role } = authorizedUser;

      return (
        <div className="dashboard-routes">
          <ToastContainer autoClose={5000} closeOnClick={false} draggablePercent={40} hideProgressBar position="bottom-right" />
          <div className={`dashboard-routes__left ${fullWidth ? 'dashboard-routes__left--full-width' : ''}`}>
            <DashboardHeader
              manageNotificationsDialog={manageNotificationsDialog}
              notificationsDialogVisible={notificationsDialogVisible}
              authorizedUser={authorizedUser}
              isMobile={isMobile}
              logout={logout}
              handleSearchView={handleSearchView}
              userSearchInput={this.state.userSearchInput}
              closeSearchView={handleCloseSearchView}
            />
            <div className="dashboard-routes__wrapper">
              {!isMobile && <DashboardSidebar authorizedUserRole={role} />}
              <div className="dashboard-routes__page-content">
                {this.state.isSearchView ? (
                  <DashboardSearch
                    userSearchInput={this.state.userSearchInput}
                    closeSearchView={handleCloseSearchView}
                    userSearchRequest={this.state.userSearchRequest}
                  />
                ) : (
                  <Switch>
                    <Route component={Start} path={START} />
                    <Route component={Settlements} path={SETTLEMENTS} />
                    <Route component={AllPlots} exact path={PLOTS} />
                    <RoleRoute authorizedUserRole={role} component={Employees} path={EMPLOYEES} />
                    <RoleRoute authorizedUserRole={role} component={Partners} path={PARTNERS} />
                    <RoleRoute authorizedUserRole={role} component={ArchivedEmployees} path={ARCHIVED_EMPLOYEES} />
                    <RoleRoute authorizedUserRole={role} component={ArchivedInvestors} path={ARCHIVED_INVESTORS} />
                    <RoleRoute authorizedUserRole={role} component={ArchivedSettlements} path={ARCHIVED_SETTLEMENTS} />
                    <RoleRoute authorizedUserRole={role} component={ArchivedPartners} path={ARCHIVED_PARTNERS} />
                    <RoleRoute authorizedUserRole={role} component={Investors} path={INVESTORS} />
                    <Route component={Calendar} path={CALENDAR} />
                    <RoleRoute authorizedUserRole={role} component={Reports} path={REPORTS} />
                    <Route component={Start} />
                  </Switch>
                )}
              </div>
            </div>
          </div>
          {/* Right active window with model details depends on url with uuid */}
          {!fullWidth && <ActiveWindow authorizedUserRole={role} />}
          {isMobile && <DashboardBottomMenu authorizedUserRole={role} />}
        </div>
      );
    }
    return null;
  }
}

DashboardRoutes.defaultProps = {
  authorizedUser: null,
};

DashboardRoutes.propTypes = {
  authorizedUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  manageNotificationsDialog: PropTypes.func.isRequired,
  notificationsDialogVisible: PropTypes.bool.isRequired,
};
const mapDispatchToProps = {
  logout: AuthActions.logout,
  manageNotificationsDialog: Actions.manageNotificationsDialog,
};

function mapStateToProps(state) {
  return {
    authorizedUser: getAuthorizedUser(state),
    isMobile: checkIsMobile(),
    notificationsDialogVisible: ViewSelector.manageNotificationsDialog(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoutes);
