import { string } from 'prop-types';
import React from 'react';
import { number, date } from 'yup';
import moment from 'moment';
import HorizontalStatusBar from './HorizontalStatusBar';

const STATUS_BAR_COLOR = {
  GREEN: 'green',
  ORANGE: 'orange',
  BLACK: 'black',
  RED: 'red',
};

const STATUS_BAR = {
  FREE: 'Dostępna',
  RESERVATION: 'Rezerwacja wstępna',
  PENDING_CASHOUTS: 'Oczekuje na wpłatę zadatku',
  SIGNED_CONTRACT: 'Rezerwacja / Umowa podpisana',
  BOUGHT: 'Sprzedano',
};

const STATUS_NUMBER = {
  FREE: 1,
  RESERVATION: 2,
  PENDING_CASHOUTS: 3,
  SIGNED_CONTRACT: 4,
  BOUGHT: 5,
};

const STATUSES = {
  [STATUS_BAR.FREE]: STATUS_BAR_COLOR.GREEN,
  [STATUS_BAR.RESERVATION]: STATUS_BAR_COLOR.ORANGE,
  [STATUS_BAR.PENDING_CASHOUTS]: STATUS_BAR_COLOR.ORANGE,
  [STATUS_BAR.SIGNED_CONTRACT]: STATUS_BAR_COLOR.RED,
  [STATUS_BAR.BOUGHT]: STATUS_BAR_COLOR.BLACK,
};

const StatusHeader = ({ status, dates, daysFromStatusChange }) => {
  const isFree = status === 'free';
  return (
    <>
      <div className="statusBar__wrapper">
        {/* {console.log(status)} */}
        <div className="statusBar">
          <div className="statusBar__statusInfo">
            <p className="statusBar__statusInfo--const">Status:</p>
            <p className={`statusBar__statusInfo--${status && STATUSES[STATUS_BAR[status.toUpperCase()]]}`}>
              {status && STATUS_BAR[status.toUpperCase()]}
            </p>
          </div>
        </div>
        <HorizontalStatusBar status={status && STATUS_NUMBER[status.toUpperCase()]} />
        <div className="statusBar__data">
          {!isFree && (
            <>
              <p>{moment(dates).format('DD.MM.YYYY')}</p>
              <p>{daysFromStatusChange} dni temu</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

StatusHeader.propTypes = {
  dates: date.isRequired,
  daysFromStatusChange: number.isRequired,
  status: string.isRequired,
};

export default StatusHeader;
