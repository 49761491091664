import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'shared/consts';

function Switch() {
  return (
    <div className="dashboard-header__switch">
      <NavLink
        activeClassName="dashboard-header__switch-button--active"
        className="dashboard-header__switch-button"
        to={ROUTES.SETTLEMENTS}
      >
        Osady
      </NavLink>
      <NavLink
        activeClassName="dashboard-header__switch-button--active"
        className="dashboard-header__switch-button "
        to={ROUTES.PLOTS}
      >
        Działki
      </NavLink>
    </div>
  );
}

export default Switch;
