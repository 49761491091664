import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: USER.RESEND_ACTIVATION_EMAIL_INIT,
});

export const success = () => ({
  type: USER.RESEND_ACTIVATION_EMAIL_SUCCESS,
});

const failure = () => ({
  type: USER.RESEND_ACTIVATION_EMAIL_FAILURE,
});

const resendActivationEmail = uuid => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    await Connector.resendActivationEmail(uuid);
    dispatch(success());
    dispatch(hidePreloader());
    toast('Pomyślnie wysłano link aktywacyjny');
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    toast('Nie udało się wysłać');
  }
};

export default resendActivationEmail;
