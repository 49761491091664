/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';

function Tile({ field: { name, value, onChange }, label, status, icon, customLabel }) {
  return (
    <div className="filters__tile">
      {status ? (
        <i className={`filters__tile--point filters__tile--point--${status}`} />
      ) : (
        <SVG className="filters__tile--icon" type={icon} />
      )}
      <p className={`filters__tile--label filters__tile--label${customLabel && '--customLabel'}`}>{label}</p>
      <input
        id={`checkbox-${name}`}
        className="filters--checkbox"
        name={name}
        onChange={onChange}
        value={value}
        type="checkbox"
        checked={value}
      />
      <label htmlFor={`checkbox-${name}`} className="filters--checkbox--label" />
    </div>
  );
}

Tile.defaultProps = {
  status: null,
};

Tile.propTypes = {
  customLabel: PropTypes.bool.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string,
};

export default Tile;
