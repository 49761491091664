import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { USER_ROLES, ROUTES, AREA_TYPES } from 'shared/consts';
// import List from '../list';
import { List } from 'shared/components';

const { DDI, DIR, DP, ADMIN, ADMINISTRATION, ADVISER, ACCOUNTING, MARKETING } = USER_ROLES;
const {
  EMPLOYEES_DDI,
  EMPLOYEES_DIR,
  EMPLOYEES_DP,
  EMPLOYEES_ADMIN,
  EMPLOYEES_ADMINISTRATION,
  EMPLOYEES_ADVISER,
  EMPLOYEES_ACCOUNTING,
  EMPLOYEES_MARKETING,
} = ROUTES;

function Subpage({
  users,
  getUsers,
  match: {
    params: { uuid },
  },
  shortcut,
  userRole,
  path,
  usersStatus,
}) {
  useEffect(async () => {
    if (!uuid || !users.length) await getUsers(userRole);
  }, [userRole]);

  return (
    <div className="subpage">
      <List
        activeRow={uuid}
        area={AREA_TYPES.EMPLOYEES}
        data={users}
        dataStatus={usersStatus}
        path={path}
        shortcut={shortcut}
      />
    </div>
  );
}
Subpage.defaultProps = {
  match: {
    params: { uuid: '' },
  },
};

Subpage.propTypes = {
  getUsers: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }),
  path: PropTypes.oneOf([
    EMPLOYEES_DDI,
    EMPLOYEES_DIR,
    EMPLOYEES_DP,
    EMPLOYEES_ADMIN,
    EMPLOYEES_ADMINISTRATION,
    EMPLOYEES_ADVISER,
    EMPLOYEES_ACCOUNTING,
    EMPLOYEES_MARKETING,
  ]).isRequired,
  shortcut: PropTypes.string.isRequired,
  userRole: PropTypes.oneOf([DDI, DIR, DP, ADMIN, ADMINISTRATION, ADVISER, ACCOUNTING, MARKETING]).isRequired,
  users: PropTypes.array.isRequired,
  usersStatus: PropTypes.string.isRequired,
};

export default Subpage;
