import * as React from 'react';
import PropTypes from 'prop-types';
import { FIELD_NAMES } from 'shared/consts';
import Label from './components/label';

const TextAreaField = ({ field: { name, onChange, value }, field, form, errors, placeholder, label, fullWidth, className }) => {
  const isError = !!(form.touched[name] && errors[name]);
  const fieldClasses = `textarea-field__textarea ${isError ? 'textarea-field__textarea--error' : ''} ${className}`;
  return (
    <div className="textarea-field">
      <div className={`textarea-field__container ${fullWidth ? 'textarea-field__container--full-width' : ''}`}>
        <Label errors={errors} field={field} isError={isError} label={label} />
        <div className={fieldClasses}>
          <textarea
            autoComplete={name === FIELD_NAMES.PASSWORD ? 'on' : 'new-password'}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          />
        </div>
      </div>
    </div>
  );
};
TextAreaField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

TextAreaField.defaultProps = {
  className: '',
  fullWidth: false,
  placeholder: '',
};

export default TextAreaField;
