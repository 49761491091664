import { createSelector } from 'reselect';

const getViewManagementStore = state => state.viewManagementStore;
const getIsHomePagePreloaderVisible = createSelector(
  getViewManagementStore,
  ({ isHomePagePreloaderVisible }) => isHomePagePreloaderVisible,
);
const getPrevPath = createSelector(getViewManagementStore, ({ prevPath }) => prevPath);
const getIsPreloaderVisible = createSelector(getViewManagementStore, ({ isPreloaderVisible }) => isPreloaderVisible);
const manageNotificationsDialog = createSelector(
  getViewManagementStore,
  ({ isNotificationsDialogVisible }) => isNotificationsDialogVisible,
);

export { getIsHomePagePreloaderVisible, getPrevPath, getIsPreloaderVisible, manageNotificationsDialog };
