import { SEARCH } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  items: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const searchReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case SEARCH.SEARCH_ITEMS_INIT: {
      return {
        ...state,
        items: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case SEARCH.SEARCH_ITEMS_SUCCESS: {
      return {
        ...state,
        items: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case SEARCH.SEARCH_ITEMS_FAILURE: {
      return {
        ...state,
        items: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
