import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions, UserBar, FieldFileInput, RegularField, InputComparison } from 'shared/components';
import { MODAL_TYPES, VALIDATION_MESSAGES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Formik, Field } from 'formik';
import { object, string } from 'yup';

function PayForPlotModal({ onClose, opened, title, onSubmit, plot }) {
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const isMobileDevice = isMobile();
  const [fileInputValue, setFileInputValue] = useState(null);
  const [amountToLarge, setAmountToLarge] = useState(null);
  const addOfferSchema = () =>
    object().shape({
      amount: string().required(REQUIRED_FIELD),
      file: string().required(REQUIRED_FIELD),
    });

  const handleFormSubmit = values => {
    onSubmit(values, fileInputValue);
  };
  const handleFileChange = e => {
    setFileInputValue(e.target.files[0]);
  };

  const handleAmountChange = e => {
    e.target.value > plot?.price - (plot?.order_status?.payed_deposit + plot?.order_status?.payed_price)
      ? setAmountToLarge(true)
      : setAmountToLarge(null);
  };

  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
            <Formik
              enableReinitialize
              initialValues={{
                file: '',
                type: 'price',
                plot_uuid: plot?.uuid,
                amount: '',
              }}
              validationSchema={addOfferSchema()}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, errors }) => (
                <form className="modal__form " onSubmit={handleSubmit}>
                  <UserBar
                    type="INWST"
                    name={`${plot?.order_status?.investor?.first_name} ${plot?.order_status?.investor?.last_name}`}
                    phone={plot?.order_status?.investor?.phone}
                  />
                  <div className="modal__addDeposit">
                    <Field
                      component={RegularField}
                      errors={errors}
                      label="Wpisz kwotę wpłaty"
                      name="amount"
                      type="text"
                      inputOnChange={handleAmountChange}
                    />
                    <InputComparison
                      label="Wpłacona kwota"
                      first={(plot?.order_status?.payed_deposit + plot?.order_status?.payed_price)
                        .toFixed()
                        .toString()
                        .replace(/.{3}$/, ' $&')}
                      second={plot?.order_status?.offer?.price?.toFixed().toString().replace(/.{3}$/, ' $&')}
                    />
                    {amountToLarge && <span className="modal__addDeposit--error">Wprowadzono za duzą kwotę</span>}
                  </div>
                  <div className="modal__addDeposit">
                    <div className="modal__addDeposit--info modal__addDeposit--info-first">
                      <p>zadatek:</p>
                      <span>
                        {plot?.order_status?.offer?.deposit_diff_joint_payment.toFixed().toString().replace(/.{3}$/, ' $&')} zł
                      </span>
                    </div>
                    <div className="modal__addDeposit--info modal__addDeposit--info-second">
                      <p>części wspólne:</p>
                      <span>{plot?.settlement?.joint_payment.toFixed().toString().replace(/.{3}$/, ' $&')} zł</span>
                    </div>
                  </div>
                  <p className="modal__form--section-name">Dołącz dokument</p>
                  <Field
                    component={FieldFileInput}
                    handleChange={handleFileChange}
                    errors={errors}
                    name="file"
                    type="file"
                    label="+ Dodaj potwierdzenie wpłaty"
                  />
                  {amountToLarge ? (
                    <ModalActions onClose={onClose} type={MODAL_TYPES.INACTIVE} />
                  ) : (
                    <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.ADD_FILE_OFFER} />
                  )}
                </form>
              )}
            </Formik>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

PayForPlotModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  plot: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default PayForPlotModal;
