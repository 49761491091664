import { string, arrayOf, func, shape, bool, object, array } from 'prop-types';
import * as React from 'react';
import { SVG, ActiveWindow } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { PLOTS_SUBMENU, PLOTS_SUBMENU_ARR } from 'shared/components/active-window/consts';
import PlotList from './PlotsList';
import ManageDocs from './ManageDocs';
import SettlementName from './SettlementName';
import Map from '../map';

const PlotsById = ({
  isMobile,
  name,
  setActiveMenu,
  activeMenu,
  plots,
  openModal,
  handleMapOpening,
  isMapOpen,
  settlementName,
  isSetMapOpen,
  settlementId,
  history,
  authorizedUser,
  handleAddFilesModalOpen,
  remove,
}) => (
  <div className="settlement-plots employees-right">
    <div className="employees-right__header">
      <div className="settlement-plots--icon">
        <div className="svg-square-wrapper svg-square-wrapper--white" onClick={() => handleMapOpening(name)}>
          <SVG type={SVG_TYPE.MAP} className={isMapOpen && 'svg--map-checked'} />
        </div>
      </div>
      <SettlementName handleMapOpening={handleMapOpening} isMapOpen={isMapOpen} isMobile={isMobile} name={name} />
      <ActiveWindow.SubMenu
        isSetMapOpen={isSetMapOpen}
        menu={PLOTS_SUBMENU_ARR}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
      />
    </div>
    {isMapOpen && isMobile && (
      <Map
        settlementId={settlementId}
        settlementName={settlementName}
        isMobile={isMobile}
        openModal={openModal}
        onClose={handleMapOpening}
        plots={plots}
        history={history}
        authorizedUser={authorizedUser}
      />
    )}
    {activeMenu === PLOTS_SUBMENU.PLOTS && (
      <div className="settlement-plots__content employees-right__wrapper">
        <PlotList isMobile={isMobile} openModal={openModal} plots={plots} authorizedUser={authorizedUser} />
      </div>
    )}
    {activeMenu === PLOTS_SUBMENU.DOCS && (
      <div className="settlement-plots__content employees-right__wrapper">
        <ManageDocs openModal={handleAddFilesModalOpen} plots={plots} remove={remove} authorizedUser={authorizedUser} />
      </div>
    )}
  </div>
);

PlotsById.propTypes = {
  activeMenu: string.isRequired,
  authorizedUser: array.isRequired,
  handleAddFilesModalOpen: func.isRequired,
  handleMapOpening: func.isRequired,
  history: object.isRequired,
  isMapOpen: bool.isRequired,
  isMobile: bool.isRequired,
  isSetMapOpen: func.isRequired,
  name: string.isRequired,
  openModal: func.isRequired,
  plots: arrayOf(shape({})).isRequired,
  remove: func.isRequired,
  setActiveMenu: func.isRequired,
  setSettlementName: func.isRequired,
  settlementId: string.isRequired,
  settlementName: bool.isRequired,
};
export default PlotsById;
