import React from 'react';
import { NavLink } from 'react-router-dom';
import { SVG } from 'shared/components';
import { MENU_ITEMS, PROTECTED_ROUTES, ROUTES } from 'shared/consts';
import { string } from 'prop-types';

function DashboardBottomMenu({ authorizedUserRole }) {
  return (
    <div className="dashboard-bottom-menu">
      {MENU_ITEMS.map(({ svgType, label, route }) => {
        // if (PROTECTED_ROUTES.get(route)?.includes(authorizedUserRole)) {
        if (route !== ROUTES.REPORTS && PROTECTED_ROUTES.get(route)?.includes(authorizedUserRole)) {
          return (
            <NavLink
              key={label}
              activeClassName="dashboard-bottom-menu__item--active"
              className="dashboard-bottom-menu__item"
              to={route}
            >
              <div className="dashboard-bottom-menu__icon">
                <SVG type={svgType} />
              </div>
              <div className="dashboard-bottom-menu__label">{label}</div>
            </NavLink>
          );
        }
        return null;
      })}
    </div>
  );
}

DashboardBottomMenu.propTypes = {
  authorizedUserRole: string.isRequired,
};

export default DashboardBottomMenu;
