import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getSettlements as getSettlementsSelector,
  getSecondarySettlements as getSecondarySettlementsSelector,
  getPlotDetails as getPlotDetailsSelector,
} from 'store/selectors/settlementsSelector';
import { getUsers as getUsersSelector, getAdvisersList } from 'store/selectors/userSelector';
import { getPlotDetails, getPlotsBySettlementId, saveFiles, deleteFile } from 'store/actions/settlements';
import { changeStatus } from 'store/actions/status';
import { isMobile as checkIsMobile } from 'shared/utils';
import { getAuthorizedUser as getAuthorizedUserSelector } from 'store/selectors/authSelector';
import * as Actions from 'store/actions/user';
import { getInvestorsLabel } from 'store/actions/investors';
import { getInvestorsLabel as getInvestorsLabelSelector } from 'store/selectors/investorSelector';
import { getAcceptedOffers as getAcceptedOffersSelector } from 'store/selectors/offerSelector';
import { getAcceptedOffers } from 'store/actions/offer';
import Main from './SettlementPlots.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsers: role => Actions.getUsers(role),
      getPlotDetails: id => getPlotDetails(id),
      getInvestorsLabel: id => getInvestorsLabel(id),
      changeStatus: (data, plot_uuid) => changeStatus(data, plot_uuid),
      getAcceptedOffers: data => getAcceptedOffers(data),
      getPlotsBySettlementId: id => getPlotsBySettlementId(id),
      saveFiles: (values, files) => saveFiles(values, files),
      deleteFile: (fileUuid, settlementUuid) => deleteFile(fileUuid, settlementUuid),
      getAdvisersList: uuid => Actions.getAdvisersList(uuid),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  users: getUsersSelector(state),
  settlements: getSettlementsSelector(state),
  plot: getPlotDetailsSelector(state),
  secondarySettlements: getSecondarySettlementsSelector(state),
  isMobile: checkIsMobile(),
  authorizedUser: getAuthorizedUserSelector(state),
  investorsLabel: getInvestorsLabelSelector(state),
  acceptedOffers: getAcceptedOffersSelector(state),
  advisersList: getAdvisersList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
