import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonItem = () => (
  <div className="list list--skeleton">
    <div className="list__skeleton-item">
      <Skeleton count={20} height={52} />
    </div>
  </div>
);

export default SkeletonItem;
