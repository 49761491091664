import { connect } from 'react-redux';
import { Actions } from 'modules/authModule';
import { activateAccount, sendResetPasswordEmail, setNewPassword } from 'store/actions/user';
import { getUserToVerify } from 'store/selectors/authSelector';
import { getIsHomePagePreloaderVisible } from 'store/selectors/viewManagementSelector';
import HomePage from './HomePage.main';

const mapDispatchToProps = {
  login: data => Actions.login(data),
  activateAccount: data => activateAccount(data),
  verifyCode: code => Actions.verifyCode(code),
  sendResetPasswordEmail: email => sendResetPasswordEmail(email),
  setNewPassword: data => setNewPassword(data),
};

const mapStateToProps = state => ({
  userToVerify: getUserToVerify(state),
  isHomePagePreloaderVisible: getIsHomePagePreloaderVisible(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
