import { ROUTES } from 'shared/consts';

const GROUP_ARR = [
  {
    label: 'Konto',
    items: [
      { label: 'Profil', path: '#' },
      { label: 'Zmień hasło', path: '#' },
      { label: 'Powiadomienia', path: '#' },
    ],
  },
  {
    label: 'Zarządzaj',
    items: [
      { label: 'Ustawienia', path: '#' },
      // { label: 'Lista Partnerów', path: ROUTES.PARTNERS },
    ],
  },
  {
    label: 'Zarchiwizowane',
    items: [
      { label: 'Użytkownicy', path: ROUTES.ARCHIVED_EMPLOYEES },
      { label: 'Partnerzy', path: ROUTES.ARCHIVED_PARTNERS },
      { label: 'Inwestorzy', path: ROUTES.ARCHIVED_INVESTORS },
      { label: 'Lista osad', path: ROUTES.ARCHIVED_SETTLEMENTS },
    ],
  },
];

export { GROUP_ARR };
