import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SVG_TYPE, MODAL_TYPES } from 'shared/consts';
import { SVG, DeleteModal, ShowModal } from 'shared/components';
import moment from 'moment';

function SingleNews({
  news: { title, text, first_name, last_name, uuid, admin, created_at },
  deleteNews,
  handleOpenModal,
  role,
  position,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [showModalOpen, setShowModalOpen] = useState(null);
  const closeModal = () => setDeleteModalOpen(null);
  const closeShowModal = () => setShowModalOpen(null);
  const handleDelete = () => {
    deleteNews(uuid);
    setDeleteModalOpen(null);
  };
  const current_date = new Date();
  const today = moment(current_date).format('DD.MM');
  const date = moment(created_at).format('DD.MM');
  const time = moment(created_at).format('HH:MM');

  return (
    <div className="single-news">
      <div className="single-news__box" onClick={() => setShowModalOpen(MODAL_TYPES.SHOW)}>
        <div className="single-news__title">{title}</div>
        <div className="single-news__description">{text}</div>
      </div>
      <div className="single-news__information">
        <div className="single-news__user">
          {first_name} {last_name}
        </div>
        <div className="single-news__user">
          {admin.first_name} {admin.last_name}
        </div>
        {date === today ? (
          <div className="single-news__date">Dzisiaj, {time}</div>
        ) : (
          <div className="single-news__date">{date}</div>
        )}
        {role === 'admin' && position === 'director' ? (
          <>
            <div className="single-news__remove" onClick={() => setDeleteModalOpen(MODAL_TYPES.DELETE)}>
              <SVG type={SVG_TYPE.EXIT} />
            </div>
            <div className="single-news__edit" onClick={() => handleOpenModal(uuid, title, text)}>
              Edytuj
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <DeleteModal
        title="Czy chcesz usunąć aktualność?"
        onClose={closeModal}
        onSubmit={handleDelete}
        opened={deleteModalOpen === MODAL_TYPES.DELETE}
      >
        <div>{title}</div>
      </DeleteModal>
      <ShowModal title={title} onClose={closeShowModal} opened={showModalOpen === MODAL_TYPES.SHOW}>
        <div>{text}</div>
      </ShowModal>
    </div>
  );
}

SingleNews.propTypes = {
  deleteNews: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  news: PropTypes.object.isRequired,
  position: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default SingleNews;
