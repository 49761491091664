import * as Connector from 'shared/connectors/offerConnector';
import { OFFER } from 'store/types';
import { USER_ROLES } from 'shared/consts';

const init = () => ({
  type: OFFER.GET_WAITING_OFFERS_INIT,
});

export const success = data => ({
  type: OFFER.GET_WAITING_OFFERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: OFFER.GET_WAITING_OFFERS_FAILURE,
});

const getWaitingOffers = ({ userRole, plotUuid, ddiUuid }) => async dispatch => {
  dispatch(init());
  try {
    let data;
    if (userRole === USER_ROLES.ADMIN) {
      data = await Connector.getAllWaitingOffer(plotUuid);
    } else if (userRole === USER_ROLES.DDI) {
      data = await Connector.getDdiWaitingOffer(plotUuid, ddiUuid);
    }
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getWaitingOffers;
