import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

const NotificationList = ({ notifications, closeSearchView }) => (
  <div>
    {notifications?.map(notification => (
      <ListItem notification={notification} closeSearchView={closeSearchView} />
    ))}
  </div>
);

export default NotificationList;

NotificationList.propTypes = {
  closeSearchView: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};
