import { connect } from 'react-redux';
import * as Actions from 'store/actions/search';
import { searchItems, searchStatus } from 'store/selectors/searchSelector';
import DashboardSearch from './DashboardSearch.main';

const mapDispatchToProps = {
  searchItems: params => Actions.searchItems(params),
};

const mapStateToProps = state => ({
  items: searchItems(state),
  status: searchStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSearch);
