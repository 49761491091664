import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import Settings from 'screens/settings/Settings.main';
import Filters from 'screens/filters/Filters.container';
import Notifications from 'screens/notifications/Notifications.container';
import { UserInfo, Search, Switch, SearchMobile, Filter } from './components';

function DashboardHeader({
  manageNotificationsDialog,
  notificationsDialogVisible,
  authorizedUser: { first_name, last_name, role },
  logout,
  isMobile,
  handleSearchView,
  userSearchInput,
  closeSearchView,
}) {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const handleSettingsClick = () => setIsSettingsOpened(prevState => !prevState);
  const handleFilterClick = () => setIsFilterOpened(prevState => !prevState);
  const handleNotificationsClick = () => manageNotificationsDialog();
  const isDesktop = !isMobile;
  const {
    location: { pathname },
  } = window;
  const isCalendarView = pathname.includes(ROUTES.CALENDAR) && isMobile;
  const isSettlementsView = (pathname === ROUTES.SETTLEMENTS && isMobile) || (pathname === ROUTES.PLOTS && isMobile);
  const isOtherView = !isCalendarView && !isSettlementsView;
  return (
    <>
      {isSettlementsView && (
        <div className="dashboard-header dashboard-header--mobile">
          <SearchMobile />
          <Switch />
          <Filter onClick={handleFilterClick} />
          <Filters isOpened={isFilterOpened} onClose={handleFilterClick} />
        </div>
      )}

      {isCalendarView && (
        <div className="dashboard-header dashboard-header--mobile">
          <SearchMobile />
          <div className="btnsWrapper">
            <div className="dashboard-header__action-icon" onClick={handleNotificationsClick}>
              <SVG type={SVG_TYPE.ADD_NOTIFICATION} className={!notificationsDialogVisible && 'inActive'} />
            </div>
            <div className="dashboard-header__action-icon" onClick={handleSettingsClick}>
              <SVG type={SVG_TYPE.SETTINGS} />
            </div>
          </div>
          <Settings isOpened={isSettingsOpened} onClose={handleSettingsClick} logout={logout} authorizedUserRole={role} />
          {notificationsDialogVisible && <Route component={Notifications} exact path={ROUTES.CALENDAR_START_END} />}
        </div>
      )}

      {isOtherView && (
        <div className="dashboard-header">
          {isDesktop && <UserInfo firstName={first_name} lastName={last_name} />}
          <div className="dashboard-header__right">
            {isDesktop && (
              <Search handleSearchView={handleSearchView} userSearchInput={userSearchInput} closeSearchView={closeSearchView} />
            )}
            <div className="dashboard-header__actions">
              <div className="dashboard-header__action-icon" onClick={handleSettingsClick}>
                <SVG type={SVG_TYPE.SETTINGS} />
              </div>
            </div>
          </div>
          <Settings isOpened={isSettingsOpened} onClose={handleSettingsClick} logout={logout} authorizedUserRole={role} />
        </div>
      )}
    </>
  );
}

DashboardHeader.propTypes = {
  authorizedUser: PropTypes.object.isRequired,
  closeSearchView: PropTypes.func.isRequired,
  handleSearchView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  manageNotificationsDialog: PropTypes.func.isRequired,
  notificationsDialogVisible: PropTypes.func.isRequired,
  userSearchInput: PropTypes.string.isRequired,
};

export default DashboardHeader;
