import React from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES, VALIDATION_MESSAGES } from 'shared/consts';
import { Modal, ModalActions, RegularField, UserBar, OfferBar } from 'shared/components';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';

const AcceptanceOfferModal = ({ opened, children, onClose, title, offerData, offerChangeStatus, plotUuid, userRole }) => {
  const isMobileDevice = isMobile();

  const formSchema = () =>
    object().shape({
      admin_comment: string().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    });

  const handleSubmitForm = values => {
    offerChangeStatus({
      offerUuid: offerData?.uuid,
      acceptance: 'yes',
      plotUuid,
      admin_comment: values.admin_comment,
      userRole,
    });
  };

  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
            {children}
            <div className="acceptOffer">
              <UserBar type="INWST" name={`${offerData?.investor?.first_name} ${offerData?.investor?.last_name}`} />
              <UserBar
                type="DDI"
                name={`${offerData?.ddi?.first_name} ${offerData?.ddi?.last_name}`}
                phone={offerData?.ddi?.phone}
              />

              <div className="acceptOffer__comment">{offerData?.comment}</div>

              <OfferBar
                SettlementName={offerData?.plot?.settlement?.name}
                plotNumber={offerData?.plot?.name}
                size={offerData?.plot?.plot_size}
                priceOld={offerData?.plot?.price}
                priceNew={offerData?.price}
                percentageReduction={offerData?.percentage_reduction}
              />
              <Formik
                enableReinitialize
                initialValues={{
                  admin_comment: '',
                }}
                validationSchema={formSchema()}
                onSubmit={values => {
                  handleSubmitForm(values);
                  onClose();
                }}
              >
                {({ handleSubmit, errors }) => (
                  <form className="modal__form addOfferModal" onSubmit={handleSubmit}>
                    <div className="addOfferModal__header--comment">
                      <Field component={RegularField} errors={errors} name="admin_comment" type="textarea" />
                    </div>
                    <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.ACCEPT_OFFER} />
                  </form>
                )}
              </Formik>
            </div>
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

AcceptanceOfferModal.propTypes = {
  children: PropTypes.any.isRequired,
  offerChangeStatus: PropTypes.object.isRequired,
  offerData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.func.isRequired,
  plotUuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default AcceptanceOfferModal;
