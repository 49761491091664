const VALIDATION_MESSAGES = {
  EMAIL: 'niepoprawny email',
  UNIQUE_EMAIL: 'podany email już istnieje',
  REQUIRED_FIELD: 'pole wymagane',
  WRONG_EMAIL: 'błędny email',
  WRONG_PASSWORD: 'błędne hasło',
  SAME_PASSWORD: 'niepoprawne hasło',
  SAME_PHONE_NUMBER: 'podany numer jest zajęty',
  WRONG_NIP: 'niepoprawny NIP',
  WRONG_REGON: 'niepoprawny REGON',
  REQUIRED_9_DIGITS: 'wymagane 9 cyfr',
  UPLOAD_2_FILES: 'wymagane 2 pliki',
};

export default VALIDATION_MESSAGES;
