import React from 'react';
import Modal from 'shared/components/modal/Modal';
import { MODAL_TYPES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { SelectField, ModalActions } from 'shared/components';
import './group-modal.scss';
import PropTypes from 'prop-types';

const GroupModal = ({ isOpened, onClose, onSubmit }) => {
  const OPTIONS_FIRST_SELECT = [
    {
      label: 'ddi',
      value: 'sales_department',
      searchableLabel: 'Data',
    },
    {
      label: 'osady',
      value: 'plot',
      searchableLabel: 'Data',
    },
    {
      label: 'miesiące',
      value: 'month',
      searchableLabel: 'Data',
    },
  ];
  const OPTIONS_SECOND_SELECT = [
    {
      label: 'ddi',
      value: 'sales_department',
      searchableLabel: 'Data',
    },
    {
      label: 'osady',
      value: 'plot',
      searchableLabel: 'Data',
    },
    {
      label: 'miesiące',
      value: 'month',
      searchableLabel: 'Data',
    },
  ];

  // const [secondOptions, setSecondOptions] = useState(OPTIONS_SECOND_SELECT);

  // const handleSelect = values => {
  //   console.log(secondOptions.length);
  //   // secondOptions.length <= 2 && setSecondOptions(OPTIONS_SECOND_SELECT);
  //   values
  //     ? secondOptions.splice(
  //         secondOptions.findIndex(i => i.value === values.value),
  //         1,
  //       )
  //     : setSecondOptions(OPTIONS_SECOND_SELECT);
  // };

  return (
    <Modal title="Grupuj" type={MODAL_TYPES.GROUP} opened={isOpened} onClose={onClose}>
      <Formik
        initialValues={{
          group: '',
          range: '',
        }}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({ handleSubmit, errors }) => (
          <form className="form">
            <span>1. Wybór</span>
            <Field
              options={OPTIONS_FIRST_SELECT}
              // onChange={values => handleSelect(values)}
              errors={errors}
              component={SelectField}
              fullWidth
              isCustomLabel
              name="group"
              placeholder="Wybierz grupę"
            />
            <div className="select--wrapper">
              <span>2. Wybór</span>
              <Field
                errors={errors}
                options={OPTIONS_SECOND_SELECT}
                component={SelectField}
                fullWidth
                isCustomLabel
                name="range"
                placeholder="Wybierz zakres"
              />
            </div>
            <ModalActions handleSubmit={handleSubmit} type={MODAL_TYPES.GROUP} onClose={onClose} />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default GroupModal;

GroupModal.propTypes = {
  isOpened: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
