const NOTIFICATION = {
  GET_NOTIFICATIONS_INIT: 'GET_NOTIFICATIONS_INIT',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  GET_ACTIVE_DAY_NOTIFICATIONS_INIT: 'GET_ACTIVE_DAY_NOTIFICATIONS_INIT',
  GET_ACTIVE_DAY_NOTIFICATIONS_SUCCESS: 'GET_ACTIVE_DAY_NOTIFICATIONS_SUCCESS',
  GET_ACTIVE_DAY_NOTIFICATIONS_FAILURE: 'GET_ACTIVE_DAY_NOTIFICATIONS_FAILURE',
  GET_SINGLE_NOTIFICATION_INIT: 'GET_SINGLE_NOTIFICATION_INIT',
  GET_SINGLE_NOTIFICATION_SUCCESS: 'GET_SINGLE_NOTIFICATION_SUCCESS',
  GET_SINGLE_NOTIFICATION_FAILURE: 'GET_SINGLE_NOTIFICATION_FAILURE',
  ADD_NOTIFICATION_INIT: 'ADD_NOTIFICATION_INIT',
  ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',
  CHANGE_STATUS_INIT: 'CHANGE_STATUS_INIT',
  CHANGE_STATUS_SUCCESS: 'CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILURE: 'CHANGE_STATUS_FAILURE',
  ARRANGING_THE_ACT_INIT: 'ARRANGING_THE_ACT_INIT',
  ARRANGING_THE_ACT_SUCCESS: 'ARRANGING_THE_ACT_SUCCESS',
  ARRANGING_THE_ACT_FAILURE: 'ARRANGING_THE_ACT_FAILURE',
  GET_EMPLOYEES_INIT: 'GET_EMPLOYEES_INIT',
  GET_EMPLOYEES_SUCCESS: 'GET_EMPLOYEES_SUCCESS',
  GET_EMPLOYEES_FAILURE: 'GET_EMPLOYEES_FAILURE',
};

export default NOTIFICATION;
