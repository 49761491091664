import React, { useState } from 'react';
import Modal from 'shared/components/modal/Modal';
import { MODAL_TYPES } from 'shared/consts';
import { Field, Formik } from 'formik';
import { SelectField, ModalActions } from 'shared/components';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './range-modal.scss';
import PropTypes from 'prop-types';

const RangeModal = ({ isOpened, onClose, onSubmit, isSetRangeModalOpen, updateFilters }) => {
  const options = [
    // {
    //   label: 'Data wpłaty pełnej kwoty',
    //   value: 'date_of_purchase',
    //   searchableLabel: 'Data wpłaty pełnej kwoty',
    // },
    {
      label: 'Data wstępnej rezerwacji',
      value: 'date_of_reservation',
      searchableLabel: 'Data wstępnej rezerwacji',
    },
    {
      label: 'Data umowy przedwstępnej',
      value: 'date_of_contract',
      searchableLabel: 'Data umowy przedwstępnej',
    },
    // {
    //   label: 'Data i godzina umowy warunkowej',
    //   value: 'conditional_agreement_date',
    //   searchableLabel: 'Data i godzina umowy warunkowej',
    // },
    // {
    //   label: 'Data i godzina aktu przyrzeczonego',
    //   value: 'date-of-promised-act',
    //   searchableLabel: 'Data i godzina aktu przyrzeczonego',
    // },
    // {
    //   label: 'Przewidywana data spłaty',
    //   value: 'date_of_purchase',
    //   searchableLabel: 'Przewidywana data spłaty',
    // },
    // {
    //   label: 'Data umowy warunkowej',
    //   value: 'date_of_conditional_contract',
    //   searchableLabel: 'Data umowy warunkowej',
    // },
    // {
    //   label: 'Data aktu przyrzeczonego',
    //   value: 'date_of_act',
    //   searchableLabel: 'Data aktu przyrzeczonego',
    // },
  ];

  const [selectedDayStart, setSelectedDayStart] = useState(null);
  const [selectedDayEnd, setSelectedDayEnd] = useState(null);
  const [option, setOptions] = useState(false);

  const handleOption = e => {
    setOptions(e);
  };

  const handleClearForm = () => {
    setSelectedDayStart(null);
    setSelectedDayEnd(null);
    setOptions(false);
  };

  function validateField(value) {
    let error;
    if (!value) {
      error = 'Wymagane';
    }
    return error;
  }

  return (
    <Modal title="Wybierz zakres" type={MODAL_TYPES.RANGE} name="date" opened={isOpened} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          isReservation: option,
          selected_day_start: selectedDayStart,
          selected_day_end: selectedDayEnd,
        }}
        // onSubmit={values => onSubmit(values, isSetRangeModalOpen, updateFilters)}
        onSubmit={values => {
          console.log('pokaz kotku co masz w srodku');
          onSubmit(values, isSetRangeModalOpen, updateFilters);
          console.log(values);
        }}
      >
        {({ handleSubmit, errors }) => (
          <form className="form">
            <div className="fieldWrapper">
              <Field
                options={options}
                component={SelectField}
                fullWidth
                name="isReservation"
                placeholder="Wybierz zakres"
                errors={errors}
                onChange={handleOption}
                validate={validateField}
              />
            </div>
            <div className="calendar__wrapper">
              <div className="calendar__wrapper--picker">
                <Calendar
                  calendarClassName="responsive-calendar"
                  value={selectedDayStart}
                  onChange={setSelectedDayStart}
                  shouldHighlightWeekends
                />
              </div>
              <div className="calendar__wrapper--picker">
                <Calendar
                  calendarClassName="responsive-calendar"
                  value={selectedDayEnd}
                  onChange={setSelectedDayEnd}
                  shouldHighlightWeekends
                />
              </div>
            </div>
            <ModalActions type={MODAL_TYPES.RANGE} onClose={handleClearForm} handleSubmit={handleSubmit} />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default RangeModal;

RangeModal.propTypes = {
  isOpened: PropTypes.func.isRequired,
  isSetRangeModalOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};
