import { bool, shape, string, func, array, object } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { PLOTS_SUBMENU } from 'shared/components/active-window/consts';
import { MODAL_TYPES } from 'shared/consts';
import { ReservationModal, MapModal, AddFilesModal, DeleteModal } from 'shared/components';
import { getSettlementDetails } from './utils';
import PlotsById from './components/plotsById';

const SettlementPlots = ({
  authorizedUser,
  isMobile,
  plot,
  users,
  investorsLabel,
  acceptedOffers,
  history,
  settlements: { data: settlements = [] } = {},
  secondarySettlements: { data: secondaySettlements = [] } = {},
  advisersList,
  actions: {
    getPlotDetails,
    getUsers,
    getInvestorsLabel,
    changeStatus,
    getAcceptedOffers,
    getPlotsBySettlementId,
    saveFiles,
    deleteFile,
    getAdvisersList,
  },
  match: {
    params: { uuid },
  },
}) => {
  if (uuid) {
    const [modalOpened, setModalOpened] = useState(null);
    const [activeMenu, setActiveMenu] = useState(PLOTS_SUBMENU.PLOTS);
    const [fileDetails, setFileDetails] = useState({ label: '', idx: '' });
    const [isMapOpen, isSetMapOpen] = useState(false);
    useEffect(() => {
      isSetMapOpen(false);
      setActiveMenu(PLOTS_SUBMENU.PLOTS);
    }, [uuid]);

    useEffect(() => {
      setTimeout(async () => {
        await getPlotsBySettlementId(uuid);
      }, 1000);
    }, []);

    const handleReservation = async (data, plot_uuid) => {
      const response = await changeStatus(data, plot_uuid);
      response && getPlotsBySettlementId(uuid);
      setModalOpened(null);
    };
    const openModal = id => {
      getPlotDetails(id);
      setModalOpened(null);
      setTimeout(() => setModalOpened(MODAL_TYPES.RESERVATION), 200);
    };

    const handleAddFilesModalOpen = type => {
      setModalOpened(type);
    };

    const handleMapOpening = () => {
      if (isMobile) {
        isSetMapOpen(prevValue => !prevValue);
        setActiveMenu(prevValue => !prevValue);
        !activeMenu && setActiveMenu(PLOTS_SUBMENU.PLOTS);
      } else {
        isSetMapOpen(prevValue => !prevValue);
        setModalOpened(MODAL_TYPES.MAP);
      }
    };
    const isOpened = modalOpened === MODAL_TYPES.RESERVATION;
    const isDeleteModalOpened = modalOpened === MODAL_TYPES.DELETE;
    const isAddFileModalOpened = modalOpened === MODAL_TYPES.ADD_FILES;
    const isOpenedMapModal = modalOpened === MODAL_TYPES.MAP;
    const { plots = [], name, id } = getSettlementDetails(uuid, [...settlements, ...secondaySettlements]) ?? {};

    const deleteSettlementFile = (idx, label) => {
      setModalOpened(MODAL_TYPES.DELETE);
      setFileDetails({ idx, label });
    };

    const closeModal = () => {
      setModalOpened(null);
    };

    const handleFileDelete = () => {
      deleteFile(fileDetails.idx, uuid);
      setModalOpened(null);
    };

    return (
      <>
        <PlotsById
          openModal={openModal}
          isMobile={isMobile}
          name={name}
          plots={plots}
          setActiveMenu={setActiveMenu}
          activeMenu={activeMenu}
          handleMapOpening={handleMapOpening}
          isMapOpen={isMapOpen}
          isSetMapOpen={isSetMapOpen}
          settlementId={id}
          history={history}
          authorizedUser={authorizedUser}
          handleAddFilesModalOpen={handleAddFilesModalOpen}
          remove={deleteSettlementFile}
        />
        <ReservationModal
          loggedUserRole={authorizedUser}
          title="Rezerwuj działkę"
          opened={isOpened}
          onClose={() => setModalOpened(null)}
          onSubmit={handleReservation}
          plot={plot}
          getUsers={getUsers}
          ddiUsers={users}
          getInvestors={getInvestorsLabel}
          investorsOptions={investorsLabel}
          acceptedOffers={acceptedOffers}
          getAcceptedOffers={getAcceptedOffers}
          getAdvisersList={getAdvisersList}
          advisersList={advisersList}
        />

        <AddFilesModal
          title="Dodaj pliki"
          opened={isAddFileModalOpened}
          onClose={() => setModalOpened(null)}
          onSubmit={saveFiles}
          settlementUuid={uuid}
          getPlotsBySettlementId={getPlotsBySettlementId}
        />

        <DeleteModal
          title="Czy chcesz usunąć plik?"
          opened={isDeleteModalOpened}
          onClose={closeModal}
          onSubmit={handleFileDelete}
          label={fileDetails.label}
        />

        <MapModal
          plots={plots}
          openModal={openModal}
          opened={isOpenedMapModal}
          settlementId={id}
          history={history}
          onClose={() => {
            setModalOpened(null);
            isSetMapOpen(false);
          }}
        />
      </>
    );
  }
  return null;
};

SettlementPlots.propTypes = {
  acceptedOffers: array.isRequired,
  actions: shape({
    getInvestorsLabel: func.isRequired,
    getPlotDetails: func.isRequired,
    getUsers: func.isRequired,
    changeStatus: func.isRequired,
    deleteFile: func.isRequired,
    saveFiles: func.isRequired,
  }).isRequired,
  advisersList: array.isRequired,
  authorizedUser: string.isRequired,
  history: object.isRequired,
  investorsLabel: array.isRequired,
  isMobile: bool.isRequired,
  match: shape({
    params: shape({
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
  plot: shape({}).isRequired,
  secondarySettlements: shape({}).isRequired,
  settlements: shape({}).isRequired,
  users: string.isRequired,
};

export default SettlementPlots;
