const AREA_TYPES = {
  EMPLOYEES: 'EMPLOYEES',
  ARCHIVED_EMPLOYEES: 'ARCHIVED_EMPLOYEES',
  ARCHIVED_PARTNERS: 'ARCHIVED_PARTNERS',
  ARCHIVED_INVESTORS: 'ARCHIVED_INVESTORS',
  PARTNERS: 'PARTNERS',
  INVESTORS: 'INVESTORS',
  LEGAL_DEPARTMENT: 'LEGAL_DEPARTMENT',
};

export default AREA_TYPES;
