import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getSettlements,
  getPlotsBySettlementId,
  getSecondarySettlements,
  getPlotsByFilter,
  addSettlements,
} from 'store/actions/settlements';
import {
  getSettlements as getSettlementsSelector,
  getSecondarySettlements as getSecondarySettlementsSelector,
  getArchivedSettlementsSelector,
} from 'store/selectors/settlementsSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { getAuthorizedUserRole } from 'store/selectors/authSelector';
import getArchivedSettlements from 'store/actions/settlements/getArchivedSettlements';
import ArchivedSettlements from './ArchivedSettlemets.main';
// import Settlements from './Settlements.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSettlements: () => getSettlements(),
      getArchivedSettlements: () => getArchivedSettlements(),
      getPlotsBySettlementId: id => getPlotsBySettlementId(id),
      getSecondarySettlements: params => getSecondarySettlements(params),
      getPlotsByFilter: params => getPlotsByFilter(params),
      addSettlement: (data, file) => addSettlements(data, file),
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  settlements: getSettlementsSelector(state),
  secondarySettlements: getSecondarySettlementsSelector(state),
  isMobile: checkIsMobile(),
  authorizedUserRole: getAuthorizedUserRole(state),
  archivedSettlements: getArchivedSettlementsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedSettlements);
