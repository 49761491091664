import * as Connector from 'shared/connectors/statusConnector';
import { STATUS } from 'store/types';
import { showPreloader, hidePreloader } from '../viewManagement';
import getPlotDetails from '../settlements/getPlotDetails';

const init = () => ({
  type: STATUS.CHANGE_STATUS_INIT,
});

export const success = data => ({
  type: STATUS.CHANGE_STATUS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: STATUS.CHANGE_STATUS_FAILURE,
});

const changeStatus = (data, plot_uuid) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    const response = await Connector.changeStatus(data);
    plot_uuid && (await dispatch(getPlotDetails(plot_uuid)));
    dispatch(success());
    dispatch(hidePreloader());
    return response.status;
  } catch (err) {
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default changeStatus;
