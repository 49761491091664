import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import PropTypes from 'prop-types';

const Header = ({
  plot: {
    name,
    price,
    plot_size,
    order_status: { offer },
  },
}) => (
  <div className="reservationModal__header">
    <div className="reservationModal__header--top">
      <SVG className="reservationModal--icon" type={SVG_TYPE.BLUE_HOME} />
      <p className="reservationModal--number" />
      <p className="reservationModal--plotName"> Kanał Elbląski </p>
    </div>
    <div className="reservationModal__header--bottom">
      <div className="reservationModal__plot-number">
        <p className="reservationModal--dot" />
        {name}
      </div>

      <p className="reservationModal__yardage">
        <p className="reservationModal__yardage--dot" /> {plot_size} m2
      </p>
      <p className="reservationModal--price">{price} zł</p>
      <p className="reservationModal--price-green">{offer?.percentage_reduction ? `-${offer?.percentage_reduction}%` : ''}</p>
      {/* // <p className="reservationModal--price-green">{offer?.percentage_reduction ? }-6%</p> */}
    </div>
    <div className="reservationModal__bottom reservationModal__bottom-second">
      <div className="reservationModal--deposit">zadatek:</div>
      <div className="reservationModal--deposit-price">
        <p className="reservationModal--price-gray">{offer?.price} zł</p>
        <p className="reservationModal--price-deposit-percentage">{offer?.deposit_percentage}%</p>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  plot: PropTypes.shape({
    name: PropTypes.string.isRequired,
    order_status: PropTypes.shape({
      offer: PropTypes.string.isRequired,
    }),
    plot_size: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
