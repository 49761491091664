import { bool, string, func } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { Link } from 'react-router-dom';

const SettlementName = ({ isMobile, name, isMapOpen, handleMapOpening }) => (
  <div className="settlementName">
    {isMobile && !isMapOpen && (
      <Link to={ROUTES.SETTLEMENTS}>
        <div className="settlementName__icon--back">
          <SVG type={SVG_TYPE.BACK} />
        </div>
      </Link>
    )}
    {isMapOpen ? (
      <div className="settlementName__icon--back" onClick={() => handleMapOpening(name)}>
        <SVG type={SVG_TYPE.BACK} />
      </div>
    ) : (
      <div className="settlementName__icon--home">
        <SVG type={SVG_TYPE.BLUE_HOME} />
      </div>
    )}
    <div className="settlementName__name">{name}</div>
  </div>
);

SettlementName.propTypes = {
  handleMapOpening: func.isRequired,
  isMapOpen: bool.isRequired,
  isMobile: bool.isRequired,
  name: string.isRequired,
};

export default SettlementName;
