import * as Connector from 'shared/connectors/startConnector';
import { START } from 'store/types';

const init = () => ({
  type: START.GET_SINGLE_NEWS_INIT,
});

export const success = data => ({
  type: START.GET_SINGLE_NEWS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: START.GET_SINGLE_NEWS_FAILURE,
});

const getSingleNews = uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getSingleNews(uuid);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getSingleNews;
