import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_PLOTS_INIT,
});

export const success = plots => ({
  type: SETTLEMENTS.GET_PLOTS_SUCCESS,
  payload: {
    plots,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_PLOTS_FAILURE,
});

const sortedReportsData = sorted => async dispatch => {
  dispatch(init());
  console.log('witaj swiecie');
  try {
    dispatch(success(sorted));
  } catch (err) {
    dispatch(failure());
  }
};

export default sortedReportsData;
