import { REPORT } from 'store/types';
import { NewUser } from 'shared/components';
import { toast } from 'react-toastify';
import React from 'react';

const init = () => ({
  type: REPORT.GET_REPORTS_DATA_INIT,
});

export const success = data => ({
  type: REPORT.GET_REPORTS_DATA_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: REPORT.GET_REPORTS_DATA_FAILURE,
});

const sortedReportsData = sorted => async dispatch => {
  dispatch(init());
  try {
    await toast(<NewUser first_name="Dane zostały posortowane" label="Wykonano akcje" />);
    dispatch(success(sorted));
  } catch (err) {
    dispatch(failure());
  }
};

export default sortedReportsData;
