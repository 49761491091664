import React from 'react';

function Header() {
  return (
    <div className="settings__header">
      <h1 className="settings__title">Ustawienia</h1>
    </div>
  );
}

export default Header;
