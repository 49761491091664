import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { bool, func, string } from 'prop-types';

const File = ({ label, path, remove, uuid, isAdmin }) => (
  <div className="form_field--fileinput_singleFile">
    <SVG type={SVG_TYPE.FILE} className="svg--file" />
    <div onClick={() => window.open(path, '_blank')} className="preBooking--file">
      {label}
    </div>
    {isAdmin && remove && (
      <div onClick={() => remove(uuid, label)}>
        <SVG type={SVG_TYPE.EXIT} />
      </div>
    )}
  </div>
);

export default File;

File.defaultProps = {
  isAdmin: false,
  label: '',
  path: '',
};
File.propTypes = {
  isAdmin: bool,
  label: string,
  path: string,
  remove: func.isRequired,
  uuid: string.isRequired,
};
