const SVG_TYPE = {
  MAGNIFIER: 'MAGNIFIER',
  SETTLEMENTS: 'SETTLEMENTS',
  INVESTORS: 'INVESTORS',
  EMPLOYEES: 'EMPLOYEES',
  PARTNERS: 'PARTNERS',
  CALENDAR: 'CALENDAR',
  SETTINGS: 'SETTINGS',
  ADD: 'ADD',
  PHONE: 'PHONE',
  CONFIRM: 'CONFIRM',
  DROPDOWN_INDICATOR: 'DROPDOWN_INDICATOR',
  MEDIA_WATER: 'MEDIA_WATER',
  MEDIA_POWER: 'MEDIA_POWER',
  MEDIA_HEAT: 'MEDIA_HEAT',
  MEDIA_TELECOMUNICATION: 'MEDIA_TELECOMUNICATION',
  BLUE_HOME: 'BLUE_HOME',
  MAP: 'MAP',
  FILE: 'FILE',
  MAIL: 'MAIL',
  EXIT: 'EXIT',
  RESTORE: 'RESTORE',
  LOUPE: 'LOUPE',
  FILTER: 'FILTER',
  BACK: 'BACK',
  DOWNLOAD: 'DOWNLOAD',
  DOT: 'DOT',
  NAVIGATE: 'NAVIGATE',
  CHECKED: 'CHECKED',
  ARROW_GRAY: 'ARROW_GRAY',
  MEETING: 'MEETING',
  LIGHT_MEETING: 'LIGHT_MEETING',
  LIGHT_FILE: 'LIGHT_FILE',
  LIGHT_PHONE: 'LIGHT_PHONE',
  SORT: 'SORT',
  GROUP: 'GROUP',
  FILTERING: 'FILTERING',
  GENERATE: 'GENERATE',
  SAVE: 'SAVE',
  LOAD: 'LOAD',
  SMALL_FILTER: 'SMALL_FILTER',
  CLEAR: 'CLEAR',
  SMALL_ARROW: 'SMALL_ARROW',
  ELLIPSE: 'ELLIPSE',
  REPORTS: 'REPORTS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  MAP_KANAL_ELBLASKI: 'MAP_KANAL_ELBLASKI',
  CONTACT: 'CONTACT',
  APPOINTMENT: 'APPOINTMENT',
  NOTE: 'NOTE',
  CLOSE: 'CLOSE',
  UPLOAD: 'UPLOAD',
  ACT: 'ACT',
  ZERO: 'ZERO',
  SOLD: 'SOLD',
};

export default SVG_TYPE;
