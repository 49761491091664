import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_PLOTS_BY_ID_INIT,
});

export const success = (uuid, plots) => ({
  type: SETTLEMENTS.GET_PLOTS_BY_ID_SUCCESS,
  payload: {
    plots,
    uuid,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_PLOTS_BY_ID_FAILURE,
});

const getPlotsBySettlementId = uuid => async dispatch => {
  dispatch(init());
  try {
    console.log('odswiez');
    const { data } = await Connector.getPlotsBySettlementId(uuid);
    dispatch(success(uuid, data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getPlotsBySettlementId;
