import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'shared/consts';
import { Badge } from 'shared/components';
import Right from 'shared/components/list/components/right';
import { withRouter, generatePath } from 'react-router-dom';

const ROLE_ROUTES = {
  admin: ROUTES.EMPLOYEES_ADMIN_UUID,
  administration: ROUTES.EMPLOYEES_ADMINISTRATION_UUID,
  sales_department: ROUTES.EMPLOYEES_DDI_UUID,
  accounting_department: ROUTES.EMPLOYEES_ACCOUNTING_UUID,
  marketing_department: ROUTES.EMPLOYEES_MARKETING_UUID,
  adviser: ROUTES.EMPLOYEES_ADVISER_UUID,
};

const ROLE_BADGES = {
  admin: 'AD',
  administration: 'ADM',
  sales_department: 'DDI',
  accounting_department: 'KS',
  marketing_department: 'MA',
  adviser: 'DO',
};

const EmployeeList = ({ users, history, area, isMobile, closeSearchView }) => {
  const handleClick = item => {
    history.push(generatePath(ROLE_ROUTES[item.role], { uuid: item?.uuid }));
    closeSearchView();
  };

  return (
    <div className="usersContainer">
      {' '}
      <div className={`list ${isMobile ? 'list--mobile' : ''}`}>
        {users?.map(item => (
          <div key={item.uuid} className="list__item-wrapper">
            <div className="list__item" onClick={() => handleClick(item)}>
              {/* {console.log('id aktywnej strony', activeRow)}
            {console.log('uuid itemu', item.uuid)} */}
              <div className="list__left-wrapper">
                <Badge label={ROLE_BADGES[item.role]} type={ROLE_BADGES[item.role]} /> {item.first_name} {item.last_name}
              </div>
              <Right area={area} item={item} isMobile={isMobile} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(EmployeeList);

EmployeeList.propTypes = {
  area: PropTypes.string.isRequired,
  closeSearchView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  shortcut: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};
