import { Connector } from 'modules/authModule';
import { AUTH } from 'store/types';
import { ROUTES } from 'shared/consts';
import { history } from 'store/store';
import { showHomePagePreloader, hideHomePagePreloader } from 'store/actions/viewManagement';
import { getAuthorizedUser } from 'store/actions/auth';

const init = () => ({
  type: AUTH.CODE_VERIFICATION_INIT,
});

export const success = data => ({
  type: AUTH.CODE_VERIFICATION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.CODE_VERIFICATION_FAILURE,
});

const verifyCode = loginData => async dispatch => {
  dispatch(init());
  try {
    dispatch(showHomePagePreloader());
    const { data } = await Connector.verifyCode(loginData);
    dispatch(success(data));
    await dispatch(getAuthorizedUser());
    history.push(ROUTES.START);
    dispatch(hideHomePagePreloader());
    return true;
  } catch (err) {
    dispatch(hideHomePagePreloader());
    dispatch(failure());
    return false;
  }
};

export default verifyCode;
