import { connect } from 'react-redux';
import * as Actions from 'store/actions/partner';
import { getArchivedPartners, getArchivedPartnersStatus } from 'store/selectors/partnerSelector';
import ArchivedPartners from './ArchivedPartners.main';

const mapDispatchToProps = {
  getArchivedPartners: () => Actions.getArchivedPartners(),
};

const mapStateToProps = state => ({
  archivedPartners: getArchivedPartners(state),
  archivedPartnersStatus: getArchivedPartnersStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedPartners);
