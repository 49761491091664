const AUTH = {
  LOGIN_INIT: 'LOGIN_INIT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  CODE_VERIFICATION_INIT: 'CODE_VERIFICATION_INIT',
  CODE_VERIFICATION_SUCCESS: 'CODE_VERIFICATION_SUCCESS',
  CODE_VERIFICATION_FAILURE: 'CODE_VERIFICATION_FAILURE',
  GET_AUTHORIZED_USER_INIT: 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS: 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE: 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  LOGOUT_INIT: 'LOGOUT_INIT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export default AUTH;
