import { START } from 'store/types';
import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  newsData: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  singleNewsData: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  statuses: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  offers: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

const startReduceer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case START.GET_NEWS_INIT: {
      return {
        ...state,
        newsData: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case START.GET_NEWS_SUCCESS: {
      return {
        ...state,
        newsData: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case START.GET_NEWS_FAILURE: {
      return {
        ...state,
        newsData: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case START.GET_SINGLE_NEWS_INIT: {
      return {
        ...state,
        singleNewsData: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case START.GET_SINGLE_NEWS_SUCCESS: {
      return {
        ...state,
        singleNewsData: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case START.GET_SINGLE_NEWS_FAILURE: {
      return {
        ...state,
        singleNewsData: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case START.GET_STATUSES_INIT: {
      return {
        ...state,
        statuses: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case START.GET_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case START.GET_STATUSES_FAILURE: {
      return {
        ...state,
        statuses: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case START.GET_OFFERS_INIT: {
      return {
        ...state,
        offers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case START.GET_OFFERS_SUCCESS: {
      return {
        ...state,
        offers: {
          data: payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case START.GET_OFFERS_FAILURE: {
      return {
        ...state,
        offers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default:
      return state;
  }
};

export default startReduceer;
