const EMPLOYEE_SHORTCUTS = {
  DDI: 'DDI',
  ADVISER: 'DO',
  ACCOUNTING: 'KS',
  ADMINISTRATION: 'ADM',
  MARKETING: 'MA',
  ADMIN: 'AD',
  DIR: 'DIR',
  DP: 'DP',
};

export default EMPLOYEE_SHORTCUTS;
