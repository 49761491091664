import { shape, func, arrayOf, string, array, bool } from 'prop-types';
import * as React from 'react';
import { SVG, Table, ReservationModal } from 'shared/components';
import { SVG_TYPE, MODAL_TYPES, REQUEST_STATUS } from 'shared/consts';
import Filters from 'screens/filters/Filters.container';
import Skeleton from 'react-loading-skeleton';
import { object } from 'yup';
import { getColumns, filterBy } from './utils';

class AllPlots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: null,
      filters: {},
      isFilterOpened: false,
    };
  }

  componentDidMount = async () => {
    this.props.history.location.state
      ? await this.props.actions.getPlotsByFilter(filterBy[this.props.history.location.state.filter])
      : await this.props.actions.getPlots();
  };

  updateFilters = (key, value) =>
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [key]: value,
      },
    }));

  handleFilterClick = () => {
    this.setState(prevState => ({
      isFilterOpened: !prevState.isFilterOpened,
    }));
  };

  render() {
    const {
      history,
      plot,
      plots,
      authorizedUser,
      users,
      investorsLabel,
      actions: { getPlotDetails, getUsers, getInvestorsLabel, changeStatus, getAcceptedOffers, getAdvisersList },
      acceptedOffers,
      isMobile,
      plotFilters,
      plotStatus,
      advisersList,
    } = this.props;

    const { INIT } = REQUEST_STATUS;
    const handleReservation = (data, plot_uuid) => {
      changeStatus(data, plot_uuid);
      this.setState({ modalOpened: null });
    };

    const openModal = async id => {
      await getPlotDetails(id);
      this.setState({ modalOpened: MODAL_TYPES.RESERVATION });
    };
    const isOpened = this.state.modalOpened === MODAL_TYPES.RESERVATION;

    const isLoading = plotStatus === INIT;
    return (
      <>
        <div className="allPlots employees-right">
          <div className="employees-right__header allPlots__header">
            <div className="allPlots__icon settlement-plots--icon">
              <div className="svg-square-wrapper" onClick={this.handleFilterClick}>
                <SVG type={SVG_TYPE.FILTER} className="white" />
              </div>
            </div>
          </div>
          <div className="allPlots__content employees-right__wrapper">
            {isLoading && (
              <>
                <Skeleton count={10} height={50} />
              </>
            )}
            {plots.length > 0 && (
              <>
                <Table
                  isMobile={isMobile}
                  className="table--sm"
                  columns={getColumns(history, openModal, authorizedUser.uuid, authorizedUser.role)}
                  data={plots}
                />
              </>
            )}
            {plots.length === 0 && !isLoading && <div className="list__empty">Brak działek spełniających wybrane kryteria</div>}
          </div>
        </div>
        <ReservationModal
          loggedUserRole={authorizedUser}
          title="Rezerwuj działkę"
          opened={isOpened}
          onClose={() => this.setState({ modalOpened: null })}
          onSubmit={handleReservation}
          plot={plot}
          getUsers={getUsers}
          ddiUsers={users}
          getInvestors={getInvestorsLabel}
          investorsOptions={investorsLabel}
          acceptedOffers={acceptedOffers}
          getAcceptedOffers={getAcceptedOffers}
          getAdvisersList={getAdvisersList}
          advisersList={advisersList}
        />

        <Filters isOpened={this.state.isFilterOpened} onClose={this.handleFilterClick} plots={plots} plotFilters={plotFilters} />
      </>
    );
  }
}

AllPlots.defaultProps = {
  plot: [],
  plots: [],
};

AllPlots.propTypes = {
  acceptedOffers: array.isRequired,
  actions: shape({
    changeStatus: func.isRequired,
    getAdvisersList: func.isRequired,
    getPlots: func.isRequired,
    getPlotDetails: func.isRequired,
    getUsers: func.isRequired,
    getInvestors: func.isRequired,
    getInvestorsOptions: func.isRequired,
  }).isRequired,
  advisersList: array.isRequired,
  authorizedUser: string.isRequired,
  investorsLabel: array.isRequired,
  isMobile: bool.isRequired,
  plot: arrayOf(shape({})),
  plotFilters: object.isRequired,
  plots: arrayOf(shape({})),
  plotStatus: object.isRequired,
  users: array.isRequired,
};

export default AllPlots;
