/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */

import * as React from 'react';
import { string, bool, number } from 'prop-types';
import { SVG, File } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import Label from 'shared/components/fields/regular-field/components/label';

class FieldFileInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      file: null,
      fileArr: [],
      base64Arr: [],
      only2Error: false,
    };
  }

  onChange(e) {
    const { setFieldValue } = this.props.form;
    const { files } = e.target;
    if (this.props.multiple) {
      if ((!this.props.only2 && files.length) > 0 || (this.props.only2 && files.length) < 3) {
        for (let idx = 0; idx < files.length; idx++) {
          const reader = new FileReader();
          reader.readAsDataURL(files[idx]);
          reader.onloadend = () => {
            const fileArrClone = [...this.state.fileArr, files[idx]];
            const base64ArrClone = [...this.state.base64Arr, reader.result];
            this.setState(
              {
                fileArr:
                  (this.props.only2 && fileArrClone.length <= 2) || (!this.props.only2 && fileArrClone.length <= 10)
                    ? fileArrClone
                    : [],
                base64Arr:
                  (this.props.only2 && fileArrClone.length <= 2) || (!this.props.only2 && fileArrClone.length <= 10)
                    ? base64ArrClone
                    : [],
                only2Error: this.props.only2 && fileArrClone.length > 2,
              },
              () =>
                (this.props.only2 && fileArrClone.length <= 2) || (!this.props.only2 && fileArrClone.length <= 10)
                  ? setFieldValue(this.props.field.name, this.state.base64Arr)
                  : setFieldValue(this.props.field.name, []),
            );
          };
        }
      } else if (this.props.only2 && files.length > 2) {
        this.setState({
          only2Error: true,
        });
      }
    } else {
      const reader = new FileReader();
      const temp = e.target.files[0];
      reader.readAsDataURL(temp);
      reader.onloadend = () => {
        this.setState({
          file: temp,
        });
        setFieldValue(this.props.field.name, reader.result);
      };
    }
  }

  render() {
    const { label, wrapperClassName, disabled, form, errors, handleChange } = this.props;
    const fileInputKey = this.props.field.name ? this.props.field.name : +new Date();
    let formClasses = 'form_field form_field--fileinput';
    const isError = !!(form.touched[this.props.field.name] && errors[this.props.field.name]);
    formClasses += isError ? ' form_field--has_error' : '';
    formClasses += wrapperClassName ? ` ${wrapperClassName} ` : '';
    formClasses += disabled ? ' form_field--disabled' : '';

    const deleteFile = idx => {
      this.setState({ fileArr: this.state.fileArr.filter((value, index) => index !== idx) });
    };

    // console.log('czy faktycznie są dwa ', this.props.only2 && this.state.fileArr.length === 2);
    return (
      <div className={formClasses}>
        <div
          className={`form_field--fileinput_label ${
            this.props.only2 && this.state.fileArr.length === 2 ? 'form_field--fileinput_label--disabled' : ''
          }`}
        >
          <label htmlFor={this.props.field.name}>
            <i className="icon--plus_sign" /> <span>{label}</span>
          </label>
        </div>
        <div>
          <input
            id={this.props.field.name}
            name={this.props.field.name}
            key={fileInputKey}
            type="file"
            accept=".jpg, .png, .jpeg, .pdf"
            onChange={event => {
              this.onChange(event);
              handleChange && handleChange(event);
            }}
            // eslint-disable-next-line no-return-assign
            ref={ref => (this.fileInput = ref)}
            multiple={this.props.multiple}
          />
          <div className="form_field--fileinput_error">
            <Label errors={errors} field={this.props.field} isError={isError} />
          </div>
        </div>
        {this.state.only2Error && (
          <div className="select-field__error-label select-field__error-label-only2">można wybrać maksymalnie 2 pliki</div>
        )}
        {this.props.multiple ? (
          this.state.fileArr.map((e, index) => {
            if (e) {
              return (
                <>
                  <div key={`${e.name}${e.lastModified}`} className="form_field--fileinput_singleFile">
                    <div>
                      <SVG type={SVG_TYPE.FILE} className="svg--file" />
                      <span className="fileName">{e.name}</span>
                    </div>
                    <div onClick={() => deleteFile(index)}>
                      <SVG type={SVG_TYPE.EXIT} className="svg--file" />
                    </div>
                  </div>
                </>
              );
            }
          })
        ) : (
          <>{this.state.file && <File label={this.state.file.name} />}</>
        )}
      </div>
    );
  }
}
FieldFileInput.defaultProps = {
  max: undefined,
  min: undefined,
  multiple: false,
  only2: false,
  placeholder: undefined,
  wrapperClassName: undefined,
};
FieldFileInput.propTypes = {
  disabled: bool.isRequired,
  label: string.isRequired,
  max: number,
  min: number,
  multiple: bool,
  only2: bool,
  placeholder: string,
  type: string.isRequired,
  wrapperClassName: string,
};
export default FieldFileInput;
