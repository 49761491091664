import React, { useState, useEffect } from 'react';
import { Modal, SelectField, ModalActions, ActiveWindow, OptionsLabel } from 'shared/components';
import { FORM_TYPES, MODAL_TYPES, VALIDATION_MESSAGES, EMPLOYEE_SHORTCUTS } from 'shared/consts';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';

const { EMAIL, REQUIRED_FIELD, REQUIRED_9_DIGITS } = VALIDATION_MESSAGES;

const getFormSchema = () =>
  object().shape({
    email: string().email(EMAIL).required(REQUIRED_FIELD),
    first_name: string().required(REQUIRED_FIELD),
    last_name: string().required(REQUIRED_FIELD),
    phone: string().required(REQUIRED_FIELD).min(9, REQUIRED_9_DIGITS).max(9, REQUIRED_9_DIGITS),
    position: string().required(REQUIRED_FIELD),
    role: string().required(REQUIRED_FIELD),
  });

const filterDdiOptions = (ddiOptions, attachedDdiList) => {
  const options = [...ddiOptions];
  attachedDdiList.forEach(element => {
    let removeIndex = options.map(item => item.value);
    removeIndex = removeIndex.indexOf(element.uuid);
    if (removeIndex > -1) {
      options.splice(removeIndex, 1);
    }
  });
  return options;
};

function Form({ onClose, onSubmit, opened, partnerData, data, ddiOptions }) {
  const [attachedDdiList, setAttachedDdiList] = useState([]);
  const [ddiOptionsList, setDdiOptionsList] = useState([]);

  useEffect(() => {
    setAttachedDdiList([...data]);
    setDdiOptionsList(filterDdiOptions([...ddiOptions], data));
  }, [data]);

  const handleDDISelected = (pickedDDI, setFieldValue) => {
    if (pickedDDI) {
      const newAttachedDdiList = [...attachedDdiList, { label: pickedDDI.label.props.children[3], uuid: pickedDDI.value }];
      setAttachedDdiList(newAttachedDdiList);
      setDdiOptionsList(filterDdiOptions([...ddiOptionsList], newAttachedDdiList));
      setFieldValue('role', '');
    }
  };

  const handleDelete = (uuidToDelete, label) => {
    const indexToRemove = attachedDdiList.map(({ uuid }) => uuid).indexOf(uuidToDelete);
    if (indexToRemove > -1) {
      attachedDdiList.splice(indexToRemove, 1);
      setAttachedDdiList([...attachedDdiList]);
      ddiOptionsList.push({ label, value: uuidToDelete });
      setDdiOptionsList([...ddiOptionsList]);
    }
  };

  const DDI_OPTIONS_LIST = OptionsLabel(ddiOptionsList, EMPLOYEE_SHORTCUTS.DDI);

  return (
    <Modal onClose={onClose} onSubmit={onSubmit} opened={opened} title="Edytuj przypisanych DDI" type={MODAL_TYPES.CONFIRM}>
      <Formik
        enableReinitialize
        initialValues={
          data || {
            role: '',
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            business_partner_uuid: '',
            ddi_uuid: '',
          }
        }
        onSubmit={async (values, { setErrors }) => {
          const response = await onSubmit(partnerData, attachedDdiList);
          if (!response) {
            onClose();
          } else if (response.field === 'email') {
            setErrors({ email: VALIDATION_MESSAGES.UNIQUE_EMAIL });
          }
        }}
        validationSchema={getFormSchema()}
      >
        {({ handleSubmit, errors }) => (
          <>
            <form className="modal__form" onSubmit={handleSubmit}>
              <ActiveWindow.Field type="select" value={partnerData.name} badgeType="Partner" />
              <Field
                component={SelectField}
                errors={errors}
                fullWidth
                label="Dodaj DDI"
                name="role"
                isCustomLabel
                options={DDI_OPTIONS_LIST}
                placeholder="Wyszukaj DDI"
                onChange={handleDDISelected}
                menuPlacement="top"
              />
              {attachedDdiList.map(({ first_name, last_name, label, uuid }) => (
                <ActiveWindow.Field
                  key={uuid}
                  type="select"
                  value={label ?? `${first_name} ${last_name}`}
                  badgeType={EMPLOYEE_SHORTCUTS.DDI}
                  onDelete={() => handleDelete(uuid, label ?? `${first_name} ${last_name}`)}
                />
              ))}
              <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.CONFIRM} />
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

Form.defaultProps = {
  data: null,
};

Form.propTypes = {
  data: PropTypes.object,
  ddiOptions: PropTypes.array.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  partnerData: PropTypes.object.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  type: PropTypes.oneOf([FORM_TYPES.ADD, FORM_TYPES.EDIT]).isRequired,
  userRole: PropTypes.string.isRequired,
};

export default Form;
