import React, { useEffect, useState } from 'react';
import { Modal, ModalActions, Button, Badge } from 'shared/components';
import { FORM_TYPES, MODAL_TYPES, VALIDATION_MESSAGES, USER_ROLES } from 'shared/consts';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import INVESTOR_STATUS from 'shared/consts/investorStatus';
import { addInvestor } from 'store/actions/investors';
import { getFormSchema, getButtonLabelForFormStep } from './utils';
import { FORM_STEPS } from './consts';
// import { FORM_STEPS, INVESTOR__DETAILS_TYPE } from './consts';
import { BasicInformationForm, DetailedInformationForm } from './components';

const { ADMIN, DDI, ADVISER } = USER_ROLES;

function AddInvestorForm({
  onClose,
  onSubmit,
  opened,
  type,
  data,
  usersDdi,
  userRole: loggedUserRole,
  getPartnersOptions,
  getDdiOptions,
  getAllDdi,
  getEmployees,
  employees,
}) {
  const [formStep, setFormStep] = useState(FORM_STEPS.BASIC);
  const [fileInputValue, setFileInputValue] = useState(null);
  // const [investorDetailsType, setInvestorDetailsType] = useState(INVESTOR__DETAILS_TYPE.COMPANY);
  const isDdi = loggedUserRole === 'sales_department';
  const fetchData = async (pickedRole, fetchMethod) => {
    pickedRole === ADVISER && (await fetchMethod());
  };

  useEffect(() => {
    getAllDdi();
    if (data && data.role === ADVISER && type === FORM_TYPES.EDIT) {
      loggedUserRole === ADMIN && fetchData(data.role, getDdiOptions);
      getPartnersOptions(data.ddi_uuid);
    }

    getEmployees && getEmployees();
  }, []);

  const handleAdviserSelected = pickedRole => {
    if (loggedUserRole === ADMIN) {
      fetchData(pickedRole?.value, getDdiOptions);
    } else if (loggedUserRole === DDI) {
      fetchData(pickedRole?.value, getPartnersOptions);
    }
  };

  const adviserOptions = () =>
    employees.length > 0 &&
    employees
      .filter(el => el.role === 'adviser')
      .map(({ uuid, first_name, last_name }) => ({
        label: (
          <div className="select-field__option">
            <Badge label="DO" type="DO" />
            {first_name} {last_name}
          </div>
        ),
        value: uuid,
        searchableLabel: first_name,
      }));

  const returnRoleOptions = () => INVESTOR_STATUS;

  const handleFormSubmit = async (values, { setErrors }) => {
    console.log(values, 'wartości');
    const response = await onSubmit(values, fileInputValue);
    if (!response) {
      onClose();
      setFileInputValue('');
    } else if (response.field === 'email') {
      // error handling powinien zostać przeniesiony do akcji
      setErrors({ email: VALIDATION_MESSAGES.UNIQUE_EMAIL });
    } else if (response.field === 'phone_number') {
      setErrors({ phone_number: VALIDATION_MESSAGES.SAME_PHONE_NUMBER });
    }
  };

  const handleFileChange = e => {
    setFileInputValue(e.target.files[0]);
  };

  const toggleFormStep = () => setFormStep(formStep === FORM_STEPS.BASIC ? FORM_STEPS.DETAILS : FORM_STEPS.BASIC);
  const renderFormTitle = () => (
    <div className="form_investor__header">
      <span className="form_investor__title">{type === FORM_TYPES.ADD ? 'Dodaj' : 'Edytuj'} inwestora</span>
      <span className="form_investor__titleButton">
        <Button classNames="gray" label={getButtonLabelForFormStep(formStep)} onClick={toggleFormStep} />
      </span>
    </div>
  );

  // console.log(data);

  return (
    <Modal onClose={onClose} onSubmit={addInvestor} opened={opened} title={renderFormTitle()} type={MODAL_TYPES.CONFIRM}>
      <Formik
        enableReinitialize
        initialValues={
          data
            ? {
                ...data,
                business_partner_uuid: data?.business_partner?.uuid,
                investorAdditionalInformation: {},
                email: data?.email || '',
                first_name: data?.first_name || '',
                last_name: data?.last_name || '',
                status: data?.status || '',
                phone_number: data?.phone_number || '',
                investorCard: data?.files?.length ? data.files[0].file_name : '',
                sales_department_uuid: data?.ddi?.uuid || '',
                adviser_uuid: data?.adivser?.uuid || '',
                uuid: data?.uuid || '',
              }
            : {
                investorAdditionalInformation: {},
                email: '',
                first_name: '',
                last_name: '',
                status: '',
                phone_number: '',
                investorCard: '',
                sales_department_uuid: '',
                adviser_uuid: '',
                uuid: data?.uuid || '',
              }
        }
        onSubmit={handleFormSubmit}
        validationSchema={getFormSchema(loggedUserRole)}
      >
        {({ handleSubmit, errors, values, setFieldValue }) => (
          <>
            <form className="modal__form form_investor" onSubmit={handleSubmit}>
              {formStep === FORM_STEPS.BASIC && (
                <BasicInformationForm
                  formType={type}
                  handleAdviserSelected={handleAdviserSelected}
                  returnRoleOptions={returnRoleOptions}
                  errors={errors}
                  usersDdi={usersDdi}
                  loggedUserRole={loggedUserRole}
                  handleFileChange={handleFileChange}
                  data={values}
                  setFileInputValue={setFileInputValue}
                  setFieldValue={setFieldValue}
                  fileInputValue={fileInputValue}
                  isDdi={isDdi}
                  adviserOptions={adviserOptions}
                />
              )}
              {formStep === FORM_STEPS.DETAILS && <DetailedInformationForm errors={errors} values={values} />}
              <ModalActions
                handleSubmit={() => {
                  errors && setFormStep(FORM_STEPS.BASIC);
                  handleSubmit();
                }}
                onClose={onClose}
                type={MODAL_TYPES.CONFIRM}
              />
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

AddInvestorForm.defaultProps = {
  data: null,
};

AddInvestorForm.propTypes = {
  data: PropTypes.object,
  ddiOptions: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  getAllDdi: PropTypes.func.isRequired,
  getDdiOptions: PropTypes.func.isRequired,
  getEmployees: PropTypes.func.isRequired,
  getPartnersOptions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  partnersOptions: PropTypes.array.isRequired,
  type: PropTypes.oneOf([FORM_TYPES.ADD, FORM_TYPES.EDIT]).isRequired,
  userRole: PropTypes.string.isRequired,
  usersDdi: PropTypes.array.isRequired,
};

export default AddInvestorForm;
