import * as Connector from 'shared/connectors/settlementConnector';
import { SETTLEMENTS } from 'store/types';

const init = () => ({
  type: SETTLEMENTS.GET_PLOT_DETAILS_INIT,
});

export const success = (uuid, plots) => ({
  type: SETTLEMENTS.GET_PLOT_DETAILS_SUCCESS,
  payload: {
    plots,
    uuid,
  },
});

const failure = () => ({
  type: SETTLEMENTS.GET_PLOT_DETAILS_FAILURE,
});

const getPlotDetails = plotUuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getPlotDetails(plotUuid);
    dispatch(success(plotUuid, data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getPlotDetails;
