import * as Connector from 'shared/connectors/investorsConnector';
import { INVESTOR } from 'store/types';

const init = () => ({
  type: INVESTOR.GET_INVESTOR_INIT,
});

export const success = data => ({
  type: INVESTOR.GET_INVESTOR_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: INVESTOR.GET_INVESTOR_FAILURE,
});

const getInvestorsByFilter = uuid => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getInvestorsByFilter(uuid);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getInvestorsByFilter;
