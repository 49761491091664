/* eslint-disable react/prop-types */
import * as React from 'react';
import { Field } from 'formik';
import { RegularField } from 'shared/components';
// import { SVG_TYPE } from 'shared/consts';

const BasicInformationForm = ({
  errors,
  // handleFileChange,
  // data,
  // setFileInputValue,
  // setFieldValue,
  // fileInputValue,
}) => (
  <>
    <Field component={RegularField} errors={errors} fullWidth label="Nazwa osady" name="name" type="text" />
    <Field
      component={RegularField}
      errors={errors}
      fullWidth
      label="Część wspólna dla osady- cena"
      name="joint_payment"
      onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
      type="number"
    />
    {/* <p className="select-field__label">Wymagane pliki </p>
    {data.investorCard && !fileInputValue ? (
      <div className="active-window__files">
        <div className="active-window__files--container">
          <SVG type={SVG_TYPE.LIGHT_FILE} />
        </div>
        <span
          onClick={() => {
            setFileInputValue('');
            setFieldValue('investorCard', '');
          }}
        >
          <SVG type={SVG_TYPE.EXIT} />
        </span>
      </div>
    ) : (
      <Field
        component={FieldFileInput}
        handleChange={handleFileChange}
        errors={errors}
        name="file"
        type="file"
        label="+ Dodaj zdjęcie dla osady"
      />
    )} */}
  </>
);
export default BasicInformationForm;
