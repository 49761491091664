import API from 'shared/connectors/config';

export function getUsers(role = false) {
  return API.get(`users${role ? `?role=${role}` : ''}`);
}

export function getUsersAmount() {
  return API.get(`users/amount`);
}

export function getActiveUser(uuid) {
  return API.get(`users/${uuid}`);
}

export function addUser(data) {
  return API.post(`users`, data);
}

export function editUser(data) {
  return API.put(`users/${data.uuid}`, data);
}

export function deleteUser(data) {
  return API.delete(`users/${data.uuid}`);
}

export function activateAccount(data) {
  return API.post(`users/activate?token=${JSON.parse(sessionStorage.getItem('activateToken'))}`, data);
}

export function sendResetPasswordEmail(email) {
  return API.post(`/users/send/change_password/token`, email);
}

export function setNewPassword(data) {
  return API.post(`/users/change_password?reset_token=${JSON.parse(sessionStorage.getItem('resetToken'))}`, data);
}

export function resendActivationEmail(uuid) {
  return API.post(`/users/resend/activate/token`, { uuid });
}

export function getDdiOptions(withPartnersOnly) {
  return API.get(`sales_department?partners=${withPartnersOnly ? 'true' : 'false'}`);
}

export function getArchivedEmployees() {
  return API.get(`users/list/deleted`);
}

export function getArchivedEmployee(uuid) {
  return API.get(`users/show/deleted/${uuid}`);
}

export function restoreEmployee(uuid) {
  return API.post(`users/restore`, { user_uuid: uuid });
}

export function getAllDdi() {
  return API.get(`sales_department`);
}

export function getAdvisersList(uuid) {
  return API.get(`advisers/list?uuid=${uuid}`);
}
