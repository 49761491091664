import React from 'react';
import { array, object, string, func, bool } from 'prop-types';
import { ActiveWindow } from 'shared/components';
import { USER_ROLES } from 'shared/consts';
import { PARTNER_SUBMENU } from 'shared/components/active-window/consts';
import PartnerName from './PartnerName';
import Ddi from '../ddi/Ddi';
import Adviser from '../adviser/Adviser';
import PartnerData from '../partner-data/PartnerData';

const PartnerByUuid = ({
  setActiveMenu,
  activeMenu,
  archived,
  partner,
  bonusesOptions,
  editPartner,
  deletePartner,
  getAttachedDdis,
  getAttachedAdvisers,
  attachedDdis,
  attachedAdvisers,
  attachedDdisStatus,
  getDdiOptions,
  ddiOptions,
  attachDdiToPartner,
  authorizedUserRole,
  restorePartner,
}) => {
  const submenuArr = [
    PARTNER_SUBMENU.DATA,
    PARTNER_SUBMENU.ADVISER,
    ...[authorizedUserRole === USER_ROLES.ADMIN && PARTNER_SUBMENU.DDI],
  ];
  return (
    <ActiveWindow.Wrapper>
      <ActiveWindow.Header>
        <PartnerName name={partner.name} />
        <ActiveWindow.SubMenu menu={submenuArr} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      </ActiveWindow.Header>
      <ActiveWindow.Content>
        {activeMenu === PARTNER_SUBMENU.DATA && (
          <PartnerData
            archived={archived}
            data={partner}
            bonusesOptions={bonusesOptions}
            editPartner={editPartner}
            deletePartner={deletePartner}
            restorePartner={restorePartner}
          />
        )}
        {activeMenu === PARTNER_SUBMENU.DDI && authorizedUserRole === USER_ROLES.ADMIN && (
          <Ddi
            data={partner}
            bonusesOptions={bonusesOptions}
            getAttachedDdis={getAttachedDdis}
            attachedDdis={attachedDdis}
            attachedDdisStatus={attachedDdisStatus}
            getDdiOptions={getDdiOptions}
            ddiOptions={ddiOptions}
            attachDdiToPartner={attachDdiToPartner}
            authorizedUserRole={authorizedUserRole}
          />
        )}
        {activeMenu === PARTNER_SUBMENU.ADVISER && (
          <Adviser
            data={partner}
            authorizedUserRole={authorizedUserRole}
            getAttachedAdvisers={getAttachedAdvisers}
            attachedAdvisers={attachedAdvisers}
          />
        )}
      </ActiveWindow.Content>
    </ActiveWindow.Wrapper>
  );
};

PartnerByUuid.defaultProps = {
  archived: false,
};

PartnerByUuid.propTypes = {
  activeMenu: string.isRequired,
  archived: bool,
  attachDdiToPartner: func.isRequired,
  attachedAdvisers: array.isRequired,
  attachedDdis: array.isRequired,
  attachedDdisStatus: string.isRequired,
  authorizedUserRole: string.isRequired,
  bonusesOptions: array.isRequired,
  ddiOptions: array.isRequired,
  deletePartner: func.isRequired,
  editPartner: func.isRequired,
  getAttachedAdvisers: func.isRequired,
  getAttachedDdis: func.isRequired,
  getDdiOptions: func.isRequired,
  partner: object.isRequired,
  restorePartner: func.isRequired,
  setActiveMenu: func.isRequired,
};
export default PartnerByUuid;
