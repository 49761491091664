import { ROUTES, USER_ROLES, EMPLOYEE_SHORTCUTS } from 'shared/consts';

const {
  EMPLOYEES_DDI,
  EMPLOYEES_ADVISER,
  EMPLOYEES_ACCOUNTING,
  EMPLOYEES_ADMINISTRATION,
  EMPLOYEES_MARKETING,
  EMPLOYEES_ADMIN,
  EMPLOYEES_DIR,
  EMPLOYEES_DP,
} = ROUTES;

const EMPLOYEES_ROUTES_ARR = [
  {
    path: EMPLOYEES_DDI,
    shortcut: EMPLOYEE_SHORTCUTS.DDI,
    userRole: USER_ROLES.DDI,
  },
  {
    path: EMPLOYEES_ADVISER,
    shortcut: EMPLOYEE_SHORTCUTS.ADVISER,
    userRole: USER_ROLES.ADVISER,
  },
  {
    path: EMPLOYEES_ACCOUNTING,
    shortcut: EMPLOYEE_SHORTCUTS.ACCOUNTING,
    userRole: USER_ROLES.ACCOUNTING,
  },
  {
    path: EMPLOYEES_ADMINISTRATION,
    shortcut: EMPLOYEE_SHORTCUTS.ADMINISTRATION,
    userRole: USER_ROLES.ADMINISTRATION,
  },
  {
    path: EMPLOYEES_MARKETING,
    shortcut: EMPLOYEE_SHORTCUTS.MARKETING,
    userRole: USER_ROLES.MARKETING,
  },
  {
    path: EMPLOYEES_ADMIN,
    shortcut: EMPLOYEE_SHORTCUTS.ADMIN,
    userRole: USER_ROLES.ADMIN,
  },
  {
    path: EMPLOYEES_DIR,
    shortcut: EMPLOYEE_SHORTCUTS.DIR,
    userRole: USER_ROLES.DIR,
  },
  {
    path: EMPLOYEES_DP,
    shortcut: EMPLOYEE_SHORTCUTS.DP,
    userRole: USER_ROLES.DP,
  },
];

export default EMPLOYEES_ROUTES_ARR;
