import { VIEW_MANAGEMENT } from 'store/types';

const INITIAL_STATE = {
  isHomePagePreloaderVisible: false,
  isPreloaderVisible: false,
  prevPath: '',
  isNotificationsDialogVisible: false,
};

const viewManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIEW_MANAGEMENT.SHOW_HOME_PAGE_PRELOADER:
      return {
        ...state,
        isHomePagePreloaderVisible: true,
      };
    case VIEW_MANAGEMENT.HIDE_HOME_PAGE_PRELOADER:
      return {
        ...state,
        isHomePagePreloaderVisible: false,
      };
    case VIEW_MANAGEMENT.SET_PREV_PATH:
      return {
        ...state,
        prevPath: action.payload.data,
      };
    case VIEW_MANAGEMENT.SHOW_PRELOADER:
      return {
        ...state,
        isPreloaderVisible: true,
      };
    case VIEW_MANAGEMENT.HIDE_PRELOADER:
      return {
        ...state,
        isPreloaderVisible: false,
      };
    case VIEW_MANAGEMENT.MANAGE_NOTIFICATION_DIALOG:
      return {
        ...state,
        isNotificationsDialogVisible: !state.isNotificationsDialogVisible,
      };
    case VIEW_MANAGEMENT.SHOW_NOTIFICATION_DIALOG:
      return {
        ...state,
        isNotificationsDialogVisible: true,
      };
    default:
      return state;
  }
};

export default viewManagementReducer;
