import React from 'react';
import * as Connector from 'shared/connectors/partnerConnector';
import { PARTNER } from 'store/types';
import { toast } from 'react-toastify';
import { NewUser } from 'shared/components';
import getAttachedDdis from './getAttachedDdis';
import { showPreloader, hidePreloader } from '../viewManagement';

const init = () => ({
  type: PARTNER.ATTACH_DDI_TO_PARTNER_INIT,
});

export const success = data => ({
  type: PARTNER.ATTACH_DDI_TO_PARTNER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PARTNER.ATTACH_DDI_TO_PARTNER_FAILURE,
});

const attachDdiToPartner = ({ uuid, name }, ddiList) => async dispatch => {
  dispatch(init());
  try {
    dispatch(showPreloader());
    await Connector.attachDdiToPartner(uuid, ddiList);
    dispatch(getAttachedDdis(uuid));
    await toast(<NewUser badgeType="Partner" first_name={name} label="Przypisano DDI" />);
    dispatch(success());
    dispatch(hidePreloader());
    return null;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(hidePreloader());
    return err.response.data;
  }
};

export default attachDdiToPartner;
