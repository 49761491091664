import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions, SelectField, OptionsLabel } from 'shared/components';
import { MODAL_TYPES, USER_ROLES, EMPLOYEE_SHORTCUTS, VALIDATION_MESSAGES } from 'shared/consts';
import { isMobile } from 'shared/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import { Header, SelectOfert } from './components';

const ReservationModal = ({
  onClose,
  opened,
  onSubmit,
  children,
  title,
  plot,
  loggedUserRole,
  getUsers,
  ddiUsers,
  investorsOptions,
  getInvestors,
  acceptedOffers,
  getAcceptedOffers,
  getAdvisersList,
  // advisersList,
}) => {
  // const [test, setTest] = useState(false);
  const DDI_USER_OPTIONS = OptionsLabel(ddiUsers, EMPLOYEE_SHORTCUTS.DDI);
  const INVESTOR_OPTIONS = OptionsLabel(investorsOptions, 'INWST');
  // const ADVISERS_OPTIONS = OptionsLabel(advisersList, EMPLOYEE_SHORTCUTS.ADVISER);
  const isMobileDevice = isMobile();
  useEffect(async () => {
    await getUsers(USER_ROLES.DDI);
    await getAdvisersList(loggedUserRole.uuid);
    loggedUserRole.role !== USER_ROLES.ADMIN && (await getInvestors(loggedUserRole.uuid));
  }, [opened]);
  const [offers, setOffers] = useState(false);

  const handleAdviserSelected = async pickedUser => {
    setOffers(false);
    loggedUserRole.role === USER_ROLES.ADMIN &&
      pickedUser &&
      (await getInvestors({ id: pickedUser.value, full_data: 1 }),
      getAcceptedOffers({ userRole: loggedUserRole.role, plotUuid: plot.uuid, ddiUuid: pickedUser.value }));
  };

  const getFormSchema = () =>
    object().shape({
      investor_uuid: string().required(VALIDATION_MESSAGES.REQUIRED_FIELD),
    });

  const ddiUuid = loggedUserRole?.role === USER_ROLES.DDI;

  const offerSelected = ({ value }) => {
    setOffers(acceptedOffers?.data?.filter(({ investor: { uuid } }) => uuid === value));
  };
  return (
    <TransitionGroup component={null}>
      {opened && (
        <CSSTransition classNames="modal__transition" timeout={isMobileDevice ? 300 : 0}>
          <Modal onClose={onClose} opened={opened} title={title} type={MODAL_TYPES.RESERVATION}>
            {children}
            {plot && (
              <Formik
                enableReinitialize
                initialValues={{
                  offer_uuid: '',
                  investor_uuid: '',
                  adviser_uuid: '',
                  sell_status: 'pre_booking',
                  ddi_uuid: ddiUuid ? loggedUserRole.uuid : '',
                  order_status: plot?.order_status?.uuid,
                }}
                onSubmit={values => {
                  onSubmit(values, plot?.uuid);
                }}
                validationSchema={getFormSchema(loggedUserRole)}
              >
                {({ handleSubmit, errors, values, setFieldValue }) => (
                  <form className="modal__form" onSubmit={handleSubmit}>
                    <Header plot={plot} />
                    {(loggedUserRole.role === USER_ROLES.ADMIN || loggedUserRole === USER_ROLES.ADMIN) && (
                      <Field
                        component={SelectField}
                        errors={errors}
                        fullWidth
                        isCustomLabel
                        label="Wybierz DDI"
                        name="ddi_uuid"
                        options={DDI_USER_OPTIONS}
                        placeholder="Wybierz sprzedawce"
                        onChange={handleAdviserSelected}
                      />
                    )}
                    <Field
                      component={SelectField}
                      errors={errors}
                      fullWidth
                      isCustomLabel
                      onChange={value => offerSelected(value)}
                      label="Rezerwuj na"
                      name="investor_uuid"
                      options={INVESTOR_OPTIONS}
                      placeholder="Wybierz inwestora"
                    />
                    {/* <Field
                      component={SelectField}
                      errors={errors}
                      fullWidth
                      isCustomLabel
                      label="Wybierz doradcę"
                      name="adviser_uuid"
                      options={ADVISERS_OPTIONS}
                      placeholder="Wybierz doradcę (opcjonalnie)"
                    /> */}
                    {offers && <SelectOfert acceptedOffers={offers} values={values} setFieldValue={setFieldValue} />}
                    <p className="reservationModal--footer">
                      Klient ma 3 dni na dostarczenie danych do umowy. <br />
                      Po tym czasie rezerwacja może zostać zdjęta.
                    </p>
                    <ModalActions handleSubmit={handleSubmit} onClose={onClose} type={MODAL_TYPES.RESERVATION} />
                  </form>
                )}
              </Formik>
            )}
          </Modal>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

ReservationModal.propTypes = {
  acceptedOffers: PropTypes.array.isRequired,
  advisersList: PropTypes.array.isRequired,
  authorizedUser: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  ddiUsers: PropTypes.array.isRequired,
  getAcceptedOffers: PropTypes.func.isRequired,
  getAdvisersList: PropTypes.func.isRequired,
  getInvestors: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  investorsOptions: PropTypes.array.isRequired,
  loggedUserRole: PropTypes.string.isRequired,
  modalOpened: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  plot: PropTypes.shape({
    order_status: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default ReservationModal;
