import React from 'react';
import { SVG, Badge } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import { array, bool, objectOf, string, number, func } from 'prop-types';
import { generatePath } from 'react-router';

const StatusesList = ({ title, counterClassName, arrow, list, history, authorizedUser: { role }, count, status }) => {
  const forwardToPlotsView = async () => {
    history.push(generatePath(ROUTES.SETTLEMENTS), { filter: status });
  };

  return (
    <>
      <div className="list__header">
        <div className="list__header--item">
          <div className={`list__header--counter ${counterClassName}`}>{count}</div>
          <div className="list__header--title">{title}</div>
        </div>
        <button type="button" className="list__header--show" onClick={forwardToPlotsView}>
          pokaz
        </button>
      </div>
      <div className="list__content">
        <ul>
          {list &&
            list?.map(listItem => (
              <li>
                {role === 'admin' ? (
                  <div
                    className="list__content--investor"
                    onClick={() => history.push(generatePath(ROUTES.INVESTORS_ID, { uuid: listItem?.investor?.uuid }))}
                  >
                    <Badge type="INWST" label="Inwst" />
                    <div className="list__content--name">{listItem?.investor?.last_name} </div>
                    <Badge type="DDI" label="DDI" />
                    <div className="list__content--name">{listItem?.ddi?.last_name} </div>
                  </div>
                ) : (
                  <div
                    className="list__content--investor"
                    onClick={() => history.push(generatePath(ROUTES.EMPLOYEES_DDI_UUID, { uuid: listItem?.ddi?.uuid }))}
                  >
                    <Badge type="DDI" label="DDI" />
                    <div className="list__content--name">{listItem?.ddi?.last_name}</div>
                  </div>
                )}
                <div className={`list__content${arrow ? '--arrow' : ''}`}>
                  <span
                    className="list__content--settlement"
                    onClick={() => history.push(generatePath(ROUTES.SETTLEMENTS_PLOT_ID, { uuid: listItem?.plot.uuid }))}
                  >
                    <span className="list__content--settlement--name">{listItem?.plot.settlement.name}</span>
                    <SVG type={SVG_TYPE.BLUE_HOME} className="blue_home" />
                    <span className="notificationsRight__plot list__content--plot">{listItem?.plot.name}</span>
                  </span>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};
export default StatusesList;

StatusesList.propTypes = {
  arrow: bool.isRequired,
  authorizedUser: objectOf.isRequired,
  count: number.isRequired,
  counterClassName: string.isRequired,
  getPlots: func.isRequired,
  list: array.isRequired,
  status: string.isRequired,
  title: string.isRequired,
};
